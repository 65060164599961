import React, { useState, useEffect } from 'react';
import { sspServiceStatusEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../utils';

export default (props) => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
    ]);
    return (
        <div>
            {isEmpty(props.value) ? "" : t(`${"configurationEnumerations:" + sspServiceStatusEnum[props.value]}`)}
        </div >
    )
}