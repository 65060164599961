import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sspActivationStatusEnum } from '../../../utils/enums';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));

const AccountStatus = props => {
    const { t } = useTranslation([
                "translation",
                "configurationEnumerations",
                "dmTool"
            ]);
    const { institution, onChageInstitution } = props;
    const classes = useStyles();
    const _onChangeAccountStatus = (e, value) => {
        onChageInstitution('institutionAccountStatus', value.value)
    }

    const accountStatus = Object.keys(sspActivationStatusEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspActivationStatusEnum[opt]}`)
        };
    });

    return (
        <React.Fragment>
            <Autocomplete
                disableClearable
                fullWidth
                size="small"
                className={classes.autocompleteStyle}
                getOptionLabel={option => option.label}
                id="legalForm_type"
                margin="normal"
                onChange={_onChangeAccountStatus}
                options={accountStatus}
                value={
                    accountStatus.find(
                        item =>
                            item.value ===
                            institution?.institutionAccountStatus,
                    ) || {}
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        label={t(`${"dmTool:accStatusInputFieldLabel"}`)}
                        placeholder={t(`${"dmTool:selectAccStatusPlcHldr"}`)}
                        variant="outlined"
                        style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </React.Fragment>
    );
};

export default AccountStatus;