import React, { useEffect, useState } from "react"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import languages from "./languages"

const useStyles = makeStyles(theme => ({
}));

const UILanguageSelector = (props) => {

    const classes = useStyles();
    const { t } = useTranslation([
        "translation",
        "dmTool",
        "languageEnumerations"
    ])
    const { value, selectedValues } = props
    const [languageOptions, setLanguageOptions] = useState([]);

    useEffect(() => {
        let keys = selectedValues?.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!!selectedValues?.length && !!keys.length) {
            let langs = [];
            let filterLang = Object.keys(languages).map((lang) => {
                if (!keys?.includes(lang)) {
                    langs.push(lang);
                }
            });
            setLanguageOptions(langs?.map(item => {
                return {
                    value: item,
                    label: `${item.toLocaleUpperCase()} - ${t("languageEnumerations:" + item)}`
                }
            }))
        } else {
            setLanguageOptions(Object.keys(languages).map(item => {
                return {
                    value: item,
                    label: `${item.toLocaleUpperCase()} - ${t("languageEnumerations:" + item)}`
                }
            }));
        }
    }, [selectedValues])

    const selectedLanguages = Object.keys(languages).map(item => {
        return {
            value: item,
            label: `${item.toLocaleUpperCase()} - ${t("languageEnumerations:" + item)}`
        }
    })

    return (
        <>
            <Autocomplete
                disableClearable
                fullWidth
                size="small"
                className={classes.autocompleteStyle}
                getOptionLabel={option => option.label}
                getOptionSelected={(option, value) => value.value === option.value}
                groupBy={(option) => (option.value === "en" || option.value === "de") ? t("languageUsedByCommunity") : t("allLanguages")}
                id="institution-description-key"
                margin="normal"
                onChange={(e, value) => props.onChange(e, value)}
                options={languageOptions}
                value={
                    selectedLanguages.find(
                        item =>
                            item.value === value,
                    ) || {}
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                        placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                        variant="outlined"
                        style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </>
    )
}

export default UILanguageSelector