import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { sspLanguageEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import { isEmpty } from "../../utils";
import UILanguageSelector from '../Languages';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const OtherServiceOffingDialog = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { open, apiCallProgress } = props;
    const classes = useStyles();

    const [otherServiceOffing, setotherServiceOffing] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (!!props.OtherServiceOffing.length) {
            setotherServiceOffing(props.OtherServiceOffing)
        } else {
            setotherServiceOffing([{
                key: '',
                value: ''
            }]
            )
        }
    }, [props.OtherServiceOffing])


    useEffect(() => {
        let keys = otherServiceOffing?.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!!otherServiceOffing?.length && !!keys.length) {
            let langs = [];
            let filterLang = sspLanguageEnum.map((lang) => {
                if (!keys?.includes(lang.value)) {
                    langs.push(lang);
                }
            });
            setLanguageOptions(langs)
        } else {
            setLanguageOptions(sspLanguageEnum);
        }
    }, [otherServiceOffing])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(otherServiceOffing)
    };

    const OnChangeOtherServiceOffingValue = (e, index) => {
        let _otherServiceOffing = otherServiceOffing
        _otherServiceOffing[index].value = e.target.value;
        addUpdateOrDeleteOtherServiceOffing(_otherServiceOffing, 'update')
    }

    const OnChangeOtherServiceOffingKey = (e, value, index) => {
        let _otherServiceOffing = otherServiceOffing
        _otherServiceOffing[index].key = value.value
        addUpdateOrDeleteOtherServiceOffing(_otherServiceOffing, 'update')
    }

    const addCopanyFocusSector = (e) => {
        addUpdateOrDeleteOtherServiceOffing({
            key: '',
            value: '',
        }, 'add')
    }

    const addUpdateOrDeleteOtherServiceOffing = (object, type) => {
        if (type == 'add') {
            setotherServiceOffing((_otherServiceOffing) => {
                return ([..._otherServiceOffing, object])

            })
        }
        if (type == 'delete') {
            setotherServiceOffing((_otherServiceOffing) => {
                return [..._otherServiceOffing.filter((item, index) => index != object)]
            })
        }
        if (type == 'update') {
            setotherServiceOffing((_otherServiceOffing) => {
                return [...object]
            })
        }
    }
    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="other_Service_Offing_dialog_title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="other-Service-Offing-dialog-title">
                        {!!props.OtherServiceOffing.length
                            ? t("dmTool:updateOtherServiceOfferingDialogHdng", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addOtherServiceOfferingDialogHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        {!!otherServiceOffing.length && otherServiceOffing.map((_otherServiceOffing, index) => {
                            return (<React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item lg={4} sm={4} xs={12}>
                                        {/* <Autocomplete
                                            disableClearable
                                            fullWidth
                                            size="small"
                                            className={classes.autocompleteStyle}
                                            getOptionLabel={option => option.label}
                                            id="other-service-offering-key"
                                            margin="normal"
                                            onChange={(e, value) => OnChangeOtherServiceOffingKey(e, value, index)}
                                            options={languageOptions}
                                            value={
                                                sspLanguageEnum.find(
                                                    item =>
                                                        item.value ===
                                                        _otherServiceOffing?.key,
                                                ) || {}
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={t(`${"dmTool:languageInputFiledHdng"}`)+"*"}
                                                    placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        /> */}
                                        <UILanguageSelector
                                            selectedValues={otherServiceOffing}
                                            label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                                            placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                            value={_otherServiceOffing?.key}
                                            onChange={(e, value) => {
                                                OnChangeOtherServiceOffingKey(e, value, index)
                                            }
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={6} xs={12}>
                                        <TextField
                                            className={classes.marginSet}
                                            fullWidth
                                            label={t(`${"dmTool:otherSeviceOfferingInputFieldLabel"}`)}
                                            placeholder={t(`${"dmTool:enterOtherSeviceOfferingPlcHldr"}`)}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            onChange={(e) => OnChangeOtherServiceOffingValue(e, index)}
                                            size='small'
                                            required={false}
                                            value={_otherServiceOffing?.value}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />

                                    </Grid>
                                    {index == 0 && <Grid item lg={1} sm={1} xs={12}>
                                        <Tooltip title={t(`${"dmTool:addOtherServiceOfferingTooltip"}`)}>
                                            <IconButton aria-label="add"
                                                color="secondary"
                                                variant="contained"
                                                onClick={addCopanyFocusSector}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>}
                                    <Grid item lg={1} sm={1} xs={12}>
                                        <IconButton
                                            key={'delete'}
                                            onClick={(e) => addUpdateOrDeleteOtherServiceOffing(index, 'delete')}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </React.Fragment>)
                        })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress || otherServiceOffing?.some((vid) => !isEmpty(vid.value) && isEmpty(vid.key))}
                            color='primary'
                            className={classes.addButton}
                        >
                            {!!props.OtherServiceOffing.length ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default OtherServiceOffingDialog;