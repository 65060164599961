import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { sspLanguageEnum } from '../../utils/enums';
import CircularProgress from '@material-ui/core/CircularProgress';
import Warning from '@material-ui/icons/Warning';
import Verified from '@material-ui/icons/VerifiedUser';
import { isEmpty } from '../../utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    },
    circular_progress: {
        marginRight: "auto",
        display: "flex"
    },
    uniqueness_msg: {
        margin: "0 0 0 5px",
        alignSelf: "center"
    }
}));


const AccountNameDialog = props => {
    const { open, apiCallProgress } = props;
    const [enitityObj, setEntityObj] = useState({});
    const classes = useStyles();
    useEffect(() => {
        setEntityObj(props.entity)
    }, [])
    const [accountName, setAccountName] = useState(props.entity?.Entity?.accountName)
    const [loader, setLoader] = useState(false)
    const [warning, setWarning] = useState(false)
    const [uniqueMsg, setUniqueMsg] = useState("")

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(accountName)
    };
    var previousXhrRequest = {};

    const OnChangeAccountName = (e) => {
        if (!isEmpty(e.target.value) && (e.target.value !== props.entity?.Entity?.accountName)) {
            if (previousXhrRequest.cancel) {
                previousXhrRequest.cancel();
                previousXhrRequest.cancel = undefined;
            }
            _checkUniqueNess(e.target.value)
        }

        setAccountName(e.target.value)
    }

    const _checkUniqueNess = (data) => {
        const httpRequestAccountNameRequest = new XMLHttpRequest();
        setLoader(true)
        setWarning(false)
        httpRequestAccountNameRequest.open('POST', process.env.PUBLIC_URL + `/accountname/unique`);
        httpRequestAccountNameRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequestAccountNameRequest.send(JSON.stringify({
            "accountName": data,
            "institutionJurisdiction": props.entity?.Entity.institutionJurisdiction,
            "i_sourceKey": props.entity?.Entity.i_sourceKey,
            "environment": props.entity.UploaderMetaData[0].environment
        }));
        return new Promise(function (resolve, reject) {
            httpRequestAccountNameRequest.onreadystatechange = () => {
                if (httpRequestAccountNameRequest.readyState === 4 && httpRequestAccountNameRequest.status === 200) {
                    setLoader(false)
                    let res = JSON.parse(httpRequestAccountNameRequest.responseText);
                    setWarning(!res.data.unique)
                    setUniqueMsg(res.data.environment)
                    resolve()
                }
            };
            previousXhrRequest.cancel = function () {  // SPECIFY CANCELLATION
                httpRequestAccountNameRequest.abort(); // abort request
                reject("cancelled previous request!!"); // reject the promise
            };
            httpRequestAccountNameRequest.onerror = reject;
        });
    }

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="account-name-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="account-name-dialog-title">
                        {t(`${"dmTool:updateAccNameDialogHdng"}`)}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <form className={classes.root} noValidate autoComplete="off">
                            <TextField
                                className={classes.marginSet}
                                fullWidth
                                label={t("dmTool:accNameDialogInputfieldLabel")}
                                placeholder={t(`${"dmTool:enterAccNamePlcHldr"}`)}
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                                onChange={(e) => OnChangeAccountName(e)}
                                size='small'
                                required={false}
                                value={accountName}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.circular_progress}>
                            {accountName && uniqueMsg && loader && <CircularProgress size={20} />}
                            {accountName && uniqueMsg && !loader && (warning ? <Warning style={{ color: "red" }} />
                                : <Verified style={{ color: "green" }} />)}
                            <div className={classes.uniqueness_msg}>
                                {accountName && loader && t(`${"dmTool:checkingAccUniquenessMsg"}`)}
                                {accountName && uniqueMsg && !loader && (warning ? t("dmTool:accIsNotUniqueMsg", { environment: uniqueMsg })
                                    : t("dmTool:uniqueAccMsg", { environment: uniqueMsg }))}
                            </div>
                        </div>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={(!isEmpty(accountName) || (!loader || !warning)) ? onClickSaveOrUpdate : ""}
                            variant="contained"
                            disabled={apiCallProgress}
                            color={(isEmpty(accountName) || loader || warning || (props.entity?.Entity?.accountName == accountName)) ? 'disabled' : 'primary'}
                            className={classes.addButton}
                        >
                            {t(`${"dmTool:updateBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default AccountNameDialog;