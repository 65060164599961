import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import { sspLanguageEnum } from '../../../utils/enums';
import UILanguageSelector from '../../Languages';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({

}));

const ServiceDescription = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { supplierInfo, addUpdateOrDeleteServiceDescription } = props;
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [languageOptions, setLanguageOptions] = useState([]);

    useEffect(() => {
        let keys = props.supplierInfo.serviceDescription.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!!props.supplierInfo.serviceDescription.length && !!keys.length) {
            let langs = [];
            let filterLang = sspLanguageEnum.map((lang) => {
                if (!keys?.includes(lang.value)) {
                    langs.push(lang);
                }
            });
            setLanguageOptions(langs)
        } else {
            setLanguageOptions(sspLanguageEnum);
        }
    }, [props.supplierInfo.serviceDescription])


    const _changeLanguage = (e, value, index) => {
        let serviceDescription = props.supplierInfo.serviceDescription
        serviceDescription[index].key = value.value;
        addUpdateOrDeleteServiceDescription(serviceDescription, 'update')
    }

    const _changeValue = (e, index) => {
        let serviceDescription = props.supplierInfo.serviceDescription
        serviceDescription[index].value = e.target.value;
        addUpdateOrDeleteServiceDescription(serviceDescription, 'update')
    }
    const addServiceDescription = (e) => {
        addUpdateOrDeleteServiceDescription({
            key: '',
            value: ''
        }, 'add')

    }


    const deleteOtherOfferings = (e, index) => {
        addUpdateOrDeleteServiceDescription(
            index,
            'delete',
        );
        enqueueSnackbar('Supplier other offering  removed successfully', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });
    };


    const renderAddCloseIcon = (index) => {
        return (
            <Tooltip title="Remove milestone">
                <IconButton
                    aria-label="remove"
                    onClick={(e) => deleteOtherOfferings(e, index)}
                >
                    <CloseIcon className={classes.icons} />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <>
            <Tooltip title="Add Other services">
                <IconButton
                    aria-label="add"
                    // onClick={confirm((e) => deleteOtherOfferings(milestone), {
                    //     title: 'Information',
                    //     confirmationText: 'Remove',
                    //     disableOkBtn: false,
                    //     description:
                    //         'Are you that you want to remove this ServiceDescription?',
                    // })}
                    onClick={addServiceDescription}
                >
                    <AddIcon className={classes.icons} />
                </IconButton>
            </Tooltip>
            {supplierInfo?.serviceDescription?.map((titleObj, index) => {
                return (
                    <Grid container lg={12} sm={12} xs={12} >
                        <Grid item lg={4} sm={4} xs={12}>
                            {/* <Autocomplete
                                disableClearable
                                size="small"
                                fullWidth
                                className={classes.autocompleteStyle}
                                getOptionLabel={option => option.label}
                                id="milestone_select_lang"
                                margin="normal"
                                onChange={(e, value) => _changeLanguage(e, value, index)}
                                options={languageOptions}
                                value={
                                    sspLanguageEnum.find(
                                        item =>
                                            item.value ===
                                            titleObj?.key,
                                    ) || {}
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select language"
                                        placeholder="Select language"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            /> */}
                            <UILanguageSelector
                                selectedValues={supplierInfo?.serviceDescription}
                                label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                                placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                value={titleObj?.key}
                                onChange={(e, value) => {
                                    _changeLanguage(e, value, index)
                                }
                                }
                            />
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                            <TextField
                                className={classes.marginSet}
                                fullWidth
                                label='Enter the milestone title'
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                                placeholder='Enter the milestone title'
                                onChange={(e) => _changeValue(e, index)}
                                size='small'
                                required={false}
                                value={titleObj?.value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item lg={2} sm={2} xs={12}>
                            {renderAddCloseIcon(index)}
                        </Grid>
                    </Grid>
                )
            })}
        </>
    );
};

export default ServiceDescription;