import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
/*
Material -UI -Components
*/
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  MilestoneType,
  MileStoneTitle,
  MileStoneDate,
  MileStoneStatus,
  MileStoneDescription,
  MileStoneQualityFlag,
  MileStoneQualityComment,
} from "./Components";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../utils";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 700,
  },
  legend: {
    padding: 0,
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "-0.05px",
    color: "#888888",
  },
  fieldset: {
    border: "1px solid #eceded",
    padding: "10px",
    borderRadius: "4px",
  },
  addButton: {
    minWidth: 91,
  },
}));

const MilestoneDialog = (props) => {
  const { t } = useTranslation(["translation", "eventsEnumerations", "dmTool"]);
  const { open, apiCallProgress } = props;
  const classes = useStyles();

  const [mileStoneObj, setMileStoneObj] = useState({});
  const [enitityObj, setEntityObj] = useState({});

  useEffect(() => {
    setEntityObj(props.entity);
    if (props.milestone) {
      setMileStoneObj(props.milestone);
    } else {
      setMileStoneObj({
        name: "EntityEvent",
        i_sourceKey: null,
        i_t_qualitySourcePriorityLevel: "",
        i_t_lifecycleElement_status: "CYC_STATUS_ACTIVE",
        i_t_qualitySourceDataQualityEvaluation: "",
        i_t_qualitySourceDataQualityComment: "",
        i_t_researchComment: "",
        i_t_startupSpiderInternalComment: "",
        i_t_qualitySourceExpertQualityComment: "",
        milestoneType: "",
        eventDate: null,
        status: "",
        title: [
          {
            key: "",
            value: "",
          },
        ],
        description: [
          {
            key: "",
            value: "",
          },
        ],
        hashtags: [],
        originalSourceMessages: [],
      });
    }
  }, [props.milestone]);

  const closeModal = () => {
    const { onClose } = props;
    onClose && onClose();
  };

  const OnChageMileStoneObj = (key, value) => {
    setMileStoneObj({
      ...mileStoneObj,
      [`${key}`]: value,
    });
  };

  const onClickSaveOrUpdate = () => {
    let updatedObject = Object.assign({}, mileStoneObj);
    if (updatedObject?.i_sourceKey == null) {
      updatedObject.i_sourceKey =
        "ssp_entityevent_" +
        Date.now() +
        "_" +
        parseInt(Math.random() * 100000);
    }
    props.onClickAddOrUpdate(updatedObject);
  };

  const OnAddOrDeleteMileStoneObjTitle = (value, action) => {
    let _milestoneObj = Object.assign({}, mileStoneObj);

    if (action == "add") {
      _milestoneObj.title = [..._milestoneObj[`title`], value];
    } else {
      _milestoneObj.title = value;
    }
    setMileStoneObj(_milestoneObj);
  };

  const OnChangeMileStoneObjTitle = (key, value, index) => {
    let _milestoneObj = Object.assign({}, mileStoneObj);
    _milestoneObj.title[index] = {
      ..._milestoneObj.title[index],
      [`${key}`]: value,
    };
    setMileStoneObj(_milestoneObj);
  };

  const OnAddOrDeleteMileStoneObjDesc = (value, action) => {
    let _milestoneObj = Object.assign({}, mileStoneObj);

    if (action == "add") {
      _milestoneObj.description = [..._milestoneObj[`description`], value];
    } else {
      _milestoneObj.description = value;
    }
    setMileStoneObj(_milestoneObj);
  };

  const OnChangeMileStoneObjDesc = (key, value, index) => {
    let _milestoneObj = Object.assign({}, mileStoneObj);
    _milestoneObj.description[index] = {
      ..._milestoneObj.description[index],
      [`${key}`]: value,
    };
    setMileStoneObj(_milestoneObj);
  };

  return (
    <React.Fragment>
      {open && (
        <Dialog
          aria-labelledby="milestone-dialog-title"
          classes={{
            paperWidthSm: classes.dialog,
          }}
          disableBackdropClick
          onClose={closeModal}
          open={open}
        >
          <DialogTitle
            className={classes.title}
            disableTypography
            id="milestone-dialog-title"
          >
            {mileStoneObj?.i_sourceKey !== null
              ? t("dmTool:updateMilestoneDialogTitle", {
                entityName: enitityObj?.Entity?.accountName,
              })
              : t("dmTool:addMilestoneDiaogTitle", {
                entityName: enitityObj?.Entity?.accountName,
              })}
          </DialogTitle>
          {apiCallProgress && <LinearProgress />}
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item lg={6} sm={6} xs={12}>
                <MilestoneType
                  mileStoneObj={mileStoneObj}
                  OnChageMileStoneObj={OnChageMileStoneObj}
                />
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <MileStoneQualityFlag
                  mileStoneObj={mileStoneObj}
                  OnChageMileStoneObj={OnChageMileStoneObj}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:milestoneTitleSectionHdng"}`)}
                  </legend>
                  <Grid container spacing={1}>
                    <MileStoneTitle
                      mileStoneObj={mileStoneObj}
                      OnAddOrDeleteMileStoneObjTitle={
                        OnAddOrDeleteMileStoneObjTitle
                      }
                      OnChangeMileStoneObjTitle={OnChangeMileStoneObjTitle}
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:milestoneDescriptionSectionHdng"}`)}
                  </legend>
                  <Grid container spacing={1}>
                    <MileStoneDescription
                      mileStoneObj={mileStoneObj}
                      OnAddOrDeleteMileStoneObjDesc={
                        OnAddOrDeleteMileStoneObjDesc
                      }
                      OnChangeMileStoneObjDesc={OnChangeMileStoneObjDesc}
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:dateAndStatusSectionHdng"}`)}
                  </legend>
                  <Grid container spacing={1}>
                    <Grid item lg={6} sm={6} xs={12}>
                      <MileStoneDate
                        mileStoneObj={mileStoneObj}
                        OnChageMileStoneObj={OnChageMileStoneObj}
                      />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                      <MileStoneStatus
                        mileStoneObj={mileStoneObj}
                        OnChageMileStoneObj={OnChageMileStoneObj}
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={apiCallProgress}
              onClick={closeModal}
              variant="contained"
              color="primary"
            >
              {t(`${"dmTool:cancelBtn"}`)}
            </Button>
            <Button
              onClick={onClickSaveOrUpdate}
              variant="contained"
              disabled={
                apiCallProgress ||
                !Boolean(mileStoneObj.milestoneType) ||
                !Boolean(mileStoneObj.status) ||
                !Boolean(mileStoneObj.eventDate) ||
                !Boolean(mileStoneObj.i_t_qualitySourceDataQualityEvaluation) ||
                mileStoneObj.title?.filter(
                  (title) => !isEmpty(title.value) && isEmpty(title.key)
                ).length ||
                mileStoneObj.description?.some(
                  (desc) => !isEmpty(desc.value) && isEmpty(desc.key)
                )
              }
              color="primary"
              className={classes.addButton}
            >
              {mileStoneObj?.i_sourceKey !== null
                ? t(`${"dmTool:updateBtn"}`)
                : t(`${"dmTool:addBtn"}`)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default MilestoneDialog;
