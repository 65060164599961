import React, { useState, forwardRef, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Chip from '@material-ui/core/Chip';
import { isEmpty } from "../../utils";
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HashtagsSelectEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(isEmpty(props.data.Entity.hashtags) ? [] : props.data.Entity.hashtags);
    const [inputValue, setInputValue] = useState(props.value);
    const [hashtagsOptions, setHashtagOptions] = useState([]);
    const filter = createFilterOptions();    
    function updateApi() {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity['hashtags'] = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
    }


    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                updateApi();
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value)
            }
        };
    });


    const onChangeHandler = (e, newValue) => {
        if (typeof newValue === 'string' && newValue !== '' && newValue !== null) {
            if (!value.includes(newValue)) {
                setValue([...value, newValue]);
            }
            return;
        }
        else {
            if (newValue && newValue.inputValue) {
                if (!value.includes(newValue.inputValue) && newValue.inputValue != '') {
                    setValue([...value, newValue.inputValue]);
                }
                return;
            } else {
                setValue([...value, newValue]);
                if (!!newValue) {
                    if (!value.includes(newValue?.name) && newValue?.name != '') {
                        setValue([...value, newValue?.name]);
                    }
                }
            }
        }
    }


    return (
        <>
            <Autocomplete
                multiple
                freeSolo
                value={value.map((item) => {
                    return item;
                })}
                onChange={onChangeHandler}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== "") {
                        filtered.push({
                            inputValue: params.inputValue,
                            title: `Add "${params.inputValue}"`
                        });
                    }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                // options={hashtagsOptions}
                getOptionLabel={(option) => {
                    if (typeof option === "string") {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.title;
                }}
                renderOption={(option) => option.title}
                style={{ width: 300 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Free solo with text demo"
                        variant="outlined"
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} size="small" />
                    ))
                }
            />

        </>
    );
})



const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(HashtagsSelectEditor);

