// *https://www.registers.service.gov.uk/registers/country/use-the-api*
// import fetch from "cross-fetch";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useTranslation } from 'react-i18next';
const filter = createFilterOptions();
function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function HashtagAsyncSelector(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const [inputValue, setInputValue] = React.useState("");
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    const onChangeHandle = async (value) => {
        const apiData = { searchKey: value }
        const httpRequestAccountNameRequest = new XMLHttpRequest();
        httpRequestAccountNameRequest.open('POST', process.env.PUBLIC_URL + `/client-data/hashtags/search`);
        httpRequestAccountNameRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequestAccountNameRequest.send(JSON.stringify(apiData));

        // return new Promise(function (resolve, reject) {
        httpRequestAccountNameRequest.onreadystatechange = () => {
            if (httpRequestAccountNameRequest.readyState === 4 && httpRequestAccountNameRequest.status === 200) {
                // setLoading(false)
                let res = JSON.parse(httpRequestAccountNameRequest.responseText);
                // console.log("resres", res);
                // res.data.push({
                //     value: inputValue,
                //     name: `Add ${inputValue}`
                // });
                let options = res.data
                    .map((hashtag) => {
                        return {
                            value: hashtag.value,
                            name: hashtag.value
                        };
                    });
                options.push({
                    value: value,
                    name: `Add ${value}`
                })
                setOptions([
                    ...options
                        .filter(i => props.value.findIndex(v => v.value == i.value) == -1)
                ])
            }
        };
        // });

        // setOptions([
        //   {
        //     name: "india",
        //     value: "India"
        //   }
        // ]);
        // setOptions(Object.keys(countries).map((key) => countries[key].item[0]));
    };

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const _onChange = (...args) => {
        setInputValue("")
        props.onChange(...args)
    }
    return (
        <Autocomplete
            id="asynchronous-demo"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={_onChange}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option) => `#${option.value}`}
            options={options}
            loading={loading}
            inputValue={inputValue}
            // onInputChange={(event, newInputValue) => {
            //     setInputValue(newInputValue);
            //     if (event.target.value !== "" || event.target.value !== null) {
            //         onInputChange(event, newInputValue);
            //     }
            // }}
            value={props.value.map(item => {
                return {
                    ...item,
                    label: item.label
                }
            })}
            multiple
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t(`${"dmTool:hashtagsinputFieldLabel"}`)}
                    placeholder={t(`${"dmTool:enterHashtagsPlcHldr"}`)}
                    variant="outlined"
                    onChange={(ev) => {
                        setInputValue(ev.target.value);
                        if (ev.target.value !== "" || ev.target.value !== null) {
                            onChangeHandle(ev.target.value);
                        }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        // if (params.inputValue !== '') {
                        //     filtered.push({
                        //         value: params.inputValue,
                        //         label: `Add #${params.inputValue}`
                        //     });
                        // }
                        return filtered;
                    }}
                />
            )}
        />
    );
}
