import React from 'react';
import { Router, Route, Link } from 'react-router-dom';

import { history } from './Helpers';
import { authenticationService } from './Services';
import { PrivateRoute } from './Components/PrivateRoute';
import { EntityDataEntryPage } from './Pages/EntityDataEntryPage';
import { LoginPage } from './Pages/LoginPage';
import "./utils/date.format"
import Topbar from "./Components/Topbar"
import { ToastContainer } from 'react-toastify';
//datepicker
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import moment from "moment";
import "./App.css"
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { store } from "./Store/index";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }

    logout() {
        authenticationService.logout();
        history.push('/login');
    }

    render() {
        const { currentUser } = this.state;
        return (
            <Provider store={store}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <SnackbarProvider maxSnack={4}>
                        <Topbar currentUser={currentUser} />
                        <Router history={history}>
                            <PrivateRoute exact path="/data-entry-entity" component={EntityDataEntryPage} />
                            <PrivateRoute exact path="/" component={EntityDataEntryPage} />
                            <Route path="/login" component={LoginPage} />
                        </Router>
                        <ToastContainer
                            position="top-right"
                            autoClose={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                        />
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </Provider>
        );
    }
}

export default App;