import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { sspSocialPlatformEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const InstitutionSocialProfileURLDialog = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { open, apiCallProgress } = props;
    const classes = useStyles();

    const [institutionSocialProfile, setInstitutionSocialProfile] = useState([]);
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (!!props.institutioonSocialProfileURL.length) {
            setInstitutionSocialProfile(props.institutioonSocialProfileURL)
        } else {
            setInstitutionSocialProfile([{
                key: '',
                value: ''
            }]
            )
        }

    }, [props.institutioonSocialProfileURL])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(institutionSocialProfile)
    };

    const sspSocialPlatformEnumOptions = Object.keys(sspSocialPlatformEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspSocialPlatformEnum[opt]}`)
        };
    });

    const OnChangeInstitutionSocialProfileUrl = (e, index) => {
        let _InstitutionSocialProfile = institutionSocialProfile
        _InstitutionSocialProfile[index].value = e.target.value
        addUpdateOrDeleteInstitutionSocialProfile(_InstitutionSocialProfile, 'update')
    }

    const onChangeInstitutionSocialProfileKey = (e, value, index) => {
        let _InstitutionSocialProfile = institutionSocialProfile
        _InstitutionSocialProfile[index].key = value.value
        addUpdateOrDeleteInstitutionSocialProfile(_InstitutionSocialProfile, 'update')
    }

    const addSocialProfileRow = (e) => {
        addUpdateOrDeleteInstitutionSocialProfile({
            key: '',
            value: '',
        }, 'add')
    }

    const addUpdateOrDeleteInstitutionSocialProfile = (object, type) => {
        if (type == 'add') {
            setInstitutionSocialProfile((_InstitutionSocialProfile) => {
                return ([..._InstitutionSocialProfile, object])

            })
        }
        if (type == 'delete') {
            setInstitutionSocialProfile((_InstitutionSocialProfile) => {
                return [..._InstitutionSocialProfile.filter((item, index) => index != object)]
            })
        }
        if (type == 'update') {
            setInstitutionSocialProfile((_InstitutionSocialProfile) => {
                return [...object]
            })
        }
    }


    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="institutionIdnumber-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="institutionIdnumber-dialog-title">
                        {!!props.institutioonSocialProfileURL.length
                            ? t("dmTool:updateSocialProfileDialoHdng", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addSocialProfileDialoHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        {!!institutionSocialProfile.length && institutionSocialProfile.map((_institutionSocialProfile, index) => {
                            return (<React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item lg={4} sm={4} xs={12}>
                                        <Autocomplete
                                            disableClearable
                                            fullWidth
                                            size="small"
                                            className={classes.autocompleteStyle}
                                            getOptionLabel={option => option.label}
                                            id="legal-form-key"
                                            margin="normal"
                                            onChange={(e, value) => onChangeInstitutionSocialProfileKey(e, value, index)}
                                            options={sspSocialPlatformEnumOptions}
                                            value={
                                                sspSocialPlatformEnumOptions.find(
                                                    item =>
                                                        item.value ===
                                                        _institutionSocialProfile?.key,
                                                ) || {}
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={`${t(`${"dmTool:socialProfileTypeInputFieldLabel"}`)}*`}
                                                    placeholder={t(`${"dmTool:selectSocialProfileTypePlcHldr"}`)}
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={6} xs={12}>
                                        <TextField
                                            className={classes.marginSet}
                                            fullWidth
                                            label={t(`${"dmTool:socialProfileUrlInputFieldLabel"}`)}
                                            placeholder={t(`${"dmTool:enterSocialProfilePlcHldr"}`)}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            onChange={(e) => OnChangeInstitutionSocialProfileUrl(e, index)}
                                            size='small'
                                            required={false}
                                            value={_institutionSocialProfile?.value}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />

                                    </Grid>
                                    {index == 0 && <Grid item lg={1} sm={1} xs={12}>
                                        <Tooltip title={t(`${"dmTool:addSocialProfileTooltip"}`)}>
                                            <IconButton aria-label="add"
                                                color="secondary"
                                                variant="contained"
                                                onClick={addSocialProfileRow}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>}
                                    <Grid item lg={1} sm={1} xs={12}>
                                        <IconButton
                                            key={'delete'}
                                            onClick={(e) => addUpdateOrDeleteInstitutionSocialProfile(index, 'delete')}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </React.Fragment>)
                        })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color='primary'
                            className={classes.addButton}
                        >
                            {!!props.institutioonSocialProfileURL.length ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default InstitutionSocialProfileURLDialog;