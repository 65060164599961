import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
    OtherServices,
    ServiceDescription
} from './Components';
import { sspServiceOfferingEnum, sspStartupPhaseTypeEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const SupplierDialog = props => {
    const { open } = props;
    const classes = useStyles();
    const [apiCallProgress, setApiCallProgress] = useState(false);
    const [supplierInfo, setSupplierInfo] = useState({});
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (props.supplier) {
            setSupplierInfo(props.supplier);
        } else {
            setSupplierInfo({
                "name": "SupplierProfile",
                "serviceOffering": [],
                "otherServiceOffing": [],
                "serviceDescription": [],
                "serviceAudienceType": ["CON_SERVICE_AUDIENCE_TYPE_ANY"],
                "audienceStartupPhase": [],
                "serviceStatus": "CON_SERVICE_STATUS_ACTIVE",
                "applyServiceZoneToAll": true,
                "serviceFocusSectors": [],
                "otherServiceSector": [],
                "serviceFeatureImage": null,
                "profileImageStatus": null,
                "institutionType": []
            })
        }
    }, [props.supplier])


    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
    ]);

    const sspServiceOfferingEnumOptions = Object.keys(sspServiceOfferingEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspServiceOfferingEnum[opt]}`)
        };
    });

    const sspStartupPhaseTypeEnumOptions = Object.keys(sspStartupPhaseTypeEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspStartupPhaseTypeEnum[opt]}`)
        };
    });
    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onClickSaveOrUpdate = () => {
        setApiCallProgress(true);
    };



    const _changeSupplierOfferings = (e, selectedOptions) => {

        setSupplierInfo((_supplierInfo) => {
            return {
                ..._supplierInfo,
                serviceOffering: selectedOptions.map((item) => item.value)
            }
        })
    }

    const _changeAudienceStartupPhase = (e, selectedOptions) => {
        setSupplierInfo((_supplierInfo) => {
            return {
                ..._supplierInfo,
                audienceStartupPhase: selectedOptions.map((item) => item.value)
            }
        })
    }

    const _addUpdateOrDeleteOtherServices = (object, type) => {
        if (type == 'add') {
            setSupplierInfo((_supplierInfo) => {
                return {
                    ..._supplierInfo,
                    otherServiceOffing: [..._supplierInfo.otherServiceOffing, object]
                }
            })
        }
        if (type == 'delete') {
            setSupplierInfo((_supplierInfo) => {
                return {
                    ..._supplierInfo,
                    otherServiceOffing: [..._supplierInfo.otherServiceOffing.filter((item, index) => index != object)]
                }
            })
        }
        if (type == 'update') {
            setSupplierInfo((_supplierInfo) => {
                return {
                    ..._supplierInfo,
                    otherServiceOffing: [...object]
                }
            })
        }
    }
    const _addUpdateOrDeleteServiceDescription = (object, type) => {
        if (type == 'add') {
            setSupplierInfo((_supplierInfo) => {
                return {
                    ..._supplierInfo,
                    serviceDescription: [..._supplierInfo.serviceDescription, object]
                }
            })
        }
        if (type == 'delete') {
            setSupplierInfo((_supplierInfo) => {
                return {
                    ..._supplierInfo,
                    serviceDescription: [..._supplierInfo.serviceDescription.filter((item, index) => index != object)]
                }
            })
        }
        if (type == 'update') {
            setSupplierInfo((_supplierInfo) => {
                return {
                    ..._supplierInfo,
                    serviceDescription: [...object]
                }
            })
        }
    }

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="institution-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="institution-dialog-title">
                        {supplierInfo?.id
                            ? `Update  ${supplierInfo?.id} Supplier with offerings for startups, investors or innovation seekers of`
                            : 'Create  Supplier with offerings for startups, investors or innovation seekers to'}
                        {" "}{enitityObj?.Entity?.accountName}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Autocomplete
                                    id="supplier-offerings-demo"
                                    multiple
                                    disableCloseOnSelect
                                    value={sspServiceOfferingEnumOptions.filter(item => supplierInfo?.serviceOffering?.indexOf(item.value) !== -1)}
                                    options={sspServiceOfferingEnumOptions}
                                    getOptionLabel={option => option.label}
                                    getOptionSelected={(option, value) => value.value === option.value}
                                    style={{ width: 500 }}
                                    renderInput={params => (
                                        <TextField {...params} label="supplier-offerings" variant="outlined" fullWidth />
                                    )}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </React.Fragment>
                                    )}
                                    onChange={_changeSupplierOfferings}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <OtherServices
                                    supplierInfo={supplierInfo}
                                    addUpdateOrDeleteOtherServices={_addUpdateOrDeleteOtherServices}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <ServiceDescription
                                    supplierInfo={supplierInfo}
                                    addUpdateOrDeleteServiceDescription={_addUpdateOrDeleteServiceDescription}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <Autocomplete
                                    id="supplier-offerings-demo"
                                    multiple
                                    disableCloseOnSelect
                                    value={sspStartupPhaseTypeEnumOptions.filter(item => supplierInfo?.audienceStartupPhase?.indexOf(item.value) !== -1)}
                                    options={sspStartupPhaseTypeEnumOptions}
                                    getOptionLabel={option => option.label}
                                    getOptionSelected={(option, value) => value.value === option.value}
                                    style={{ width: 500 }}
                                    renderInput={params => (
                                        <TextField {...params} label="supplier-offerings" variant="outlined" fullWidth />
                                    )}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </React.Fragment>
                                    )}
                                    onChange={_changeAudienceStartupPhase}
                                />
                            </Grid>
                            {/*
                            <Grid item lg={6} sm={6} xs={12}>
                                <InstitutionCity
                                    supplierInfo={supplierInfo}
                                    OnChagesupplierInfo={OnChagesupplierInfo}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Grid container spacing={1}>
                                    <BusinessIdNumber
                                        supplierInfo={supplierInfo}
                                        OnChangeBussinessId={OnChangeBussinessId}
                                        OnAddOrDeleteBussinessId={OnAddOrDeleteBussinessId}
                                    />
                                </Grid>

                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <AssignRole
                                    supplierInfo={supplierInfo}
                                    OnChagesupplierInfo={OnChagesupplierInfo}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}></Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <CreationChannelType
                                    createdInfo={createdInfo}
                                    OnChageCratedInfo={OnChageCratedInfo}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <WhereHeardAboutUs
                                    createdInfo={createdInfo}
                                    OnChageCratedInfo={OnChageCratedInfo}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Comment
                                    createdInfo={createdInfo}
                                    OnChageCratedInfo={OnChageCratedInfo}
                                />
                            </Grid>*/}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >Cancel</Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color='primary'
                            className={classes.addButton}
                        >
                            {supplierInfo?.id ? 'Update' : 'Create'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default SupplierDialog;