import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

import LanguageSelector from '../../LanguageSelector';
import DateFormatSelector from '../../DateFormatSelector';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));

const SettingsDialog = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
      ]);
    const { open, apiCallProgress } = props;
    const [dateFormat, setDateFormat] = useState("")
    const [language, setLanguage] = useState("")
    const classes = useStyles();


    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };

    const onClickApply = () => {
        const { onClose } = props;
        onClose && onClose();
        props.configuration({ "dateFormat": dateFormat, "language": language })
        i18n.changeLanguage(language.value || "en")
    };

    const _selectedFormat = (e) => {
        setDateFormat(e);
    }
    const _selectedLanguage = (e) => {
        setLanguage(e)
    }


    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="settings-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="settings-dialog-title">
                        {t(`${"dmTool:settingsDialogHdng"}`)}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={5} sm={6} xs={12}>
                                {t(`${"dmTool:applicationLangFieldTitle"}`)}
                            </Grid>
                            <Grid item lg={7} sm={6} xs={12}>
                                <LanguageSelector selectedLanguage={_selectedLanguage} context={props.context} />
                            </Grid>
                            <Grid item lg={5} sm={6} xs={12}>
                                {t(`${"dmTool:dateFormatFieldTitle"}`)}
                            </Grid>
                            <Grid item lg={7} sm={6} xs={12}>
                                <DateFormatSelector selectedFormat={_selectedFormat} context={props.context} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickApply}
                            variant="contained"
                            disabled={apiCallProgress}
                            color='primary'
                            className={classes.addButton}
                        >
                            {t(`${"dmTool:applyButton"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default SettingsDialog;