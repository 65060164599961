import React, { useState, useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { useTheme, fade, makeStyles } from '@material-ui/core/styles';
import MainPictureUrlDialog from '../MainPictureUrlDialog';
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";


const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 13,
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    milestones: {
        overflow: 'auto',
        maxHeight: 215,
    },
    milestone: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    }

}));

const MainPictureUrlRenderer = (props) => {
    const classes = useStyles();
    const [_mainPictreUrl, _setMainPicturerl] = useState(props.value || '')
    const [mainPictreUrlDialog, setMainPictreUrlDialogOpen] = React.useState(false);
    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const openDialog = () => {
        setMainPictreUrlDialogOpen(true);
    }

    const closeDialog = () => {
        setMainPictreUrlDialogOpen(false);
    }

    const hadleEditClick = (e) => {
        openDialog()
    }

    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.mainPictureUrl = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData);      
        closeDialog();
    }

    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }


    return (
        <div className={classes.root}>
            <div className={classes.milestones}>
                <div className={classes.milestone}>
                    <div className={classes.topbuttons}>
                        <IconButton
                            key={'edit'}
                            color="secondary"
                            variant="contained"
                            onClick={hadleEditClick}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>

                    {(_mainPictreUrl !== '') && (
                        <div>
                            <img src={_mainPictreUrl} width={200} height={200} />
                        </div>)}

                    {!!mainPictreUrlDialog && <MainPictureUrlDialog
                        open={mainPictreUrlDialog}
                        onClose={closeDialog}
                        entity={props.data}
                        mainPictureUrl={_mainPictreUrl}
                        onClickAddOrUpdate={onClickAddOrUpdate}
                        apiCallProgress={apiCallProgress}
                    />}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainPictureUrlRenderer);
