import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  RatingStartupSpiderInternalComment,
  RatingQualityFlag,
  RatingTechnologyReadiness,
  RatingInnovationDegree,
} from "./Components";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 700,
  },
  legend: {
    padding: 0,
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "-0.05px",
    color: "#888888",
  },
  fieldset: {
    border: "1px solid #eceded",
    padding: "10px",
    borderRadius: "4px",
  },
  addButton: {
    minWidth: 91,
  },
}));

const MilestoneDialog = (props) => {
  const { t } = useTranslation([
    "translation",
    "configurationEnumerations",
    "dmTool",
  ]);
  const { open, apiCallProgress } = props;
  const classes = useStyles();

  const [ratingObj, setRatingObj] = useState({});
  const [enitityObj, setEntityObj] = useState({});

  useEffect(() => {
    setEntityObj(props.entity);
    if (props.Rating) {
      setRatingObj(props.Rating);
    } else {
      setRatingObj({
        name: "EntityRatingCriteria",
        i_sourceKey: null,
        date_sourceCreationTs: null,
        date_sourceUpdateTs: null,
        i_t_qualitySourcePriorityLevel: "",
        i_t_qualitySourceDataQualityEvaluation: "",
        i_t_researchComment: "",
        i_t_startupSpiderInternalComment: "",
        i_t_qualitySourceExpertQualityComment: "",
        technologyReadiness: 0,
        innovationDegree: 0,
        innovationSector: [],
        innovationField: [],
        r_t_id_ratedObject: "",
        r_t_name_ratedObject: "",
        RatingAgentOrGenerator: null,
        name: "EntityRatingCriteria",
      });
    }
  }, [props.Rating]);

  const closeModal = () => {
    const { onClose } = props;
    onClose && onClose();
  };

  const OnChageRatingObj = (key, value) => {
    setRatingObj({
      ...ratingObj,
      [`${key}`]: value,
    });
  };

  const onClickSaveOrUpdate = () => {
    let updatedObject = Object.assign({}, ratingObj);
    if (updatedObject?.i_sourceKey == null) {
      updatedObject.i_sourceKey =
        "ssp_rating_" + Date.now() + "_" + parseInt(Math.random() * 100000);
    }
    props.onClickAddOrUpdate(updatedObject);
  };

  return (
    <React.Fragment>
      {open && (
        <Dialog
          aria-labelledby="rating-dialog-title"
          classes={{
            paperWidthSm: classes.dialog,
          }}
          disableBackdropClick
          onClose={closeModal}
          open={open}
        >
          <DialogTitle
            className={classes.title}
            disableTypography
            id="rating-dialog-title"
          >
            {ratingObj?.i_sourceKey !== null
              ? t("dmTool:updateRatingDialogHdng", {
                  entityName: enitityObj?.Entity?.accountName,
                })
              : t("dmTool:addRatingDialogHdng", {
                  entityName: enitityObj?.Entity?.accountName,
                })}
          </DialogTitle>
          {apiCallProgress && <LinearProgress />}
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item lg={12} sm={12} xs={12}>
                <RatingTechnologyReadiness
                  ratingObj={ratingObj}
                  OnChageRatingObj={OnChageRatingObj}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <RatingInnovationDegree
                  ratingObj={ratingObj}
                  OnChageRatingObj={OnChageRatingObj}
                />
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <RatingQualityFlag
                  ratingObj={ratingObj}
                  OnChageRatingObj={OnChageRatingObj}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <RatingStartupSpiderInternalComment
                  ratingObj={ratingObj}
                  OnChageRatingObj={OnChageRatingObj}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={apiCallProgress}
              onClick={closeModal}
              variant="contained"
              color="primary"
            >
              {t(`${"dmTool:cancelBtn"}`)}
            </Button>
            <Button
              onClick={onClickSaveOrUpdate}
              variant="contained"
              disabled={
                apiCallProgress ||
                !Boolean(ratingObj.technologyReadiness <= 10) ||
                !Boolean(ratingObj.innovationDegree <= 10) ||
                !Boolean(ratingObj.i_t_qualitySourceDataQualityEvaluation)
              }
              color="primary"
              className={classes.addButton}
            >
              {ratingObj?.i_sourceKey !== null
                ? t(`${"dmTool:updateBtn"}`)
                : t(`${"dmTool:addBtn"}`)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default MilestoneDialog;
