import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sspQualityCommentEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({

}));

const KeypersonQualityComment = props => {
    const { keyPersonObj, OnChageKeypersonObj } = props;
    const classes = useStyles();

    const OnChangeQualityComment = (e, value) => {
        OnChageKeypersonObj('i_t_qualitySourceDataQualityComment', value.value)
    }

    const { t } = useTranslation([
        "translation",
        "SSPDataQualityCommentMessages",
    ]);


    const sspQualityCommentEnumOptions = Object.keys(sspQualityCommentEnum).map(opt => {
        return {
            value: opt,
            //   label: t(`${"eventsEnumerations:"+(sspMilestoneTypeEnum[opt])}`)
            label: t(`${"SSPDataQualityCommentMessages:" + sspQualityCommentEnum[opt]}`)
        };
    });


    return (
        <React.Fragment>
            <Autocomplete
                disableClearable
                fullWidth
                size="small"
                className={classes.autocompleteStyle}
                getOptionLabel={option => option.label}
                id="quality-comment"
                margin="normal"
                onChange={OnChangeQualityComment}
                options={sspQualityCommentEnumOptions}
                value={
                    sspQualityCommentEnumOptions.find(
                        item =>
                            item.value ===
                            keyPersonObj?.i_t_qualitySourceDataQualityComment,
                    ) || {}
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Quality comment"
                        placeholder="Select quality comment"
                        variant="outlined"
                        style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </React.Fragment>
    );
};

export default KeypersonQualityComment;