import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import { sspSocialPlatformEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));

const KeypersonSocialProfiles = props => {
    const { keyPersonObj, addUpdateOrDeleteKeypersonSocialProfiles } = props;
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);

    const socialProfilesOptions = Object.keys(sspSocialPlatformEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspSocialPlatformEnum[opt]}`)
        };
    });


    const _changeSocialprofileType = (e, value, index) => {
        let socialProfileURL = keyPersonObj.socialProfileURL
        socialProfileURL[index].key = value.value;
        addUpdateOrDeleteKeypersonSocialProfiles(socialProfileURL, 'update')
    }

    const _changeSocialProfileValue = (e, index) => {
        let socialProfileURL = keyPersonObj.socialProfileURL
        socialProfileURL[index].value = e.target.value;
        addUpdateOrDeleteKeypersonSocialProfiles(socialProfileURL, 'update')
    }
    const addKeypersonSocialProfiles = (e) => {
        addUpdateOrDeleteKeypersonSocialProfiles({
            key: '',
            value: ''
        }, 'add')

    }


    const deleteOtherOfferings = (e, index) => {
        addUpdateOrDeleteKeypersonSocialProfiles(
            index,
            'delete',
        );
        enqueueSnackbar(t("dmTool:removingEntrySuccessMsg", { type: t(`${"dmTool:socialProfile"}`) }), {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });
    };


    const renderAddCloseIcon = (index) => {
        return (
            <Tooltip title={t(`${"dmTool:removeScoialProfileTooltip"}`)}>
                <IconButton
                    aria-label="remove"
                    onClick={(e) => deleteOtherOfferings(e, index)}
                >
                    <CloseIcon className={classes.icons} />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <>
            <Tooltip title={t(`${"dmTool:addSocialProfileTooltip"}`)}>
                <Button
                    aria-label="add"
                    onClick={addKeypersonSocialProfiles}
                    color='primary'
                    startIcon={<AddIcon />}
                >
                    {t(`${"dmTool:addScoialProfileSectionHdng"}`)}
                </Button>
            </Tooltip>

            {keyPersonObj?.socialProfileURL?.map((socialProfile, index) => {
                return (
                    <Grid container spacing={1} key={index}>
                        <Grid item lg={4} sm={4} xs={12}>
                            <Autocomplete
                                disableClearable
                                size="small"
                                fullWidth
                                className={classes.autocompleteStyle}
                                getOptionLabel={option => option.label}
                                id="social-profile-key"
                                margin="normal"
                                onChange={(e, value) => _changeSocialprofileType(e, value, index)}
                                options={socialProfilesOptions}
                                value={
                                    socialProfilesOptions.find(
                                        item =>
                                            item.value ===
                                            socialProfile?.key,
                                    ) || {}
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t(`${"dmTool:socialProfileInputFieldLabel"}`) + "*"}
                                        placeholder={t(`${"dmTool:selectSocialProfileTypePlcHldr"}`)}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                            <TextField
                                className={classes.marginSet}
                                fullWidth
                                label={t(`${"dmTool:socialProfileUrlInputFieldLabel"}`) + "*"}
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                                placeholder={t(`${"dmTool:enterSocialProfileUrlPlcHldr"}`)}
                                onChange={(e) => _changeSocialProfileValue(e, index)}
                                size='small'
                                required={false}
                                value={socialProfile?.value}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item lg={2} sm={2} xs={12}>
                            {renderAddCloseIcon(index)}
                        </Grid>
                    </Grid>
                )
            })}
        </>
    );
};

export default KeypersonSocialProfiles;