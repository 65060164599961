import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countries } from '../Countrieslist';


import { sspLegalFormTypeEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import Warning from '@material-ui/icons/Warning';
import Verified from '@material-ui/icons/VerifiedUser';


const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    },
    circular_progress: {
        marginRight: "auto",
        display: "flex"
    },
    uniqueness_msg: {
        margin: "0 0 0 5px",
        alignSelf: "center"
    }
}));

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}


const LegalFormDialog = props => {
    const { open, apiCallProgress, entity } = props;
    const classes = useStyles();
    const [warning, setWarning] = useState(false)
    const [uniqueMsg, setUniqueMsg] = useState("")
    const [loader, setLoader] = useState(false)
    var previousXhrRequest = {};

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);

    const [legalFormObj, setLegalFormObj] = useState({});
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (props.legalForm) {
            setLegalFormObj(props.legalForm)
        } else {
            setLegalFormObj({
                key: '',
                value: ''
            }
            )
        }

    }, [props.legalForm])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const OnChagelegalFormObj = (key, value) => {
        setLegalFormObj({
            ...legalFormObj,
            [`${key}`]: value
        })
    }

    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(legalFormObj)
    };



    const sspLegalFormTypeEnumOptions = Object.keys(sspLegalFormTypeEnum).map(opt => {
        return {
            value: opt,
            //   label: t(`${"eventsEnumerations:"+(sspMilestoneTypeEnum[opt])}`)
            label: t(`${"configurationEnumerations:" + sspLegalFormTypeEnum[opt]}`)
        };
    });

    const OnChangeLegalFormType = (e, value) => {
        OnChagelegalFormObj('value', value.value)
    }

    const onChangeLegalFormKey = (e, value) => {
        let apiData = {
            accountName: entity.Entity.accountName,
            institutionJurisdiction: value.code,
            i_sourceKey: null
        }
        if (value) {
            OnChagelegalFormObj('key', value.code)
            _checkUniqueNess(apiData)
        } else {
            OnChagelegalFormObj('key', null)
        }
    }

    const _checkUniqueNess = (data) => {

        const httpRequestAccountNameRequest = new XMLHttpRequest();
        setLoader(true);
        setWarning(false)
        httpRequestAccountNameRequest.open('POST', process.env.PUBLIC_URL + `/accountname/unique`);
        httpRequestAccountNameRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequestAccountNameRequest.send(JSON.stringify(data));

        return new Promise(function (resolve, reject) {
            httpRequestAccountNameRequest.onreadystatechange = () => {
                if (httpRequestAccountNameRequest.readyState === 4 && httpRequestAccountNameRequest.status === 200) {

                    let res = JSON.parse(httpRequestAccountNameRequest.responseText);
                    setLoader(false);
                    setWarning(!res.data.unique)
                    setUniqueMsg(res.data.environment)
                    resolve()
                }
            };
            previousXhrRequest.cancel = function () {  // SPECIFY CANCELLATION
                httpRequestAccountNameRequest.abort(); // abort request
                reject("cancelled previous request!!"); // reject the promise
            };
            httpRequestAccountNameRequest.onerror = reject;
        });

    }

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="legalForm-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="legalForm-dialog-title">
                        {!!props.legalForm
                            ? t("dmTool:updateLegalFormDialogHdng", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addLegalFormDialogHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={6} sm={6} xs={12}>
                                <Autocomplete
                                    id="country-select-demo"
                                    size='small'
                                    style={{ width: '100%' }}
                                    options={countries}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    value={countries.find((item) => item.code === legalFormObj.key) || {}}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    onChange={onChangeLegalFormKey}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span><img className="country-flag" src={`./flags/${option.code?.toLowerCase()}.png`} ></img></span>
                                            {option.label} ({option.code}) +{option.phone}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t(`${"dmTool:countryInputFieldLabel"}`)}*`}
                                            placeholder={t(`${"dmTool:countryofCompanyPlcHldr"}`)}
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                autoComplete: 'new-password',
                                                startAdornment: legalFormObj.key && legalFormObj.key !== '' && (
                                                    <React.Fragment>
                                                        <span><img className="country-flag" src={`./flags/${legalFormObj.key?.toLowerCase()}.png`} /></span> {' '}
                                                        {params.InputProps.startAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <Autocomplete
                                    disableClearable
                                    fullWidth
                                    size="small"
                                    className={classes.autocompleteStyle}
                                    getOptionLabel={option => option.label}
                                    id="legal-form-value"
                                    margin="normal"
                                    onChange={OnChangeLegalFormType}
                                    options={sspLegalFormTypeEnumOptions}
                                    value={
                                        sspLegalFormTypeEnumOptions.find(
                                            item =>
                                                item.value ===
                                                legalFormObj?.value,
                                        ) || {}
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={`${t(`${"dmTool:legalFormTypeInputFieldLabel"}`)}*`}
                                            placeholder={t(`${"dmTool:selectLegalFoformTypePlcHldr"}`)}
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.circular_progress}>
                            {uniqueMsg && loader && <CircularProgress size={20} />}
                            {uniqueMsg && !loader && (warning ? <Warning style={{ color: "red" }} />
                                : <Verified style={{ color: "green" }} />)}
                            <div className={classes.uniqueness_msg}>
                                {loader && t("dmTool:checkingAccUniquenessMsg")}
                                {uniqueMsg && !loader && (warning ? t("dmTool:accIsNotUniqueMsg", { environment: uniqueMsg })
                                    : t("dmTool:uniqueAccMsg", { environment: uniqueMsg }))}
                            </div>
                        </div>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress || loader || warning}
                            color='primary'
                            className={classes.addButton}
                        >
                            {!!props.legalForm ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default LegalFormDialog;