
/*
* Key prefix: EVN_STATUS_TYPE_
*/
export const sspStatusTypeEnum = {
    EVN_STATUS_TYPE_PLANNED: "EVN_STATUS_TYPE_PLANNED"
    , EVN_STATUS_TYPE_ACHIEVED: "EVN_STATUS_TYPE_ACHIEVED"
    , EVN_STATUS_TYPE_POSTPONED: "EVN_STATUS_TYPE_POSTPONED"
    , EVN_STATUS_TYPE_NOT_ACHIEVED: "EVN_STATUS_TYPE_NOT_ACHIEVED"
    , EVN_STATUS_TYPE_CANCELED: "EVN_STATUS_TYPE_CANCELED"
    , EVN_STATUS_TYPE_PUBLISHED: "EVN_STATUS_TYPE_PUBLISHED"
    , EVN_STATUS_TYPE_UNKNOWN: "EVN_STATUS_TYPE_UNKNOWN"
}


export const sspLanguageEnum = [
    {
        value: 'en',
        label: 'en'
    }, {
        value: 'de',
        label: 'de'
    }

];

export const dateFormat = [
    {
        value: "dd.MM.yyyy HH:mm:ss Z",
        label: "dd.MM.yyyy HH:mm:ss Z"
    },
    {
        value: "dd-MM-yyyy HH:mm:ss Z",
        label: "dd-MM-yyyy HH:mm:ss Z"
    },
    {
        value: "dd/MM/yyyy HH:mm:ss Z",
        label: "dd/MM/yyyy HH:mm:ss Z"
    },
    {
        value: "dd.MMM.yyyy HH:mm:ss Z",
        label: "dd.MMM.yyyy HH:mm:ss Z"
    },
    {
        value: "dd-MMM-yyyy HH:mm:ss Z",
        label: "dd-MMM-yyyy HH:mm:ss Z"
    },
    {
        value: "dd/MMM/yyyy HH:mm:ss Z",
        label: "dd/MMM/yyyy HH:mm:ss Z"
    }
];

export const WHERE_HEARD_ABOUT_USE_NUM = {
    SSPWEBSITE: "SSPWEBSITE",
    FRIEND: "FRIEND",
    LINKEDIN: "LINKEDIN",
    PLATFORM: "PLATFORM",
};

export const CREATION_CHANNEL_TYPE_ENUM = {
    NEWSLETTER: "NEWSLETTER",
    WEBSITE: "WEBSITE",
    MASSUPLOAD: "MASSUPLOAD",
};

export const ACCOUNT_ROLE = {
    // EMPLOYEE: "EMPLOYEE",
    TEAM: "TEAM",
    IT: "IT",
    SUPPORTER: "SUPPORTER",
    PRODUCTION: "PRODUCTION",
    DEVELOPMENT: "DEVELOPMENT",
    SALES: "SALES",
    OTHER: "OTHER",
    FOUNDER: "FOUNDER",
    CO_FOUNDER: "CO_FOUNDER",
    INVESTOR: "INVESTOR",
    LEAD_INVESTOR: "LEAD_INVESTOR",
    HEAD_MARKETING: "HEAD_MARKETING",
    HEAD_SALES: "HEAD_SALES",
    PRODUCT_MANAGER: "PRODUCT_MANAGER",
    BUSINESS_DEVELOPMENT: "BUSINESS_DEVELOPMENT",
    PROJECT_MANAGER: "PROJECT_MANAGER",
    CEO: "CEO",
    CFO: "CFO",
    CIO: "CIO",
    CTO: "CTO",
    COO: "COO",
    DIRECTOR: "DIRECTOR",
    MANAGER: "MANAGER",
    ADVISOR: "ADVISOR",
    CHAIRMAN: "CHAIRMAN",
    BOARD_DIRECTOR: "BOARD_DIRECTOR",
    COMPLIANCE: "COMPLIANCE",
    CPO: "CPO",
    CCO: "CCO",
    CORE_TEAM: "CORE_TEAM"
  };

export const sspBusinessIdentificationTypeEnum = {
	CON_BUSINESS_IDENTIFICATION_TYPE_UID : "CON_BUSINESS_IDENTIFICATION_TYPE_UID",
	CON_BUSINESS_IDENTIFICATION_TYPE_EUID : "CON_BUSINESS_IDENTIFICATION_TYPE_EUID",
	CON_BUSINESS_IDENTIFICATION_TYPE_BIN : "CON_BUSINESS_IDENTIFICATION_TYPE_BIN",
	CON_BUSINESS_IDENTIFICATION_TYPE_BID : "CON_BUSINESS_IDENTIFICATION_TYPE_BID",
	CON_BUSINESS_IDENTIFICATION_TYPE_LEI : "CON_BUSINESS_IDENTIFICATION_TYPE_LEI",
	CON_BUSINESS_IDENTIFICATION_TYPE_BIC : "CON_BUSINESS_IDENTIFICATION_TYPE_BIC",
	CON_BUSINESS_IDENTIFICATION_TYPE_CIF : "CON_BUSINESS_IDENTIFICATION_TYPE_CIF",
	CON_BUSINESS_IDENTIFICATION_TYPE_CVR : "CON_BUSINESS_IDENTIFICATION_TYPE_CVR",
	CON_BUSINESS_IDENTIFICATION_TYPE_GK : "CON_BUSINESS_IDENTIFICATION_TYPE_GK",
	CON_BUSINESS_IDENTIFICATION_TYPE_RCS : "CON_BUSINESS_IDENTIFICATION_TYPE_RCS",
	CON_BUSINESS_IDENTIFICATION_TYPE_CIK : "CON_BUSINESS_IDENTIFICATION_TYPE_CIK",
	CON_BUSINESS_IDENTIFICATION_TYPE_VAT : "CON_BUSINESS_IDENTIFICATION_TYPE_VAT",
	CON_BUSINESS_IDENTIFICATION_TYPE_UIC : "CON_BUSINESS_IDENTIFICATION_TYPE_UIC",
	CON_BUSINESS_IDENTIFICATION_TYPE_UKCN : "CON_BUSINESS_IDENTIFICATION_TYPE_UKCN",
	CON_BUSINESS_IDENTIFICATION_TYPE_VP : "CON_BUSINESS_IDENTIFICATION_TYPE_VP",
	CON_BUSINESS_IDENTIFICATION_TYPE_UNKNOWN : "CON_BUSINESS_IDENTIFICATION_TYPE_UNKNOWN"
}


export const sspLegalFormTypeEnum = {
	CON_LEGAL_TYPE_SOLE : "CON_LEGAL_TYPE_SOLE",
	CON_LEGAL_TYPE_PARTNERSHIP : "CON_LEGAL_TYPE_PARTNERSHIP",
	CON_LEGAL_TYPE_LIMITED : "CON_LEGAL_TYPE_LIMITED",
	CON_LEGAL_TYPE_LLP : "CON_LEGAL_TYPE_LLP",
	CON_LEGAL_TYPE_COMMUNITY : "CON_LEGAL_TYPE_COMMUNITY",
	CON_LEGAL_TYPE_GENERAL : "CON_LEGAL_TYPE_GENERAL",
	CON_LEGAL_TYPE_PLC : "CON_LEGAL_TYPE_PLC",
	CON_LEGAL_TYPE_LTD : "CON_LEGAL_TYPE_LTD",
	CON_LEGAL_TYPE_COMP_LIM : "CON_LEGAL_TYPE_COMP_LIM",
	CON_LEGAL_TYPE_SICAV : "CON_LEGAL_TYPE_SICAV",
	CON_LEGAL_TYPE_KOAG : "CON_LEGAL_TYPE_KOAG",
    CON_LEGAL_TYPE_GMBHCOKG : "CON_LEGAL_TYPE_GMBHCOKG",
	CON_LEGAL_TYPE_UNLTD : "CON_LEGAL_TYPE_UNLTD",
    CON_LEGAL_TYPE_SE : "CON_LEGAL_TYPE_SE",
	CON_LEGAL_TYPE_COORP : "CON_LEGAL_TYPE_COORP",
	CON_LEGAL_TYPE_ASSOC : "CON_LEGAL_TYPE_ASSOC",
	CON_LEGAL_TYPE_NPO : "CON_LEGAL_TYPE_NPO",
	CON_LEGAL_TYPE_FORMATION : "CON_LEGAL_TYPE_FORMATION",
	CON_LEGAL_TYPE_REWARD : "CON_LEGAL_TYPE_REWARD",
	CON_LEGAL_TYPE_INNOVATION : "CON_LEGAL_TYPE_INNOVATION",
    CON_LEGAL_TYPE_CONFERENCE : "CON_LEGAL_TYPE_CONFERENCE",
	CON_LEGAL_TYPE_UNKNOWN : "CON_LEGAL_TYPE_UNKNOWN"
}

export const sspMilestoneTypeEnum = {
    EVN_MILESTONE_TYPE_FORMATION: "EVN_MILESTONE_TYPE_FORMATION"
    , EVN_MILESTONE_TYPE_IDEA: "EVN_MILESTONE_TYPE_IDEA"
    , EVN_MILESTONE_TYPE_IP: "EVN_MILESTONE_TYPE_IP"
    , EVN_MILESTONE_TYPE_MOCKUP: "EVN_MILESTONE_TYPE_MOCKUP"
    , EVN_MILESTONE_TYPE_PROTOTYPE: "EVN_MILESTONE_TYPE_PROTOTYPE"
    , EVN_MILESTONE_TYPE_MVP: "EVN_MILESTONE_TYPE_MVP"
    , EVN_MILESTONE_TYPE_PRODUCT: "EVN_MILESTONE_TYPE_PRODUCT"
    , EVN_MILESTONE_TYPE_CUSTOMER: "EVN_MILESTONE_TYPE_CUSTOMER"
    , EVN_MILESTONE_TYPE_FUNDING_FOUNDER: "EVN_MILESTONE_TYPE_FUNDING_FOUNDER"
    , EVN_MILESTONE_TYPE_FUNDING_FAF: "EVN_MILESTONE_TYPE_FUNDING_FAF"
    , EVN_MILESTONE_TYPE_FUNDING_EXTERNAL: "EVN_MILESTONE_TYPE_FUNDING_EXTERNAL"
    , EVN_MILESTONE_TYPE_BREAKEVEN: "EVN_MILESTONE_TYPE_BREAKEVEN"
    , EVN_MILESTONE_TYPE_NEWS: "EVN_MILESTONE_TYPE_NEWS"
    , EVN_MILESTONE_TYPE_MISC: "EVN_MILESTONE_TYPE_MISC"
}

export const sspBusinessTypeEnum = {
    CON_BUSINESS_TYPE_B2C: "CON_BUSINESS_TYPE_B2C",
    CON_BUSINESS_TYPE_B2B: "CON_BUSINESS_TYPE_B2B",
    CON_BUSINESS_TYPE_PLATFORM: "CON_BUSINESS_TYPE_PLATFORM",
}

export const sspCompanyTypeEnum = {
    // CON_COMPANY_TYPE_DODEFINE : "CON_COMPANY_TYPE_DODEFINE",
    CON_COMPANY_TYPE_BECOMING: "CON_COMPANY_TYPE_BECOMING",
    CON_COMPANY_TYPE_STARTUP: "CON_COMPANY_TYPE_STARTUP",
    CON_COMPANY_TYPE_SME: "CON_COMPANY_TYPE_SME",
    CON_COMPANY_TYPE_LE: "CON_COMPANY_TYPE_LE",
    CON_COMPANY_TYPE_ASSOCIATION: "CON_COMPANY_TYPE_ASSOCIATION",
    CON_COMPANY_TYPE_CLUB: "CON_COMPANY_TYPE_CLUB",
    CON_COMPANY_TYPE_PROJECT: "CON_COMPANY_TYPE_PROJECT",
    CON_COMPANY_TYPE_INNOVATION: "CON_COMPANY_TYPE_INNOVATION",
    CON_COMPANY_TYPE_UNKNOWN: "CON_COMPANY_TYPE_UNKNOWN",
}

export const sspSourcingStrategyTypeEnum = {
	CYC_SOURCE_ORIGINATOR_PUSH : "CYC_SOURCE_ORIGINATOR_PUSH",
	CYC_SOURCE_ORIGINATOR_UPLOAD : "CYC_SOURCE_ORIGINATOR_UPLOAD",
	CYC_SOURCE_ORIGINATOR_PULL : "CYC_SOURCE_ORIGINATOR_PULL",
	CYC_SOURCE_CROWDFUNDING_PUSH : "CYC_SOURCE_CROWDFUNDING_PUSH",
	CYC_SOURCE_CROWDFUNDING_UPLOAD : "CYC_SOURCE_CROWDFUNDING_UPLOAD",
	CYC_SOURCE_CROWDFUNDING_PULL : "CYC_SOURCE_CROWDFUNDING_PULL",
	CYC_SOURCE_ACTIVE_PLATFORM_PUSH : "CYC_SOURCE_ACTIVE_PLATFORM_PUSH",
	CYC_SOURCE_ACTIVE_PLATFORM_UPLOAD : "CYC_SOURCE_ACTIVE_PLATFORM_UPLOAD",
	CYC_SOURCE_ACTIVE_PLATFORM_PULL : "CYC_SOURCE_ACTIVE_PLATFORM_PULL",
	CYC_SOURCE_PASSIVE_PLATFORM_PULL : "CYC_SOURCE_PASSIVE_PLATFORM_PULL",
	CYC_SOURCE_DATA_PROVIDER_PUSH : "CYC_SOURCE_DATA_PROVIDER_PUSH",
	CYC_SOURCE_DATA_PROVIDER_UPLOAD : "CYC_SOURCE_DATA_PROVIDER_UPLOAD",
	CYC_SOURCE_DATA_PROVIDER_PULL : "CYC_SOURCE_DATA_PROVIDER_PULL",
	CYC_SOURCE_CROWD_USER_UPLOAD : "CYC_SOURCE_CROWD_USER_UPLOAD",
	CYC_SOURCE_SSP_UPLOAD : "CYC_SOURCE_SSP_UPLOAD",
	CYC_SOURCE_INTERNET_PULL : "CYC_SOURCE_INTERNET_PULL",
	CYC_SOURCE_UNKNOWN : "CYC_SOURCE_UNKNOWN"
}

export const sspActivationStatusEnum = {
    CON_ACTIVATION_STATUS_ACTIVE: "CON_ACTIVATION_STATUS_ACTIVE",
    CON_ACTIVATION_STATUS_INOPERATIVE: "CON_ACTIVATION_STATUS_INOPERATIVE",
    CON_ACTIVATION_STATUS_MERGED: "CON_ACTIVATION_STATUS_MERGED",
    CON_ACTIVATION_STATUS_CLARIFICATION: "CON_ACTIVATION_STATUS_CLARIFICATION",
}

export const sspStartupCustomerStatusEnum = {
    CON_STARTUP_CUSTOMER_STATUS_NOTAVAILABLE: "CON_STARTUP_CUSTOMER_STATUS_NOTAVAILABLE",
    CON_STARTUP_CUSTOMER_STATUS_TESTING: "CON_STARTUP_CUSTOMER_STATUS_TESTING",
    CON_STARTUP_CUSTOMER_STATUS_CUSTOMER: "CON_STARTUP_CUSTOMER_STATUS_CUSTOMER",
}

export const sspStartupIntellectualPropertyEnum = {
    CON_STARTUP_IP_NONE: "CON_STARTUP_IP_NONE",
    CON_STARTUP_IP_SUBMITTED: "CON_STARTUP_IP_SUBMITTED",
    CON_STARTUP_IP_IP: "CON_STARTUP_IP_IP",
    CON_STARTUP_IP_TRADEMARK: "CON_STARTUP_IP_TRADEMARK",
}

export const sspStartupProductStatusEnum = {
    CON_STARTUP_PRODUCT_STATUS_INDEVELOPMENT: "CON_STARTUP_PRODUCT_STATUS_INDEVELOPMENT",
    CON_STARTUP_PRODUCT_STATUS_MVP: "CON_STARTUP_PRODUCT_STATUS_MVP",
    CON_STARTUP_PRODUCT_STATUS_INPLACE: "CON_STARTUP_PRODUCT_STATUS_INPLACE",
    CON_STARTUP_PRODUCT_STATUS_UNKNOWN: "CON_STARTUP_PRODUCT_STATUS_UNKNOWN",
}

export const sspSocialPlatformEnum = {
    CON_SOCIAL_PLATFORM_LINKEDIN: "CON_SOCIAL_PLATFORM_LINKEDIN",
    CON_SOCIAL_PLATFORM_GOOGLE: "CON_SOCIAL_PLATFORM_GOOGLE",
    CON_SOCIAL_PLATFORM_FACEBOOK: "CON_SOCIAL_PLATFORM_FACEBOOK",
    CON_SOCIAL_PLATFORM_BITCOIN: "CON_SOCIAL_PLATFORM_BITCOIN",
    CON_SOCIAL_PLATFORM_ELECTRUM: "CON_SOCIAL_PLATFORM_ELECTRUM",
    CON_SOCIAL_PLATFORM_ARMORY: "CON_SOCIAL_PLATFORM_ARMORY",
    CON_SOCIAL_PLATFORM_TWTTER: "CON_SOCIAL_PLATFORM_TWTTER",
    CON_SOCIAL_PLATFORM_YOUTUBE: "CON_SOCIAL_PLATFORM_YOUTUBE",
    CON_SOCIAL_PLATFORM_INSTAGRAM: "CON_SOCIAL_PLATFORM_INSTAGRAM",
    // CON_SOCIAL_PLATFORM_STARTUPSPIDER : "CON_SOCIAL_PLATFORM_STARTUPSPIDER",
}

export const sspStartupPhaseTypeEnum = {
    CON_STARTUP_PHASE_PRE_SEED: "CON_STARTUP_PHASE_PRE_SEED",
    CON_STARTUP_PHASE_SEED: "CON_STARTUP_PHASE_SEED",
    CON_STARTUP_PHASE_GROWTH: "CON_STARTUP_PHASE_GROWTH",
    CON_STARTUP_PHASE_UNLIMIT: "CON_STARTUP_PHASE_UNLIMIT",
    CON_STARTUP_PHASE_UNKNOWN: "CON_STARTUP_PHASE_UNKNOWN",
}

export const sspServiceOfferingEnum = {
    CON_SERVICE_OFFERING_TYPE_FUNDING_SHARE : "CON_SERVICE_OFFERING_TYPE_FUNDING_SHARE",
	CON_SERVICE_OFFERING_TYPE_FUNDING_LENDING : "CON_SERVICE_OFFERING_TYPE_FUNDING_LENDING",
	CON_SERVICE_OFFERING_TYPE_FUNDING_REWARDORDONATION : "CON_SERVICE_OFFERING_TYPE_FUNDING_REWARDORDONATION",
	CON_SERVICE_OFFERING_TYPE_SHARE_REGISTER : "CON_SERVICE_OFFERING_TYPE_SHARE_REGISTER",
	CON_SERVICE_OFFERING_TYPE_FUNDS : "CON_SERVICE_OFFERING_TYPE_FUNDS",
	CON_SERVICE_OFFERING_TYPE_ADVISORY : "CON_SERVICE_OFFERING_TYPE_ADVISORY",
	CON_SERVICE_OFFERING_TYPE_UNIVERSITY : "CON_SERVICE_OFFERING_TYPE_UNIVERSITY",
	CON_SERVICE_OFFERING_TYPE_SEEK_INNOVATION : "CON_SERVICE_OFFERING_TYPE_SEEK_INNOVATION",
    CON_SERVICE_OFFERING_TYPE_VENTURE_CAPITAL: "CON_SERVICE_OFFERING_TYPE_VENTURE_CAPITAL",
    CON_SERVICE_OFFERING_TYPE_BA_INVESTMENT: "CON_SERVICE_OFFERING_TYPE_BA_INVESTMENT",
    CON_SERVICE_OFFERING_TYPE_INVESTOR_INVESTMENT: "CON_SERVICE_OFFERING_TYPE_INVESTOR_INVESTMENT",
    CON_SERVICE_OFFERING_TYPE_NETWORK_4INVESTOR: "CON_SERVICE_OFFERING_TYPE_NETWORK_4INVESTOR",
	CON_SERVICE_OFFERING_TYPE_NETWORK_STARTUP : "CON_SERVICE_OFFERING_TYPE_NETWORK_STARTUP",
	CON_SERVICE_OFFERING_TYPE_NETWORK_COMMUNITY : "CON_SERVICE_OFFERING_TYPE_NETWORK_COMMUNITY",
	CON_SERVICE_OFFERING_TYPE_NEWS : "CON_SERVICE_OFFERING_TYPE_NEWS",
	CON_SERVICE_OFFERING_TYPE_EVENTS : "CON_SERVICE_OFFERING_TYPE_EVENTS",
	CON_SERVICE_OFFERING_TYPE_SERVICE_VALUATION : "CON_SERVICE_OFFERING_TYPE_SERVICE_VALUATION",
	CON_SERVICE_OFFERING_TYPE_SERVICE_RATING : "CON_SERVICE_OFFERING_TYPE_SERVICE_RATING",
	CON_SERVICE_OFFERING_TYPE_CHALLENGES : "CON_SERVICE_OFFERING_TYPE_CHALLENGES",
	CON_SERVICE_OFFERING_TYPE_TRAINING : "CON_SERVICE_OFFERING_TYPE_TRAINING",
	CON_SERVICE_OFFERING_TYPE_MENTORING : "CON_SERVICE_OFFERING_TYPE_MENTORING",
	CON_SERVICE_OFFERING_TYPE_KNOWLEDGE_DB : "CON_SERVICE_OFFERING_TYPE_KNOWLEDGE_DB",
	CON_SERVICE_OFFERING_TYPE_SERVICE_RESEARCH : "CON_SERVICE_OFFERING_TYPE_SERVICE_RESEARCH",
	CON_SERVICE_OFFERING_TYPE_SERVICE_CONSULTING : "CON_SERVICE_OFFERING_TYPE_SERVICE_CONSULTING",
	CON_SERVICE_OFFERING_TYPE_SERVICE_LEGAL : "CON_SERVICE_OFFERING_TYPE_SERVICE_LEGAL",
	CON_SERVICE_OFFERING_TYPE_SERVICE_CORPORATE : "CON_SERVICE_OFFERING_TYPE_SERVICE_CORPORATE",
	CON_SERVICE_OFFERING_TYPE_SOFTWARE_DEVELOPMENT : "CON_SERVICE_OFFERING_TYPE_SOFTWARE_DEVELOPMENT",
	CON_SERVICE_OFFERING_TYPE_WORKSPACE : "CON_SERVICE_OFFERING_TYPE_WORKSPACE",
	CON_SERVICE_OFFERING_TYPE_SOFTWARE : "CON_SERVICE_OFFERING_TYPE_SOFTWARE",
	CON_SERVICE_OFFERING_TYPE_GOODS_FROMSTARTUPS : "CON_SERVICE_OFFERING_TYPE_GOODS_FROMSTARTUPS",
	CON_SERVICE_OFFERING_TYPE_GOODS_FORSTARTUPS : "CON_SERVICE_OFFERING_TYPE_GOODS_FORSTARTUPS",
	CON_SERVICE_OFFERING_TYPE_DB_STARTUPS : "CON_SERVICE_OFFERING_TYPE_DB_STARTUPS",
	CON_SERVICE_OFFERING_TYPE_DB_PLATFORMS : "CON_SERVICE_OFFERING_TYPE_DB_PLATFORMS",
	CON_SERVICE_OFFERING_TYPE_MATCHING_STARTUPSANDINVETORS : "CON_SERVICE_OFFERING_TYPE_MATCHING_STARTUPSANDINVETORS",
	CON_SERVICE_OFFERING_TYPE_FIND_TEAM : "CON_SERVICE_OFFERING_TYPE_FIND_TEAM",
	CON_SERVICE_OFFERING_TYPE_HUB_LAB : "CON_SERVICE_OFFERING_TYPE_HUB_LAB",
	CON_SERVICE_OFFERING_TYPE_SERVICE_MARKETING : "CON_SERVICE_OFFERING_TYPE_SERVICE_MARKETING",
	CON_SERVICE_OFFERING_TYPE_SERVICE_HOSTING : "CON_SERVICE_OFFERING_TYPE_SERVICE_HOSTING",
	CON_SERVICE_OFFERING_TYPE_TRADING : "CON_SERVICE_OFFERING_TYPE_TRADING",
    CON_SERVICE_OFFERING_TYPE_BUSINESS_DEVELOPMENT: "CON_SERVICE_OFFERING_TYPE_BUSINESS_DEVELOPMENT",
	CON_SERVICE_OFFERING_TYPE_SERVICE_OTHER : "CON_SERVICE_OFFERING_TYPE_SERVICE_OTHER",
}

export const sspQualityCommentEnum = {
    STSP_ENTITY_MILESTONE_DATE_ISSUE: "STSP_ENTITY_MILESTONE_DATE_ISSUE",
    STSP_ROLE_FROM_TO_DATE_UNKNOWN: "STSP_ROLE_FROM_TO_DATE_UNKNOWN",
    STSP_ENTITY_MILESTONE_DATE_MISSING: "STSP_ENTITY_MILESTONE_DATE_MISSING",
    STSP_ENTITY_DATA_RESEARCH: "STSP_ENTITY_DATA_RESEARCH",
    C4A_ROLE_FROM_TO_DATE_UNKNOWN: "C4A_ROLE_FROM_TO_DATE_UNKNOWN",
    C4A_PERSON_IDENTIFICATION_NOT_UNIQUE: "C4A_PERSON_IDENTIFICATION_NOT_UNIQUE",
    STSP_CROWD_USER_SYNC: "STSP_CROWD_USER_SYNC",
    GOOGLE_ALERT_GENERAL_QUERY: "GOOGLE_ALERT_GENERAL_QUERY"
}

export const sspDataReliabilityEnum = {
    CYC_DATA_RELIABILITY_LOW: "CYC_DATA_RELIABILITY_LOW",
    CYC_DATA_RELIABILITY_VAGUE: "CYC_DATA_RELIABILITY_VAGUE",
    CYC_DATA_RELIABILITY_INCONSISTENT: "CYC_DATA_RELIABILITY_INCONSISTENT",
    CYC_DATA_RELIABILITY_CREDIBLE: "CYC_DATA_RELIABILITY_CREDIBLE",
    CYC_DATA_RELIABILITY_FIRM: "CYC_DATA_RELIABILITY_FIRM"
};

export const sspRoleTypeEnum = {
	IKP_ROLE_TYPE_FOUNDER: "IKP_ROLE_TYPE_FOUNDER",
	IKP_ROLE_TYPE_CO_FOUNDER: "IKP_ROLE_TYPE_CO_FOUNDER",
	IKP_ROLE_TYPE_INVESTOR: "IKP_ROLE_TYPE_INVESTOR",
	IKP_ROLE_TYPE_LEAD_INVESTOR: "IKP_ROLE_TYPE_LEAD_INVESTOR",
	IKP_ROLE_TYPE_MARKETING: "IKP_ROLE_TYPE_MARKETING",
	IKP_ROLE_TYPE_SALES: "IKP_ROLE_TYPE_SALES",
	IKP_ROLE_TYPE_PRODUCT_MANAGER: "IKP_ROLE_TYPE_PRODUCT_MANAGER",
	IKP_ROLE_TYPE_BUSINESS_DEVELOPMENT: "IKP_ROLE_TYPE_BUSINESS_DEVELOPMENT",
	IKP_ROLE_TYPE_PROJECT_MANAGER: "IKP_ROLE_TYPE_PROJECT_MANAGER",
	IKP_ROLE_TYPE_CEO: "IKP_ROLE_TYPE_CEO",
	IKP_ROLE_TYPE_CFO: "IKP_ROLE_TYPE_CFO",
	IKP_ROLE_TYPE_CIO: "IKP_ROLE_TYPE_CIO",
	IKP_ROLE_TYPE_CTO: "IKP_ROLE_TYPE_CTO",
	IKP_ROLE_TYPE_COO: "IKP_ROLE_TYPE_COO",
	IKP_ROLE_TYPE_DIRECTOR: "IKP_ROLE_TYPE_DIRECTOR",
	IKP_ROLE_TYPE_MANAGER: "IKP_ROLE_TYPE_MANAGER",
	IKP_ROLE_TYPE_ADVISOR: "IKP_ROLE_TYPE_ADVISOR",
	IKP_ROLE_TYPE_CHAIRMAN: "IKP_ROLE_TYPE_CHAIRMAN",
	IKP_ROLE_TYPE_BOARD_DIRECTOR: "IKP_ROLE_TYPE_BOARD_DIRECTOR",
	//IKP_ROLE_TYPE_COMPLIANCE: "IKP_ROLE_TYPE_COMPLIANCE",
	IKP_ROLE_TYPE_CPO: "IKP_ROLE_TYPE_CPO",
	IKP_ROLE_TYPE_CCO: "IKP_ROLE_TYPE_CCO",
	IKP_ROLE_TYPE_CORE: "IKP_ROLE_TYPE_CORE",
	IKP_ROLE_TYPE_UNKNOWN: "IKP_ROLE_TYPE_UNKNOWN",
	IKP_ROLE_TYPE_OTHER: "IKP_ROLE_TYPE_OTHER"
}

export const sspMarketRelevantRolesEnum = {
	CON_MARKET_RELEVANT_ROLES_PROJECTMGT : "CON_MARKET_RELEVANT_ROLES_PROJECTMGT",
	CON_MARKET_RELEVANT_ROLES_ENTRPRENEUR : "CON_MARKET_RELEVANT_ROLES_ENTRPRENEUR",
	CON_MARKET_RELEVANT_ROLES_REQUIREMENTS : "CON_MARKET_RELEVANT_ROLES_REQUIREMENTS",
	CON_MARKET_RELEVANT_ROLES_ITDEV : "CON_MARKET_RELEVANT_ROLES_ITDEV",
	CON_MARKET_RELEVANT_ROLES_ITPROTO : "CON_MARKET_RELEVANT_ROLES_ITPROTO",
	CON_MARKET_RELEVANT_ROLES_TESTING : "CON_MARKET_RELEVANT_ROLES_TESTING",
	CON_MARKET_RELEVANT_ROLES_TECHNICLCHANGE : "CON_MARKET_RELEVANT_ROLES_TECHNICLCHANGE",
	CON_MARKET_RELEVANT_ROLES_BLOCKCHAIN : "CON_MARKET_RELEVANT_ROLES_BLOCKCHAIN",
	CON_MARKET_RELEVANT_ROLES_ACCOUNTING : "CON_MARKET_RELEVANT_ROLES_ACCOUNTING",
	CON_MARKET_RELEVANT_ROLES_FINANALYSIS : "CON_MARKET_RELEVANT_ROLES_FINANALYSIS",
	CON_MARKET_RELEVANT_ROLES_MARKTING : "CON_MARKET_RELEVANT_ROLES_MARKTING",
	CON_MARKET_RELEVANT_ROLES_RESEARCH : "CON_MARKET_RELEVANT_ROLES_RESEARCH",
	CON_MARKET_RELEVANT_ROLES_DESIGNER : "CON_MARKET_RELEVANT_ROLES_DESIGNER",
	CON_MARKET_RELEVANT_ROLES_MEDICINE : "CON_MARKET_RELEVANT_ROLES_MEDICINE",
	CON_MARKET_RELEVANT_ROLES_CHEMESTRY : "CON_MARKET_RELEVANT_ROLES_CHEMESTRY",
	CON_MARKET_RELEVANT_ROLES_DIRECTSALES : "CON_MARKET_RELEVANT_ROLES_DIRECTSALES",
	CON_MARKET_RELEVANT_ROLES_SALESFORCE : "CON_MARKET_RELEVANT_ROLES_SALESFORCE",
	CON_MARKET_RELEVANT_ROLES_MECHANIC : "CON_MARKET_RELEVANT_ROLES_MECHANIC",
	CON_MARKET_RELEVANT_ROLES_HOTELINDUSTRY : "CON_MARKET_RELEVANT_ROLES_HOTELINDUSTRY",
}



export const sspHighLevelSectorEnum = {
    // CON_HIGH_LEVEL_SECTOR_DODEFINE : "CON_HIGH_LEVEL_SECTOR_DODEFINE",
    CON_HIGH_LEVEL_SECTOR_ENERGY: "CON_HIGH_LEVEL_SECTOR_ENERGY",
    CON_HIGH_LEVEL_SECTOR_ENVIRONMENT: "CON_HIGH_LEVEL_SECTOR_ENVIRONMENT",
    CON_HIGH_LEVEL_SECTOR_RETAIL: "CON_HIGH_LEVEL_SECTOR_RETAIL",
    CON_HIGH_LEVEL_SECTOR_FINANCE: "CON_HIGH_LEVEL_SECTOR_FINANCE",
    CON_HIGH_LEVEL_SECTOR_IT: "CON_HIGH_LEVEL_SECTOR_IT",
    CON_HIGH_LEVEL_SECTOR_RESEARCH: "CON_HIGH_LEVEL_SECTOR_RESEARCH",
    CON_HIGH_LEVEL_SECTOR_ESTATE: "CON_HIGH_LEVEL_SECTOR_ESTATE",
    CON_HIGH_LEVEL_SECTOR_SERVICES: "CON_HIGH_LEVEL_SECTOR_SERVICES",
    CON_HIGH_LEVEL_SECTOR_SALES: "CON_HIGH_LEVEL_SECTOR_SALES",
    CON_HIGH_LEVEL_SECTOR_GREEN: "CON_HIGH_LEVEL_SECTOR_GREEN",
    CON_HIGH_LEVEL_SECTOR_MATERIALS: "CON_HIGH_LEVEL_SECTOR_MATERIALS",
    CON_HIGH_LEVEL_SECTOR_INDUSTRIAL: "CON_HIGH_LEVEL_SECTOR_INDUSTRIAL",
    CON_HIGH_LEVEL_SECTOR_STARTUP: "CON_HIGH_LEVEL_SECTOR_STARTUP",
    CON_HIGH_LEVEL_SECTOR_ALL: "CON_HIGH_LEVEL_SECTOR_ALL"
}

export const sspSectorEnum = {
    // CON_DETAIL_SECTOR__DODEFINE : "CON_DETAIL_SECTOR__DODEFINE",
    CON_DETAIL_SECTOR_ENERGY_ELECTRIC: "CON_DETAIL_SECTOR_ENERGY_ELECTRIC",
    CON_DETAIL_SECTOR_ENERGY_GAS: "CON_DETAIL_SECTOR_ENERGY_GAS",
    CON_DETAIL_SECTOR_ENERGY_INDEP: "CON_DETAIL_SECTOR_ENERGY_INDEP",
    CON_DETAIL_SECTOR_ENERGY_MULTI: "CON_DETAIL_SECTOR_ENERGY_MULTI",
    CON_DETAIL_SECTOR_ENERGY_WATER: "CON_DETAIL_SECTOR_ENERGY_WATER",
    CON_DETAIL_SECTOR_ENERGY_HYDROGEN: "CON_DETAIL_SECTOR_ENERGY_HYDROGEN",
    CON_DETAIL_SECTOR_ENVIRONMENT_ENVIRONMENT: "CON_DETAIL_SECTOR_ENVIRONMENT_ENVIRONMENT",
    CON_DETAIL_SECTOR_ENVIRONMENT_HEALTHCARE: "CON_DETAIL_SECTOR_ENVIRONMENT_HEALTHCARE",
    CON_DETAIL_SECTOR_ENVIRONMENT_SOCIAL: "CON_DETAIL_SECTOR_ENVIRONMENT_SOCIAL",
    CON_DETAIL_SECTOR_ENVIRONMENT_AGRICULTURE: "CON_DETAIL_SECTOR_ENVIRONMENT_AGRICULTURE",
    CON_DETAIL_SECTOR_RETAIL_CLOTHING: "CON_DETAIL_SECTOR_RETAIL_CLOTHING",
    CON_DETAIL_SECTOR_RETAIL_MARKET_DIST: "CON_DETAIL_SECTOR_RETAIL_MARKET_DIST",
    CON_DETAIL_SECTOR_RETAIL_FOOD_STAPLES: "CON_DETAIL_SECTOR_RETAIL_FOOD_STAPLES",
    CON_DETAIL_SECTOR_RETAIL_FOOD_PRODUCTS: "CON_DETAIL_SECTOR_RETAIL_FOOD_PRODUCTS",
    CON_DETAIL_SECTOR_RETAIL_BEVERAGE: "CON_DETAIL_SECTOR_RETAIL_BEVERAGE",
    CON_DETAIL_SECTOR_RETAIL_HOUSEHOLD: "CON_DETAIL_SECTOR_RETAIL_HOUSEHOLD",
    CON_DETAIL_SECTOR_RETAIL_PERSONAL: "CON_DETAIL_SECTOR_RETAIL_PERSONAL",
    CON_DETAIL_SECTOR_RETAIL_TOBACCO: "CON_DETAIL_SECTOR_RETAIL_TOBACCO",
    CON_DETAIL_SECTOR_RETAIL_CONSUMER_STAPLES: "CON_DETAIL_SECTOR_RETAIL_CONSUMER_STAPLES",
    CON_DETAIL_SECTOR_RETAIL_AUTOMOBILE_COMP: "CON_DETAIL_SECTOR_RETAIL_AUTOMOBILE_COMP",
    CON_DETAIL_SECTOR_RETAIL_AUTOMOBILES: "CON_DETAIL_SECTOR_RETAIL_AUTOMOBILES",
    CON_DETAIL_SECTOR_RETAIL_CONSUMER_SERV: "CON_DETAIL_SECTOR_RETAIL_CONSUMER_SERV",
    CON_DETAIL_SECTOR_RETAIL_HOTEL: "CON_DETAIL_SECTOR_RETAIL_HOTEL",
    CON_DETAIL_SECTOR_RETAIL_LEISURE_PRODUCTS: "CON_DETAIL_SECTOR_RETAIL_LEISURE_PRODUCTS",
    CON_DETAIL_SECTOR_RETAIL_MULTILINE: "CON_DETAIL_SECTOR_RETAIL_MULTILINE",
    CON_DETAIL_SECTOR_RETAIL_SPECIALITY: "CON_DETAIL_SECTOR_RETAIL_SPECIALITY",
    CON_DETAIL_SECTOR_RETAIL_INTERNET: "CON_DETAIL_SECTOR_RETAIL_INTERNET",
    CON_DETAIL_SECTOR_RETAIL_TRAVEL: "CON_DETAIL_SECTOR_RETAIL_TRAVEL",
    CON_DETAIL_SECTOR_RETAIL_CREATIVE: "CON_DETAIL_SECTOR_RETAIL_CREATIVE",
    CON_DETAIL_SECTOR_RETAIL_MUSIC: "CON_DETAIL_SECTOR_RETAIL_MUSIC",
    CON_DETAIL_SECTOR_RETAIL_LEISURE: "CON_DETAIL_SECTOR_RETAIL_LEISURE",
    CON_DETAIL_SECTOR_RETAIL_SPORTS: "CON_DETAIL_SECTOR_RETAIL_SPORTS",
    CON_DETAIL_SECTOR_FINANCE_BANKING: "CON_DETAIL_SECTOR_FINANCE_BANKING",
    CON_DETAIL_SECTOR_FINANCE_CAPITAL: "CON_DETAIL_SECTOR_FINANCE_CAPITAL",
    CON_DETAIL_SECTOR_FINANCE_CONSUMER: "CON_DETAIL_SECTOR_FINANCE_CONSUMER",
    CON_DETAIL_SECTOR_FINANCE_DIVERSIFIED: "CON_DETAIL_SECTOR_FINANCE_DIVERSIFIED",
    CON_DETAIL_SECTOR_FINANCE_INSURANCE: "CON_DETAIL_SECTOR_FINANCE_INSURANCE",
    CON_DETAIL_SECTOR_FINANCE_MORTGAGE: "CON_DETAIL_SECTOR_FINANCE_MORTGAGE",
    CON_DETAIL_SECTOR_FINANCE_THRIFTS: "CON_DETAIL_SECTOR_FINANCE_THRIFTS",
    CON_DETAIL_SECTOR_IT_INFORMATION: "CON_DETAIL_SECTOR_IT_INFORMATION",
    CON_DETAIL_SECTOR_IT_GAMES: "CON_DETAIL_SECTOR_IT_GAMES",
    CON_DETAIL_SECTOR_IT_SAAS: "CON_DETAIL_SECTOR_IT_SAAS",
    CON_DETAIL_SECTOR_IT_PROGRAMMING: "CON_DETAIL_SECTOR_IT_PROGRAMMING",
    CON_DETAIL_SECTOR_IT_CONTENT: "CON_DETAIL_SECTOR_IT_CONTENT",
    CON_DETAIL_SECTOR_IT_DATA: "CON_DETAIL_SECTOR_IT_DATA",
    CON_DETAIL_SECTOR_IT_BLOCKCHAIN: "CON_DETAIL_SECTOR_IT_BLOCKCHAIN",
    CON_DETAIL_SECTOR_RESEARCH_SCIENCE: "CON_DETAIL_SECTOR_RESEARCH_SCIENCE",
    CON_DETAIL_SECTOR_RESEARCH_ENGINEERING: "CON_DETAIL_SECTOR_RESEARCH_ENGINEERING",
    CON_DETAIL_SECTOR_RESEARCH_STUDIES: "CON_DETAIL_SECTOR_RESEARCH_STUDIES",
    CON_DETAIL_SECTOR_ESTATE_HOME: "CON_DETAIL_SECTOR_ESTATE_HOME",
    CON_DETAIL_SECTOR_ESTATE_PROPERTY: "CON_DETAIL_SECTOR_ESTATE_PROPERTY",
    CON_DETAIL_SECTOR_ESTATE_EQUITY: "CON_DETAIL_SECTOR_ESTATE_EQUITY",
    CON_DETAIL_SECTOR_SERVICES_RECRUITMENT: "CON_DETAIL_SECTOR_SERVICES_RECRUITMENT",
    CON_DETAIL_SECTOR_SERVICES_BUSINESS: "CON_DETAIL_SECTOR_SERVICES_BUSINESS",
    CON_DETAIL_SECTOR_SERVICES_HOSPITALITY: "CON_DETAIL_SECTOR_SERVICES_HOSPITALITY",
    CON_DETAIL_SECTOR_SERVICES_EDUCATION: "CON_DETAIL_SECTOR_SERVICES_EDUCATION",
    CON_DETAIL_SECTOR_SERVICES_LAW: "CON_DETAIL_SECTOR_SERVICES_LAW",
    CON_DETAIL_SECTOR_SALES_SALES: "CON_DETAIL_SECTOR_SALES_SALES",
    CON_DETAIL_SECTOR_SALES_TELECOM: "CON_DETAIL_SECTOR_SALES_TELECOM",
    CON_DETAIL_SECTOR_SALES_ENTERTAINMENT: "CON_DETAIL_SECTOR_SALES_ENTERTAINMENT",
    CON_DETAIL_SECTOR_SALES_MEDIA: "CON_DETAIL_SECTOR_SALES_MEDIA",
    CON_DETAIL_SECTOR_SALES_PR: "CON_DETAIL_SECTOR_SALES_PR",
    CON_DETAIL_SECTOR_SALES_ADS: "CON_DETAIL_SECTOR_SALES_ADS",
    CON_DETAIL_SECTOR_GREEN_PRODUCTS: "CON_DETAIL_SECTOR_GREEN_PRODUCTS",
    CON_DETAIL_SECTOR_GREEN_CHARITY: "CON_DETAIL_SECTOR_GREEN_CHARITY",
    CON_DETAIL_SECTOR_MATERIALS_CHEM: "CON_DETAIL_SECTOR_MATERIALS_CHEM",
    CON_DETAIL_SECTOR_MATERIALS_CONSTRUCTION: "CON_DETAIL_SECTOR_MATERIALS_CONSTRUCTION",
    CON_DETAIL_SECTOR_MATERIALS_CONTAINERS: "CON_DETAIL_SECTOR_MATERIALS_CONTAINERS",
    CON_DETAIL_SECTOR_MATERIALS_METAL: "CON_DETAIL_SECTOR_MATERIALS_METAL",
    CON_DETAIL_SECTOR_MATERIALS_PAPER: "CON_DETAIL_SECTOR_MATERIALS_PAPER",
    CON_DETAIL_SECTOR_MATERIALS_COMPOSITE: "CON_DETAIL_SECTOR_MATERIALS_COMPOSITE",
    CON_DETAIL_SECTOR_MATERIALS_GRAPHENE: "CON_DETAIL_SECTOR_MATERIALS_GRAPHENE",
    CON_DETAIL_SECTOR_MATERIALS_NATURALFIBRE: "CON_DETAIL_SECTOR_MATERIALS_NATURALFIBRE",
    CON_DETAIL_SECTOR_INDUSTRIAL_AEROSPACE: "CON_DETAIL_SECTOR_INDUSTRIAL_AEROSPACE",
    CON_DETAIL_SECTOR_INDUSTRIAL_AIR_FREIGHT: "CON_DETAIL_SECTOR_INDUSTRIAL_AIR_FREIGHT",
    CON_DETAIL_SECTOR_INDUSTRIAL_AIRLINES: "CON_DETAIL_SECTOR_INDUSTRIAL_AIRLINES",
    CON_DETAIL_SECTOR_INDUSTRIAL_BUILDING: "CON_DETAIL_SECTOR_INDUSTRIAL_BUILDING",
    CON_DETAIL_SECTOR_INDUSTRIAL_COMMERCIAL: "CON_DETAIL_SECTOR_INDUSTRIAL_COMMERCIAL",
    CON_DETAIL_SECTOR_INDUSTRIAL_CONSTRUCTION: "CON_DETAIL_SECTOR_INDUSTRIAL_CONSTRUCTION",
    CON_DETAIL_SECTOR_INDUSTRIAL_ELECTRICAL: "CON_DETAIL_SECTOR_INDUSTRIAL_ELECTRICAL",
    CON_DETAIL_SECTOR_INDUSTRIAL_INDUSTRIAL: "CON_DETAIL_SECTOR_INDUSTRIAL_INDUSTRIAL",
    CON_DETAIL_SECTOR_INDUSTRIAL_MACHINERY: "CON_DETAIL_SECTOR_INDUSTRIAL_MACHINERY",
    CON_DETAIL_SECTOR_INDUSTRIAL_MARINE: "CON_DETAIL_SECTOR_INDUSTRIAL_MARINE",
    CON_DETAIL_SECTOR_INDUSTRIAL_SERVICES: "CON_DETAIL_SECTOR_INDUSTRIAL_SERVICES",
    CON_DETAIL_SECTOR_INDUSTRIAL_RNR: "CON_DETAIL_SECTOR_INDUSTRIAL_RNR",
    CON_DETAIL_SECTOR_INDUSTRIAL_TRADING: "CON_DETAIL_SECTOR_INDUSTRIAL_TRADING",
    CON_DETAIL_SECTOR_INDUSTRIAL_TRANSPORTATION: "CON_DETAIL_SECTOR_INDUSTRIAL_TRANSPORTATION",
    CON_DETAIL_SECTOR_STARTUP_CROWDFUNDING: "CON_DETAIL_SECTOR_STARTUP_CROWDFUNDING",
    CON_DETAIL_SECTOR_INDUSTRIAL_3DPRINTING: "CON_DETAIL_SECTOR_INDUSTRIAL_3DPRINTING",
    CON_DETAIL_SECTOR_STARTUP_SERVICES: "CON_DETAIL_SECTOR_STARTUP_SERVICES",
    CON_DETAIL_SECTOR_STARTUP_CONSULTING: "CON_DETAIL_SECTOR_STARTUP_CONSULTING",
    CON_DETAIL_SECTOR_STARTUP_EDUCATION: "CON_DETAIL_SECTOR_STARTUP_EDUCATION",
    CON_DETAIL_SECTOR_STARTUP_COMMUNITY: "CON_DETAIL_SECTOR_STARTUP_COMMUNITY",
    CON_DETAIL_SECTOR_STARTUP_MARKETING: "CON_DETAIL_SECTOR_STARTUP_MARKETING",
    CON_DETAIL_SECTOR_STARTUP_LEGAL: "CON_DETAIL_SECTOR_STARTUP_LEGAL",
    CON_DETAIL_SECTOR_STARTUP_SOFTWARE: "CON_DETAIL_SECTOR_STARTUP_SOFTWARE",
    CON_DETAIL_SECTOR_STARTUP_OTHER: "CON_DETAIL_SECTOR_STARTUP_OTHER",
    CON_DETAIL_SECTOR_ALL_ALL: "CON_DETAIL_SECTOR_ALL_ALL"
}


export const sspAdStatusEnum = {
    CON_AD_STATUS_CREATED: "Created"
  , CON_AD_STATUS_DRAFT: "In finalization"
  , CON_AD_STATUS_ACTIVE: "Active"
  , CON_AD_STATUS_MAINTAINED: "Active and Updated"
  , CON_AD_STATUS_ONHOLD: "On Hold"
  , CON_AD_STATUS_DEACTIVATED: "Deactivated"
};

/*
* Key prefix: DOC_DOC_CLASS_
*/
export const sspDocClassEnum = {
    DOC_DOC_CLASS_USER: "A personal user document"
    , DOC_DOC_CLASS_COMPANY: "A company or project document"
    , DOC_DOC_CLASS_INVESTMENT: "A investment related document"
    , DOC_DOC_CLASS_PRODUCT: "A product releated document"
    , DOC_DOC_CLASS_RESEARCH: "A research releated document"
};

/*
* Key prefix: DOC_DOC_TYPE_
*/
export const sspDocTypeEnum = {
    DOC_DOC_TYPE_USER_CV: "CV"
    , DOC_DOC_TYPE_USER_INTERVIEW: "Documented interview"
    , DOC_DOC_TYPE_USER_CREDIT: "User credit statement"
    , DOC_DOC_TYPE_USER_PERSONAL: "Personal user document"
    , DOC_DOC_TYPE_COMPANY_REPORT: "Report, annual or any other reporting period, document"
    , DOC_DOC_TYPE_COMPANY_FINANCIAL: "Company financial statement document"
    , DOC_DOC_TYPE_COMPANY_PRESENTATION: "Company presentation document"
    , DOC_DOC_TYPE_COMPANY_NEWS: "Company news document"
    , DOC_DOC_TYPE_COMPANY_PRES_RELEASE: "Company press release document"
    , DOC_DOC_TYPE_COMPANY_BUSINESSPLAN: "Business plan document"
    , DOC_DOC_TYPE_COMPANY_PITCHDECK: "Pitchdeck document"
    , DOC_DOC_TYPE_COMPANY_COMPANY: "Company document"
    , DOC_DOC_TYPE_INVESTMENT_PROSPECTUS: "Pospectus document"
    , DOC_DOC_TYPE_INVESTMENT_KEY_SHEET: "Key investment information sheet"
    , DOC_DOC_TYPE_INVESTMENT_INVESTOR_INFO: "Additional information for investors"
    , DOC_DOC_TYPE_INVESTMENT_TERMSHEET: "Term sheet document"
    , DOC_DOC_TYPE_INVESTMENT_SHAREHOLDER: "Shareholder agreement"
    , DOC_DOC_TYPE_INVESTMENT_INVESTMENT: "Investment document"
    , DOC_DOC_TYPE_PRODUCT_FACTSHEET: "Product factsheeet"
    , DOC_DOC_TYPE_PRODUCT_SERVICE: "Service description"
    , DOC_DOC_TYPE_PRODUCT_PRODUCT: "Product description"
    , DOC_DOC_TYPE_PRODUCT_IDEA: "Idea & Innovation document"
    , DOC_DOC_TYPE_RESEARCH_ANALYSIS: "Analysis report"
    , DOC_DOC_TYPE_RESEARCH_RESEARCH_REPORT: "Research report"
    , DOC_DOC_TYPE_RESEARCH_PROFESSIONAL: "Profession report"
    , DOC_DOC_TYPE_RESEARCH_MEDICAL: "Medical writing"
    , DOC_DOC_TYPE_RESEARCH_STATISTICS: "Statistics"
    , DOC_DOC_TYPE_RESEARCH_MARKET: "Market research"
    , DOC_DOC_TYPE_RESEARCH_RESEARCH_DOC: "Research document"
//    , DOC_DOC_TYPE_ALL_ALL: "All document types"
    
};




/*
* Key prefix: CON_LOOKING_FOR_TYPE_
*/
export const sspLookingForTypeEnum = {
    CON_LOOKING_FOR_TYPE_BETATESTER: "CON_LOOKING_FOR_TYPE_BETATESTER",
    CON_LOOKING_FOR_TYPE_EARLYADAPTER: "CON_LOOKING_FOR_TYPE_EARLYADAPTER",
    CON_LOOKING_FOR_TYPE_MONEY: "CON_LOOKING_FOR_TYPE_MONEY",
    CON_LOOKING_FOR_TYPE_REVIEWER: "CON_LOOKING_FOR_TYPE_REVIEWER",
    CON_LOOKING_FOR_TYPE_EMPLOYEES: "CON_LOOKING_FOR_TYPE_EMPLOYEES",
    CON_LOOKING_FOR_TYPE_COFOUNDER: "CON_LOOKING_FOR_TYPE_COFOUNDER",
    CON_LOOKING_FOR_TYPE_ADVISOR: "CON_LOOKING_FOR_TYPE_ADVISOR"
}

// key prefix: ADDRESS_TYPE_ENUM

export const ADDRESS_TYPE_ENUM = {
    RESIDENCE: "RESIDENCE",
    BILLING: "BILLING",
    BUSINESS: "BUSINESS",
}

/*
* Key prefix: CON_IP_TYPE_
*/
export const sspIntellectualPropertyTypeEnum = {
    CON_IP_TYPE_PATENT: "CON_IP_TYPE_PATENT"
    , CON_IP_TYPE_TRADEMARK: "CON_IP_TYPE_TRADEMARK"
    , CON_IP_TYPE_UNKOWN: "CON_IP_TYPE_UNKOWN"
};

export const sspServiceAreaEnum = {
    CON_SERVICE_AREA_TYPE_GLOBAL: 'Service Area worldwide'
    , CON_SERVICE_AREA_TYPE_APAC: 'Service Area Asian Pacific (APAC)'
    , CON_SERVICE_AREA_TYPE_EMEA: 'Service Area European, Middle East, Africa (EMEA)'
    , CON_SERVICE_AREA_TYPE_LATAM: 'Service Area Latain America (LATAM)'
    , CON_SERVICE_AREA_TYPE_EEA: 'Service Area  European Economic Area (EEA)'
    , CON_SERVICE_AREA_TYPE_DACH: 'Service Area Germany (D), Austria (A) and Switzerland (CH'
    , CON_SERVICE_AREA_TYPE_ANZ: 'Service Area  Australia New Zealand(ANZ)'
    , CON_SERVICE_AREA_TYPE_NORAM: 'Service Area  North America Region, i.e. CA, US, Mexico  (NORAM)'
    , CON_SERVICE_AREA_TYPE_UNKNOWN: 'Service Area unknown'
};

//TO DO: translation
export const sspServiceAudienceTypeEnum = {
    CON_SERVICE_AUDIENCE_TYPE_STARTUP: "CON_SERVICE_AUDIENCE_TYPE_STARTUP",
    CON_SERVICE_AUDIENCE_TYPE_INVESTOR: "CON_SERVICE_AUDIENCE_TYPE_INVESTOR",
    CON_SERVICE_AUDIENCE_TYPE_INNOVATION: "CON_SERVICE_AUDIENCE_TYPE_INNOVATION",
    CON_SERVICE_AUDIENCE_TYPE_SERVICEPROVIDER: "CON_SERVICE_AUDIENCE_TYPE_SERVICEPROVIDER",
    CON_SERVICE_AUDIENCE_TYPE_ANY: "CON_SERVICE_AUDIENCE_TYPE_ANY",
    CON_SERVICE_AUDIENCE_TYPE_UNKNOWN: "CON_SERVICE_AUDIENCE_TYPE_UNKNOWN",
}

export const sspInstitutionTypeEnum = {
    CON_INSTITUTION_TYPE_INVESTMENT: "CON_INSTITUTION_TYPE_INVESTMENT",
    CON_INSTITUTION_TYPE_HUB_LAB: "CON_INSTITUTION_TYPE_HUB_LAB",
    CON_INSTITUTION_TYPE_UNIVERSITY: "CON_INSTITUTION_TYPE_UNIVERSITY",
    CON_INSTITUTION_TYPE_NETWORK: "CON_INSTITUTION_TYPE_NETWORK",
    CON_INSTITUTION_TYPE_INFORMATION: "CON_INSTITUTION_TYPE_INFORMATION",
    CON_INSTITUTION_TYPE_OTHER: "CON_INSTITUTION_TYPE_OTHER",
    CON_INSTITUTION_TYPE_UNKNOWN: "CON_INSTITUTION_TYPE_UNKNOWN"
};

export const  sspServiceStatusEnum =  {
	CON_SERVICE_STATUS_ACTIVE : "CON_SERVICE_STATUS_ACTIVE",
	CON_SERVICE_IN_PREPARATION : "CON_SERVICE_IN_PREPARATION",
	CON_SERVICE_ON_HOLD : "CON_SERVICE_ON_HOLD",
	CON_SERVICE_EOL_ANNOUNCED : "CON_SERVICE_EOL_ANNOUNCED",
	CON_SERVICE_DEACTIVATED : "CON_SERVICE_DEACTIVATED",
	CON_SERVICE_UNKNOWN : "CON_SERVICE_UNKNOWN",
}

export const sspOrganizationContactTypeEnum = {
    IED_CONTACT_STATUS_CONTACTED: "IED_CONTACT_STATUS_CONTACTED"
    , IED_CONTACT_STATUS_NOT_CONTACTED: "IED_CONTACT_STATUS_NOT_CONTACTED"
    , IED_CONTACT_STATUS_CONTACT_PLANNED: "IED_CONTACT_STATUS_CONTACT_PLANNED"
    , IED_CONTACT_STATUS_CONTACT_REJECTED: "IED_CONTACT_STATUS_CONTACT_REJECTED"
    , IED_CONTACT_STATUS_CONTACT_JOINED: "IED_CONTACT_STATUS_CONTACT_JOINED"
};

export const  sspLifecycleStatusEnum =  {
	CYC_STATUS_DRAFT : "CYC_STATUS_DRAFT",
	CYC_STATUS_ACTIVE : "CYC_STATUS_ACTIVE",
	CYC_STATUS_CORRECTED : "CYC_STATUS_CORRECTED",
	CYC_STATUS_UPDATED : "CYC_STATUS_UPDATED",
	CYC_STATUS_DELETED : "CYC_STATUS_DELETED",
	CYC_STATUS_ARCHIVED : "CYC_STATUS_ARCHIVED",
	CYC_STATUS_REACTIVATED : "CYC_STATUS_REACTIVATED",
    CYC_STATUS_ONHOLD: "CYC_STATUS_ONHOLD",
	CYC_STATUS_UNKNOWN : "CYC_STATUS_UNKNOWN",
}
