import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import CompanyFocusSectorsSelector from "./CompanyFocusSectorsSelector"
import { sspHighLevelSectorEnum, sspSectorEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../utils';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const CompanyFocusSectorsDialog = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { open, apiCallProgress } = props;
    const classes = useStyles();
    const [enitityObj, setEntityObj] = useState({});
    const [compayFocusSectors, setCompanyFocusSectors] = useState([]);
    useEffect(() => {
        setEntityObj(props.entity)
        if (!!props.CompanyFocusSectors.length) {
            setCompanyFocusSectors(props.CompanyFocusSectors)
        } else {
            setCompanyFocusSectors([{
                key: '',
                value: ''
            }]
            )
        }
    }, [props.companyFocusSectors])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };
    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(enitityObj.Entity.companyFocusSectors)
    };



    const OnChangeCompanyObjSector = (key, value, index) => {
        debugger
        console.log(key, "jdkhddh")
        let _companyFocusDetailedSectors = key?.detailSector?.map((item) => {
          let highLevel = item.split("_").slice(0, 4);
          highLevel[1] = "HIGH_LEVEL";
          return { key: highLevel.join("_"), value: item };
        });
        let _companyFocusSectors = _companyFocusDetailedSectors;
        let _enitityObj = Object.assign({}, enitityObj);
        _enitityObj.Entity.companyFocusSectors = _companyFocusSectors;
        setEntityObj(_enitityObj);
      };
    
      const onRemoveFocusSector = (key, value, index) => {
        let _enitityObj = Object.assign({}, enitityObj);
        let _companyFocusSector = _enitityObj.Entity?.companyFocusSectors.filter(
          (item) => item.value !== key
        );
        _enitityObj.Entity.companyFocusSectors = _companyFocusSector;
        setEntityObj(_enitityObj);
      };
      let fil = enitityObj?.Entity?.companyFocusSectors?.filter((item) => {
        return item.value !== null;
      })
      let highLevelSector= fil?.map((item) => {
          return item.key;
        })
        let detailSector = fil?.map((item) => {
            return item.value;
        })
      
    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="institutionIdnumber-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="institutionIdnumber-dialog-title">
                        {!!props.CompanyFocusSectors.length
                            ? t("dmTool:updateCompanyFocusSectorDialogTitle", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addCompanyFocusSectorDialogTitle", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                    <CompanyFocusSectorsSelector
                      enitityObj={enitityObj}
                      OnChangeCompanyObjSector={OnChangeCompanyObjSector}
                      label={t(
                        `${"dmTool:companyFocusSectorsInputFieldLabel"}`
                      )}
                      placeholder={t(
                        `${"dmTool:selectDocumentFocusSectorsPlcHldr"}`
                      )}
                      // selectedFocus={enitityObj?.companyFocusSectors}
                      selectedFocus={{
                        highLevelSector: highLevelSector,
                        detailSector: detailSector,
                      }}
                      companyFocusSectors={enitityObj?.Entity?.companyFocusSectors}
                      onChange={(data) => {
                        console.log(data);
                      }}
                      selectorOption={enitityObj?.Entity?.companyFocusSectors}
                      onRemoveFocusSector={onRemoveFocusSector}
                    />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color='primary'
                            className={classes.addButton}
                        >
                            {!!props.CompanyFocusSectors.length ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default CompanyFocusSectorsDialog;