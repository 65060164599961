import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, time_ago } from '../../../utils';
import Divider from '@material-ui/core/Divider';
import MoreVert from '@material-ui/icons/MoreVert';
import { ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import Tooltip from '@material-ui/core/Tooltip';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Warning from '@material-ui/icons/Warning';
import InstistutionDialog from '../../InstistutionDialog/InstistutionDialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';

import {
    updateEntityToServer,
    syncToEnvironment,
    createNewVersion,
    editVersion,
    deleteOfflineVersions,
    undoMarkAsVersion
} from "../../../Store/Entity/actions";
import { connect } from "react-redux";

import DeletePermanentlyDialog from '../../DeletePermanentlyDialog/DeletePermanently';
import CompareDialog from '../../CompareDialog/CompareDialog';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 13,
    },
    flex: {
        display: 'flex',
        gap: 10

    },
    activeListItem: {
        boxShadow: "0px 0px 5px 1px #5ce523",
        marginBottom: "5px",
        border: "2px solid #5ce523",
        paddingRight: "16px"
    },
    deletedItem: {
        boxShadow: "0px 0px 5px 1px red",
        marginBottom: "5px",
        border: "2px solid red",
        paddingRight: "16px",
        background: "yellow"
    },
    passiveListItem: {
        boxShadow: "0px 0px 5px 1px #ccc",
        marginBottom: "5px",
        border: "2px solid #ccc",
        paddingRight: "16px"
    },
    secondaryListItemText: {
        marginTop: "5px"
    },
    accountCreationInfo: {
        marginRight: "2px",
        marginBottom: "5px"
    },
    listContainer: {
        overflow: 'auto',
        maxHeight: 215,
    },
    cardStatus: {
        marginLeft: "auto",
        display: "grid"
    },
    cardstatusStyle: {
        margin: "0 0 2px 0"
    }
}));

const VersionListItem = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [openCopyAsDialog, setOpenCopyAsDialog] = React.useState(false)
    const [institution, setInstitution] = React.useState(JSON.parse(JSON.stringify(props.data.Entity)))
    const [apiStatus, setApiStatus] = useState("");
    const [apiMessage, setApiMessage] = useState("");
    const lastUpdatedUser = props.version.userId
    const lastSyncedUser = props.version.processingUserId
    const [openCompareDialog, setOpenCompareDialog] = React.useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    const handleClick = (e) => {
        setOpen(true)
        setAnchorEl(e.currentTarget)
    };

    const handleRequestClose = (e) => {
        setOpen(false)
    };
    const handleSyncToDev = (e) => {
        handleSync("DEVELOPMENT")
    }
    const handleSyncToProd = (e) => {
        handleSync("PRODUCTION")
    }
    const handleSync = (environment) => {
        props.syncToEnvironment && props.syncToEnvironment({
            i_sourceKey: props.data.Entity.i_sourceKey,
            fileSuffixId: props.version.fileSuffixId,
            environment
        })
        refreshMe();
    }
    const saveAs = (e) => {
        props.createNewVersion && props.createNewVersion(
            {
                i_sourceKey: props.data.Entity.i_sourceKey,
                fileSuffixId: props.version.fileSuffixId
            }
        );
        refreshMe();
    }
    const copyAs = (institution) => {
        let _institution = JSON.parse(JSON.stringify(institution));
        _institution.accountName = _institution.accountName + "_copy"
        _institution.i_t_lifecycleElement_status = "CYC_STATUS_ACTIVE";
        _institution.i_sourceKey = "ssp_entity_" + Date.now() + "_" + parseInt(Math.random() * 100000);
        _institution.r_t_id_entity = null
        if (_institution.Events) {
            _institution.Events = _institution.Events.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED")?.map((milestone) => {
                return {
                    ...milestone,
                    i_sourceKey: "ssp_entityevent_" + Date.now() + "_" + parseInt(Math.random() * 100000),
                    Entity: null,
                    r_t_id_event: null,
                    id_crowdAccountRelevantAddress: null
                }
            })
        }
        if (_institution.KeyPerson) {
            _institution.KeyPerson = _institution.KeyPerson.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED")?.map((keyperson) => {
                return {
                    ...keyperson,
                    i_sourceKey: "ssp_keyperson_" + Date.now() + "_" + parseInt(Math.random() * 100000),
                    Entity: null,
                    r_t_id_keyPerson: null,
                    r_t_id_keyPersonParent: null,
                    Roles: keyperson.Roles.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED")?.map((role) => {
                        return {
                            ...role,
                            i_sourceKey: "ssp_keyperson_role_" + Date.now() + "_" + parseInt(Math.random() * 100000),
                            r_t_id_entity: null,
                            keyPerson: null
                        }
                    })
                }
            })
        }
        if (!isEmpty(_institution.IntellectualProperties)) {
            _institution.IntellectualProperties = _institution.IntellectualProperties.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED")?.map((ip) => {
                return {
                    ...ip,
                    i_sourceKey: "ssp_intellectualproperty_" + ip.i_t_date_creationTs + "" + parseInt(Math.random() * 100000),
                    Entity: null
                }
            })
        }
        if (_institution.Supplier) {
            _institution.Supplier.i_sourceKey = "ssp_supplierprofile_" + Date.now() + "_" + parseInt(Math.random() * 100000);
            _institution.Supplier.r_t_id_supplierProfile = null;
            _institution.Supplier.Account = null;
            _institution.Supplier.ServiceZones = _institution.Supplier.ServiceZones.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED")?.map((serviceZones) => {
                return {
                    ...serviceZones,
                    i_sourceKey: "ssp_servicezone_" + Date.now() + "_" + parseInt(Math.random() * 100000),
                    r_t_id_serviceZone: null,
                    Account: null
                }
            })
        }
        if (_institution.Documents) {
            _institution.Documents = _institution.Documents.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED")?.map((document) => {
                return {
                    ...document,
                    i_sourceKey: "ssp_entitydocument_" + Date.now() + "" + parseInt(Math.random() * 100000),
                    Entity: null,
                    r_t_id_entityDocument: null,
                    Documents: document.Documents.map(item => {
                        return {
                            ...item,
                            i_sourceKey: "ssp_entitydoc_" + Date.now() + "" + parseInt(Math.random() * 100000),
                            r_t_id_entity: null,
                            parent: null
                        }
                    })
                }
            })
        }
        if (_institution.Rating) {
            _institution.Rating.i_sourceKey = "ssp_rating_" + Date.now() + "_" + parseInt(Math.random() * 100000);
            _institution.Rating.r_t_id_ratedObject = null;
            _institution.Rating.r_t_name_ratedObject = null;
        }
        if (!isEmpty(_institution.CrowdAccountRelevantAddress)) {
            _institution.CrowdAccountRelevantAddress.i_sourceKey = "ssp_crowd_account_relevant_address_" + Date.now() + "_" + parseInt(Math.random() * 100000);
            delete _institution.CrowdAccountRelevantAddress.r_t_id_address;
            _institution.CrowdAccountRelevantAddress.AccountConfidentialData = null;
        }
        if (!_institution.legalForm) {
            _institution.legalForm = { key: null, value: null }
        }
        if (_institution.BlobEntityData) {
            _institution.BlobEntityData.i_sourceKey = "ssp_blobentitydata_" + Date.now() + "_" + parseInt(Math.random() * 100000)
            _institution.BlobEntityData.Entity = null
            _institution.BlobEntityData.sourceSpecificInformation = _institution.BlobEntityData.sourceSpecificInformation?.map((item, index) => {
                if (item.key === "entityCreationTs") {
                    item.value = Date.now()
                }
                return { ...item }
            })
        }
        delete _institution.r_t_id_entity;
        delete _institution.id_crowdAccountRelevantAddress;


        setInstitution(_institution);
        setOpenCopyAsDialog(true)
    }
    const editMe = (e) => {
        props.editVersion && props.editVersion(
            {
                i_sourceKey: props.data.Entity.i_sourceKey,
                fileSuffixId: props.version.fileSuffixId
            }
        )
        refreshMe();

    }
    const refreshMe = () => {
        console.log(props.api.getModel().gridOptionsWrapper);
        // setTimeout(() => {
        //     props.refreshCells({ columns: ['mycolumn'] });
        // }, 1000);
    }
    const copyAsApi = () => {
        const httpRequestCopyAs = new XMLHttpRequest();
        httpRequestCopyAs.open('POST', process.env.PUBLIC_URL + `/get/client-data/${props.data.Entity.i_sourceKey}`);
        httpRequestCopyAs.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequestCopyAs.send(JSON.stringify({
            fileSuffixId: props.version.fileSuffixId
        }));
        httpRequestCopyAs.onreadystatechange = () => {
            if (httpRequestCopyAs.readyState === 4 && httpRequestCopyAs.status === 200) {
                let res = JSON.parse(httpRequestCopyAs.responseText);
                if (res.status == 'success') {
                    setInstitution(res.data);
                    let copyInstitutio = res.data.Entity;
                    copyInstitutio.tempCommentForVersionManagement = `Created new entity from base template ${res.data.Entity.accountName} - ${props.version.environment}:${new Date(parseInt(props.version.versionTs)).format("UTC:yyyy-MM-dd'T'HH:mm:ss'Z'")}`;
                    copyInstitutio.tempversionTs = props.version.versionTs;
                    copyInstitutio.tempversion = props.version;
                    copyAs(copyInstitutio);
                }
            }
        };
    }
    const deleteInactiveLocalVersion = (e) => {
        props.deleteOfflineVersions && props.deleteOfflineVersions({
            i_sourceKey: props.data.Entity.i_sourceKey,
            fileSuffixId: props.version.fileSuffixId
        })
        refreshMe();
    }
    const undoMarkAsDelete = () => {
        props.undoMarkAsVersion && props.undoMarkAsVersion({
            i_sourceKey: props.data.Entity.i_sourceKey,
            fileSuffixId: props.version.fileSuffixId
        })
        refreshMe();
    }
    const _handleSnackbarClose = () => {
        setApiStatus("");
    }
    const onCloseInstitutionDialo = () => {
        setOpenCopyAsDialog(false)
    }
    const onDeletePermanently = () => {
        setOpenDeleteDialog(!openDeleteDialog);
        setOpen(false)
    }
    const onOpenCompareDialog = () => {
        setOpenCompareDialog(!openCompareDialog)
    }

    const renederEntityCreationTs = (sourceSpecificInformation) => {
        let entityCreationTsValue = !isEmpty(sourceSpecificInformation.filter(item => item.key === "entityCreationTs")) ?
            sourceSpecificInformation.filter(item => item.key === "entityCreationTs")[0].value : "";
        if (isEmpty(entityCreationTsValue)) {
            return ""
        } else {
            return <Tooltip arrow title={t(`${"dmTool:companyCreatedToolTip"}`) + new Date(parseInt(entityCreationTsValue)).format(props.context.date_format.value)}>
                <Chip
                    component={"span"}
                    size="small"
                    key={"Created"}
                    label={
                        time_ago(parseInt(entityCreationTsValue), props.context.date_format.value)
                    }
                    style={toMaterialStyle(
                        "Created"?.toLowerCase(),
                        500,
                    )}
                    className={classes.accountCreationInfo}
                />
            </Tooltip>
        }
    }

    return <React.Fragment key={"fragment" + props.index}>
        <ListItem
            key={"listItem" + props.index}
            ContainerComponent="li"
            className={props.version?.deletedOnEnvironment ? classes.deletedItem : !props.version.fileSuffixId ? classes.activeListItem : classes.passiveListItem}
        >
            <ListItemText
                className={classes.textOverFlowSet}
                primary={
                    <React.Fragment>
                        {!isEmpty(props.version.versionTs) && <Chip
                            component={"span"}
                            size="small"
                            key={props.version.versionTs}
                            label={new Date(parseInt(props.version.versionTs)).format("UTC:yyyy-MM-dd'T'HH:mm:ss'Z'")}
                            style={toMaterialStyle(
                                "versionTs"?.toLowerCase(),
                                500,
                            )}
                            className={classes.accountCreationInfo}
                        />}
                        {!isEmpty(props.version.environment) && <Chip
                            component={"span"}
                            size="small"
                            key={props.version.environment}
                            label={props.version.active ? t("dmTool:liveOnLabel") + " " + props.version.environment : props.version.environment}
                            style={toMaterialStyle(
                                props.version.environment?.toLowerCase(),
                                500,
                            )}
                            className={classes.accountCreationInfo}
                        />}
                        {isEmpty(props.version.environment) && <Chip
                            component={"span"}
                            size="small"
                            key={props.version.environment}
                            label={t(`${"dmTool:draftChipHdng"}`)}
                            style={toMaterialStyle(
                                props.version.environment?.toLowerCase(),
                                500,
                            )}
                            className={classes.accountCreationInfo}
                        />}
                        {!props.version.fileSuffixId && <Chip
                            component={"span"}
                            size="small"
                            key={t(`${"dmTool:activelyEditingChipHdng"}`)}
                            label={t(`${"dmTool:activelyEditingChipHdng"}`)}
                            style={{ background: "green", color: "#fff" }}
                            className={classes.accountCreationInfo}
                        />}
                    </React.Fragment>
                }
                secondary={<span className={classes.secondaryListItemText}>
                    <span>{props.version.commitComment}</span>
                    {!!props.version.failMessages && props?.version?.failMessages?.length > 0 &&
                        <Tooltip arrow title={!!props.version.failMessages && props.version.failMessages.join()}>
                            <Warning style={{ verticalAlign: "middle", color: "red", float: "right" }} />
                        </Tooltip>
                    }
                    <span style={{ display: "flex", margin: "0 0 5px 0" }}>
                        {renederEntityCreationTs(props.Entity.Entity?.BlobEntityData?.sourceSpecificInformation)}
                        {props.version.date_sourceCreationTs && <Tooltip arrow title={t(`${"dmTool:versionCreatedToolTip"}`) + new Date(parseInt(props.version.date_sourceCreationTs)).format(props.context.date_format.value)}>
                            <Chip
                                component={"span"}
                                size="small"
                                key={"Created"}
                                label={
                                    time_ago(props.version.date_sourceCreationTs, props.context.date_format.value)
                                }
                                style={toMaterialStyle(
                                    "Created"?.toLowerCase(),
                                    500,
                                )}
                                className={classes.accountCreationInfo}
                            />
                        </Tooltip>}
                        {props.version.date_sourceUpdateTs && <Tooltip arrow title={t(`${"dmTool:updatedToolTip"}`) + new Date(parseInt(props.version.date_sourceUpdateTs)).format(props.context.date_format.value)}>
                            <Chip
                                component={"span"}
                                size="small"
                                key={"Updated"}
                                label={
                                    time_ago(props.version.date_sourceUpdateTs, props.context.date_format.value)
                                }
                                style={toMaterialStyle(
                                    "Updated"?.toLowerCase(),
                                    500,
                                )}
                                className={classes.accountCreationInfo}
                            />
                        </Tooltip>}
                        {props.version.i_t_date_updateTs && <Tooltip arrow title={t(`${"dmTool:syncedToolTip"}`) + new Date(parseInt(props.version.i_t_date_updateTs)).format(props.context.date_format.value)}>
                            <Chip
                                component={"span"}
                                size="small"
                                key={"Last Synced"}
                                label={
                                    time_ago(props.version.i_t_date_updateTs, props.context.date_format.value)
                                }
                                style={toMaterialStyle(
                                    "Synced"?.toLowerCase(),
                                    500,
                                )}
                                className={classes.accountCreationInfo}
                            />
                        </Tooltip>}
                        <span className={classes.cardStatus}>
                            {props.version.i_t_date_updateTs != null && !props.version?.deletedOnEnvironment && (props.version.date_sourceUpdateTs - props.version.i_t_date_updateTs) > 500 && <Chip
                                component={"span"}
                                size="small"
                                key={"Modified"}
                                label={t(`${"dmTool:modifiedChipHdng"}`)}
                                style={toMaterialStyle(
                                    "Modified"?.toLowerCase(),
                                    500,
                                )}
                                className={classes.cardstatusStyle}
                            />}
                            {!props.version?.deletedOnEnvironment && !!props.version.setfordelete && <Chip
                                component={"span"}
                                size="small"
                                key={"ToDelete"}
                                label={t(`${"dmTool:toDeletedChipHdng"}`)}
                                style={{ backgroundColor: 'red', color: "white" }}
                                className={classes.cardstatusStyle}
                            />}
                            {props.version?.deletedOnEnvironment && <Chip
                                component={"span"}
                                size="small"
                                key={"Deleted"}
                                label={t(`${"dmTool:deletedChipHdng"}`)}
                                style={{ backgroundColor: 'red', color: "white" }}
                                className={classes.cardstatusStyle}
                            />}
                        </span>
                    </span>
                    <span>
                        {!isEmpty(lastUpdatedUser) && <Tooltip arrow title={t(`${"dmTool:lastUpdatedByHdng"}`) + ": " + lastUpdatedUser}>
                            <Chip
                                component={"span"}
                                size="small"
                                key={"lastUpadtedBy"}
                                label={lastUpdatedUser}
                                style={toMaterialStyle(
                                    "lastUpadtedBy"?.toLowerCase(),
                                    500,
                                )}
                                className={classes.accountCreationInfo}
                            />
                        </Tooltip>}
                        {!isEmpty(lastSyncedUser) && <Tooltip arrow title={t(`${"dmTool:lastSyncedByHdng"}`) + ": " + lastSyncedUser}>
                            <Chip
                                component={"span"}
                                size="small"
                                key={"lastSyncedBy"}
                                label={lastSyncedUser}
                                style={toMaterialStyle(
                                    "lastSyncedBy"?.toLowerCase(),
                                    500,
                                )}
                                className={classes.accountCreationInfo}
                            />
                        </Tooltip>}
                    </span>
                </span>}
            />
            <ListItemSecondaryAction className={classes.actionPaddingSet} style={{ top: "20%", right: "0px" }}>
                <Tooltip arrow title={t("dmTool:actionsTooltipHdng")}>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        aria-owns={open ? 'simple-menu' : null} aria-haspopup="true"
                        onClick={handleClick}>
                        <MoreVert style={{ verticalAlign: "middle" }} />
                    </IconButton>
                </Tooltip>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={open}
                    MenuListProps={{ onMouseLeave: handleRequestClose }}
                >
                    <MenuItem disabled={!props.version.fileSuffixId || props.version?.deletedOnEnvironment} onClick={editMe}>{t(`${"dmTool:editThisVersionMenuTitle"}`)}</MenuItem>
                    <MenuItem disabled={(props.version?.active || props.version?.deletedOnEnvironment) && !props.version?.deletedOnEnvironment} onClick={onDeletePermanently}>{t(`${"dmTool:deletePermanentlyFromDmToolMenuTitle"}`)}</MenuItem>
                    <MenuItem disabled={(props.version?.active ? props.version.setfordelete : true)} onClick={deleteInactiveLocalVersion}>{t(`${"dmTool:markAsDeleteMenuTitle"}`)}</MenuItem>
                    {/* ---Undo Deleted for future use--- */}
                    <MenuItem disabled={!props.version.setfordelete || props.version?.deletedOnEnvironment} onClick={undoMarkAsDelete}>{t(`${"dmTool:undoMarkDeleteMenuTitle"}`)}</MenuItem>
                    {process.env.NODE_ENV === "production" && <MenuItem disabled={props.version?.deletedOnEnvironment || (props.version.setfordelete && props.version.environment == "DEVELOPMENT") || props.store.navBarLoading} onClick={handleSyncToProd}>{t(`${"dmTool:syncToProdMenuTitle"}`)}</MenuItem>}
                    <MenuItem disabled={props.version?.deletedOnEnvironment || (props.version.setfordelete && props.version.environment == "PRODUCTION") || props.store.navBarLoading} onClick={handleSyncToDev}>{t(`${"dmTool:syncToDevMenuTitle"}`)}</MenuItem>
                    <MenuItem disabled={props.version?.deletedOnEnvironment} onClick={saveAs}>{t(`${"dmTool:saveAsMenuTitle"}`)}</MenuItem>
                    <MenuItem onClick={copyAsApi}>{t(`${"dmTool:copyAsMenuTitle"}`)}</MenuItem>
                    <MenuItem disabled={props.version?.deletedOnEnvironment} onClick={onOpenCompareDialog}>{t(`${"dmTool:compareMenuTitle"}`)}</MenuItem>
                </Menu>
            </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={apiStatus == "success"} autoHideDuration={6000} onClose={_handleSnackbarClose} >
            <Alert severity="success">{apiMessage}</Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={apiStatus == "fail"} autoHideDuration={6000} onClose={_handleSnackbarClose} >
            <Alert severity="error">{apiMessage}</Alert>
        </Snackbar>
        {openCopyAsDialog && <InstistutionDialog institution={institution} open={openCopyAsDialog} gridref={props.agGridReact} onClose={onCloseInstitutionDialo} />}
        {openCompareDialog && <CompareDialog
            open={openCompareDialog}
            onClose={onOpenCompareDialog}
            data={props.data}
            sourceVersion={props.version}
            destinationVersion={null}
        />}
        {openDeleteDialog && <DeletePermanentlyDialog entityName={props.data.Entity.accountName} _deleteInactiveLocalVersion={deleteInactiveLocalVersion} open={openDeleteDialog} onClose={onDeletePermanently} />}
    </React.Fragment>
}

const mapStateToProps = (state, ownProps) => {
    return {
        store: state.Entity,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload)),
        syncToEnvironment: (payload) => dispatch(syncToEnvironment(payload)),
        createNewVersion: (payload) => dispatch(createNewVersion(payload)),
        editVersion: (payload) => dispatch(editVersion(payload)),
        deleteOfflineVersions: (payload) => dispatch(deleteOfflineVersions(payload)),
        undoMarkAsVersion: (payload) => dispatch(undoMarkAsVersion(payload)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(VersionListItem);