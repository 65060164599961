import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sspOrganizationContactTypeEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));


const OrganizationContactStatus = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "entityDataEnumerations"
    ]);
    const { dataSourceInfoObj, onChageDataSourceInfoObj } = props;
    const classes = useStyles();

    const OnChangesspIntellectualPropertyEnumStutus = (e, value) => {
        onChageDataSourceInfoObj('organizationContactStatus', value.value)
    }



    const sspDataSourceInfoEnumOptions = Object.keys(sspOrganizationContactTypeEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"entityDataEnumerations:" + sspOrganizationContactTypeEnum[opt]}`)
        };
    });


    return (
        <React.Fragment>
            <Autocomplete
                disableClearable
                fullWidth
                size="small"
                className={classes.autocompleteStyle}
                getOptionLabel={option => option.label}
                id="intellectual-propert_status"
                margin="normal"
                onChange={OnChangesspIntellectualPropertyEnumStutus}
                options={sspDataSourceInfoEnumOptions}
                value={
                    sspDataSourceInfoEnumOptions.find(
                        item =>
                            item.value ===
                            dataSourceInfoObj?.filter(item => item.key == "organizationContactStatus")?.[0]?.value ,
                    ) || {}
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        label={`${t(`${"dmTool:statusInputFieldLabel"}`)}*`}
                        placeholder={t(`${"dmTool:selectStatusPlcHldr"}`)}
                        variant="outlined"
                        style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </React.Fragment>
    );
};

export default OrganizationContactStatus;