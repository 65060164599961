import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { sspLanguageEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import { isEmpty } from "../../utils";
import UILanguageSelector from '../Languages';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const InstitutionDescriptionDialog = props => {

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    const { open, apiCallProgress } = props;
    const classes = useStyles();

    const [institutionDescription, setInstitutionDescription] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (!!props.InstitutionDescription.length) {
            setInstitutionDescription(props.InstitutionDescription)
        } else {
            setInstitutionDescription([{
                key: '',
                value: ''
            }]
            )
        }
    }, [props.InstitutionDescription])


    useEffect(() => {
        let keys = institutionDescription?.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!!institutionDescription?.length && !!keys.length) {
            let langs = [];
            let filterLang = sspLanguageEnum.map((lang) => {
                if (!keys?.includes(lang.value)) {
                    langs.push(lang);
                }
            });
            setLanguageOptions(langs)
        } else {
            setLanguageOptions(sspLanguageEnum);
        }
    }, [institutionDescription])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(institutionDescription)
    };

    const OnChangeInstitutionDescriptionValue = (e, index) => {
        let _institutionDescription = institutionDescription
        _institutionDescription[index].value = e.target.value;
        addUpdateOrDeleteInstitutionDescription(_institutionDescription, 'update')
    }

    const OnChangeInstitutionDescriptionKey = (e, value, index) => {
        let _institutionDescription = institutionDescription
        _institutionDescription[index].key = value.value
        addUpdateOrDeleteInstitutionDescription(_institutionDescription, 'update')
    }

    const addCopanyFocusSector = (e) => {
        addUpdateOrDeleteInstitutionDescription({
            key: '',
            value: '',
        }, 'add')
    }

    const addUpdateOrDeleteInstitutionDescription = (object, type) => {
        if (type == 'add') {
            setInstitutionDescription((_institutionDescription) => {
                return ([..._institutionDescription, object])

            })
        }
        if (type == 'delete') {
            setInstitutionDescription((_institutionDescription) => {
                return [..._institutionDescription.filter((item, index) => index != object)]
            })
        }
        if (type == 'update') {
            setInstitutionDescription((_institutionDescription) => {
                return [...object]
            })
        }
    }


    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="institutionIdnumber-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="institutionIdnumber-dialog-title">
                        {!!props.InstitutionDescription.length
                            ? t("dmTool:updateInstDescInputFieldLabel", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addInstDescInputFieldLabel", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        {!!institutionDescription.length && institutionDescription.map((_institutionDescription, index) => {
                            return (<React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item lg={4} sm={4} xs={12}>
                                        {/* <Autocomplete
                                            disableClearable
                                            fullWidth
                                            size="small"
                                            className={classes.autocompleteStyle}
                                            getOptionLabel={option => option.label}
                                            getOptionSelected={(option, value) => value.value === option.value}
                                            id="institution-description-key"
                                            margin="normal"
                                            onChange={(e, value) => OnChangeInstitutionDescriptionKey(e, value, index)}
                                            options={languageOptions}
                                            value={
                                                sspLanguageEnum.find(
                                                    item =>
                                                        item.value ===
                                                        _institutionDescription?.key,
                                                ) || {}
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                                                    placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        /> */}
                                        <UILanguageSelector
                                            selectedValues={institutionDescription}
                                            label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                                            placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                            value={_institutionDescription.key}
                                            onChange={(e, value) => {
                                                OnChangeInstitutionDescriptionKey(e, value, index)
                                            }
                                            }
                                        />

                                    </Grid>
                                    <Grid item lg={6} sm={6} xs={12}>
                                        <TextField
                                            className={classes.marginSet}
                                            fullWidth
                                            label={t(`${"dmTool:instDescInputFieldLabel"}`)}
                                            placeholder={t(`${"dmTool:enterInstDescPlcHldr"}`)}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            onChange={(e) => OnChangeInstitutionDescriptionValue(e, index)}
                                            size='small'
                                            required={false}
                                            multiline
                                            rowsMax={4}
                                            value={_institutionDescription?.value}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />

                                    </Grid>
                                    {index == 0 && <Grid item lg={1} sm={1} xs={12}>
                                        <Tooltip title={t(`${"dmTool:addInstDescTooltip"}`)}>
                                            <IconButton aria-label="add"
                                                color="secondary"
                                                variant="contained"
                                                onClick={addCopanyFocusSector}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>}
                                    <Grid item lg={1} sm={1} xs={12}>
                                        <IconButton
                                            key={'delete'}
                                            onClick={(e) => addUpdateOrDeleteInstitutionDescription(index, 'delete')}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </React.Fragment>)
                        })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress || institutionDescription?.filter((vid) => !isEmpty(vid.value) && isEmpty(vid.key)).length || institutionDescription?.filter((vid) => isEmpty(vid.value) && isEmpty(vid.key)).length}
                            color='primary'
                            className={classes.addButton}
                        >
                            {!!props.InstitutionDescription.length ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default InstitutionDescriptionDialog;