import { isEmpty } from "../../utils";
import { countries } from "../Countrieslist";
// import { useTranslation } from 'react-i18next';

const newLine = "\n"
const space = " "
const dash = " - "
const colon = ": "
const tab = "\t"

function jsonToReadableText(json, t) {
    let content = t(`${"dmTool:accNameAndStatus"}`) + colon
    content += json.Entity.accountName + dash + t("lifecycleEnumerations:" + json.Entity.i_t_lifecycleElement_status);
    if (!isEmpty(json.Entity.institutionJurisdiction)) {
        content += newLine + t(`${"dmTool:jurisdiction"}`) + colon
        content += countries.filter(type => type.code == json.Entity.institutionJurisdiction).map(option => option.label);
    }
    if (!isEmpty(json.Entity.institutionAccountStatus)) {
        content += newLine + t(`${"dmTool:instAccStatus"}`) + colon
        content += t("configurationEnumerations:" + json.Entity.institutionAccountStatus);
    }
    if (!isEmpty(json.Entity.legalForm)) {
        content += newLine + t(`${"dmTool:legalFormHeader"}`) + colon
        content += countries.filter(type => type.code == json.Entity.legalForm.key).map(option => option.label) + dash + t("configurationEnumerations:" + json.Entity.legalForm.value);
    }
    if (!isEmpty(json.Entity.institutionIdentificationNumber)) {
        content += newLine + t(`${"dmTool:institutionIdNumberHeader"}`) + colon
        json.Entity.institutionIdentificationNumber.map((item, index) => {
            content += newLine + t("configurationEnumerations:" + item.key) + dash + item.value;
        });
    }
    if (!isEmpty(json.Entity.startupProductStatus)) {
        content += newLine + t(`${"dmTool:startupProductStatusHeader"}`) + colon
        content += t("configurationEnumerations:" + json.Entity.startupProductStatus);
    }
    if (!isEmpty(json.Entity.businessType)) {
        content += newLine + t(`${"dmTool:businessTypeHeader"}`) + colon
        content += t("configurationEnumerations:" + json.Entity.businessType);
    }
    if (json.Entity.institutionSocialProfileURL?.length) {
        content += newLine + t(`${"dmTool:institutionSocialProfilesHeader"}`) + colon
        json.Entity.institutionSocialProfileURL.map((item, index) => {
            content += newLine + t("configurationEnumerations:" + item.key) + dash + item.value;
        });
    }
    if (!isEmpty(json.Entity.startupCrowdfundingFromURL)) {
        content += newLine + t(`${"dmTool:startupCrowdFundingPlatformHeader"}`) + colon
        json.Entity.startupCrowdfundingFromURL?.map((item, index) => {
            content += newLine + item.key + dash + item.value;
        });
    }
    if (!isEmpty(json.Entity.companyType)) {
        content += newLine + t(`${"dmTool:companyTypeHeader"}`) + colon
        content += t("configurationEnumerations:" + json.Entity.companyType);
    }
    if (!isEmpty(json.Entity.companyFocusSectors)) {
        content += newLine + t(`${"dmTool:companyFocusSectorHeader"}`) + colon
        json.Entity.companyFocusSectors?.map((item, index) => {
            content += newLine + t("configurationEnumerations:" + item.key) + dash + t("configurationEnumerations:" + item.value);
        });
    }
    if (!isEmpty(json.Entity.institutionDescription)) {
        content += newLine + t(`${"dmTool:institutionDescriptionHeader"}`) + colon
        json.Entity.institutionDescription?.map((item, index) => {
            content += newLine + item.key + dash + item.value;
        });
    }
    if (!isEmpty(json.Entity.t_institutionAccountProfileImage_URL)) {
        content += newLine + t(`${"dmTool:accountProfileImageHeader"}`) + colon
        content += json.Entity.t_institutionAccountProfileImage_URL;
    }
    if (!isEmpty(json.Entity.entityVideo)) {
        content += newLine + t(`${"dmTool:entityVideoHeader"}`) + colon
        json.Entity.entityVideo?.map((item, index) => {
            content += newLine + item.key + dash + item.value;
        });
    }
    if (!isEmpty(json.Entity.startupCustomerStatus)) {
        content += newLine + t(`${"dmTool:startupCustomerStatusHeader"}`) + colon
        content += t("configurationEnumerations:" + json.Entity.startupCustomerStatus);
    }
    if (!isEmpty(json.Entity.institutionWebsiteURL)) {
        content += newLine + t(`${"dmTool:institutionWebsiteHeader"}`) + colon
        content += json.Entity.institutionWebsiteURL;
    }
    if (!isEmpty(json.Entity.dateOfBirthOfInstitution)) {
        content += newLine + t(`${"dmTool:dobInstitutionHeader"}`) + colon
        content += new Date(json.Entity.dateOfBirthOfInstitution);
    }
    if (!isEmpty(json.Entity.startupLookingForTypes)) {
        content += newLine + t(`${"dmTool:startupLookingForHeader"}`) + colon
        json.Entity.startupLookingForTypes?.map((item, index) => {
            content += newLine + t("configurationEnumerations:" + item)
        });
    }
    if (!isEmpty(json.Entity.mainPictureUrl)) {
        content += newLine + t(`${"dmTool:mainPictureHeader"}`) + colon
        content += json.Entity.mainPictureUrl;
    }
    if (!isEmpty(json.Entity.emailAddress)) {
        content += newLine + t(`${"dmTool:emailAdressHeader"}`) + colon
        content += json.Entity.emailAddress;
    }
    if (!isEmpty(json.Entity.phoneNumber)) {
        content += newLine + t(`${"dmTool:phoneNumberHeader"}`) + colon
        content += json.Entity.phoneNumber;
    }
    if (!isEmpty(json.Entity.hashtags)) {
        content += newLine + t(`${"dmTool:hashTagsHeader"}`) + colon
        json.Entity.hashtags?.map((item, index) => {
            content += newLine + item
        });
    }
    if (json.Entity.Events.length) {
        content += newLine + t(`${"dmTool:milestonesHeader"}`) + colon
        json.Entity.Events.map((item, index) => {
            content += newLine + item.i_sourceKey + dash + t("lifecycleEnumerations:" + item.i_t_lifecycleElement_status);
            content += newLine + t("eventsEnumerations:" + item.milestoneType) + dash + t("eventsEnumerations:" + item.status) + ` (${t("lifecycleEnumerations:" + item.i_t_qualitySourceDataQualityEvaluation)})`;
            content += newLine + dash + new Date(item.eventDate);
            // content += newLine + t(`${"dmTool:titleAndDesc"}`) + colon
            if (!isEmpty(item.title)) {
                content += newLine + t(`${"dmTool:title"}`) + colon
                item.title.map(title => {
                    content += newLine + title.key + dash + title.value;
                })
            }
            if (!isEmpty(item.description)) {
                content += newLine + t(`${"dmTool:description"}`) + colon
                item.description.map(desc => {
                    content += newLine + desc.key + dash + desc.value;
                })
            }
        });
    }
    if (json.Entity.KeyPerson.length) {
        content += newLine + t(`${"dmTool:keyPersonsHeader"}`) + colon
        json.Entity.KeyPerson?.map((item, index) => {
            content += newLine + item.i_sourceKey + dash + t("lifecycleEnumerations:" + item.i_t_lifecycleElement_status);
            content += newLine + t("lifecycleEnumerations:" + item.i_t_qualitySourceDataQualityEvaluation);
            content += newLine + item.firstName + dash + item.surname + dash + item.fullName;

            if (!isEmpty(item.socialProfileURL)) {
                content += newLine + t(`${"dmTool:institutionSocialProfilesHeader"}`) + colon
                item.socialProfileURL.map(social => {
                    content += newLine + social.key + dash + social.value;
                })
            }

            // content += newLine + t(`${"dmTool:institutionSocialProfilesHeader"}`) + colon
            // const map = new Map();
            // item.socialProfileURL.forEach(item => map.set(item.key, { socialProfileURL: item.value, key: item.key }));
            // map.forEach((value, key, mp) => {
            //     content += newLine + t("configurationEnumerations:" + key);
            //     content += newLine + dash + value.socialProfileURL;
            // })
            if (item.Roles.length) {
                content += newLine + t(`${"dmTool:rolesHeader"}`) + colon
                item.Roles.map(item => {
                    content += newLine + item.i_sourceKey + dash + t("lifecycleEnumerations:" + item.i_t_lifecycleElement_status);
                    content += newLine + t("lifecycleEnumerations:" + item.i_t_qualitySourceDataQualityEvaluation);
                    content += newLine + t("keyPersonEnumerations:" + item.roleType);
                    content += newLine + "From" + dash + new Date(item.dateFrom) + space + "To" + dash + new Date(item.dateTo)
                })
            }
        });
    }
    if (json.Entity.Documents.length) {
        content += newLine + t(`${"dmTool:documentHeaders"}`) + colon
        json.Entity.Documents?.map((item, index) => {
            content += newLine + item.i_sourceKey + dash + t("lifecycleEnumerations:" + item.i_t_lifecycleElement_status);
            content += newLine + t("lifecycleEnumerations:" + item.i_t_qualitySourceDataQualityEvaluation);
            if (!isEmpty(item.documentFeatureImage)) {
                content += newLine + item.documentFeatureImage;
            }

            if (!isEmpty(item.titleOfDocument)) {
                content += newLine + t(`${"dmTool:title"}`) + colon
                item.titleOfDocument.map(title => {
                    content += newLine + title.key + dash + title.value;
                })
            }
            if (!isEmpty(item.descriptionOfDocument)) {
                content += newLine + t(`${"dmTool:description"}`) + colon
                item.descriptionOfDocument.map(desc => {
                    content += newLine + desc.key + dash + desc.value;
                })
            }

            // content += newLine + t(`${"dmTool:titleAndDesc"}`) + colon
            // const map = new Map();
            // item.titleOfDocument.forEach(item => map.set(item.key, { title: item.value, key: item.key }));
            // item.descriptionOfDocument.forEach(item => map.set(item.key, { ...map.get(item.key), ...{ description: item.value, key: item.key } }));
            // map.forEach((value, key, mp) => {
            //     content += newLine + key;
            //     content += newLine + dash + value.title;
            //     content += newLine + dash + value.description;
            // })
            // item.Documents.forEach(item => map.set(item.key, { title: item.value, key: item.key }));
            if (item.Documents.length) {
                content += newLine + t(`${"dmTool:documentsHeader"}`) + colon
                item.Documents.map(item => {
                    content += newLine + item.i_sourceKey + dash + t("lifecycleEnumerations:" + item.i_t_lifecycleElement_status);
                    content += newLine + item.docLanguage + dash + item.connectionDocURL;
                    content += newLine + t("lifecycleEnumerations:" + item.i_t_qualitySourceDataQualityEvaluation);
                })
            }
        });
    }
    if (json.Entity.IntellectualProperties.length) {
        content += newLine + t(`${"dmTool:ipHeader"}`) + colon
        json.Entity.IntellectualProperties.map((item, index) => {
            content += newLine + item.i_sourceKey + dash + t("lifecycleEnumerations:" + item.i_t_qualitySourceDataQualityEvaluation);
            if (!isEmpty(item.i_t_startupSpiderInternalComment)) {
                content += newLine + item.i_t_startupSpiderInternalComment;
            }
            if (!isEmpty(item.title)) {
                content += newLine + t(`${"dmTool:title"}`) + colon
                item.title.map(title => {
                    content += newLine + title.key + dash + title.value;
                })
            }
            if (!isEmpty(item.description)) {
                content += newLine + t(`${"dmTool:description"}`) + colon
                item.description.map(desc => {
                    content += newLine + desc.key + dash + desc.value;
                })
            }
            if (!isEmpty(item.link)) {
                content += newLine + t(`${"dmTool:description"}`) + colon
                item.link.map(link => {
                    content += newLine + link.key + dash + link.value;
                })
            }
            if (!isEmpty(item.type)) {
                content += newLine + t("configurationEnumerations:" + item.type);
            }
        });
    }
    if (!isEmpty(json.Entity.Rating)) {
        content += newLine + t(`${"dmTool:ratingsHeader"}`) + colon
        content += newLine + json.Entity.Rating.i_sourceKey + dash + t("lifecycleEnumerations:" + json.Entity.Rating.i_t_qualitySourceDataQualityEvaluation);
        if (!isEmpty(json.Entity.Rating.i_t_startupSpiderInternalComment)) {
            content += newLine + json.Entity.Rating.i_t_startupSpiderInternalComment;
        }
        if (!isEmpty(json.Entity.Rating.technologyReadiness)) {
            content += newLine + t("dmTool:techReadinessRatingHdng") + dash + json.Entity.Rating.technologyReadiness;
        }
        if (!isEmpty(json.Entity.Rating.innovationDegree)) {
            content += newLine + t("dmTool:innovationRatingHdng") + dash + json.Entity.Rating.innovationDegree;
        }
    }

    if (!isEmpty(json.Entity.CrowdAccountRelevantAddress)) {
        content += newLine + t(`${"dmTool:addressHeader"}`) + colon
        content += newLine + json.Entity.CrowdAccountRelevantAddress.i_sourceKey + dash + t("lifecycleEnumerations:" + json.Entity.CrowdAccountRelevantAddress.r_addressStatus);
        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_addressLine0_receiver)) {
            content += newLine + json.Entity.CrowdAccountRelevantAddress.r_addressLine0_receiver;
        }
        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_addressName)) {
            content += newLine + json.Entity.CrowdAccountRelevantAddress.r_addressName
        }
        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_addressType)) {
            content += space + json.Entity.CrowdAccountRelevantAddress.r_addressType;
        }

        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_addressLine2_addressSuffix)) {
            content += newLine + json.Entity.CrowdAccountRelevantAddress.r_addressLine2_addressSuffix;
        }

        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_addressLine1_streetName)) {
            content += newLine + json.Entity.CrowdAccountRelevantAddress.r_addressLine1_streetName;
        }
        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_addressLine1_houseNumber)) {
            content += space + json.Entity.CrowdAccountRelevantAddress.r_addressLine1_houseNumber;
        }

        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_zipOrPostcode)) {
            content += newLine + json.Entity.CrowdAccountRelevantAddress.r_zipOrPostcode;
        }
        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_city)) {
            content += space + json.Entity.CrowdAccountRelevantAddress.r_city;
        }
        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_addresseeName)) {
            content += newLine + json.Entity.CrowdAccountRelevantAddress.r_addresseeName;
        }
        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_nameOfProvince)) {
            content += newLine + json.Entity.CrowdAccountRelevantAddress.r_nameOfProvince;
        }
        if (!isEmpty(json.Entity.CrowdAccountRelevantAddress.r_address_jurisdiction)) {
            content += newLine + json.Entity.CrowdAccountRelevantAddress.r_address_jurisdiction;
        }
    }

    if (!isEmpty(json.Entity.BlobEntityData)) {
        content += newLine + t(`${"dmTool:AdditionalDataSourceInformationHeader"}`) + colon
        content += newLine + json.Entity.BlobEntityData.i_sourceKey;
        json.Entity.BlobEntityData.sourceSpecificInformation.map((item, index) => {
            if (item.key == t("dmTool:commentHdng") && !isEmpty(item.value)) {
                content += newLine + item.key + dash + item.value
            }
            if (item.key == t("dmTool:searchTriggerHdng") && !isEmpty(item.value)) {
                content += newLine + item.key + dash + item.value
            }
            if (item.key == t("dmTool:organizationContactStatusHdng") && !isEmpty(item.value)) {
                content += newLine + item.key + dash + t("entityDataEnumerations:" + item.value)
            }
            if (item.key == t("dmTool:organizationContactCommentHdng") && !isEmpty(item.value)) {
                content += newLine + item.key + dash + item.value
            }
            if (item.key == t("dmTool:thoughtsHdng") && !isEmpty(item.value)) {
                content += newLine + item.key + dash + item.value
            }
        });
    }
    if (json.Entity.Supplier) {
        content += newLine + t(`${"dmTool:supplierHeader"}`) + colon
        content += newLine + json.Entity.Supplier.i_sourceKey + dash + t("lifecycleEnumerations:" + json.Entity.Supplier.i_t_lifecycleElement_status);

        if (json.Entity.Supplier.serviceOffering.length) {
            content += newLine + t(`${"dmTool:serviceOfferingHeader"}`) + colon
            const map = new Map();
            json.Entity.Supplier.serviceOffering.forEach(item => map.set(item, { serviceOffering: item }));
            map.forEach((value, key, mp) => {
                content += newLine + dash + t("configurationEnumerations:" + value.serviceOffering);
            });
        }
        if (json.Entity.Supplier.serviceDescription.length) {
            content += newLine + t(`${"dmTool:supplierDescriptionHeader"}`) + colon
            const map = new Map();
            json.Entity.Supplier.serviceDescription.forEach(item => map.set(item.key, { serviceDescription: item.value, key: item.key }));
            map.forEach((value, key, mp) => {
                content += newLine + key;
                content += newLine + dash + value.serviceDescription;
            });
        }
        if (json.Entity.Supplier.serviceAudienceType.length) {
            content += newLine + t(`${"dmTool:supplierAudienceHeader"}`) + colon
            const map = new Map();
            json.Entity.Supplier.serviceAudienceType.forEach(item => map.set(item, { serviceAudienceType: item }));
            map.forEach((value, key, mp) => {
                content += newLine + t("configurationEnumerations:" + value.serviceAudienceType);
            });
        }
        if (!isEmpty(json.Entity.Supplier.serviceStatus)) {
            content += newLine + t(`${"dmTool:supplierServiceStatusHeader"}`) + colon + t("configurationEnumerations:" + json.Entity.Supplier.serviceStatus);
        }
        content += newLine + t(`${"dmTool:applyServiceZoneToAllHeader"}`) + dash + json.Entity.Supplier.applyServiceZoneToAll;
        if (json.Entity.Supplier.otherServiceOffing.length) {
            content += newLine + t(`${"dmTool:supplierOtherServicesHeader"}`) + colon
            const map = new Map();
            json.Entity.Supplier.otherServiceOffing.forEach(item => map.set(item.key, { otherServiceOffing: item.value, key: item.key }));
            map.forEach((value, key, mp) => {
                content += newLine + key;
                content += newLine + dash + value.otherServiceOffing;
            });
        }
        if (json.Entity.Supplier.audienceStartupPhase.length) {
            content += newLine + t(`${"dmTool:supplierAudienceHeader"}`) + colon
            const map = new Map();
            json.Entity.Supplier.audienceStartupPhase.forEach(item => map.set(item, { audienceStartupPhase: item }));
            map.forEach((value, key, mp) => {
                content += newLine + t("configurationEnumerations:" + value.audienceStartupPhase);
            });
        }
        if (json.Entity.Supplier.serviceFocusSectors.length) {
            content += newLine + t(`${"dmTool:supplierSericeFocusHeader"}`) + colon
            const map = new Map();
            json.Entity.Supplier.serviceFocusSectors.forEach(item => map.set(item.key, { serviceFocusSectors: item.value, key: item.key }));
            map.forEach((value, key, mp) => {
                content += newLine + t("configurationEnumerations:" + key);
                content += newLine + dash + t("configurationEnumerations:" + value.serviceFocusSectors);
            });
        }
        if (json.Entity.Supplier.otherServiceSector.length) {
            content += newLine + t(`${"dmTool:supplierSectorOtherHeader"}`) + colon
            const map = new Map();
            json.Entity.Supplier.otherServiceSector.forEach(item => map.set(item.key, { otherServiceSector: item.value, key: item.key }));
            map.forEach((value, key, mp) => {
                content += newLine + key;
                content += newLine + dash + value.otherServiceSector;
            });
        }
        if (json.Entity.Supplier.ServiceZones.length) {
            content += newLine + t(`${"dmTool:supplierTargetCustomerAndServiceZoneHeader"}`) + colon
            json.Entity.Supplier.ServiceZones.map((item, index) => {
                content += newLine + item.i_sourceKey + dash + t("lifecycleEnumerations:" + item.i_t_lifecycleElement_status);
                if (!isEmpty(item.serviceAudienceType)) {
                    content += newLine + t(`${"dmTool:audienceTypeHeader"}`) + colon + newLine + dash + t("configurationEnumerations:" + item.serviceAudienceType);
                }
                if (item.geographies.length) {
                    content += newLine + t(`${"dmTool:geographies"}`) + colon
                    item.geographies.map(geo => {
                        content += newLine + dash + t("configurationEnumerations:" + geo);
                    })
                }
                if (item.countries.length) {
                    content += newLine + t(`${"dmTool:allowedCountries"}`) + colon
                    item.countries.map(country => {
                        content += newLine + dash + country + dash + countries.filter(type => type.code == country).map(option => option.label);
                    })
                }
                if (item.blockedcountries.length) {
                    content += newLine + t(`${"dmTool:blockedCountries"}`) + colon
                    item.blockedcountries.map(blocked => {
                        content += newLine + dash + blocked + dash + countries.filter(type => type.code == blocked).map(option => option.label);
                    })
                }
            });
        }
        if (!!json.Entity.Supplier.hasOwnProperty("institutionType") && json.Entity.Supplier.institutionType.length) {
            content += newLine + t(`${"dmTool:institutionTypeHeader"}`) + colon
            const map = new Map();
            json.Entity.Supplier.institutionType.forEach(item => map.set(item, { institutionType: item }));
            map.forEach((value, key, mp) => {
                content += newLine + dash + t("configurationEnumerations:" + value.institutionType);
            });
        }
    }


    return content;
}

export default jsonToReadableText;