import React, { useState, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { makeStyles } from "@material-ui/core/styles";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { columnDefsEntity, defaultColDef } from "../../columns";
import GridComponents from "../../Components";
import Button from "@material-ui/core/Button";
import InstistutionDialog from "../../Components/InstistutionDialog";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import SettingsDialog from "../../Components/SettingsDialog/Components/settingsDialog";
import { useTranslation } from "react-i18next";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { getEntityDataFromServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";

import MenuItem from "@material-ui/core/MenuItem";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import FastForwardIcon from "@material-ui/icons/FastForward";
import Tooltip from "@material-ui/core/Tooltip";
import { updateColumnsDef } from "../../Store/ColumnDefs/actions";

const useStyles = makeStyles((theme) => ({
  containerSelect: {
    "& .ag-root": {
      "user-select": "text !important",
    },
  },
  agGrid_Header: {
    display: "flex",
  },
  margin_institution_buton: {
    marginLeft: "auto",
  },
  check_box_padding: {
    padding: "9px 0 9px 9px",
  },
  filtering_left_margin: {
    marginLeft: "20px",
    flex: "1",
  },
}));

function EntityDataEntryPage(props) {
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const classes = useStyles();
  const [rowData, setRowData] = useState(props.store.Entities);
  const [dateFormat, setDateFormat] = useState("");
  const [language, setLanguage] = useState("");
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const agRef = useRef();
  const [open, setOpen] = useState(false);
  const [columnOptionsOpen, setColumnOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation([
    "translation",
    "configurationEnumerations",
    "languageEnumerations",
    "dmTool",
  ]);

  const handleRowData = (value) => {
    let data = rowData;
    let updatedRowDataIndex = data.findIndex(
      (item) => item.Entity.uuid === value.Entity.uuid
    );
    data[updatedRowDataIndex] = value;
    setRowData(data);
  };

  const [context, setContext] = useState({
    handleRowData: handleRowData,
    rowData: rowData,
    date_format: {
      value: "dd-MM-yyyy HH:mm:ss Z",
      label: "dd-MM-yyyy HH:mm:ss Z",
    },
    preffered_language: {
      label: `${"en".toLocaleUpperCase()} - ${t("languageEnumerations:" + "en")}`,
      value: "en",
    },
  });
  const _selectedDateFormat = (e) => {
    setDateFormat(e);
    setContext({ ...context, date_format: e });
    setTimeout(() => {
      agRef.current.api.refreshCells({ force: true });
    }, 100);
  };
  const _prefferedLanguage = (e) => {
    setLanguage(e);
    setContext({ ...context, preffered_language: e });
    setTimeout(() => {
      agRef.current.api.refreshCells({ force: true });
    }, 100);
  };

  useEffect(() => {
    setContext({ ...context, rowData: [...rowData] });
  }, [rowData]);
  const [intitutionDialog, setInstitutionDialog] = useState(false);

  const OpenInstitutionDialog = () => {
    setInstitutionDialog(true);
  };

  const CloseInstitutionDialogOpen = () => {
    setInstitutionDialog(false);
  };

  const _openSettingsDialog = () => {
    setOpenSettingsDialog(true);
  };

  useEffect(() => {
    console.log("refreshing!!!");
    if (!props.store.navBarLoading && gridApi) {
      // debugger;
      gridApi.refreshCells({
        // columns: ['Entity.accountName'],
        force: true, // skips change detection, refresh everything
        suppressFlash: false, // skips cell flashing, if cell flashing is enabled
      });
    }
  }, [props.store.navBarLoading]);
  const closeSettingsDialog = () => {
    setOpenSettingsDialog(false);
  };

  const frameworkComponents = {
    SimpleEditor: GridComponents.SimpleEditor,
    AsyncValidationEditor: GridComponents.AsyncValidationEditor,
    AutoCompleteEditor: GridComponents.AutoCompleteEditor,
    AgDateInput: GridComponents.MyDatePicker,
    DateEditor: GridComponents.DateEditor,
    ActionsRenderer: GridComponents.ActionsRenderer,
    LastSyncedProRenderer: GridComponents.LastSyncedProRenderer,
    LastSyncedDevRenderer: GridComponents.LastSyncedDevRenderer,
    LastUpdatedLocalRenderer: GridComponents.LastUpdatedLocalRenderer,
    AddRowStatusBar: GridComponents.AddRowStatusBar,
    MileStoneRenderer: GridComponents.MileStoneRenderer,
    KeyPersonRenderer: GridComponents.KeyPersonRenderer,
    AddressRenderer: GridComponents.AddressRenderer,
    DataSourceSelectEditor: GridComponents.DataSourceSelectEditor,
    InstitutionDateEditor: GridComponents.InstitutionDateEditor,
    DataSourceSelectRenderer: GridComponents.DataSourceSelectRenderer,
    StartupCustomerStatusSelectRenderer:
      GridComponents.StartupCustomerStatusSelectorRenderer,
    InstitutionJurisdictionSelectorRenderer:
      GridComponents.InstitutionJurisdictionSelectorRenderer,
    InstitutionAccountStatusSelectorRenderer:
      GridComponents.InstitutionAccountStatusSelectorRenderer,
    StartupProductStatusSelectorRenderer:
      GridComponents.StartupProductStatusSelectorRenderer,
    IntellectualPropertiesRenderer:
      GridComponents.IntellectualPropertiesRenderer,
    BusinessTypeSelectorRenderer: GridComponents.BusinessTypeSelectorRenderer,
    CompanyTypeSelectorRenderer: GridComponents.CompanyTypeSelectorRenderer,
    InstitutionWebsiteURLRenderer: GridComponents.InstitutionWebsiteURLRenderer,
    HashtagsRenderer: GridComponents.HashtagsRenderer,
    LegalFormRenderer: GridComponents.LegalFormRenderer,
    InstitutionIdentificationNumberRenderer:
      GridComponents.InstitutionIdentificationNumberRenderer,
    InstitutionSocialProfileURLRenderer:
      GridComponents.InstitutionSocialProfileURLRenderer,
    KnowledgeInRolesSelectorRenderer:
      GridComponents.KnowledgeInRolesSelectorRenderer,
    StartupCrowdfundingFromURLRenderer:
      GridComponents.StartupCrowdfundingFromURLRenderer,
    CompanyFocusSectorsRenderer: GridComponents.CompanyFocusSectorsRenderer,
    InstitutionDescriptionRenderer:
      GridComponents.InstitutionDescriptionRenderer,
    StartupLookingForTypesSelectorRenderer:
      GridComponents.StartupLookingForTypesSelectorRenderer,
    StartupCustomerStatusSelectEditor:
      GridComponents.StartupCustomerStatusSelectEditor,
    InstitutionJurisdictionSelectEditor:
      GridComponents.InstitutionJurisdictionSelectEditor,
    InstitutionAccountStatusSelectEditor:
      GridComponents.InstitutionAccountStatusSelectEditor,
    StartupProductStatusSelectEditor:
      GridComponents.StartupProductStatusSelectEditor,
    StartupIntellectualPropertySelectEditor:
      GridComponents.StartupIntellectualPropertySelectEditor,
    BusinessTypeSelectEditor: GridComponents.BusinessTypeSelectEditor,
    CompanyTypeSelectEditor: GridComponents.CompanyTypeSelectEditor,
    HashtagsSelectEditor: GridComponents.HashtagsSelectEditor,
    KnowledgeInRolesSelectorEditor:
      GridComponents.KnowledgeInRolesSelectorEditor,
    StartupLookingForTypesSelectorEditor:
      GridComponents.StartupLookingForTypesSelectorEditor,
    RatingRenderer: GridComponents.RatingRenderer,
    SupplierInfo: GridComponents.SupplierInfo,
    MainPictureUrlRenderer: GridComponents.MainPictureUrlRenderer,
    InternalMainPictureUrlRenderer:
      GridComponents.InternalMainPictureUrlRenderer,
    EntityVideoRenderer: GridComponents.EntityVideoRenderer,

    ServiceZonesSelectorEditor: GridComponents.ServiceZonesSelectorEditor,
    ServiceZonesSelectorRenderer: GridComponents.ServiceZonesSelectorRenderer,
    AudienceStartUpPhaseRenderer: GridComponents.AudienceStartUpPhaseRenderer,
    AudienceStartUpPhaseEditor: GridComponents.AudienceStartUpPhaseEditor,
    OtherServiceOffingRenderer: GridComponents.OtherServiceOffingRenderer,
    ServiceDescriptionRenderer: GridComponents.ServiceDescriptionRenderer,
    OtherServiceSectorRenderer: GridComponents.OtherServiceSectorRenderer,
    ServiceFocusSectorsRenderer: GridComponents.ServiceFocusSectorsRenderer,
    ServiceZonesRenderer: GridComponents.ServiceZonesRenderer,
    ServiceZonesEditor: GridComponents.ServiceZonesEditor,
    VersionHistoryRenderer: GridComponents.VersionHistoryRenderer,
    DocumentRenderer: GridComponents.DocumentsRenderer,
    agColumnHeader: GridComponents.ColumnHeaderComponents,

    AccountProfilePictureRenderer: GridComponents.AccountProfilePictureRenderer,
    InternalAccountProfilePictureRenderer:
      GridComponents.InternalAccountProfilePictureRenderer,

    InstitutionTypesSelectorRenderer:
      GridComponents.InstitutionTypesSelectorRenderer,
    InstitutionTypeSelectorEditor: GridComponents.InstitutionTypeSelectorEditor,
    AdditionalDataSourceInformationSelectorRenderer:
      GridComponents.AdditionalDataSourceInformationSelectorRenderer,

    DobOfInstitutionRenderer: GridComponents.DobOfInstitutionRenderer,

    ServiceStatusSelectEditor: GridComponents.ServiceStatusSelectEditor,
    ServiceStatusSelectorRenderer: GridComponents.ServiceStatusSelectorRenderer,
  };
  const getRowHeight = (params) => {
    return 70 * 4;
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    props.getEntityDataFromServer && props.getEntityDataFromServer();
  }
  function onColumnResized(params) {
    params.api.resetRowHeights();
  }
  function onColumnVisible(params) {
    params.api.resetRowHeights();
  }
  function onCellEditingStopped(params) {
    params.api.resetRowHeights();
  }

  const _configuration = (e) => {
    setDateFormat(e);
    setContext({
      ...context,
      preffered_language: e.language,
      date_format: e.dateFormat,
    });
    setTimeout(() => {
      agRef.current.api.refreshCells({ force: true });
    }, 100);
  };
  const [filterType, setFilterType] = useState("all");

  const _handleFilterOptionChange = (e, value) => {
    setFilterType(value);
    filter = value;
    gridApi.onFilterChanged();
  };

  const _handleSupplier = (e, value) => {
    setRowData((_rowData) => {
      return _rowData.Entity.sort(
        (a, b) =>
          a.UploaderMetaData.find((item) => item.fileSuffixId == null)
            .date_sourceUpdateTs -
          b.UploaderMetaData.find((item) => item.fileSuffixId == null)
            .date_sourceUpdateTs
      );
    });
  };
  const isExternalFilterPresent = () => {
    return filter !== ("" || null);
  };

  const doesExternalFilterPass = (node) => {
    switch (filter) {
      case "markedToDelete":
        return node.data.UploaderMetaData.filter(
          (data) => data?.deletedOnEnvironment !== true
        ).some((item) => item?.setfordelete == true);
      case "deleted":
        return node.data.UploaderMetaData.some(
          (item) => item?.deletedOnEnvironment == true
        );
      case "modified":
        return node.data.UploaderMetaData.some(
          (item) =>
            item.i_t_date_updateTs !== null &&
            item?.date_sourceUpdateTs - item.i_t_date_updateTs > 500
        );
      case "draft":
        return node.data.UploaderMetaData.some(
          (item) => item?.i_t_date_updateTs == null
        );
      case "liveDEV":
        return node.data.UploaderMetaData.some(
          (item) => item?.environment == "DEVELOPMENT"
        );
      case "livePROD":
        return node.data.UploaderMetaData.some(
          (item) => item?.environment == "PRODUCTION"
        );
      case "notLivePROD":
        return node.data.UploaderMetaData.some(
          (item) => ((item?.environment !== "PRODUCTION") && (item?.active !== "true"))
        );
      case "notLiveDEV":
        return node.data.UploaderMetaData.some(
          (item) => ((item?.environment !== "DEVELOPMENT") && (item?.active !== "true"))
        );
      case "supplier":
        return node.data.Entity.name === "StSpRecordedInstitution"

      default:
        return true;
    }
  };

  const handleToggleColumnOptions = (e) => {
    setColumnOptionsOpen((prevOpen) => !prevOpen);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseColumnOptions = (event) => {
    setColumnOptionsOpen(false);
  };

  const onClickColumnDef = (colid) => {
    agRef.current.api.ensureColumnVisible(colid);
    setColumnOptionsOpen(false);
  };
  const toogleShowHide = (item) => {
    console.log("agRef.current.api");
    item.colId !== "Entity.accountName" &&
      columnApi.setColumnVisible(item.colId, item.hide);
    props.updateColumnsDef(item);
  };
  // function onFilterTextBoxChanged() {
  //   gridOptions.api.setQuickFilter(document.getElementById('filter-text-box').value);
  // }

  return (
    <React.Fragment>
      {props.store.navBarLoading && <LinearProgress />}
      <div className={classes.agGrid_Header}>
        <div className={classes.filtering_left_margin}>
          <Button
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggleColumnOptions}
          >
            {t(`${"dmTool:hideShowColumn"}`)}
            <ArrowDropDownCircleIcon color="primary" />
          </Button>
          <Popper
            open={columnOptionsOpen}
            anchorEl={anchorEl}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: "1" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                  maxHeight: "85vh",
                  overflow: "auto",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseColumnOptions}>
                    <MenuList id="split-button-menu">
                      {props.ColumnsDefs.columns
                        ?.sort((a, b) =>
                          t(`${"dmTool:" + a.headerName}`).localeCompare(
                            t(`${"dmTool:" + b.headerName}`)
                          )
                        )
                        ?.map((item, index) => {
                          return (
                            <MenuItem key={item.colId + index}>
                              <Tooltip title={t(`${"dmTool:hideShowColumn"}`)}>
                                <Checkbox
                                  defaultChecked
                                  checked={!item.hide}
                                  onChange={() => toogleShowHide(item)}
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "secondary checkbox",
                                  }}
                                />
                              </Tooltip>
                              <span
                                style={{
                                  maxWidth: "150px",
                                  whiteSpace: "break-spaces",
                                }}
                              >
                                {t(`${"dmTool:" + item.headerName}`)}
                              </span>
                              <Tooltip title={t(`${"dmTool:gotoColumn"}`)}>
                                <IconButton
                                  key={"gotoColumn"}
                                  onClick={() =>
                                    item.hide
                                      ? ""
                                      : onClickColumnDef(item.colId)
                                  }
                                  style={{ marginLeft: "auto" }}
                                >
                                  <FastForwardIcon />
                                </IconButton>
                              </Tooltip>
                            </MenuItem>
                          );
                        })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "all"}
                onChange={(e) => _handleFilterOptionChange(e, "all")}
                name="all"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filterAll"}`)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "markedToDelete"}
                onChange={(e) => _handleFilterOptionChange(e, "markedToDelete")}
                name="marked to delete"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filterMarkedToDelete"}`)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "deleted"}
                onChange={(e) => _handleFilterOptionChange(e, "deleted")}
                name="deleted"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filterDeleted"}`)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "modified"}
                onChange={(e) => _handleFilterOptionChange(e, "modified")}
                name="modified"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filterModified"}`)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "draft"}
                onChange={(e) => _handleFilterOptionChange(e, "draft")}
                name="Draft"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filterDraft"}`)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "liveDEV"}
                onChange={(e) => _handleFilterOptionChange(e, "liveDEV")}
                name="live Dev"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filterLiveDev"}`)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "notLiveDEV"}
                onChange={(e) => _handleFilterOptionChange(e, "notLiveDEV")}
                name=" not live Dev"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filternotLiveDev"}`)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "livePROD"}
                onChange={(e) => _handleFilterOptionChange(e, "livePROD")}
                name="live PPROD"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filterLiveProd"}`)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "notLivePROD"}
                onChange={(e) => _handleFilterOptionChange(e, "notLivePROD")}
                name="not live PROD"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filternotLiveProd"}`)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType == "supplier"}
                onChange={e => _handleFilterOptionChange(e, "supplier")}
                name="supplier"
                color="primary"
                className={classes.check_box_padding}
              />
            }
            label={t(`${"dmTool:filterOnlySupplier"}`)}
          />
        </div>
        <div className={classes.margin_institution_buton}>
          <Button
            onClick={OpenInstitutionDialog}
            variant="contained"
            color="primary"
            startIcon={<AddIcon></AddIcon>}
          >
            {t(`${"dmTool:addInstitutionBtnLabel"}`)}
          </Button>

          {!!intitutionDialog && (
            <InstistutionDialog
              open={intitutionDialog}
              gridref={agRef.current}
              onClose={CloseInstitutionDialogOpen}
            />
          )}

          <IconButton
            key={"settings"}
            style={{ marginLeft: "auto" }}
            onClick={_openSettingsDialog}
          >
            <SettingsIcon />
          </IconButton>

          {!!openSettingsDialog && (
            <SettingsDialog
              open={openSettingsDialog}
              onClose={closeSettingsDialog}
              selectedDateFormat={_selectedDateFormat}
              prefferedLanguage={_prefferedLanguage}
              context={context}
              configuration={_configuration}
            />
          )}
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 800 }}>
        <AgGridReact
          className={classes.containerSelect}
          ref={agRef}
          columnDefs={props.ColumnsDefs.columns}
          defaultColDef={props.ColumnsDefs.defaultColDef}
          rowData={props.store.Entities}
          getRowNodeId={(data) => data.Entity.i_sourceKey}
          onGridReady={onGridReady}
          // domLayout={'autoHeight'}

          frameworkComponents={frameworkComponents}
          // editType="fullRow"
          getRowHeight={getRowHeight}
          cacheQuickFilter={true}
          // suppressClickEdit
          statusBar={{
            statusPanels: [{ statusPanel: "addRowStatusBar" }],
          }}
          onColumnResized={onColumnResized}
          onColumnVisible={onColumnVisible}
          enterMovesDownAfterEdit={true}
          stopEditingWhenGridLosesFocus={true}
          onCellEditingStopped={onCellEditingStopped}
          // immutableData={true}
          suppressHorizontalScroll={false}
          enableSorting={true}
          immutableData={true}
          context={context}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
        // enableCellChangeFlash={true}
        />
      </div>
    </React.Fragment>
  );
}

var filter = "all";

const mapStateToProps = (state, ownProps) => {
  return {
    store: state.Entity,
    ColumnsDefs: state.ColumnsDefs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntityDataFromServer: () => dispatch(getEntityDataFromServer()),
    updateColumnsDef: (payload) => dispatch(updateColumnsDef(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityDataEntryPage);
