import axios from 'axios';
//import { toast } from "react-toastify";
import i18n from "./../i18n"
import { authenticationService } from './../Services';
i18n.loadNamespaces(["translation", "SSPErrorMessages"])
const modem = () => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        crossOrigin: true
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const token = localStorage.getItem('Authorization');
        if (token) {
            if (token.indexOf("Bearer") !== -1) {
                config.headers.Authorization = token ? `${token}` : '';
            } else {
                config.headers.Authorization = token ? `Bearer ${token}` : '';
            }
        }
        return config;
    });
    instance.interceptors.response.use(
        (response) => {
            // all 2xx/3xx responses will end here
            return response;
        },
        (error) => {
            if ([401, 403].indexOf(error.response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                window.location.reload(true);
            }       
            return Promise.reject(error);
        }
    );

    return instance;
};

export default modem();
