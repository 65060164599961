import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useSnackbar } from "notistack";
import { sspDataReliabilityEnum, sspLanguageEnum } from "../../../utils/enums";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../../utils";
import UILanguageSelector from "../../Languages";
import languages from "../../Languages/languages";
import SingleDocumentClassSelector from "./SingleDocumentClassSelector";
import { Divider } from "@material-ui/core";
import { sspLifecycleStatusEnum } from "../../../utils/enums";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({}));

const DocumentTitle = (props) => {
  const { t } = useTranslation([
    "translation",
    "eventsEnumerations",
    "lifecycleEnumerations",
    "dmTool",
  ]);
  const { documentObj, setLinkValidate, addUpdateOrDeleteDocumentsLink } =
    props;
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [languageOptions, setLanguageOptions] = useState([]);
  useEffect(() => {
    let keys = props.documentObj.Documents.filter(
      (doc) => doc.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
    )
      .filter((item) => item.docLanguage && item.docLanguage !== "")
      .map((item) => item.docLanguage);
    if (!!props.documentObj.Documents.length && !!keys.length) {
      let langs = [];
      let filterLang = sspLanguageEnum.map((lang) => {
        if (!keys?.includes(lang.value)) {
          langs.push(lang);
        }
      });
      setLanguageOptions(langs);
    } else {
      setLanguageOptions(sspLanguageEnum);
    }
  }, [props.documentObj.Documents]);

  const OnChangeDocLangChange = (e, value, docObj, index) => {
    let _documents = documentObj.Documents;
    let docIndex = _documents.findIndex(
      (item) => item.i_sourceKey === docObj.i_sourceKey
    );
    _documents[docIndex].docLanguage = value.value;
    _documents[docIndex].date_sourceUpdateTs= Date.now();
    _documents[docIndex].i_t_date_updateTs= Date.now();
    // _documents[index].docLanguage = value.value
    addUpdateOrDeleteDocumentsLink(_documents, "update");
  };

  const OnChangelinkChange = (e, docObj, index) => {
    let _documents = documentObj.Documents;

    let docIndex = _documents.findIndex(
      (item) => item.i_sourceKey === docObj.i_sourceKey
    );
    // let pattern = new RegExp(/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/)
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    if (!isEmpty(e.target.value)) {
      if (!pattern.test(e.target.value)) {
        _documents[docIndex].linkValidate = true;
        setLinkValidate(true);
      } else {
        _documents[docIndex].linkValidate = false;
        setLinkValidate(false);
      }
    } else {
      _documents[docIndex].linkValidate = false;
      setLinkValidate(false);
    }
    _documents[docIndex].connectionDocURL = e.target.value;
    _documents[docIndex].date_sourceUpdateTs= Date.now();
    _documents[docIndex].i_t_date_updateTs= Date.now();
    addUpdateOrDeleteDocumentsLink(_documents, "update");
  };

  const addDocumentLink = (e) => {
    let keys = props.documentObj.Documents.filter(
      (doc) => doc.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
    )
      .filter((item) => item.docLanguage && item.docLanguage !== "")
      .map((item) => item.docLanguage);
    if (!(keys.length === Object.keys(languages).length)) {
      if (!keys.length) {
        enqueueSnackbar(t(`${"dmTool:selectLangToAddNewErrorMsg"}`), {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        addUpdateOrDeleteDocumentsLink(
          {
            name: "EntityDoc",
            documentType: "DOC_ENTITY_FACTSHEET",
            i_sourceKey:
              "ssp_entitydoc_" +
              Date.now() +
              "_" +
              parseInt(Math.random() * 100000),
            connectionDocURL: null,
            docLanguage: "",
            docDocumentName: null,
            i_docMimeType: "",
            docSpecificJurisdictionRegion: [],
            docSpecificJurisdictionCountry: [],
            docSpecificJurisdictionType: "",
            docTargetGroupList: [],
            docDocumentTimeRangeStartDate: null,
            docDocumentTimeRangeType: "",
            i_t_lifecycleElement_status: "CYC_STATUS_ACTIVE",
            date_sourceCreationTs: Date.now(),
            date_sourceUpdateTs: Date.now(),
            i_t_qualitySourceDataQualityEvaluation: "",
            i_t_researchComment: null,
            i_t_startupSpiderInternalComment: "",
            i_t_qualitySourceExpertQualityComment: null,
            documentClass: {},
          },
          "add"
        );
      }
    } else {
      enqueueSnackbar(
        t("dmTool:duplicateLangNotAllowedErrorMsg", {
          type: t(`${"dmTool:link"}`),
        }),
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

  const removeLinkRow = (e, _docObj, index) => {
    let docs = documentObj?.Documents;
    // docs.splice(index, 1);
    let _docIndex = docs.findIndex(
      (item) => item.i_sourceKey === _docObj.i_sourceKey
    );
    // if (docs.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED").length > 1) {
    //     docs[_docIndex].i_t_lifecycleElement_status = 'CYC_STATUS_DELETED'
    //     delete docs[_docIndex].linkValidate;
    // }

    if (
      docs.filter(
        (item) => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
      ).length === 1
    ) {
      enqueueSnackbar(
        t("dmTool:fieldShldHaveAtleastOneEntryErrorMsg", {
          type: t(`${"dmTool:link"}`),
        }),
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    } else {
      docs[_docIndex].i_t_lifecycleElement_status = "CYC_STATUS_DELETED";
      delete docs[_docIndex].linkValidate;
      docs[_docIndex].date_sourceUpdateTs= Date.now();
      docs[_docIndex].i_t_date_updateTs= Date.now();
      addUpdateOrDeleteDocumentsLink(docs, "update");

      enqueueSnackbar(
        t("dmTool:removingEntrySuccessMsg", { type: t(`${"dmTool:link"}`) }),
        {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

  const renderAddCloseIcon = (_docObj, index) => {
    if (
      index ===
      documentObj.Documents.filter(
        (item) => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
      ).length -
        1
    ) {
      return (
        <span style={{ display: "flex" }}>
          <Tooltip title={t(`${"dmTool:addLinkTooltip"}`)}>
            <IconButton
              aria-label="Add more document link"
              color="secondary"
              onClick={addDocumentLink}
            >
              <AddIcon className={classes.icons} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t(`${"dmTool:removeLinkTooltip"}`)}>
            <IconButton
              aria-label="settings"
              onClick={(e) => removeLinkRow(e, _docObj, index)}
            >
              <DeleteIcon className={classes.icons} />
            </IconButton>
          </Tooltip>
        </span>
      );
    }
    return (
      <Tooltip title={t(`${"dmTool:removeLinkTooltip"}`)}>
        <IconButton
          aria-label="settings"
          onClick={(e) => removeLinkRow(e, _docObj, index)}
        >
          <DeleteIcon className={classes.icons} />
        </IconButton>
      </Tooltip>
    );
  };

  const sspDataReliabilityEnumOptions = Object.keys(sspDataReliabilityEnum).map(
    (opt) => {
      return {
        value: opt,
        label: t(`${"lifecycleEnumerations:" + sspDataReliabilityEnum[opt]}`),
      };
    }
  );

  const OnChangeDocumentsDocumentType = (key, value, docObj, index) => {
    console.log("OnChangeDocumentsDocumentType", key);
    let _documents = documentObj.Documents;

    let docIndex = _documents.findIndex(
      (item) => item.i_sourceKey === docObj.i_sourceKey
    );
    _documents[docIndex].documentClass = {
      key: key.documentClass,
      value: key.documentType,
    };
    _documents[docIndex].date_sourceUpdateTs= Date.now();
    _documents[docIndex].i_t_date_updateTs= Date.now();
    addUpdateOrDeleteDocumentsLink(_documents, "update");
  };

  const _onChangeQualityFlag = (e, value, docObj, index) => {
    let _documents = documentObj.Documents;
    
    let docIndex = _documents.findIndex(
      (item) => item.i_sourceKey === docObj.i_sourceKey
      );
    
    
      _documents[docIndex].i_t_qualitySourceDataQualityEvaluation = value.value;
      _documents[docIndex].date_sourceUpdateTs= Date.now();
      _documents[docIndex].i_t_date_updateTs= Date.now();
    addUpdateOrDeleteDocumentsLink(_documents, "update");
  };

  const _OnChangeDocumentStateType = (e, value, docObj, index) => {
    let _documents = documentObj.Documents;

    let docIndex = _documents.findIndex(
      (item) => item.i_sourceKey === docObj.i_sourceKey
    );

    _documents[docIndex].i_t_lifecycleElement_status = value.value;
    addUpdateOrDeleteDocumentsLink(_documents, "update");
  };

  const sspAdStatusEnumOptions = Object.keys(sspLifecycleStatusEnum).map((opt) => {
    return {
      value: opt,
      label: t(`${"lifecycleEnumerations:" + sspLifecycleStatusEnum[opt]}`),
    };
  });

  const OnChangeDocumentUpdateDate = (date, e, docObj, index) => {
    const value = date !== null ? new Date(date).getTime() : null;
    
    let _documents = documentObj.Documents;
    
    let docIndex = _documents.findIndex(
      (item) => item.i_sourceKey === docObj.i_sourceKey
      );
      // _documents[docIndex].date_sourceUpdateTs = value;
      _documents[docIndex].versionTs = value;
      
      // if(index==0 && documentObj.Documents.length==1 ){
      //   _documents[docIndex].date_sourceCreationTs = value;
      // }

    addUpdateOrDeleteDocumentsLink(_documents, "update");
  };

  const OnChangeVersionChange = (e, docObj, index) => {
    let _documents = documentObj.Documents;

    let docIndex = _documents.findIndex(
      (item) => item.i_sourceKey === docObj.i_sourceKey
    );
    _documents[docIndex].date_sourceUpdateTs = Date.now();
    _documents[docIndex].versionIdentifier = e.target.value;
    addUpdateOrDeleteDocumentsLink(_documents, "update");
  };

  return documentObj?.Documents?.filter(
    (item) => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
  )?.map((docObj, index) => {
    return (
      <React.Fragment key={"docment_link" + index}>
        <Grid
          container
          dividers
          spacing={1}
          style={{
            margin: "10px 0",
            border: "1px solid #ccc",
            padding: "5px 0 0 0",
          }}
        >
          <Grid item lg={4} sm={4} xs={12}>
            <Autocomplete
              disableClearable
              fullWidth
              size="small"
              className={classes.autocompleteStyle}
              getOptionLabel={(option) => option.label}
              id="milestone_type"
              margin="normal"
              onChange={(e, value) =>
                _OnChangeDocumentStateType(e, value, docObj, index)
              }
              options={sspAdStatusEnumOptions}
              value={
                sspAdStatusEnumOptions.find(
                  (item) => item.value === docObj?.i_t_lifecycleElement_status
                ) || {}
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t(
                    `${"dmTool:documentDocumentStatusInputFieldLabel"}`
                  )}`}
                  placeholder={t(`${"dmTool:selectDocumentStatusplcHldr"}`)}
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <SingleDocumentClassSelector
              documentObj={documentObj}
              OnChangeDocumentType={(e, value) =>
                OnChangeDocumentsDocumentType(e, value, docObj, index)
              }
              label="Select complex values"
              placeholder={t(`${"dmTool:selectDocumentTypesPlcHldr"}`)}
              selectedClass={{
                documentClass: isEmpty(docObj?.documentClass?.key) ?  "": docObj?.documentClass?.key,
                documentType: isEmpty(docObj?.documentClass?.value) ? "": docObj?.documentClass?.value,
              }}
              onChange={(data) => {
                console.log(data);
              }}
              selectorOption={docObj?.documentClass}
            />
          </Grid>
          <Grid item lg={2} sm={2} xs={12}>
            {renderAddCloseIcon(docObj, index)}
          </Grid>
          <Grid item lg={4} sm={4} xs={12}>
            <KeyboardDatePicker
              autoOk
              size="small"
              clearable
              keyboard
              inputVariant="outlined"
              placeholder={t(`${"dmTool:enterDocumentDatePlcHldr"}`)}
              label={`${t(`${"dmTool:documentDateInputFieldLabel"}`)}`}
              format={"DD/MM/YYYY"}
              value={docObj.versionTs}
              InputAdornmentProps={{
                position: "start",
              }}
              onChange={(e, value) =>
                OnChangeDocumentUpdateDate(e, value, docObj, index)
              }
            />
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <TextField
              className={classes.marginSet}
              fullWidth
              label={t(`${"dmTool:versionInputFieldLabel"}`)}
              inputProps={{
                autoComplete: "new-password",
              }}
              placeholder={t(`${"dmTool:enterVersionPlcHldr"}`)}
              onChange={(e) => OnChangeVersionChange(e, docObj, index)}
              size="small"
              required={false}
              value={docObj?.versionIdentifier}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
         
          <Grid item lg={4} sm={4} xs={12}>
            {/* <Autocomplete
                            disableClearable
                            size="small"
                            fullWidth
                            className={classes.autocompleteStyle}
                            getOptionLabel={option => option.label}
                            id="document_link_lang"
                            margin="normal"
                            onChange={(e, value) => OnChangeDocLangChange(e, value, docObj, index)}
                            options={languageOptions}
                            value={
                                sspLanguageEnum.find(
                                    item =>
                                        item.value ===
                                        docObj?.docLanguage,
                                ) || {}
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                                    placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        /> */}
            <UILanguageSelector
              selectedValues={documentObj?.Documents?.filter(
                (item) =>
                  item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
              )}
              label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
              placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
              value={docObj?.docLanguage}
              onChange={(e, value) => {
                OnChangeDocLangChange(e, value, docObj, index);
              }}
            />
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <TextField
              className={classes.marginSet}
              fullWidth
              label={t(`${"dmTool:linkInputFieldLabel"}`) + "*"}
              inputProps={{
                autoComplete: "new-password",
              }}
              placeholder={t(`${"dmTool:enterLinkPlcHldr"}`)}
              onChange={(e) => OnChangelinkChange(e, docObj, index)}
              size="small"
              required={false}
              value={docObj?.connectionDocURL}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              error={docObj.linkValidate}
              helperText={
                docObj.linkValidate && t(`${"dmTool:enterValidURLWarningMsg"}`)
              }
            />
          </Grid>
          

          <Grid item lg={10} sm={10} xs={12} dividers>
            <Autocomplete
              disableClearable
              size="small"
              fullWidth
              className={classes.autocompleteStyle}
              getOptionLabel={(option) => option.label}
              id="quality-flag"
              margin="normal"
              onChange={(e, value) =>
                _onChangeQualityFlag(e, value, docObj, index)
              }
              options={sspDataReliabilityEnumOptions}
              value={
                sspDataReliabilityEnumOptions.find(
                  (item) =>
                    item.value ===
                    docObj?.i_t_qualitySourceDataQualityEvaluation
                ) || {}
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(`${"dmTool:qualityInputFieldLabel"}`) + "*"}
                  placeholder={t(`${"dmTool:selectQualityPlcHldr"}`)}
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  });
};

export default DocumentTitle;
