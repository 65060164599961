import React, { useState, useEffect } from 'react';
import { sspStartupProductStatusEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../utils';

let selectOptions = Object.keys(sspStartupProductStatusEnum)
    .map((item) => {
        return {
            name: sspStartupProductStatusEnum[item],
            value: item
        }
    })
export default (props) => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
    ]);
    return (
        <div>
            {isEmpty(props.value) ? "" : t(`${"configurationEnumerations:" + sspStartupProductStatusEnum[props.value]}`)}
        </div >
    )
}