import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Compare from "./components/Compare"
import LinearProgress from '@material-ui/core/LinearProgress';
import SourceAndDestFiles from './components/SourceAndDstinationFiles';

import { loadFromEnvironment } from "../../Store/Entity/actions";
import { connect } from "react-redux";
import jsonToReadableText from "./format";
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Alert from '@material-ui/lab/Alert';

import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import GetAppIcon from '@material-ui/icons/GetApp';
import { json } from 'body-parser';
import { isEmpty } from '../../utils';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    content_margin: {
        margin: "0 50px"
    },
    progress_bar_margin: {
        margin: "10px"
    },
    alert_style: {
        width: "fit-content",
        padding: "0px 5px",
        margin: "5px 0"
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CompareDialog = props => {
    const classes = useStyles();
    const { onClose, open, data, sourceVersion, destinationVersion } = props
    const [loading, setLoading] = useState(false)
    const [_sourceVersion, setSourceVersion] = useState(sourceVersion);
    const [_destinationVersion, setDestinationVersion] = useState(destinationVersion);
    const [sourceValueFormatted, setSourceValueFormatted] = useState("")
    const [destinationValueFormatted, setDestinationValueFormatted] = useState("")
    const [sourceValueJson, setSourceValueJson] = useState({})
    const [destinationValueJson, setDestinationValueJson] = useState({})
    const [diffTypeFormatter, setDiffTypeFormatter] = useState(false)
    const [showFormatted, setShowFormatted] = useState(true)

    const loadAnchorRef = React.useRef(null);
    const [loadOptionsOpen, setLoadOptionsOpen] = useState(false);

    const { t } = useTranslation([
        "translation",
        "dmTool",
        "configurationEnumerations",
        "entityDataEnumerations",
        "eventsEnumerations",
        "lifecycleEnumerations",
        "SSPDataQualityCommentMessages",
        "keyPersonEnumerations",
    ]);

    const closeModal = () => {
        onClose && onClose();
    };

    const fetchSourceVersionData = () => {
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', process.env.PUBLIC_URL + `/client-data/${props.data.Entity.i_sourceKey}`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        let data = {}
        if (_sourceVersion.fromUpstream) {
            data.environment = _sourceVersion.environment
        } else {
            data.fileSuffixId = _sourceVersion.fileSuffixId;
        }
        httpRequest.send(JSON.stringify(data));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status === "success") {
                    setSourceValueJson((res.data));
                    setSourceValueFormatted(jsonToReadableText(res.data, t));
                }
                //  else {
                //     setSourceValueJson("");
                //     setSourceValueFormatted("");
                // }
            }
        };
    }
    const fetchDestinationVersionData = () => {
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', process.env.PUBLIC_URL + `/client-data/${props.data.Entity.i_sourceKey}`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        let data = {}
        if (_destinationVersion.fromUpstream) {
            data.environment = _destinationVersion.environment
        } else {
            data.fileSuffixId = _destinationVersion.fileSuffixId;
        }
        httpRequest.send(JSON.stringify(data));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status === "success") {
                    setDestinationValueJson((res.data));
                    setDestinationValueFormatted(jsonToReadableText(res.data, t));
                }
                //  else {
                //     setDestinationValueJson("");
                //     setDestinationValueFormatted("");
                // }
            }
        };

    }
    const onChangeSourceVersion = (version) => {
        setSourceVersion(version)
        setDiffTypeFormatter(false)
    }
    const onChangeDestinationVersion = (version) => {
        setDestinationVersion(version);
        setDiffTypeFormatter(false)
    }
    React.useEffect(() => {
        _sourceVersion && fetchSourceVersionData();
    }, [_sourceVersion])
    React.useEffect(() => {
        _destinationVersion && fetchDestinationVersionData();
    }, [_destinationVersion])


    const onClickCompare = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
        setDiffTypeFormatter(true)
    }
    const getSourceContent = () => {
        if (!showFormatted) {
            return JSON.stringify(sourceValueJson, null, 2);
        } else {
            return sourceValueFormatted;
        }
    }
    const getDestinationContent = () => {
        if (!showFormatted) {
            return JSON.stringify(destinationValueJson, null, 2);
        } else {
            return destinationValueFormatted;
        }
    }

    const onClickFormat = () => {
        setShowFormatted(true)
    }
    const onClickRaw = () => {
        setShowFormatted(false)
    }

    const showDiffStat = () => {
        const equalAlert = <Alert variant="outlined" severity="success" className={classes.alert_style}>
            {t(`${"dmTool:sourceAndDestinationVersionsAreEqual"}`)}
        </Alert>
        const notEqualAlert = <Alert variant="outlined" severity="error" className={classes.alert_style}>
            {t(`${"dmTool:sourceAndDestinationVersionsAreNotEqual"}`)}
        </Alert>
        if (showFormatted) {
            if (sourceValueFormatted == destinationValueFormatted) {
                return equalAlert
            } else {
                return notEqualAlert
            }
        } else {
            if (_sourceVersion == _destinationVersion) {
                return equalAlert
            } else {
                return notEqualAlert
            }
        }
    }

    const handleToggleDownloadOptions = () => {
        setLoadOptionsOpen((prevOpen) => !prevOpen);
    };
    const handleCloseDownloadOptions = (event) => {
        if (loadAnchorRef.current && loadAnchorRef.current.contains(event.target)) {
            return;
        }

        setLoadOptionsOpen(false);
    };

    // const downloadSourceJson = (el) => {
    //     var data = "text/json;charset=utf-8," + encodeURIComponent(sourceValueJson);

    //     el.setAttribute("href", "data:" + data);
    //     el.setAttribute("download", "data.json");
    // }

    const download = (content, fileName, contentType) => {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    const onDownload = (jsonFile, name) => {
        download(JSON.stringify(jsonFile, null, 4), `${jsonFile?.Entity?.i_sourceKey + "_" + name}.json`, "text/plain");
        setLoadOptionsOpen(false);
    }

    return (
        <React.Fragment>
            {open && (
                <Dialog fullScreen open={open} onClose={closeModal} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {t(`${"dmTool:compareEntityDialoHdng"}`)} {props.data.Entity?.accountName}
                            </Typography>
                            <Button autoFocus color="inherit" onClick={closeModal}>
                                <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content_margin}>
                        <SourceAndDestFiles
                            onClickCompare={onClickCompare}
                            onChangeSourceVersion={onChangeSourceVersion}
                            onChangeDestinationVersion={onChangeDestinationVersion}
                            data={props.data}
                            sourceVersion={_sourceVersion}
                            destinationVersion={_destinationVersion}
                        />
                        <div style={{ display: "flex" }}>
                            <div style={{ flex: "1" }}>
                                {diffTypeFormatter && showDiffStat()}
                            </div>
                            {!showFormatted && <div style={{ margin: "7px 5px 0 0" }}>
                                <ButtonGroup size="small" variant="contained" color="primary" ref={loadAnchorRef} aria-label="split button">
                                    <Button> <GetAppIcon />{t(`${"dmTool:downloadJson"}`)}</Button>
                                    <Button
                                        color="primary"
                                        size="small"
                                        aria-controls={loadOptionsOpen ? 'split-button-menu' : undefined}
                                        aria-expanded={loadOptionsOpen ? 'true' : undefined}
                                        aria-label="select merge strategy"
                                        aria-haspopup="menu"
                                        onClick={handleToggleDownloadOptions}
                                    >
                                        <ArrowDropDownIcon />
                                    </Button>
                                </ButtonGroup>
                                <Popper open={loadOptionsOpen} anchorEl={loadAnchorRef.current} role={undefined} transition disablePortal style={{ zIndex: "1" }}>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleCloseDownloadOptions}>
                                                    <MenuList id="split-button-menu">
                                                        <MenuItem onClick={e => onDownload(sourceValueJson, t(`${"dmTool:source"}`))} disabled={isEmpty(sourceValueJson)}><GetAppIcon />{t(`${"dmTool:downloadSourceVersion"}`)}</MenuItem>
                                                        <MenuItem onClick={e => onDownload(destinationValueJson, t(`${"dmTool:target"}`))} disabled={isEmpty(destinationValueJson)}><GetAppIcon />{t(`${"dmTool:downloadDestVersion"}`)}</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>}
                            <div style={{ margin: "5px 0 0 0" }}>
                                <ButtonGroup disableElevation variant="contained" color="primary">
                                    <Button onClick={onClickFormat} color={showFormatted ? "primary" : "disabled"}>{t(`${"dmTool:formatedVesrionHdng"}`)}</Button>
                                    <Button onClick={onClickRaw} color={!showFormatted ? "primary" : "disabled"}>{t(`${"dmTool:rawVesrionHdng"}`)}</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        {loading ?
                            <div className={classes.progress_bar_margin}> <LinearProgress /> </div> :
                            <Compare
                                data={props.data}
                                compare={diffTypeFormatter}
                                _sourceValueFormatted={getSourceContent()}
                                _destinationValueFormatted={getDestinationContent()}
                            />}
                    </div>
                </Dialog>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadFromEnvironment: (payload) => dispatch(loadFromEnvironment(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareDialog);