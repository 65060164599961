import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sspServiceAreaEnum, sspServiceAudienceTypeEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { countries } from '../../Countrieslist';
import Grid from '@material-ui/core/Grid';
import Chip from "@material-ui/core/Chip";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles(theme => ({
    zone: {
        border: "2px solid #ccc",
        margin: "4px 0",
        padding: "5px"
    }
}));

const ServiceZones = props => {
    const [serviceZones, setServiceZones] = useState(props.serviceZones);
    const [applyToAllCustomer, setApplyToAllCustomer] = useState(serviceZones?.some(item => item.serviceAudienceType == "CON_SERVICE_AUDIENCE_TYPE_ANY"))
    useEffect(() => {
        // add or remove serviceZones from serviceZones state
        //check if you have applytoAllCostomer zone
        let applyToAllCustomerZone = serviceZones.filter(item => item.serviceAudienceType === "CON_SERVICE_AUDIENCE_TYPE_ANY")
        if (!applyToAllCustomerZone.length) {
            if (props.serviceAudienceType.length < serviceZones.length) {
                setServiceZones((_serviceZones) => {
                    return [..._serviceZones.filter((_zone => {
                        return props.serviceAudienceType.indexOf(_zone.serviceAudienceType) !== -1
                    }))]
                })
            }
            if (props.serviceAudienceType.length > serviceZones.length) {
                let diffServiceAudienceType = props.serviceAudienceType.filter((_audienceType) => !props.serviceZones.filter(_zone => _zone.serviceAudienceType == _audienceType).length)
                setServiceZones((_serviceZones) => {
                    return [..._serviceZones,
                    ...diffServiceAudienceType.map((item) => {
                        return {
                            "name": "ServiceZone",
                            "serviceAudienceType": item,
                            "geographies": ["CON_SERVICE_AREA_TYPE_GLOBAL"],
                            "countries": [],
                            "blockedcountries": [],
                            "i_sourceKey": "ssp_servicezone_" + Date.now() + "_" + parseInt(Math.random() * 100000),
                            "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE",
                            "date_sourceCreationTs": null,
                            "date_sourceUpdateTs": null,
                            "i_t_date_creationTs": null,
                            "i_t_date_updateTs": null,
                            "i_t_date_lastCheckTs": null,
                            "i_t_qualitySourcePriorityLevel": null,
                            "i_t_qualitySourceDataQualityEvaluation": null,
                            "i_t_researchComment": null,
                            "i_t_startupSpiderInternalComment": null,
                            "i_t_qualitySourceExpertQualityComment": null,
                        }
                    })
                    ]
                })
            }
        }
    }, [props.serviceAudienceType])

    useEffect(() => {
        props.onChageServiceZones && props.onChageServiceZones(serviceZones);
    }, [serviceZones])
    const classes = useStyles();
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const _onChangeServiceZones = (e, value, reason, index) => {
        if (value.value) {
            if (value.value == "APPLY_TO_ALL_CUSTOMERS") {
                _applyToAllCustomers();
                setApplyToAllCustomer(true)
            } else {
                setApplyToAllCustomer(false)
                let _serviceZones = serviceZones;
                if (_serviceZones.length == 1) {
                    setServiceZones((_serviceZone) => {
                        return [
                            ...props.serviceAudienceType.map((type) => {
                                return {
                                    "name": "ServiceZone",
                                    "serviceAudienceType": type,
                                    "geographies": ["CON_SERVICE_AREA_TYPE_GLOBAL"],
                                    "countries": [],
                                    "blockedcountries": [],
                                    "i_sourceKey": "ssp_servicezone_" + Date.now() + "_" + parseInt(Math.random() * 100000),
                                    "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE",
                                    "date_sourceCreationTs": null,
                                    "date_sourceUpdateTs": null,
                                    "i_t_date_creationTs": null,
                                    "i_t_date_updateTs": null,
                                    "i_t_date_lastCheckTs": null,
                                    "i_t_qualitySourcePriorityLevel": null,
                                    "i_t_qualitySourceDataQualityEvaluation": null,
                                    "i_t_researchComment": null,
                                    "i_t_startupSpiderInternalComment": null,
                                    "i_t_qualitySourceExpertQualityComment": null,
                                }
                            })
                        ]
                    })
                } else {
                    _serviceZones[index].serviceAudienceType = value.value;
                    setServiceZones(_serviceZones);
                }
            }
        }
    }

    const serviceFocus = (props.serviceAudienceType)?.map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspServiceAudienceTypeEnum[opt]}`)
        };
    }).concat({ value: "APPLY_TO_ALL_CUSTOMERS", label: t("dmTool:APPLY_TO_ALL_CUSTOMERS") })

    const targetCutomerOptions =
        applyToAllCustomer ? serviceFocus?.filter((type, index) => {
            return (type.value !== "APPLY_TO_ALL_CUSTOMERS")
        }) : serviceFocus?.filter((type, index) => {
            return (type.value === "APPLY_TO_ALL_CUSTOMERS")
        })
    const _applyToAllCustomers = () => {
        setServiceZones([{
            "name": "ServiceZone",
            "serviceAudienceType": "CON_SERVICE_AUDIENCE_TYPE_ANY",
            "geographies": ["CON_SERVICE_AREA_TYPE_GLOBAL"],
            "countries": [],
            "blockedcountries": [],
            "i_sourceKey": "ssp_servicezone_" + Date.now() + "_" + parseInt(Math.random() * 100000),
            "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE",
            "date_sourceCreationTs": null,
            "date_sourceUpdateTs": null,
            "i_t_date_creationTs": null,
            "i_t_date_updateTs": null,
            "i_t_date_lastCheckTs": null,
            "i_t_qualitySourcePriorityLevel": null,
            "i_t_qualitySourceDataQualityEvaluation": null,
            "i_t_researchComment": null,
            "i_t_startupSpiderInternalComment": null,
            "i_t_qualitySourceExpertQualityComment": null,
        }])
    }

    const eventStatusOptions = Object.keys(sspServiceAreaEnum).map(opt => {
        return {
            value: opt,
            name: t(`${"eventsEnumerations:" + sspServiceAreaEnum[opt]}`)
        };
    });

    const _onChangeServiceZoneGeograpgy = (e, value, reason, index) => {
        let _serviceZones = serviceZones;
        _serviceZones[index].geographies = [...value?.map(item => item.value)]
        setServiceZones((_serviceZone) => {
            return [..._serviceZones]
        })
    }
    const _onServiceZonesCountries = (e, value, reason, index) => {
        let _serviceZones = serviceZones;
        _serviceZones[index].countries = value?.map(item => item.code)
        setServiceZones((_serviceZone) => {
            return [..._serviceZones]
        })
    }

    const _onServiceZonesBlockedCountries = (e, value, reason, index) => {
        let _serviceZones = serviceZones;
        _serviceZones[index].blockedcountries = value?.map(item => item.code)
        setServiceZones((_serviceZone) => {
            return [..._serviceZones]
        })
    }
    return (
        <React.Fragment>
            {serviceZones.map((zone, index) => {
                return <Paper key={"zone" + index} className={classes.zone}>
                    <Grid container spacing={1}>
                        <Grid item lg={6} sm={6} xs={12}>
                            <Autocomplete
                                disableClearable
                                fullWidth
                                size="small"
                                className={classes.autocompleteStyle}
                                getOptionLabel={option => option.label}
                                id="target_customer"
                                margin="normal"
                                onChange={(e, value, reason,) => _onChangeServiceZones(e, value, reason, index)}
                                options={targetCutomerOptions}
                                value={zone.serviceAudienceType !== "CON_SERVICE_AUDIENCE_TYPE_ANY"
                                    ? serviceFocus?.find(item => item.value === zone.serviceAudienceType)
                                    : serviceFocus?.find(item => item.value === "APPLY_TO_ALL_CUSTOMERS")}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t(`${"dmTool:targetCustomerInputFieldLabel"}`)}
                                        placeholder={t(`${"dmTool:selectTargetCustomerPlcHldr"}`)}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                            <Autocomplete
                                id="zone"
                                multiple
                                disableCloseOnSelect
                                value={eventStatusOptions.filter(item => zone?.geographies?.indexOf(item.value) !== -1)}
                                options={eventStatusOptions}
                                getOptionLabel={option => option.name}
                                getOptionSelected={(option, value) => value.value === option.value}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t(`${"dmTool:zoneInputFieldLabel"}`)}
                                        variant="standard"
                                    />
                                )}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                onChange={(e, value, reason) => _onChangeServiceZoneGeograpgy(e, value, reason, index)}
                            />
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                            <Autocomplete
                                id="country-select-demo"
                                multiple
                                disableCloseOnSelect
                                value={countries.filter(item => zone?.countries?.indexOf(item.code) !== -1
                                )}
                                options={countries}
                                getOptionLabel={option => option.label}
                                getOptionSelected={(option, value) =>
                                    value.label === option.label
                                }
                                renderInput={params => (
                                    < TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            autoComplete: 'new-password',
                                            startAdornment:
                                                <React.Fragment>
                                                    {params.InputProps.startAdornment}
                                                </React.Fragment>

                                        }}
                                        label={`${t(`${"dmTool:countryInputFieldLabel"}`)}*`}
                                        variant="standard"
                                    />
                                )
                                }
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            size="small"
                                            avatar={<img className="country-flag country_Selected_flag" src={`./flags/${option.code?.toLowerCase()}.png`} />}
                                            variant="outlined"
                                            label={option.label}
                                            {...getTagProps({ index })} />
                                    ))
                                }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        <img className="country-flag" src={`./flags/${option.code?.toLowerCase()}.png`} />{option.label}
                                    </React.Fragment>
                                )}
                                onChange={(e, value, reason) => _onServiceZonesCountries(e, value, reason, index)}
                            />
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                            <Autocomplete
                                id="blocked-countries-select-demo"
                                multiple
                                disableCloseOnSelect
                                value={countries.filter(item => zone?.blockedcountries?.indexOf(item.code) !== -1
                                )}
                                options={countries}
                                getOptionLabel={option => option.label}
                                getOptionSelected={(option, value) =>
                                    value.label === option.label
                                }
                                renderInput={params => (
                                    < TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            autoComplete: 'new-password',
                                            startAdornment:
                                                <React.Fragment>
                                                    {params.InputProps.startAdornment}
                                                </React.Fragment>

                                        }}
                                        label={t(`${"dmTool:blockedCountryInputFieldLabel"}`)}
                                        variant="standard"
                                    />
                                )
                                }
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            size="small"
                                            avatar={<img className="country-flag country_Selected_flag" src={`./flags/${option.code?.toLowerCase()}.png`} />}
                                            variant="outlined"
                                            label={option.label}
                                            {...getTagProps({ index })} />
                                    ))
                                }
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        <img className="country-flag" src={`./flags/${option.code?.toLowerCase()}.png`} />{option.label}
                                    </React.Fragment>
                                )}
                                onChange={(e, value, reason) => _onServiceZonesBlockedCountries(e, value, reason, index)}
                            />
                        </Grid>
                    </Grid>
                </Paper>

            })}
        </React.Fragment >
    );
};

export default ServiceZones;