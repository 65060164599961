import React, { useState, forwardRef, useImperativeHandle } from "react";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { useTranslation } from "react-i18next";

export default forwardRef((props, ref) => {
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate((date !== null ? new Date(date).getTime() : null))
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                let dateString = null;
                if (selectedDate) {
                    dateString = format(selectedDate, 'dd/MM/yyyy');
                }
                return dateString;
            },
            isCancelAfterEnd: () => {
                return !selectedDate;
            },
            afterGuiAttached: () => {
                if (!props.value) {
                    return;
                }
                setSelectedDate((selectedDate !== null ? new Date(selectedDate).getTime() : null));
            }
        };
    });

    return (
        <>
            <KeyboardDatePicker
                autoOk
                // margin="normal"
                size='small'
                clearable
                keyboard
                inputVariant="outlined"
                placeholder={t(`${"dmTool:enterDatePlcHldr"}`)}
                label={t(`${"dmTool:dateInputFieldLabel"}`)}
                format={("DD/MM/YYYY")}
                value={selectedDate}
                InputAdornmentProps={{
                    position: 'start',
                }}
                onChange={handleDateChange}
            />

        </>
    );
})
