import React, { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import { sspMarketRelevantRolesEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';


export default (props) => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
    ]);
    let selectOptions = Object.keys(sspMarketRelevantRolesEnum)
        .map((item) => {
            return {
                name: t(`${"configurationEnumerations:" + sspMarketRelevantRolesEnum[item]}`),
                value: item
            }
        })


    return (
        <div style={{ overflow: 'auto', height: 280 }}>
            {!!props.value && props.value?.map((item) => {
                if (typeof (item) == 'string' && item !== '') {
                    return (

                        <Chip
                            size="small"
                            label={t(`${"configurationEnumerations:" + sspMarketRelevantRolesEnum[item]}`)}
                            style={toMaterialStyle(
                                sspMarketRelevantRolesEnum[item]?.toLowerCase(),
                                900,
                            )}
                        />
                    )
                }
            })
            }
        </div>
    )


}