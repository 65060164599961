import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { sspHighLevelSectorEnum, sspSectorEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const ServiceFocusSectorsDialog = props => {
    const { open, apiCallProgress } = props;
    const classes = useStyles();
    const [enitityObj, setEntityObj] = useState({});
    const [serviceFocusSectors, setServiceFocusSectors] = useState([]);
    useEffect(() => {
        setEntityObj(props.entity)
        if (!!props.ServiceFocusSectors && props.ServiceFocusSectors.length) {
            setServiceFocusSectors(props.ServiceFocusSectors)
        } else {
            setServiceFocusSectors([{
                key: '',
                value: ''
            }]
            )
        }

    }, [props.ServiceFocusSectors])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(serviceFocusSectors)
    };

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);

    const sspHighLevelSectorEnumOptions = Object.keys(sspHighLevelSectorEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + opt}`)
        };
    });




    const sspSectorEnumOptions = Object.keys(sspSectorEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspSectorEnum[opt]}`)
        };
    });

    const handleDetailSectorOption = (outerIndex) => {

        if (serviceFocusSectors?.length) {
            let highLevel = serviceFocusSectors.map(item => {
                return item.key
            })
            let key = highLevel[outerIndex]?.split("_").slice(4)[0];

            let serviceSectorsOptionsData = Object.keys(sspSectorEnum)?.filter((type, index) => {
                return serviceFocusSectors?.findIndex((item) => item.value == type) == -1
            })
                .filter(item => item.includes(key))
                .map(item => {
                    return {
                        value: item,
                        label: t(`${"configurationEnumerations:" + sspSectorEnum[item]}`)
                    }
                }
                )
            if (!!serviceSectorsOptionsData.length && serviceSectorsOptionsData?.some(item => item.value !== "CON_DETAIL_SECTOR_ALL_ALL")) {
                serviceSectorsOptionsData.push({
                    value: 'CON_DETAIL_SECTOR_ALL_ALL',
                    label: t(`${"configurationEnumerations:" + sspSectorEnum['CON_DETAIL_SECTOR_ALL_ALL']}`)
                })
            }
            return serviceSectorsOptionsData;
        }
    }


    const OnChangeServiceFocusSectorsValue = (e, value, index) => {
        let _serviceFocusSectors = serviceFocusSectors
        _serviceFocusSectors[index].value = value.value;
        addUpdateOrDeleteServiceFocusSectors(_serviceFocusSectors, 'update')
    }

    const OnChangeServiceFocusSectorsKey = (e, value, index) => {
        let _serviceFocusSectors = serviceFocusSectors
        _serviceFocusSectors[index].key = value.value
        addUpdateOrDeleteServiceFocusSectors(_serviceFocusSectors, 'update')
    }

    const addServiceFocusSector = (e) => {
        addUpdateOrDeleteServiceFocusSectors({
            key: '',
            value: '',
        }, 'add')
    }

    const addUpdateOrDeleteServiceFocusSectors = (object, type) => {
        if (type == 'add') {
            setServiceFocusSectors((_serviceFocusSectors) => {
                return ([..._serviceFocusSectors, object])

            })
        }
        if (type == 'delete') {
            setServiceFocusSectors((_serviceFocusSectors) => {
                return [..._serviceFocusSectors.filter((item, index) => index != object)]
            })
        }
        if (type == 'update') {
            setServiceFocusSectors((_serviceFocusSectors) => {
                return [...object]
            })
        }
    }


    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="institutionIdnumber-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="institutionIdnumber-dialog-title">
                        {(!!props.ServiceFocusSectors && props.ServiceFocusSectors?.length)
                            ? t("dmTool:updateServiceFocusSectorDialogHdng", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addServiceFocusSectorDialogHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        {!!serviceFocusSectors.length && serviceFocusSectors.map((_serviceFocusSectors, index) => {
                            return (<React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item lg={4} sm={4} xs={12}>
                                        <Autocomplete
                                            disableClearable
                                            fullWidth
                                            size="small"
                                            className={classes.autocompleteStyle}
                                            getOptionLabel={option => option.label}
                                            id="Service-higlevel-sector"
                                            margin="normal"
                                            onChange={(e, value) => OnChangeServiceFocusSectorsKey(e, value, index)}
                                            options={sspHighLevelSectorEnumOptions}
                                            value={
                                                sspHighLevelSectorEnumOptions.find(
                                                    item =>
                                                        item.value ===
                                                        _serviceFocusSectors?.key,
                                                ) || {}
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={t(`${"dmTool:serviceHighLevelSectorInputFieldLabel"}`)}
                                                    placeholder={t(`${"dmTool:selectServiceHighLevelSectorPlcHldr"}`)}
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={6} xs={12}>

                                        <Autocomplete
                                            disableClearable
                                            disabled={!_serviceFocusSectors?.key}
                                            fullWidth
                                            size="small"
                                            className={classes.autocompleteStyle}
                                            getOptionLabel={option => option.label}
                                            id="Service-detailed-sector"
                                            margin="normal"
                                            onChange={(e, value) => OnChangeServiceFocusSectorsValue(e, value, index)}
                                            options={handleDetailSectorOption(index)}
                                            value={
                                                sspSectorEnumOptions.find(
                                                    item =>
                                                        item.value ===
                                                        _serviceFocusSectors?.value,
                                                ) || {}
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={t(`${"dmTool:serviceDetailedSectorInputFieldLabel"}`)}
                                                    placeholder={t(`${"dmTool:selectServiceDetailedSectorPlcHldr"}`)}
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {index == 0 && <Grid item lg={1} sm={1} xs={12}>
                                        <Tooltip title={t(`${"dmTool:addServiceFocusSectorTooltip"}`)}>
                                            <IconButton aria-label="add"
                                                color="secondary"
                                                variant="contained"
                                                onClick={addServiceFocusSector}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>}
                                    <Grid item lg={1} sm={1} xs={12}>
                                        <IconButton
                                            key={'delete'}
                                            onClick={(e) => addUpdateOrDeleteServiceFocusSectors(index, 'delete')}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </React.Fragment>)
                        })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color='primary'
                            className={classes.addButton}
                        >
                            {(!!props.ServiceFocusSectors && props.ServiceFocusSectors.length) ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default ServiceFocusSectorsDialog;