import React from 'react';
import { Paper, withStyles, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons'
import { authenticationService } from '../../Services';
import { toast } from "react-toastify";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withTranslation } from 'react-i18next';
const styles = theme => ({
    margin: {
        margin: theme.spacing.unit * 2,
    },
    padding: {
        padding: theme.spacing.unit,
        maxWidth: 330,
        margin: "0 auto",
        marginTop: 40
    }
});

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            apiProgress: false,
            error: ""
        }
        // redirect to home if already logged in
        if (authenticationService.currentUserValue) {
            this.props.history.push('/data-entry-entity');
        }
    }
    changeEmail = (event) => {
        this.setState({
            ...this.state,
            username: event.target.value
        })
    }
    changePassword = (event) => {
        this.setState({
            ...this.state,
            password: event.target.value
        })
    }
    setSubmitting = (apiProgress) => {
        this.setState({
            ...this.state,
            apiProgress
        })
    }
    setStatus = (error) => {
        this.setState({
            ...this.state,
            error
        })
    }
    onLogin = () => {
        this.setSubmitting(true);
        authenticationService.login(this.state.username, this.state.password)
            .then(
                responseData => {
                    if (responseData.status === 'success') {
                        const { from } = this.props.location.state || { from: { pathname: "/data-entry-entity" } };
                        this.props.history.push(from);
                        toast.success(this.props.t(`${"dmTool:loginWasSuccessful"}`), {
                            toastId: responseData.status,
                            autoClose: 5000
                        })
                    } else {
                        this.setSubmitting(false);
                        this.setStatus(responseData.message);
                        toast.error(this.props.t(`${"dmTool:" + responseData.message}`), {
                            toastId: responseData.status,
                            autoClose: 5000
                        })
                    }
                },
                error => {
                    this.setSubmitting(false);
                    this.setStatus(error);
                }
            );
    }
    render() {
        const { classes, t } = this.props;
        return (
            <Paper className={classes.padding}>
                <div className={classes.margin}>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item>
                            <Face />
                        </Grid>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField id="Email" label="Email" type="text" fullWidth autoFocus required onChange={this.changeEmail} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item>
                            <Fingerprint />
                        </Grid>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField id="passowrd" label="Password" type="password" fullWidth required onChange={this.changePassword} />
                        </Grid>
                    </Grid>

                    <Grid container justify="center" style={{ marginTop: '10px' }}>
                        <Button variant="outlined" color="primary" type="submit"
                            style={{ textTransform: "none" }}
                            onClick={this.onLogin}
                        >Login</Button>
                        {this.apiProgress && <LinearProgress />}
                    </Grid>
                </div>
            </Paper>
        );
    }
}

export default withTranslation("translation", "dmTool")(withStyles(styles)(LoginPage));
