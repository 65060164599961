import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useSnackbar } from "notistack";
import { sspLanguageEnum } from "../../../utils/enums";
import { useTranslation } from "react-i18next";
import UILanguageSelector from "../../Languages";
import languages from "../../Languages/languages";

const useStyles = makeStyles((theme) => ({}));

const DocumentDescription = (props) => {
  const { t } = useTranslation([
    "translation",
    "configurationEnumerations",
    "dmTool",
  ]);
  const { documentObj, OnChangeDocumentObjDesc, OnAddOrDeleteDocumentObjDesc } =
    props;
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [languageOptions, setLanguageOptions] = useState([]);

  useEffect(() => {
    let keys = props.documentObj.descriptionOfDocument
      .filter((item) => item.key && item.key !== "")
      .map((item) => item.key);
    if (!!props.documentObj.descriptionOfDocument.length && !!keys.length) {
      let langs = [];
      let filterLang = sspLanguageEnum.map((lang) => {
        if (!keys?.includes(lang.value)) {
          langs.push(lang);
        }
      });
      setLanguageOptions(langs);
    } else {
      setLanguageOptions(sspLanguageEnum);
    }
  }, [props.documentObj.descriptionOfDocument]);

  const OnChangeDocumentDescKey = (e, value, index) => {
    OnChangeDocumentObjDesc("key", value.value, index);
  };

  const OnChangeDescValueChange = (e, index) => {
    OnChangeDocumentObjDesc("value", e.target.value, index);
  };

  const addDocumentDesc = (e) => {
    let keys = props.documentObj.descriptionOfDocument
      .filter((item) => item.key && item.key !== "")
      .map((item) => item.key);
    if (!(keys.length === Object.keys(languages).length)) {
      if (!keys.length) {
        enqueueSnackbar(t(`${"dmTool:selectLangToAddNewErrorMsg"}`), {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        OnAddOrDeleteDocumentObjDesc(
          {
            key: "",
            value: "",
          },
          "add"
        );
      }
    } else {
      enqueueSnackbar(
        t("dmTool:duplicateLangNotAllowedErrorMsg", {
          type: t(`${"dmTool:description"}`),
        }),
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

  const removeDescRow = (e, index) => {
    let filerDesc = documentObj?.descriptionOfDocument;
    filerDesc.splice(index, 1);

    if (!filerDesc.length) {
      enqueueSnackbar(
        t("dmTool:fieldShldHaveAtleastOneEntryErrorMsg", {
          type: t(`${"dmTool:description"}`),
        }),
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    } else {
      OnAddOrDeleteDocumentObjDesc(filerDesc, "delete");

      enqueueSnackbar(
        t("dmTool:removingEntrySuccessMsg", {
          type: t(`${"dmTool:description"}`),
        }),
        {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

  const renderAddCloseIcon = (index) => {
    if (index === documentObj.descriptionOfDocument.length - 1) {
      return (
        <span style={{ display: "flex" }}>
          <Tooltip title={t(`${"dmTool:addDescriptionTooltip"}`)}>
            <IconButton
              aria-label="Add more document description"
              color="secondary"
              onClick={addDocumentDesc}
            >
              <AddIcon className={classes.icons} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t(`${"dmTool:removeDescriptionTooltip"}`)}>
            <IconButton
              aria-label="settings"
              onClick={(e) => removeDescRow(e, index)}
            >
              <DeleteIcon className={classes.icons} />
            </IconButton>
          </Tooltip>
        </span>
      );
    }
    return (
      <Tooltip title={t(`${"dmTool:removeDescriptionTooltip"}`)}>
        <IconButton
          aria-label="settings"
          onClick={(e) => removeDescRow(e, index)}
        >
          <DeleteIcon className={classes.icons} />
        </IconButton>
      </Tooltip>
    );
  };

  return documentObj.descriptionOfDocument?.map((descObj, index) => {
    return (
      <React.Fragment key={"document-desc" + index}>
        <Grid item lg={4} sm={4} xs={12}>
          {/* <Autocomplete
                            disableClearable
                            size="small"
                            fullWidth
                            className={classes.autocompleteStyle}
                            getOptionLabel={option => option.label}
                            id="document_select_lang"
                            margin="normal"
                            onChange={(e, value) => OnChangeDocumentDescKey(e, value, index)}
                            options={languageOptions}
                            value={
                                sspLanguageEnum.find(
                                    item =>
                                        item.value ===
                                        descObj?.key,
                                ) || {}
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={t(`${"dmTool:languageInputFiledHdng"}`)}
                                    placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        /> */}
          <UILanguageSelector
            selectedValues={documentObj.descriptionOfDocument}
            label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
            placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
            value={descObj?.key}
            onChange={(e, value) => {
              OnChangeDocumentDescKey(e, value, index);
            }}
          />
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            className={classes.marginSet}
            fullWidth
            label={t(`${"dmTool:descriptionInputFieldLabel"}`)}
            inputProps={{
              autoComplete: "new-password",
            }}
            size="small"
            multiline
            placeholder={t(`${"dmTool:enterDescriptionPlcHldr"}`)}
            onChange={(e) => OnChangeDescValueChange(e, index)}
            required={false}
            className={classes.textfiledHeight}
            value={descObj?.value}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item lg={2} sm={2} xs={12}>
          {renderAddCloseIcon(index)}
        </Grid>
      </React.Fragment>
    );
  });
};

export default DocumentDescription;
