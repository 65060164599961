import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import { sspLanguageEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
import UILanguageSelector from '../../Languages';
import languages from "../../Languages/languages"

const useStyles = makeStyles(theme => ({
    input: {
        display: 'none'
    }
}));

const EntityVideoKeyAndValue = props => {
    const { entityVideos, OnChangeEntityVedio, OnAddOrDeleteEntityVideo } = props;
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [languageOptions, setLanguageOptions] = useState([]);
    const [_entityVideos, _setEntityVideos] = useState([]);

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    const inputRef = useRef();
    useEffect(() => {
        _setEntityVideos(entityVideos)
    }, [entityVideos])
    useEffect(() => {
        let keys = props.entityVideos.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!!props.entityVideos.length && !!keys.length) {
            let langs = [];
            let filterLang = sspLanguageEnum.map((lang) => {
                if (!keys?.includes(lang.value)) {
                    langs.push(lang);
                }
            });
            setLanguageOptions(langs)
        } else {
            setLanguageOptions(sspLanguageEnum);
        }
    }, [props.entityVideos])


    const OnChangeEntityVideoKey = (e, value, index) => {
        OnChangeEntityVedio('key', value.value, index)
    }

    const OnChangeEntityVideoUrl = (e, index) => {
        OnChangeEntityVedio('value', e.target.value, index)
    }
    const addEntityVideoKeyAndValue = (e) => {
        let keys = props.entityVideos.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!(keys.length === Object.keys(languages).length)) {
            if (!keys.length) {
                enqueueSnackbar(t(`${"dmTool:selectLangToAddNewErrorMsg"}`), {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            } else {
                OnAddOrDeleteEntityVideo({
                    key: '',
                    value: ''
                }, 'add')
            }
        } else {
            enqueueSnackbar(t("dmTool:duplicateLangNotAllowedErrorMsg", { type: t(`${"dmTool:url"}`) }), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            })
        }
    }

    const removeEntityVideoRow = (e, index) => {
        let filerVideo = entityVideos
        filerVideo.splice(index, 1);

        if (!filerVideo.length) {
            enqueueSnackbar(t("dmTool:fieldShldHaveAtleastOneEntryErrorMsg", { type: t(`${"dmTool:entityVideoField"}`) }), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        } else {
            OnAddOrDeleteEntityVideo(
                filerVideo,
                'delete',
            );

            enqueueSnackbar(t("dmTool:removingEntrySuccessMsg", { type: t(`${"dmTool:entityVideo"}`) }), {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });

        }
    };

    const renderAddCloseIcon = (index) => {

        if (index === 0) {
            return (
                <span style={{ display: 'flex' }}>
                    <Tooltip title={t(`${"dmTool:addMoreEnityVideoTooltip"}`)}>
                        <IconButton aria-label="Add more entity video"
                            onClick={addEntityVideoKeyAndValue}
                        >
                            <AddIcon className={classes.icons} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={t(`${"dmTool:removeEnityVideoTooltip"}`)}>
                        <IconButton
                            aria-label="settings"
                            onClick={(e) => removeEntityVideoRow(e, index)}
                        >
                            <CloseIcon className={classes.icons} />
                        </IconButton>
                    </Tooltip>
                </span>
            );
        }
        return (
            <React.Fragment>
                <Tooltip title={t(`${"dmTool:removeEnityVideoTooltip"}`)}>

                    <IconButton
                        aria-label="settings"
                        onClick={(e) => removeEntityVideoRow(e, index)}
                    >
                        <CloseIcon className={classes.icons} />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        );
    }

    return (
        _entityVideos?.map((video, index) => {
            return (
                <React.Fragment key={"entity_video" + index}>
                    <Grid item lg={4} sm={4} xs={12}>
                        {/* <Autocomplete
                            disableClearable
                            size="small"
                            fullWidth
                            className={classes.autocompleteStyle}
                            getOptionLabel={option => (option.label ? option.label : "")}
                            id={`entity_video_select_lang_${index}`}
                            margin="normal"
                            onChange={(e, value) => OnChangeEntityVideoKey(e, value, index)}
                            options={languageOptions}
                            value={sspLanguageEnum.find(
                                item =>
                                    item.value ===
                                    video?.key,
                            ) || null
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={t(`${"dmTool:selectLangInputFiledHdng"}`)+"*"}
                                    placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        /> */}
                        <UILanguageSelector
                            selectedValues={_entityVideos}
                            label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                            placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                            value={video?.key}
                            onChange={(e, value) => {
                                OnChangeEntityVideoKey(e, value, index)
                            }
                            }
                        />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                        <TextField
                            className={classes.marginSet}
                            fullWidth
                            label={t(`${"dmTool:enterEntityVideoUrlInputFieldLabel"}`)}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                            placeholder={t(`${"dmTool:enterEntityVideoUrlPlcHldr"}`)}
                            onChange={(e) => OnChangeEntityVideoUrl(e, index)}
                            size='small'
                            required={false}
                            value={video?.value}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item lg={2} sm={2} xs={12}>
                        {renderAddCloseIcon(index)}
                    </Grid>
                </React.Fragment>
            )
        })
    );
};

export default EntityVideoKeyAndValue;