import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import { toast } from "react-toastify";
import {
    PlaceOfResidency,
    LegalFormType,
    DateOfinCorporation,
    BusinessIdNumber,
    Comment,
    AccountName,
    Supplier,
    AccountStatus
} from './Components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Warning from '@material-ui/icons/Warning';
import Verified from '@material-ui/icons/VerifiedUser';
import { useTranslation } from 'react-i18next';

import { addEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
import { LinearProgress } from '@material-ui/core';
import { isEmpty } from '../../utils';
const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    },
    circular_progress: {
        marginRight: "auto",
        display: "flex"
    },
    uniqueness_msg: {
        margin: "0 0 0 5px",
        alignSelf: "center"
    }
}));

var previousXhrRequest = {};

const InstistutionDialog = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { open, gridref } = props;
    const classes = useStyles();
    const [apiCallProgress, setApiCallProgress] = useState(true);
    const [institution, setInstitution] = useState({});
    const [loader, setLoader] = useState(false)
    const [warning, setWarning] = useState(false)
    const [uniqueMsg, setUniqueMsg] = useState("")
    console.log("props.institution", props.institution)
    useEffect(() => {
        if (props.institution) {
            setInstitution(props.institution);
            let apiData = {
                accountName: props.institution.accountName,
                institutionJurisdiction: props.institution.institutionJurisdiction,
                i_sourceKey: null
            }
            if (previousXhrRequest.cancel) {
                previousXhrRequest.cancel();
                previousXhrRequest.cancel = undefined;
            }
            _checkUniqueNess(apiData)
        } else {
            setInstitution({
                'i_sourceKey': null,
                "r_t_id_entitySourced": "",
                "accountName": "",
                "institutionJurisdiction": "",
                "institutionAccountStatus": "CON_ACTIVATION_STATUS_CLARIFICATION",
                "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE",
                "r_t_id_stSpRecordedInstitution": null,
                "legalForm": {
                    "key": "",
                    "value": ""
                },
                "institutionIdentificationNumber": [],
                "startupProductStatus": "",
                "businessType": "",
                "institutionSocialProfileURL": [],
                "r_knowledgeInRoles": [],
                "startupCrowdfundingFromURL": [],
                "accountRole": [],
                "companyType": [],
                "institutionLegalName": "",
                "companyFocusSectors": [],
                "institutionDescription": [],
                "t_institutionAccountProfileImage_URL": "",
                "entityLogoImageInternal": null,
                "entityVideo": [],
                "entityVideoInternal": [],
                "startupCustomerStatus": "",
                "institutionWebsiteURL": "",
                "dateOfBirthOfInstitution": null,
                "startupLookingForTypes": [],
                "mainPictureUrl": '',
                "mainPictureInternal": null,
                "emailAddress": "",
                "phoneNumber": "",
                "hashtags": [],
                "Events": [],
                "KeyPerson": [],
                "Documents": [],
                'IntellectualProperties': [],
                'Rating': null,
                'CrowdAccountRelevantAddress': null,
                'AdditionalDataSourceInformation': null,
                'BlobEntityData': {
                    "i_sourceKey": "ssp_blobentitydata_" + Date.now() + "_" + parseInt(Math.random() * 100000),
                    "sourceSpecificInformation": [
                        {
                            "key": "editorUserName",
                            "value": props?.User?.userInfo?.email
                        },
                        {
                            "key": "processingUserId",
                            "value": ""
                        },
                        {
                            "key": "versionTs",
                            "value": ""
                        },
                        {
                            "key": "comment",
                            "value": ""
                        },
                        {
                            "key": "searchTrigger",
                            "value": ""
                        },
                        {
                            "key": "organizationContactStatus",
                            "value": ""
                        },
                        {
                            "key": "organizationContactStatusComment",
                            "value": ""
                        },
                        {
                            "key": "thoughts",
                            "value": ""
                        },
                        {
                            "key": "entityCreationTs",
                            "value": Date.now()
                        }
                    ]
                }
                ,
                "Supplier": {
                    "name": "SupplierProfile",
                    "serviceOffering": [],
                    "serviceDescription": [],
                    "serviceAudienceType": ["CON_SERVICE_AUDIENCE_TYPE_ANY"],
                    "serviceStatus": "CON_SERVICE_STATUS_ACTIVE",
                    "applyServiceZoneToAll": true,
                    "otherServiceOffing": [],
                    "audienceStartupPhase": [],
                    "serviceFocusSectors": [],
                    "otherServiceSector": [],
                    "serviceFeatureImage": null,
                    "ServiceZones": [{
                        "name": "ServiceZone",
                        "serviceAudienceType": "CON_SERVICE_AUDIENCE_TYPE_ANY",
                        "geographies": ["CON_SERVICE_AREA_TYPE_GLOBAL"],
                        "countries": [],
                        "blockedcountries": [],
                        "i_sourceKey": "ssp_servicezone_" + Date.now() + "_" + parseInt(Math.random() * 100000),
                        "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE",
                        "date_sourceCreationTs": null,
                        "date_sourceUpdateTs": null,
                        "i_t_date_creationTs": null,
                        "i_t_date_updateTs": null,
                        "i_t_date_lastCheckTs": null,
                        "i_t_qualitySourcePriorityLevel": null,
                        "i_t_qualitySourceDataQualityEvaluation": null,
                        "i_t_researchComment": null,
                        "i_t_startupSpiderInternalComment": null,
                        "i_t_qualitySourceExpertQualityComment": null,
                    }],
                    "i_sourceKey": null,
                    "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE",
                    "date_sourceCreationTs": null,
                    "date_sourceUpdateTs": null,
                    "i_t_date_creationTs": null,
                    "i_t_date_updateTs": null,
                    "i_t_date_lastCheckTs": null,
                    "i_t_qualitySourcePriorityLevel": null,
                    "i_t_qualitySourceDataQualityEvaluation": null,
                    "i_t_researchComment": null,
                    "i_t_startupSpiderInternalComment": null,
                    "i_t_qualitySourceExpertQualityComment": null,
                    "institutionType": []
                },
                "name": "EntitySourced"
            })
        }
    }, [props.institution])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const _onChageInstitution = (key, value) => {
        if (((key == 'accountName' && institution.accountName !== value && institution.institutionJurisdiction != "")
            || (key == 'institutionJurisdiction' && institution.institutionJurisdiction !== value))
            && institution.accountName != ""
        ) {
            let apiData = {
                accountName: key == 'accountName' ? value : institution.accountName,
                institutionJurisdiction: key == 'institutionJurisdiction' ? value : institution.institutionJurisdiction,
                i_sourceKey: null
            }
            if (previousXhrRequest.cancel) {
                previousXhrRequest.cancel();
                previousXhrRequest.cancel = undefined;
            }
            _checkUniqueNess(apiData)

        }
        setInstitution({
            ...institution,
            [`${key}`]: value
        })

    }


    function createEntityApi() {
        setApiCallProgress(true);
        let updatedObject = Object.assign({}, institution);
        if (updatedObject?.i_sourceKey == null) {
            updatedObject.i_sourceKey = "ssp_entity_" + Date.now() + "_" + parseInt(Math.random() * 100000)
        }
        if (updatedObject?.Supplier) {
            if (updatedObject?.Supplier.i_sourceKey == null) {
                updatedObject.Supplier.i_sourceKey = "ssp_supplierprofile_" + Date.now() + "_" + parseInt(Math.random() * 100000)
            }
        }
        if (updatedObject?.accountName) {
            updatedObject.institutionLegalName = updatedObject?.accountName
        }

        if (updatedObject.name == "EntitySourced") {
            delete updatedObject.Supplier;
        }
        // delete updatedObject.supplierCompany
        updatedObject = JSON.parse(JSON.stringify(updatedObject, (key, value) => {
            return value === "" ? null : value;
        }))

        let data = { Entity: { ...updatedObject } }
        props.addEntityToServer && props.addEntityToServer(data);
        closeModal();
    }



    const _onClickSaveOrUpdate = () => {
        createEntityApi();
        setApiCallProgress(true);

    };



    const _onChangeBussinessId = (key, value, index) => {
        let _institution = Object.assign({}, institution)
        _institution.institutionIdentificationNumber[index] = { ..._institution.institutionIdentificationNumber[index], [`${key}`]: value }
        setInstitution(_institution);
    }

    const _onAddOrDeleteBussinessId = (value, action) => {
        let _institution = Object.assign({}, institution)
        if (action == 'add') {
            _institution.institutionIdentificationNumber = [..._institution[`institutionIdentificationNumber`], value]
        } else {
            if (isEmpty(value)) {
                _institution.institutionIdentificationNumber = []
            } else {
                _institution.institutionIdentificationNumber = [value]
            }
        }
        setInstitution(_institution);
    }

    const _onChangeLegalForm = (key, value) => {
        let _institution = Object.assign({}, institution)
        _institution.legalForm[`${key}`] = value

        setInstitution(_institution);
    }

    const _onChangeSupplier = (value) => {
        let _institution = Object.assign({}, institution)
        _institution.name = value ? "StSpRecordedInstitution" : "EntitySourced"
        if (value) {
            return _institution.Supplier = {
                "name": "SupplierProfile",
                "serviceOffering": [],
                "serviceDescription": [],
                "serviceAudienceType": ["CON_SERVICE_AUDIENCE_TYPE_ANY"],
                "serviceStatus": "CON_SERVICE_STATUS_ACTIVE",
                "applyServiceZoneToAll": true,
                "otherServiceOffing": [],
                "audienceStartupPhase": [],
                "serviceFocusSectors": [],
                "otherServiceSector": [],
                "serviceFeatureImage": null,
                "ServiceZones": [{
                    "name": "ServiceZone",
                    "serviceAudienceType": "CON_SERVICE_AUDIENCE_TYPE_ANY",
                    "geographies": ["CON_SERVICE_AREA_TYPE_GLOBAL"],
                    "countries": [],
                    "blockedcountries": [],
                    "i_sourceKey": "ssp_servicezone_" + Date.now() + "_" + parseInt(Math.random() * 100000),
                    "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE",
                    "date_sourceCreationTs": null,
                    "date_sourceUpdateTs": null,
                    "i_t_date_creationTs": null,
                    "i_t_date_updateTs": null,
                    "i_t_date_lastCheckTs": null,
                    "i_t_qualitySourcePriorityLevel": null,
                    "i_t_qualitySourceDataQualityEvaluation": null,
                    "i_t_researchComment": null,
                    "i_t_startupSpiderInternalComment": null,
                    "i_t_qualitySourceExpertQualityComment": null,
                }],
                "i_sourceKey": null,
                "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE",
                "date_sourceCreationTs": null,
                "date_sourceUpdateTs": null,
                "i_t_date_creationTs": null,
                "i_t_date_updateTs": null,
                "i_t_date_lastCheckTs": null,
                "i_t_qualitySourcePriorityLevel": null,
                "i_t_qualitySourceDataQualityEvaluation": null,
                "i_t_researchComment": null,
                "i_t_startupSpiderInternalComment": null,
                "i_t_qualitySourceExpertQualityComment": null
            },
                setInstitution(_institution);
        } else {
            setInstitution(_institution);
        }
    }
    const _onChangeInstType = (value) => {
        let _institution = Object.assign({}, institution)
        _institution.Supplier.institutionType = value
        setInstitution(_institution);
    }

    const _checkUniqueNess = (data) => {

        const httpRequestAccountNameRequest = new XMLHttpRequest();
        setLoader(true);
        setWarning(false)
        httpRequestAccountNameRequest.open('POST', process.env.PUBLIC_URL + `/accountname/unique`);
        httpRequestAccountNameRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequestAccountNameRequest.send(JSON.stringify(data));

        return new Promise(function (resolve, reject) {
            httpRequestAccountNameRequest.onreadystatechange = () => {
                if (httpRequestAccountNameRequest.readyState === 4 && httpRequestAccountNameRequest.status === 200) {

                    let res = JSON.parse(httpRequestAccountNameRequest.responseText);
                    setLoader(false);
                    setWarning(!res.data.unique)
                    setUniqueMsg(res.data.environment)
                    resolve()
                }
            };
            previousXhrRequest.cancel = function () {  // SPECIFY CANCELLATION
                httpRequestAccountNameRequest.abort(); // abort request
                reject("cancelled previous request!!"); // reject the promise
            };
            httpRequestAccountNameRequest.onerror = reject;
        });

    }

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="institution-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="institution-dialog-title">
                        {institution?.id
                            ? `Update  ${institution?.id}`
                            : t(`${"dmTool:createInstituionDialogHdng"}`)}
                    </DialogTitle>
                    {props.store.navBarLoading && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={6} sm={6} xs={12}>
                                <AccountName
                                    institution={institution}
                                    onChageInstitution={_onChageInstitution}
                                />
                            </Grid>
                            {/* <Grid item lg={6} sm={6} xs={12}>
                                <PlaceOfResidency
                                    institution={institution}
                                    onChageInstitution={_onChageInstitution}
                                />
                            </Grid> */}
                            <Grid item lg={6} sm={6} xs={12}>
                                <DateOfinCorporation
                                    institution={institution}
                                    onChageInstitution={_onChageInstitution}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <AccountStatus
                                    institution={institution}
                                    onChageInstitution={_onChageInstitution}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <LegalFormType
                                    institution={institution}
                                    onChangeLegalForm={_onChangeLegalForm}
                                    onChageInstitution={_onChageInstitution}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>

                            </Grid>

                            <Grid item lg={12} sm={12} xs={12}>
                                <Grid container spacing={1}>
                                    <BusinessIdNumber
                                        institution={institution}
                                        onChangeBussinessId={_onChangeBussinessId}
                                        onAddOrDeleteBussinessId={_onAddOrDeleteBussinessId}
                                    />
                                </Grid>

                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Supplier
                                    institution={institution}
                                    onChangeSupplier={_onChangeSupplier}
                                    onChangeInstType={_onChangeInstType}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Comment
                                    institution={institution}
                                    onChageInstitution={_onChageInstitution}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.circular_progress}>
                            {institution.accountName && uniqueMsg && loader && <CircularProgress size={20} />}
                            {institution.accountName && uniqueMsg && !loader && (warning ? <Warning style={{ color: "red" }} />
                                : <Verified style={{ color: "green" }} />)}
                            <div className={classes.uniqueness_msg}>
                                {institution.accountName && loader && t("dmTool:checkingAccUniquenessMsg")}
                                {institution.accountName && uniqueMsg && !loader && (warning ? t("dmTool:accIsNotUniqueMsg", { environment: uniqueMsg })
                                    : t("dmTool:uniqueAccMsg", { environment: uniqueMsg })
                                )}
                            </div>
                        </div>
                        <Button
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >{t(`${"dmTool:cancelBtn"}`)}</Button>
                        <Button
                            onClick={_onClickSaveOrUpdate}
                            variant="contained"
                            disabled={props.store.navBarLoading}
                            color='primary'
                            disabled={loader || warning || !institution.accountName || !institution.institutionJurisdiction || !institution?.legalForm?.value}
                            className={classes.addButton}
                        >
                            {institution?.id ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:createButton"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};



const mapStateToProps = (state, ownProps) => {
    return {
        store: state.Entity,
        User: state.User
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addEntityToServer: (payload) => dispatch(addEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InstistutionDialog);