import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AccountNameDialog from '../AccountNameDialog';
import VersionListItem from "./Components/versionListItem"
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
// import CompareIcon from '@material-ui/icons/Compare';
// import CompareDialog from '../CompareDialog/CompareDialog';
// import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

const useStyles = makeStyles((theme) => ({
    activeListItem: {
        boxShadow: "0px 0px 5px 1px #5ce523",
        marginBottom: "5px",
        border: "2px solid #5ce523"
    },
    passiveListItem: {
        boxShadow: "0px 0px 5px 1px #ccc",
        marginBottom: "5px",
        border: "2px solid #ccc"
    },
    secondaryListItemText: {
        marginTop: "5px"
    },
    accountCreationInfo: {
        marginRight: "2px"
    },
    listContainer: {
        overflow: 'auto',
        maxHeight: 215,
    },
    // compare_button: {
    //     float: "right"
    // },
    // compare_same_button: {
    //     float: "right"
    // }

}));

const VersionHistoryRenderer = (props) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false)
    const [apiCallProgress, setApiCallProgress] = React.useState(false);
    // const [openFullScreenDialog, setOpenFullScreenDialog] = React.useState(false)

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.accountName = value

        props.updateEntityToServer && props.updateEntityToServer(updatedData);
        closeDialog();
    }
    const hadleEditClick = (e) => {
        setOpenDialog(true);
    }
    const closeDialog = () => {
        setOpenDialog(false);
    }
    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }
    // const _handleCompareDialog = () => {
    //     setOpenFullScreenDialog(!openFullScreenDialog)
    // }
    return (
        <React.Fragment>
            <Chip
                component={"span"}
                size="small"
                key={"Account type"}
                label={props.data.Entity?.name == "StSpRecordedInstitution" ? t(`${"dmTool:companyTypeSupplier"}`) : t(`${"dmTool:companyTypeEntity"}`)}
                style={toMaterialStyle(
                    props.data.Entity?.name == "StSpRecordedInstitution" ? t(`${"dmTool:companyTypeSupplier"}`).toLowerCase() : t(`${"dmTool:companyTypeEntity"}`).toLowerCase(),
                    500,
                )}
            /> {props.data.Entity.accountName}
            {< Tooltip title={t(`${"dmTool:editAccNameTooltip"}`)}>
                <IconButton
                    key={'edit'}
                    onClick={hadleEditClick}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>}
            {/* {
                <span className={classes.compare_button}>
                    <IconButton
                        key={"compare"}
                        onClick={_handleCompareDialog}
                    >
                        <CompareIcon />
                    </IconButton>
                    <IconButton
                        key={"compare"}
                    >
                        <DoneOutlineIcon style={{ color: "green" }} />
                    </IconButton>
                </span>
            } */}

            <div className={classes.root}>
                <List
                    className={classes.listContainer}
                >
                    {props.data.UploaderMetaData.map((item, index) => {
                        return <VersionListItem version={item} key={index} Entity={props.data}
                            {...props} />
                    })}
                </List>
            </div>
            {
                !!openDialog && <AccountNameDialog
                    open={openDialog}
                    onClose={closeDialog}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                    entity={props.data}
                />
            }
            {/* {openFullScreenDialog && <CompareDialog open={openFullScreenDialog} onClose={_handleCompareDialog} data={props.data} />} */}
        </React.Fragment >
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(VersionHistoryRenderer);