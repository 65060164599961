import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { isEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
import { sspInstitutionTypeEnum } from "../../utils/enums";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 700,
    width: "100%",
  },
  legend: {
    padding: 0,
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "-0.05px",
    color: "#888888",
  },
  fieldset: {
    border: "1px solid #eceded",
    padding: "10px",
    borderRadius: "4px",
  },
  addButton: {
    minWidth: 91,
  },
  circular_progress: {
    marginRight: "auto",
    display: "flex",
  },
  uniqueness_msg: {
    margin: "0 0 0 5px",
    alignSelf: "center",
  },
}));

const InstitutionTypeDialog = (props) => {
  const { t } = useTranslation([
    "translation",
    "dmTool",
    "configurationEnumerationsF",
  ]);

  let institutionTypeEnumOptions = Object.keys(sspInstitutionTypeEnum).map(
    (item) => {
      return {
        name: t(
          `${"configurationEnumerations:" + sspInstitutionTypeEnum[item]}`
        ),
        value: item,
      };
    }
  );

  const { open } = props;
  const classes = useStyles();
  const [value, setValue] = useState(
    isEmpty(props.data.Entity.Supplier.institutionType)
      ? []
      : props.data.Entity.Supplier.institutionType
  );
  function onChangeHandler(e, value) {
    // console.log("valueinstitutionType", value)
    // if (value.findIndex(item => item.value == "CON_INSTITUTION_TYPE_UNKNOWN") == -1) {
    //     setValue(value.map((item) => item.value));
    // } else {
    //     setValue(["CON_INSTITUTION_TYPE_UNKNOWN"]);
    // }
    setValue(value.map((item) => item.value));
  }

  const closeModal = () => {
    const { onClose } = props;
    onClose && onClose();
  };

  function updateApi() {
    let updatedData = Object.assign({}, props.data);
    updatedData.Entity.Supplier.institutionType = value;
    props.updateEntityToServer && props.updateEntityToServer(updatedData);
    closeModal();
  }

  return (
    <React.Fragment>
      {open && (
        <Dialog
          aria-labelledby="account-name-dialog-title"
          classes={{
            paperWidthSm: classes.dialog,
          }}
          disableBackdropClick
          onClose={closeModal}
          open={open}
        >
          <DialogTitle
            className={classes.title}
            disableTypography
            id="account-name-dialog-title"
          >
            {t("dmTool:institutionTypeDialoTitle", {
              entityName: props.data.Entity?.accountName,
            })}
          </DialogTitle>
          <DialogContent dividers>
            <Autocomplete
              id="startup_looking_for_types-demo"
              multiple
              disableCloseOnSelect
              value={institutionTypeEnumOptions.filter(
                (item) => value?.indexOf(item.value) !== -1
              )}
              options={institutionTypeEnumOptions}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) =>
                value.value === option.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(`${"dmTool:institutionTypeInputFieldLabel"}`)}
                  variant="standard"
                />
              )}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              onChange={onChangeHandler}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} variant="contained" color="primary">
              {t(`${"dmTool:cancelBtn"}`)}
            </Button>
            <Button
              onClick={updateApi}
              variant="contained"
              color={"primary"}
              className={classes.addButton}
            >
              {t(`${"dmTool:updateBtn"}`)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    // store: state.Entity
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionTypeDialog);
