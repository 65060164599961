import {
    USER_INFO
} from "../actionsTypes";

export default (
    state = {
        userInfo: {}
    },
    action
) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                userInfo: { ...action.payload }
            }
            break;
        default:
            return { ...state }
    }
}