
import {
    BULK_ADD_ENTITY,
    NAV_BAR_LOADING,
    APP_LOADING,
    UPDATE_ENTITY,
    DELETE_ENTITY,
    ADD_ENTITY
} from "../actionsTypes";
import { toast } from "react-toastify";
import i18n from "./../../i18n";
const _bulkUpdateEntity = (payload) => {
    return {
        type: BULK_ADD_ENTITY,
        payload: payload
    }
}
const _appLoader = (payload) => {
    return {
        type: APP_LOADING,
        payload: payload
    }
}
const _navBarLoading = (payload) => {
    return {
        type: NAV_BAR_LOADING,
        payload: payload
    }
}
const _updateEntity = (payload) => {
    return {
        type: UPDATE_ENTITY,
        payload
    }
}
const _deleteEntity = (payload) => {
    return {
        type: DELETE_ENTITY,
        payload
    }
}
const _addEntity = (payload) => {
    return {
        type: ADD_ENTITY,
        payload
    }
}
export const getEntityDataFromServer = (payload) => {
    return (dispatch) => {
        dispatch(_appLoader(true));
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', process.env.PUBLIC_URL + '/get/client-data');
        httpRequest.send();
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let response = JSON.parse(httpRequest.responseText)
                if (response.status == "success") {
                    dispatch(_bulkUpdateEntity(response.data))
                    dispatch(_appLoader(false));
                }
                setTimeout(() => {
                    dispatch(_navBarLoading(false));
                }, 1000);
            }
        };
    }
}
if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (str, newStr) {

        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
        }

        // If a string
        return this.replace(new RegExp(str, 'g'), newStr);

    };
}

export const updateEntityToServer = (payload) => {
    return (dispatch) => {
        dispatch(_navBarLoading(true));
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('PATCH', process.env.PUBLIC_URL + `/patch/client-data/${payload.Entity.i_sourceKey}`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequest.send(JSON.stringify(payload, (key, value) => {
            return value === "" ? null : value;
        }));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status === "success") {
                    dispatch(_updateEntity(res.data));
                } else {
                    // if (res.message == "REMOVE") {
                    //     dispatch(_deleteEntity({
                    //         i_sourceKey: payload.Entity.i_sourceKey
                    //     }))
                    //     toast.success(i18n.t("dmTool:deleteFromDMToolWasSuccessful"),
                    //         {
                    //             toastId: res.data,
                    //             autoClose: 5000
                    //         })
                    // }
                    toast.error(res.message, {
                        toastId: res.data,
                        autoClose: 5000
                    })
                }
                setTimeout(() => {
                    dispatch(_navBarLoading(false));
                }, 1000);
            }
        };

    }
}

export const addEntityToServer = (payload) => {
    return (dispatch) => {
        dispatch(_navBarLoading(true));
        const httpRequest = new XMLHttpRequest();
        console.log(payload);
        httpRequest.open('POST', process.env.PUBLIC_URL + `/post/client-data`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequest.send(JSON.stringify(payload, (key, value) => {
            return value === "" ? null : value;
        }));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status === "success") {
                    dispatch(_addEntity(res.data));
                    toast.success(i18n.t("dmTool:institutionCreateWasSuccessful"),
                        {
                            toastId: res.data,
                            autoClose: 5000
                        })
                } else {
                    toast.error(res.message, {
                        toastId: res.data,
                        autoClose: 5000
                    })
                }
                setTimeout(() => {
                    dispatch(_navBarLoading(false));
                }, 1000);
            }
        };

    }
}

export const loadFromEnvironment = ({ i_sourceKey, environment, options }) => {
    return (dispatch) => {
        dispatch(_navBarLoading(true));
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('GET', process.env.PUBLIC_URL + `/reload-from-entity/${i_sourceKey}/${environment}`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequest.send(JSON.stringify(options));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status === "success") {
                    dispatch(_updateEntity(res.data));
                    toast.success(i18n.t("dmTool:loadFromDevSuccesToastMsg"), {
                        toastId: "res.data",
                        autoClose: 5000
                    })
                } else {
                    toast.error(res.message, {
                        toastId: res.data,
                        autoClose: 5000
                    })
                }
                setTimeout(() => {
                    dispatch(_navBarLoading(false));
                }, 1000);
            }
        };
    }
}

export const syncToEnvironment = ({ fileSuffixId, environment, i_sourceKey }) => {
    return (dispatch) => {
        dispatch(_navBarLoading(true));
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', process.env.PUBLIC_URL + `/client-data/sync-to/${i_sourceKey}`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequest.send(JSON.stringify(
            { fileSuffixId, environment }
        ));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status == 'success') {
                    dispatch(_updateEntity(res.data));
                    toast.success(i18n.t("dmTool:syncWasSuccessful", { environment }), {
                        toastId: res.data,
                        autoClose: 5000
                    })
                } else {
                    if (res.message == "remoteDeleteWasSuccessfulDMToolEntityExist") {
                        dispatch(_updateEntity(res.data));
                        toast.error(i18n.t("dmTool:remoteDeleteWasSuccessfulDMToolEntityExist"), {
                            toastId: res.data,
                            autoClose: 5000
                        })
                    } else if (res.message == "remoteDeleteWasSuccessfulDMToolEntityNotExist") {
                        dispatch(_deleteEntity({ i_sourceKey }))
                        toast.error(i18n.t("dmTool:remoteDeleteWasSuccessfulDMToolEntityNotExist"), {
                            toastId: res.data,
                            autoClose: 5000
                        })
                    } else {
                        toast.error(res.message, {
                            toastId: res.data,
                            autoClose: 5000
                        })
                    }
                }
                setTimeout(() => {
                    dispatch(_navBarLoading(false));
                }, 1000);
            }
        };
    }
}

export const createNewVersion = ({ fileSuffixId, i_sourceKey }) => {
    return (dispatch) => {
        dispatch(_navBarLoading(true));
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', process.env.PUBLIC_URL + `/client-data/newversion/${i_sourceKey}`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequest.send(JSON.stringify(
            { fileSuffixId }
        ));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status == 'success') {
                    dispatch(_updateEntity(res.data));
                    toast.success(i18n.t("dmTool:DraftHasBeenAdded"),
                        {
                            toastId: res.data,
                            autoClose: 5000
                        })
                } else {
                    toast.error(res.message, {
                        toastId: res.data,
                        autoClose: 5000
                    })

                }
                setTimeout(() => {
                    dispatch(_navBarLoading(false));
                }, 1000);
            }
        };
    }
}

export const editVersion = ({ fileSuffixId, i_sourceKey }) => {
    return (dispatch) => {
        dispatch(_navBarLoading(true));
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', process.env.PUBLIC_URL + `/client-data/master/${i_sourceKey}`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequest.send(JSON.stringify({
            fileSuffixId
        }));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status == 'success') {
                    dispatch(_updateEntity(res.data));
                    toast.success(i18n.t("dmTool:selectionVersionMadeActiveToEdit"),
                        {
                            toastId: res.data,
                            autoClose: 5000
                        })
                } else {
                    toast.error(res.message, {
                        toastId: res.data,
                        autoClose: 5000
                    })

                }
                setTimeout(() => {
                    dispatch(_navBarLoading(false));
                }, 1000);
            }
        };
    }
}

export const deleteOfflineVersions = ({ fileSuffixId, i_sourceKey }) => {
    return (dispatch) => {
        dispatch(_navBarLoading(true));
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', process.env.PUBLIC_URL + `/client-data/delete/version/${i_sourceKey}`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequest.send(JSON.stringify({ fileSuffixId }));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status == 'success') {
                    dispatch(_updateEntity(res.data));
                    toast.success(i18n.t("dmTool:entityVersionDeleteWasSuccessfulFromDMTool"),
                        {
                            toastId: res.data,
                            autoClose: 5000
                        })
                } else {
                    if (res.message == "entityDeleteFromDMToolWasSuccessful") {
                        dispatch(_deleteEntity({ i_sourceKey }))
                        toast.error(i18n.t("dmTool:entityDeleteFromDMToolWasSuccessful"),
                            {
                                toastId: res.data,
                                autoClose: 5000
                            })
                    } else if (res.message == "entityVersionMarkedAsDelete") {
                        dispatch(_updateEntity(res.data));
                        toast.error(i18n.t("dmTool:entityVersionMarkedAsDelete"),
                            {
                                toastId: res.data,
                                autoClose: 5000
                            })
                    }
                }
                setTimeout(() => {
                    dispatch(_navBarLoading(false));
                }, 1000);
            }
        };
    }
}

export const undoMarkAsVersion = ({ fileSuffixId, i_sourceKey }) => {
    return (dispatch) => {
        dispatch(_navBarLoading(true));
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', process.env.PUBLIC_URL + `/client-data/undo-delete/version/${i_sourceKey}`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequest.send(JSON.stringify({ fileSuffixId }));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status == 'success') {
                    dispatch(_updateEntity(res.data));
                    toast.success(i18n.t("dmTool:operationWasSuccessful"), {
                        toastId: res.data,
                        autoClose: 5000
                    })
                } else {
                    toast.error(res.message, {
                        toastId: res.data,
                        autoClose: 5000
                    })
                }
                setTimeout(() => {
                    dispatch(_navBarLoading(false));
                }, 1000);
            }
        };
    }
}
