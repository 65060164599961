import React, { useState } from "react";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RatingDialog from "../RatingDialog";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import DetailsTwoToneIcon from "@material-ui/icons/DetailsTwoTone";
import Typography from "@material-ui/core/Typography";
import { isEmpty } from "../../utils";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
const labels = {
  1: "Useless",
  2: "Useless+",
  3: "Poor",
  4: "Poor+",
  5: "Ok",
  6: "Ok+",
  7: "Good",
  8: "Good+",
  9: "Excellent",
  10: "Excellent+",
};

const useStyles = makeStyles((theme) => ({
  root: {
    root: {
      width: 200,
      display: "flex",
      alignItems: "center",
    }, // width: 221,
    fontSize: 13,
  },
  button: {
    fontSize: 13,
    width: "100%",
    textAlign: "left",
    paddingBottom: 8,
    color: "#586069",
    fontWeight: 600,
    "&:hover,&:focus": {
      color: "#0366d6",
    },
    "& span": {
      width: "100%",
    },
    "& svg": {
      width: 16,
      height: 16,
    },
  },
  topbuttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: ".15em 4px",
    fontWeight: 600,
    lineHeight: "15px",
    borderRadius: 2,
  },
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa",
  },
  header: {
    borderBottom: "1px solid #e1e4e8",
    padding: "8px 10px",
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
  listItemContainer: {
    overflow: "auto",
    maxHeight: 215,
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
  },
}));

const RatingRenderer = (props) => {
  const { t } = useTranslation([
    "translation",
    "configurationEnumerations",
    "lifecycleEnumerations",
    "SSPDataQualityCommentMessages",
    "dmTool",
  ]);

  const [
    hoverTechnologyReadiness,
    setHoverTechnologyReadiness,
  ] = React.useState();
  const [hoverInnovationDegree, setHoverInnovationDegree] = React.useState();
  const classes = useStyles();
  const [ratingDialogOpen, setratingDialogOpen] = React.useState(false);
  const [_editrating, _setEditRating] = React.useState(false);
  const [apiCallProgress, setApiCallProgress] = React.useState(false);
  const [_rating, _setRating] = React.useState(props.value || {});

  const innovationRatingLabels = {
    1: t(`${"dmTool:IL1"}`),
    2: t(`${"dmTool:IL2"}`),
    3: t(`${"dmTool:IL3"}`),
    4: t(`${"dmTool:IL4"}`),
    5: t(`${"dmTool:IL5"}`),
  };

  const technologyRatingLabels = {
    1: t(`${"dmTool:TRL1"}`),
    2: t(`${"dmTool:TRL2"}`),
    3: t(`${"dmTool:TRL3"}`),
    4: t(`${"dmTool:TRL4"}`),
    5: t(`${"dmTool:TRL5"}`),
    6: t(`${"dmTool:TRL6"}`),
    7: t(`${"dmTool:TRL7"}`),
    8: t(`${"dmTool:TRL8"}`),
    9: t(`${"dmTool:TRL9"}`),
  };

  const handleDelete = (e, value, index) => {
    let updatedData = Object.assign({}, props.data);
    console.log(updatedData);
    updatedData.Entity.Rating.innovationDegree = 0;
    updatedData.Entity.Rating.technologyReadiness = 0;

    props.updateEntityToServer && props.updateEntityToServer(updatedData);
  };

  const openDialog = () => {
    setratingDialogOpen(true);
  };

  const closeDialog = () => {
    setratingDialogOpen(false);
    _setEditRating(false);
  };

  function updateApi(value) {
    setApiCallProgress(true);
    let updatedData = Object.assign({}, props.data);
    updatedData.Entity.Rating = value;
    props.updateEntityToServer && props.updateEntityToServer(updatedData);
    closeDialog();
  }

  const onClickAddOrUpdate = (value) => {
    updateApi(value);
    setApiCallProgress(false);
  };

  const hadleEditClick = (e) => {
    _setEditRating(true);
    openDialog();
  };
  const renderTechnologyReadiness = (rating) => {
    return (
      <React.Fragment>
        {t(`${"dmTool:techReadinessRatingHdng"}`)}
        <div>
          <Tooltip
            title={
              <React.Fragment>
                {rating?.technologyReadiness !== null && (
                  <Box ml={2}>
                    {
                      technologyRatingLabels[
                        !isEmpty(hoverTechnologyReadiness)
                          ? hoverTechnologyReadiness
                          : Number(rating?.technologyReadiness)
                      ]
                    }
                  </Box>
                )}
              </React.Fragment>
            }
          >
            <span>
              <Rating
                name="hover-feedback"
                value={Number(rating?.technologyReadiness)}
                precision={1}
                max={9}
                readOnly
                onChangeActive={(event, newHover) => {
                  setHoverTechnologyReadiness(newHover);
                }}
              />
            </span>
          </Tooltip>
          {/* <span>{rating?.technologyReadiness !== null && <Box ml={2}>{technologyRatingLabels[!isEmpty(hoverTechnologyReadiness) ? hoverTechnologyReadiness : Number(rating?.technologyReadiness)]}</Box>}</span> */}
        </div>
      </React.Fragment>
    );
  };

  const renderInnovationDegree = (rating) => {
    return (
      <React.Fragment>
        {t(`${"dmTool:innovationRatingHdng"}`)}
        <div>
          <Tooltip
            title={
              <React.Fragment>
                {rating?.innovationDegree !== null && (
                  <Box ml={2}>
                    {
                      innovationRatingLabels[
                        !isEmpty(hoverInnovationDegree)
                          ? hoverInnovationDegree
                          : Number(rating?.innovationDegree)
                      ]
                    }
                  </Box>
                )}
              </React.Fragment>
            }
          >
            <span>
              <Rating
                name="hover-feedback"
                value={Number(rating?.innovationDegree)}
                precision={1}
                max={5}
                readOnly
                onChangeActive={(event, newHover) => {
                  setHoverInnovationDegree(newHover);
                }}
              />
            </span>
          </Tooltip>
          {/* <span> {rating?.innovationDegree !== null && <Box ml={2}>{innovationRatingLabels[!isEmpty(hoverInnovationDegree) ? hoverInnovationDegree : Number(rating?.innovationDegree)]}</Box>}</span> */}
        </div>
      </React.Fragment>
    );
  };

  const DATAQUALITYFILLCOLOR = {
    CYC_DATA_RELIABILITY_LOW: "#FF3D00",
    CYC_DATA_RELIABILITY_VAGUE: "#F44336",
    CYC_DATA_RELIABILITY_INCONSISTENT: "#0091EA",
    CYC_DATA_RELIABILITY_CREDIBLE: "#546E7A",
    CYC_DATA_RELIABILITY_FIRM: "#388E3C",
  };
  const DATAQUALITYICON = {
    CYC_DATA_RELIABILITY_LOW: "key-person-triangle-down low",
    CYC_DATA_RELIABILITY_VAGUE: "key-person-triangle-down vague",
    CYC_DATA_RELIABILITY_INCONSISTENT: "key-person-triangle-down inconsistent",
    CYC_DATA_RELIABILITY_CREDIBLE: "key-person-triangle-down credible",
    CYC_DATA_RELIABILITY_FIRM: "key-person-triangle-down firm",
  };
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#000000",
      color: "rgba(255, 255, 255, 1)",
      // maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.topbuttons}>
          {((_rating.technologyReadiness == 0 &&
            _rating.innovationDegree == 0) ||
            !Object.keys(_rating)?.length) && (
            <Tooltip title={t(`${"dmTool:addRatingTooltip"}`)}>
              <IconButton
                aria-label="delete"
                color="secondary"
                variant="contained"
                onClick={openDialog}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>

        {(_rating.technologyReadiness != 0 || _rating.innovationDegree != 0) &&
        !!Object.keys(_rating)?.length ? (
          <React.Fragment key={"fragment"}>
            <List className={classes.listItemContainer}>
              <ListItem
                key={"address"}
                ContainerComponent="li"
                className={classes.listItem}
              >
                <ListItemText
                  className={classes.textOverFlowSet}
                  primary={
                    <div>
                      {!isEmpty(
                        _rating.i_t_qualitySourceDataQualityEvaluation
                      ) && (
                        <HtmlTooltip
                          arrow
                          title={
                            <React.Fragment>
                              <Typography color="inherit">
                                {t(
                                  `${
                                    "lifecycleEnumerations:" +
                                    _rating.i_t_qualitySourceDataQualityEvaluation
                                  }`
                                )}
                              </Typography>
                              {!isEmpty(
                                _rating.i_t_qualitySourceDataQualityComment
                              ) &&
                                t(
                                  `${
                                    "SSPDataQualityCommentMessages:" +
                                    _rating.i_t_qualitySourceDataQualityComment
                                  }`
                                )}
                            </React.Fragment>
                          }
                        >
                          <span
                            style={{
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            <DetailsTwoToneIcon
                              style={{
                                fill:
                                  DATAQUALITYFILLCOLOR[
                                    _rating
                                      .i_t_qualitySourceDataQualityEvaluation
                                  ],
                              }}
                            />
                          </span>
                        </HtmlTooltip>
                      )}
                      <div>
                        {_rating.technologyReadiness !== 0
                          ? renderTechnologyReadiness(_rating)
                          : ""}
                      </div>
                      <div>
                        {_rating.innovationDegree !== 0
                          ? renderInnovationDegree(_rating)
                          : ""}
                      </div>
                    </div>
                  }
                  secondary={<></>}
                />
                <ListItemSecondaryAction className={classes.actionPaddingSet}>
                  {!!Object.keys(_rating)?.length && (
                    <React.Fragment>
                      <Tooltip title={t(`${"dmTool:editRatingTooltip"}`)}>
                        <IconButton
                          key={"edit"}
                          color="secondary"
                          variant="contained"
                          onClick={hadleEditClick}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"delete"}>
                        <IconButton onClick={() => handleDelete()}>
                          <DeleteIcon></DeleteIcon>
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
      {!!ratingDialogOpen && !_editrating && (
        <RatingDialog
          open={ratingDialogOpen}
          onClose={closeDialog}
          entity={props.data}
          onClickAddOrUpdate={onClickAddOrUpdate}
          apiCallProgress={apiCallProgress}
        />
      )}

      {!!ratingDialogOpen &&
        !!_rating &&
        !!Object.keys(_rating) &&
        !!_editrating && (
          <RatingDialog
            open={ratingDialogOpen}
            onClose={closeDialog}
            edit={_editrating}
            entity={props.data}
            Rating={_rating}
            onClickAddOrUpdate={onClickAddOrUpdate}
            apiCallProgress={apiCallProgress}
          />
        )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    // store: state.Entity
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RatingRenderer);
