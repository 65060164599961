import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import toMaterialStyle from "material-color-hash";
import { sspInstitutionTypeEnum } from "../../utils/enums";
import { useTranslation } from "react-i18next";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import InstitutionTypeDialog from "../InstitutionTypeDialog";
import Tooltip from "@material-ui/core/Tooltip";
import { isEmpty } from "../../utils";

export default (props) => {
  const { t } = useTranslation(["translation", "configurationEnumerations"]);
  const [openDialog, setOpenDialog] = useState(false);
  const hadleEditClick = (e) => {
    setOpenDialog(true);
  };
  const closeDialog = () => {
    setOpenDialog(false);
  };

  console.log(props.value, "ksjhshhhhhh");
  return (
    <React.Fragment>
      {
        <Tooltip title={t(`${"dmTool:editInstitutionTypeTooltip"}`)}>
          <IconButton key={"edit"} onClick={hadleEditClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      }
      <div style={{ overflow: "auto", height: 280 }}>
        {!isEmpty(props.value) &&
          props.value?.map((item) => {
            if (typeof item == "string" && item !== "") {
              if (!isEmpty(sspInstitutionTypeEnum[item])) {
                return (
                  <Chip
                    size="small"
                    label={t(
                      `${
                        "configurationEnumerations:" +
                        sspInstitutionTypeEnum[item]
                      }`
                    )}
                    style={toMaterialStyle(sspInstitutionTypeEnum[item], 900)}
                  />
                );
              }
            } else {
              return "";
            }
          })}
      </div>
      {!!openDialog && (
        <InstitutionTypeDialog
          {...props}
          open={openDialog}
          onClose={closeDialog}
        />
      )}
    </React.Fragment>
  );
};
