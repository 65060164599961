/* eslint-disable no-use-before-define */
import React from 'react';
import { useTheme, fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from "@material-ui/core";
import IntellectualPropertyDialog from '../IntellectualPropertyDialog';
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import { useTranslation } from 'react-i18next';
import { sspIntellectualPropertyTypeEnum, sspStartupIntellectualPropertyEnum } from '../../utils/enums';
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const IN_CELL_SEPARATOR = '<br/>';
const IN_CELL_SPACE = ' ';
const IN_CELL_RECORD_SEPARATOR = '<br/>';

const useStyles = makeStyles((theme) => ({
    root: {
        // width: 221,
        fontSize: 13,
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    tag: {
        marginTop: 3,
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    milestones: {
        overflow: 'auto',
        maxHeight: 215,
    },
    milestone: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
    }

}));


const IntellectualPropertiesRenderer = (props) => {
    const classes = useStyles();
    const [intellectualProperties, setIntellectualPropertiesRenderer] = React.useState(props.value)
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const [intellectualPropertiesDialogOpen, setIntellectualPropertiesDialogOpen] = React.useState(false);
    const [_intellectualProperties, _setIntellectualProperties] = React.useState({});
    const [_editIntellectualProperties, _setEditIntellectualProperties] = React.useState(false);
    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const openDialog = () => {
        setIntellectualPropertiesDialogOpen(true);
    }

    const closeDialog = () => {
        setIntellectualPropertiesDialogOpen(false);
        _setIntellectualProperties({});
        _setEditIntellectualProperties(false);
    }

    const hadleEditClick = (e, intellectualProperty) => {
        _setEditIntellectualProperties(true);
        openDialog();
        _setIntellectualProperties(intellectualProperty);
    }

    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        if (updatedData?.Entity?.IntellectualProperties == undefined) {
            updatedData.Entity["IntellectualProperties"] = [];
        }
        let finIndex = updatedData.Entity?.IntellectualProperties.findIndex((item) => item.i_sourceKey === value.i_sourceKey)
        if (finIndex != -1) {
            updatedData.Entity.IntellectualProperties[finIndex] = value;
        } else {
            updatedData.Entity.IntellectualProperties.push(value)
        }
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
        closeDialog();

    }

    const hadleDeleteClick = (e, value, index) => {

        //value.hasOwnProperty("uuid") ? updatedData.Entity.IntellectualProperties[index].i_t_lifecycleElement_status = "CYC_STATUS_DELETED" : updatedData.Entity.IntellectualProperties.splice(index, 1)
        // updatedData.Entity.IntellectualProperties.splice(index, 1)

        let updatedData = Object.assign({}, props.data);
        let ipIndex = updatedData.Entity.IntellectualProperties.findIndex((item) => item.i_sourceKey == value.i_sourceKey)
        updatedData.Entity.IntellectualProperties[ipIndex].i_t_lifecycleElement_status = "CYC_STATUS_DELETED"

        props.updateEntityToServer && props.updateEntityToServer(updatedData);
    }

    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }


    const renderIPTitle = (intellectualProperty) => {
        let langugaleMap = new Map();
        intellectualProperty.title?.forEach(item => langugaleMap.set(item.key, { title: item.value, key: item.key }));
        intellectualProperty.description?.forEach(item => langugaleMap.set(item.key, { ...langugaleMap.get(item.key), ...{ description: item.value, key: item.key } }));

        return Array.from(langugaleMap.keys()).map((key, index) => {
            return !!key && <div style={{ margin: "0 0 5px 0" }}><Chip
                size="small"
                component={"span"}
                key={"language" + key}
                label={`${`${key.toLocaleUpperCase()} - ${t("languageEnumerations:" + key)}`} : ${langugaleMap.get(key).title}`}
                style={toMaterialStyle(
                    key?.toLowerCase(),
                    500,
                )}
            />
            </div>
        })
    }

    const renderIPDescription = (intellectualProperty) => {
        let langugaleMap = new Map();
        intellectualProperty.title?.forEach(item => langugaleMap.set(item.key, { title: item.value, key: item.key }));
        intellectualProperty.description?.forEach(item => langugaleMap.set(item.key, { ...langugaleMap.get(item.key), ...{ description: item.value, key: item.key } }));

        return Array.from(langugaleMap.keys()).map((key, index) => {
            return !!key && <div>
                <Chip
                    size="small"
                    component={"span"}
                    key={"language" + key}
                    label={`${`${key.toLocaleUpperCase()} - ${t("languageEnumerations:" + key)}`} : ${langugaleMap.get(key).description}`}
                    style={toMaterialStyle(
                        key?.toLowerCase(),
                        500,
                    )}
                />
            </div>
        })
    }


    const renderLanguageLinkChips = (intellectualProperty) => {
        let langugaleMap = new Map();
        intellectualProperty.link?.forEach(item => langugaleMap.set(item.key, { link: item.value, key: item.key }));
        //    intellectualProperty.description?.forEach(item => langugaleMap.set(item.key, {...langugaleMap.get(item.key), ...{description: item.value, key: item.key } }));

        return Array.from(langugaleMap.keys()).map((key, index) => {
            return !!key && <span
                size="small"
                key={"language" + key}

                style={{
                    maxWidth: '80%',
                    display: 'block',
                    width: 'fit-content',
                    padding: '1px 12px',
                    margin: ' 5px 0',
                    borderRadius: "5px",
                    ...toMaterialStyle(
                        key?.toLowerCase(),
                        500,
                    )
                }}
            >
                {`${key.toLocaleUpperCase()} - ${t("languageEnumerations:" + key)}`} :  <Link href={langugaleMap.get(key).link} target="_blank" rel="noreferrer">
                    {langugaleMap.get(key).link}
                </Link>
            </span>
        })
    }


    const renderTypeAndStatus = (intellectualProperty) => {
        return <>
            {intellectualProperty.status && <Chip
                component={"span"}
                size="small"
                key={"status" + intellectualProperty.status}
                label={t(`${"configurationEnumerations:" + sspStartupIntellectualPropertyEnum[intellectualProperty.status]}`)}
                style={toMaterialStyle(
                    intellectualProperty.status?.toLowerCase(),
                    500,
                )}
            />}
            {intellectualProperty?.type && <Chip
                size="small"
                component={"span"}
                key={"intellectualPropertyType" + intellectualProperty?.type}
                label={t(`${"dmTool:" + sspIntellectualPropertyTypeEnum[intellectualProperty?.type]}`)}
                style={toMaterialStyle(
                    intellectualProperty?.type?.toLowerCase(),
                    500,
                )}
            />}  </>
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.topbuttons}>
                    <Tooltip title={t(`${"dmTool:addIpToolTip"}`)}>
                        <IconButton aria-label="delete"
                            color="secondary"
                            variant="contained"
                            onClick={openDialog}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <List
                    className={classes.milestones}
                >
                    {intellectualProperties?.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED").map((intellectualProperty, index) => (
                        <React.Fragment key={"fragment" + index}>
                            <ListItem
                                key={"intellectualProperty" + index}
                                ContainerComponent="li"
                                className={classes.milestone}
                            >
                                <ListItemText
                                    className={classes.textOverFlowSet}
                                    primary={
                                        <>
                                            {renderTypeAndStatus(intellectualProperty)}
                                        </>
                                    }
                                    secondary={<>
                                        {renderLanguageLinkChips(intellectualProperty)}
                                        {renderIPTitle(intellectualProperty)}
                                        {renderIPDescription(intellectualProperty)}

                                    </>}
                                />
                                <ListItemSecondaryAction className={classes.actionPaddingSet}>
                                    <IconButton
                                        key={'edit'}
                                        onClick={(e) => hadleEditClick(e, intellectualProperty)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        key={'delete'}
                                        onClick={(e) => hadleDeleteClick(e, intellectualProperty, index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </div>
            {
                !!intellectualPropertiesDialogOpen && !!Object.keys(_intellectualProperties) && !!_editIntellectualProperties && <IntellectualPropertyDialog
                    open={intellectualPropertiesDialogOpen}
                    onClose={closeDialog}
                    IntellectualProperty={_intellectualProperties}
                    entity={props.data}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                />
            }
            {
                !!intellectualPropertiesDialogOpen && !_editIntellectualProperties && <IntellectualPropertyDialog
                    open={intellectualPropertiesDialogOpen}
                    onClose={closeDialog}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    entity={props.data}
                    apiCallProgress={apiCallProgress}
                />
            }
        </React.Fragment >
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(IntellectualPropertiesRenderer);