import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ServiceAudienceType, ServiceZones, } from './Components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%',
        minWidth: 300
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));

const ServiceZonesDialog = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { open, apiCallProgress } = props;
    const classes = useStyles();

    const [serviceZones, setServiceZones] = useState([]);
    const [serviceAudienceType, setServiceAudienceType] = useState([]);
    const [enitityObj, setEntityObj] = useState({});

    useEffect(() => {
        setEntityObj(props.entity)
        if (props.serviceZones) {
            setServiceZones(props.serviceZones)
            setServiceAudienceType(props.serviceAudienceType)
        } else {
            setServiceZones([])
            setServiceAudienceType([])
        }
    }, [props.serviceZones])
    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };

    const _onChageServiceZones = (value) => {
        setServiceZones(value)
    }
    const _onChageServiceAudienceType = (value) => {
        setServiceAudienceType(value)

    }
    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate && props.onClickAddOrUpdate(serviceZones, serviceAudienceType)
    };
    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="serviceZones-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="serviceZones-dialog-title">
                        {serviceZones?.i_sourceKey !== null
                            ? t("dmTool:updateServiceZoneDialoHdng", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addServiceZoneDialoHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <ServiceAudienceType
                                    serviceAudienceType={serviceAudienceType}
                                    onChageServiceAudienceType={_onChageServiceAudienceType}
                                    Entity={enitityObj} />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <ServiceZones
                                    serviceZones={serviceZones}
                                    onChageServiceZones={_onChageServiceZones}
                                    Entity={enitityObj}
                                    serviceAudienceType={serviceAudienceType}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color={'primary'}
                            className={classes.addButton}
                        >
                            {serviceZones?.i_sourceKey !== null ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default ServiceZonesDialog;