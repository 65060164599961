import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sspLanguageEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
import languages from "../../Languages/languages"
const useStyles = makeStyles(theme => ({

}));

const LanguageSelector = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "languageEnumerations",
        "dmTool"
    ]);
    const classes = useStyles();
    const Languages = Object.keys(languages).map(item => {
        return {
            value: item,
            label: `${item.toLocaleUpperCase()} - ${t("languageEnumerations:" + item)}`
        }
    })
    const [languageOptions, setLanguageOptions] = useState(Object.keys(languages).filter(item => (item === "en" || item === "de")).map(lang => {
        return {
            value: lang,
            label: `${lang.toLocaleUpperCase()} - ${t("languageEnumerations:" + lang)}`
        }
    }));
    const [selectedLang, setSelectedLang] = useState(props.context.preffered_language)

    useEffect(() => {
        const { selectedLanguage } = props
        selectedLanguage && selectedLanguage(selectedLang)
    }, [])

    const _changeLanguage = (e, value) => {
        setSelectedLang(value)
        props.selectedLanguage && props.selectedLanguage(value)
    }


    return (
        <Autocomplete
            disableClearable
            size="small"
            fullWidth
            className={classes.autocompleteStyle}
            getOptionLabel={option => option.label}
            getOptionSelected={(option, value) => value.value === option.value}
            id="milestone_select_lang"
            margin="normal"
            onChange={_changeLanguage}
            options={languageOptions}
            value={languageOptions.find(
                item =>
                    item.value === selectedLang.value,
            ) || {}}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t(`${"dmTool:languageInputFiledHdng"}`)}
                    placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                    variant="outlined"
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
};

export default LanguageSelector;