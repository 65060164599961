import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    },
    circular_progress: {
        marginRight: "auto",
        display: "flex"
    },
    uniqueness_msg: {
        margin: "0 0 0 5px",
        alignSelf: "center"
    }
}));

const SourceInformationDialog = props => {
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    const { open } = props;
    const classes = useStyles();
    const [value, setValue] = useState(props.data.Entity.sourceIdentificationInformation);

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };

    function updateApi() {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity['sourceIdentificationInformation'] = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
        closeModal();
    }

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="account-name-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="account-name-dialog-title">
                        {t("dmTool:sourceOfResourceInfoDialogTitle", { entityName: props.data.Entity?.accountName })}
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            className={classes.marginSet}
                            fullWidth
                            multiline
                            rows={10}
                            label={t(`${"dmTool:sourceOfResourceInfoInputFieldLabel"}`)}
                            placeholder={t(`${"dmTool:enterSourceOfResourceInfoPlcHldr"}`)}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                            onChange={(e) => setValue(
                                e.target.value
                            )}
                            size='small'
                            required={false}
                            value={value}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={updateApi}
                            variant="contained"
                            color={'primary'}
                            className={classes.addButton}
                        >
                            {t(`${"dmTool:updateBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SourceInformationDialog);