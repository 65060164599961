import React, { useState, useEffect } from 'react';
import { countries } from '../Countrieslist';


export default (props) => {
    return (
        <div>
            <span>
                <img className="country-flag" src={`./flags/${props?.value?.toLowerCase()}.png`} ></img>
            </span>
            {countries.find((item) => item.code == props.value)?.label}
        </div >
    )
}
