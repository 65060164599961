/* eslint-disable no-use-before-define */
import React from 'react';
import { useTheme, fade, makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';


import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction
} from "@material-ui/core";
import LegalFormDialog from '../LegalFormDialog';
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';

import { countries } from '../Countrieslist';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
    root: {
        // width: 221,
        fontSize: 13,
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    tag: {
        marginTop: 3,
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    legalForms: {
        overflow: 'auto',
        maxHeight: 215,
    },
    legalForm: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    },
    fontSizeSet: {
        '& .MuiTypography-body1': {
            fontSize: '14px !important'
        }
    }

}));



const LegalFormRenderer = (props) => {
    const classes = useStyles();

    const [pendingValue, setPendingValue] = React.useState([]);
    const theme = useTheme();

    const [legalFormDialogOpen, setLegalFormDialogOpen] = React.useState(false);
    const [_legalForm, _setLegalForm] = React.useState(props.value);
    const [_editlegalForm, _setEditLegalForm] = React.useState(false);
    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
    ]);

    const openDialog = () => {
        setLegalFormDialogOpen(true);
    }

    const closeDialog = () => {
        setLegalFormDialogOpen(false);
        _setEditLegalForm(false);
    }


    const hadleEditClick = (e, legalForm) => {
        _setEditLegalForm(true);
        openDialog();
    }

    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.legalForm = value;
        updatedData.Entity.institutionJurisdiction = value.key

        props.updateEntityToServer && props.updateEntityToServer(updatedData);
        closeDialog();
    }

    const renderLegalFormTypeChips = (_legalFormvalue) => {
        if (_legalFormvalue) {
            return (
                !!_legalFormvalue && <Chip
                    component={"span"}
                    size="small"
                    key={t(`${"configurationEnumerations:" + _legalFormvalue}`)}
                    label={t(`${"configurationEnumerations:" + _legalFormvalue}`)}
                    style={toMaterialStyle(
                        t(`${"configurationEnumerations:" + _legalFormvalue}`)?.toLowerCase(),
                        500,
                    )}
                />
            )

        }

    }

    const renderLogo = (country) => {
        if (country) {
            return <img className="country-flag" src={`./flags/${country?.toLowerCase()}.png`} ></img>
        }
        return "";
    }
    const renderCountryLabel = (country) => {
        return countries.find((item) => item.code == country)?.label;
    }

    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }
    return (
        <React.Fragment>
            <div className={classes.root}>
                {_legalForm && <List
                    className={classes.legalForms}
                >
                    <ListItem
                        key={"legalForm"}
                        ContainerComponent="li"
                        className={classes.legalForm}
                    >
                        <ListItemText
                            className={classes.fontSizeSet}
                            primary={
                                <div >
                                    {renderLogo(_legalForm.key)}
                                    {renderCountryLabel(_legalForm.key)}
                                </div>
                            }
                            secondary={<>
                                {_legalForm?.value && renderLegalFormTypeChips(_legalForm.value)}
                            </>}
                        />
                        <ListItemSecondaryAction className={classes.actionPaddingSet}>
                            <IconButton
                                onClick={(e) => hadleEditClick(e, _legalForm)}
                            >
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>}
            </div>
            {
                !!legalFormDialogOpen && !!Object.keys(_legalForm) && !!_editlegalForm && <LegalFormDialog
                    open={legalFormDialogOpen}
                    onClose={closeDialog}
                    legalForm={_legalForm}
                    entity={props.data}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                />
            }
            {
                !!legalFormDialogOpen && !_editlegalForm && <LegalFormDialog
                    open={legalFormDialogOpen}
                    onClose={closeDialog}
                    entity={props.data}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                />
            }
        </React.Fragment >
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LegalFormRenderer);