import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

import TextField from '@material-ui/core/TextField';
import { countries } from '../Countrieslist';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ADDRESS_TYPE_ENUM } from '../../utils/enums';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));

const AddressDialog = props => {

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);

    const { open } = props;
    const classes = useStyles();
    const [apiCallProgress, setApiCallProgress] = useState(false);
    const [addressObj, setAddressObj] = useState({});
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (props.Address) {
            setAddressObj(props.Address)
        } else {
            setAddressObj({
                "name": "CrowdAccount_RelevantAddress",
                "i_sourceKey": null, // Record Key used by the source, i.e. uploader tool
                "name": "",
                "r_t_id_address": "",
                "source_date_creationTs": Date.now(),
                "source_date_updateTs": Date.now(),
                "t_date_validToTs": 0,
                "t_lifecycleElement_type": "CYC_TYPE_UNKNOWN",
                "i_t_researchComment": null,
                "i_t_startupSpiderInternalComment": null,
                "r_addressLine0_receiver": null,
                "r_addressLine1_houseNumber": " ",
                // "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE",
                "r_addressLine1_streetName": "",
                "r_addressLine2_addressSuffix": null,
                "r_addressName": "",
                "r_addressStatus": "CYC_STATUS_ACTIVE",
                "r_addressType": "",
                "r_address_jurisdiction": "",
                "r_addresseeName": null,
                "r_city": "",
                "r_contactPhoneNumberIfIssue": null,
                "r_country": null,
                "r_nameOfProvince": null,
                "r_zipOrPostcode": "",
            })
        }
    }, [props.Address])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };
    const onClickSaveOrUpdate = (e) => {
        let updatedObject = Object.assign({}, addressObj);
        if (updatedObject?.i_sourceKey == null) {
            updatedObject.i_sourceKey = "ssp_crowd_account_relevant_address_" + Date.now() + "_" + parseInt(Math.random() * 100000)
        }
        updatedObject.name = "CrowdAccount_RelevantAddress"
        updatedObject.name = "CrowdAccount_RelevantAddress"
        props.onClickAddOrUpdate(updatedObject)
    };

    const OnChageAddressObj = (key, value) => {
        setAddressObj({
            ...addressObj,
            [`${key}`]: value
        })
    }


    const onChangeAddressCardName = (e) => {
        OnChageAddressObj('r_addressName', e.target.value)
    }
    const onChangeReaceiverName = (e) => {
        OnChageAddressObj('r_addressLine0_receiver', e.target.value)
    }
    const onChangeStreet = (e) => {
        OnChageAddressObj('r_addressLine1_streetName', e.target.value)
    }
    const onChangeNo = (e) => {
        OnChageAddressObj('r_addressLine1_houseNumber', e.target.value)
    }
    const onChangeAddressSuffix = (e) => {
        OnChageAddressObj('r_addressLine2_addressSuffix', e.target.value)
    }
    const onChangeCity = (e) => {
        OnChageAddressObj('r_city', e.target.value)
    }
    const onChangePostCode = (e) => {
        OnChageAddressObj('r_zipOrPostcode', e.target.value)
    }
    const onChangeState = (e) => {
        OnChageAddressObj('r_nameOfProvince', e.target.value)
    }
    const onCountryChange = (e, value) => {
        if (value) {
            OnChageAddressObj('r_address_jurisdiction', value.code)
        } else {
            OnChageAddressObj('r_address_jurisdiction', null)
        }
    }
    const _addressType = (e, value) => {
        OnChageAddressObj('r_addressType', value.value)
    }
    const addressTypeOptions = Object.keys(ADDRESS_TYPE_ENUM).map(opt => {
        return {
            value: opt,
            label: t(ADDRESS_TYPE_ENUM[opt])
        };
    });

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="key-person-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="address-dialog-title">
                        {addressObj?.i_sourceKey !== null ?
                            t("dmTool:updateAddresDialogHdng", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addAddresDialogHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={6} sm={6} xs={12}>
                                {`${t(`${"dmTool:addressCardNameFieldHdng"}`)}*`}
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <TextField
                                    className={classes.marginSet}
                                    fullWidth
                                    label={`${t(`${"dmTool:addressCardNameInputFieldLabel"}`)}*`}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    placeholder={t(`${"dmTool:enterAddressCardNamePlcHldr"}`)}
                                    onChange={onChangeAddressCardName}
                                    size='small'
                                    required={false}
                                    value={addressObj.r_addressName}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                {`${t(`${"dmTool:addresTypeFieldHdng"}`)}*`}
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                <Autocomplete
                                    disableClearable
                                    size="small"
                                    fullWidth
                                    className={classes.autocompleteStyle}
                                    getOptionLabel={option => option.label}
                                    id="addresType"
                                    margin="normal"
                                    onChange={_addressType}
                                    options={addressTypeOptions}
                                    value={addressTypeOptions.find(item => item.value == addressObj.r_addressType) || {}}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={`${t(`${"dmTool:addresTypeInputFieldLabel"}`)}*`}
                                            placeholder={t(`${"dmTool:selectAddresTypePlcHldr"}`)}
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                {`${t(`${"dmTool:countryFieldHdng"}`)}*`}
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                <Autocomplete
                                    id="country-select-demo"
                                    size='small'
                                    style={{ width: '100%' }}
                                    options={countries}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    value={countries.find((item) => item.code === addressObj.r_address_jurisdiction) || {}}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    onChange={onCountryChange}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span><img className="country-flag" src={`./flags/${option.code?.toLowerCase()}.png`} /></span>
                                            {option.label} ({option.code}) +{option.phone}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t(`${"dmTool:countryInputFieldLabel"}`)}*`}
                                            placeholder={t(`${"dmTool:countryofCompanyPlcHldr"}`)}
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                autoComplete: 'new-password',
                                                startAdornment: addressObj.r_address_jurisdiction && addressObj.r_address_jurisdiction !== '' && (
                                                    <React.Fragment>
                                                        <span><img className="country-flag" src={`./flags/${addressObj.r_address_jurisdiction?.toLowerCase()}.png`} /></span> {' '}
                                                        {params.InputProps.startAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                {t(`${"dmTool:receiverNameFieldHdng"}`)}
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                <TextField
                                    className={classes.marginSet}
                                    fullWidth
                                    label={t(`${"dmTool:receiverNameInputFieldLabel"}`)}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    placeholder={t(`${"dmTool:enterReceiverNamePlcHldr"}`)}
                                    onChange={onChangeReaceiverName}
                                    size='small'
                                    required={false}
                                    value={addressObj.r_addressLine0_receiver}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                {`${t(`${"dmTool:streetFieldHdng"}`)}*`}
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                <TextField
                                    className={classes.marginSet}
                                    fullWidth
                                    label={`${t(`${"dmTool:streetInputFieldLabel"}`)}*`}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    placeholder={t(`${"dmTool:enterStreetPlcHldr"}`)}
                                    onChange={onChangeStreet}
                                    size='small'
                                    required={false}
                                    value={addressObj.r_addressLine1_streetName}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                {t(`${"dmTool:houseNumberFieldHdng"}`)}
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                <TextField
                                    className={classes.marginSet}
                                    fullWidth
                                    label={t(`${"dmTool:houseNumberInputFieldLabel"}`)}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    placeholder={t(`${"dmTool:houseNumberPlcHldr"}`)}
                                    onChange={onChangeNo}
                                    size='small'
                                    required={false}
                                    value={addressObj.r_addressLine1_houseNumber}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                {t(`${"dmTool:addressSuffixFieldHdng"}`)}
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                <TextField
                                    className={classes.marginSet}
                                    fullWidth
                                    label={t(`${"dmTool:addressSuffixInputFieldLabel"}`)}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    placeholder={t(`${"dmTool:enterAddressSuffixPlcHldr"}`)}
                                    onChange={onChangeAddressSuffix}
                                    size='small'
                                    required={false}
                                    value={addressObj.r_addressLine2_addressSuffix}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                {`${t(`${"dmTool:cityFieldHdng"}`)}*`}
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                <TextField
                                    className={classes.marginSet}
                                    fullWidth
                                    label={`${t(`${"dmTool:cityInputFieldLabel"}`)}*`}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    placeholder={t(`${"dmTool:enterCitynamePlcHldr"}`)}
                                    onChange={onChangeCity}
                                    size='small'
                                    required={false}
                                    value={addressObj.r_city}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                {`${t(`${"dmTool:postCodeOrZipCodeFieldHdng"}`)}*`}
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                <TextField
                                    className={classes.marginSet}
                                    fullWidth
                                    label={`${t(`${"dmTool:postCodeOrZipCodeInputFieldLabel"}`)}*`}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    placeholder={t(`${"dmTool:enterPostCodeOrZipCodePlcHldr"}`)}
                                    onChange={onChangePostCode}
                                    size='small'
                                    required={false}
                                    value={addressObj.r_zipOrPostcode}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                {t(`${"dmTool:stateOrFederalOrCantonFieldHdng"}`)}
                            </Grid><Grid item lg={6} sm={6} xs={12}>
                                <TextField
                                    className={classes.marginSet}
                                    fullWidth
                                    label={t(`${"dmTool:stateOrFederalOrCantonInputFieldLabel"}`)}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    placeholder={t(`${"dmTool:enterStateOrFederalOrCantonPlcHldr"}`)}
                                    onChange={onChangeState}
                                    size='small'
                                    required={false}
                                    value={addressObj.r_nameOfProvince}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={!Boolean(addressObj.r_addressName) || !Boolean(addressObj.r_addressType) || !Boolean(addressObj.r_address_jurisdiction)
                                || !Boolean(addressObj.r_addressLine1_streetName) || !Boolean(addressObj.r_city) || !Boolean(addressObj.r_zipOrPostcode)}
                            color={'primary'}
                            className={classes.addButton}
                        >
                            {addressObj?.i_sourceKey !== null ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default AddressDialog;