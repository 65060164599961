import { isObservable } from "rxjs";
import {
    UPDATE_ENTITY,
    BULK_ADD_ENTITY,
    ADD_ENTITY,
    DELETE_ENTITY,
    APP_LOADING,
    NAV_BAR_LOADING
} from "../actionsTypes";

export default (
    state = {
        Entities: [],
        appLoading: true,
        navBarLoading: true
    },
    action
) => {
    switch (action.type) {
        case BULK_ADD_ENTITY:
            return {
                ...state,
                Entities: [...action.payload]
            }
            break;
        case ADD_ENTITY:
            return {
                ...state,
                Entities: [
                    action.payload,
                    ...state.Entities
                ]
            }
            break;
        case UPDATE_ENTITY:
            let entityIndex = state.Entities.findIndex(item => item.Entity.i_sourceKey == action.payload.Entity.i_sourceKey);
            const Entities = [...state.Entities]; //making a new array
            if (entityIndex !== -1) {
                Entities[entityIndex] = {
                    ...Entities[entityIndex] ,
                    ...action.payload
                }//changing value in the new array
            } else {
                Entities = [action.payload, ...Entities];
            }
            return {
                ...state,
                Entities,
            }
            break;
        case DELETE_ENTITY:
            return {
                ...state,
                Entities: state.Entities.filter(item => item.Entity.i_sourceKey !== action.payload.i_sourceKey)
            }
            break;
        case APP_LOADING:
            return {
                ...state,
                appLoading: action.payload
            };
            break;
        case NAV_BAR_LOADING:
            return {
                ...state,
                navBarLoading: action.payload
            }
            break;
        default:
            return { ...state }
    }
}