import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { sspInstitutionTypeEnum } from '../../../utils/enums';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({}));

const Supplier = props => {
    const { institution, onChangeSupplier, onChangeInstType } = props;
    const classes = useStyles();
    const [supplierChecked, setSupplierChecked] = useState(institution.name == "StSpRecordedInstitution")
    const [value, setValue] = useState(institution.Supplier?.institutionType || []);

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);

    let institutionTypeEnumOptions = Object.keys(sspInstitutionTypeEnum)
        .map((item) => {
            return {
                name: t(`${"configurationEnumerations:" + sspInstitutionTypeEnum[item]}`),
                value: item
            }
        })

    function onChangeHandler(e, value) {
        setValue(value.map((item) => item.value));
        onChangeInstType(value.map((item) => item.value))
    }

    const _onChangeSupplierInfo = (value) => {
        setSupplierChecked(!supplierChecked)
        onChangeSupplier(!supplierChecked)
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item lg={6} sm={6} xs={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox
                                checked={supplierChecked}
                                onChange={_onChangeSupplierInfo}
                                name="checkedA"
                                color="primary" />}
                            label={t(`${"dmTool:entiyIsSupplierFieldTitle"}`)}
                        />
                    </FormGroup>
                </Grid>
                {supplierChecked && <Grid item lg={6} sm={6} xs={12}>
                    <Autocomplete
                        id="institution_type-demo"
                        multiple
                        disableCloseOnSelect
                        value={institutionTypeEnumOptions.filter(item => value?.indexOf(item.value) !== -1)}
                        options={institutionTypeEnumOptions}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) => value.value === option.value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t(`${"dmTool:institutionTypeInputFieldLabel"}`)}
                                variant="standard"
                            />
                        )}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={onChangeHandler}
                    />
                </Grid>}
            </Grid>
        </React.Fragment >
    );
};

export default Supplier;