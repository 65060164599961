import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    },
    orText: {
        fontSize: 30,
        textAlign: 'center'
    }
}));

const AccountProfileImageDialog = props => {
    const { open, apiCallProgress } = props;
    const classes = useStyles();

    const [accountProfilePictureUrl, setAccountProfilePictureUrl] = useState({});
    const [enitityObj, setEntityObj] = useState({});

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    useEffect(() => {
        setEntityObj(props.entity)
        if (props.accountProfilePictureUrl) {
            setAccountProfilePictureUrl(props.accountProfilePictureUrl)
        } else {
            setAccountProfilePictureUrl('')
        }
    }, [props.accountProfilePictureUrl])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };

    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(accountProfilePictureUrl)
    };



    const onChangeMainPictreUrl = (e) => {
        setAccountProfilePictureUrl(e.target.value)
    }

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="account_profile_picture_url-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="account_profile_picture_url-dialog-title">
                        {t("dmTool:updateAccountProfilePicUrlDialogHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <TextField
                                    className={classes.marginSet}
                                    fullWidth
                                    label={t(`${"dmTool:EnterUrlInputFieldLabel"}`)}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    placeholder={t(`${"dmTool:EnterAccProfileImageUrlPlcHldr"}`)}
                                    onChange={onChangeMainPictreUrl}
                                    size='small'
                                    required={false}
                                    value={accountProfilePictureUrl}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color={(Boolean(accountProfilePictureUrl)) ? 'primary' : 'disabled'}
                            className={classes.addButton}
                        >
                            {t(`${"dmTool:updateBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default AccountProfileImageDialog;