import { TextField } from "@material-ui/core";
import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";

const SimpleEditor = forwardRef((props, ref) => {
    console.log("proopss", props)
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    const inputRef = useRef();
    const [value, setValue] = useState('');

    function inputHandler(e) {
        setValue(e.target.value);
    }

    function updateApi() {
        let spiltData = props.column.colId.split('.');
        let updatedData = Object.assign({}, props.data);
        updatedData[spiltData[0]][spiltData[1]] = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData); 

    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {

                updateApi();
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
                inputRef.current.focus();
                // inputRef.current.select();
            }
        };
    });

    return (
        <TextField
            id="standard-multiline-flexible"
            label=""
            multiline
            rowsMax={4}

            ref={inputRef}
            onChange={inputHandler}
            value={value}
            placeholder={t(`${"dmTool:" + props.column?.userProvidedColDef?.placeHolder}`)}
        />
    )
})


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SimpleEditor);
