import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { CREATION_CHANNEL_TYPE_ENUM } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({

}));

const CreationChannelType = props => {
    const { t } = useTranslation([
                "translation",
                "configurationEnumerations",
                "dmTool"
            ]);
    const { institutionObj, OnChageCratedInfo } = props;
    const classes = useStyles();

    const OnChangeCreationChannelType = (e, value) => {
        OnChageCratedInfo('r_t_creationChannelType', value.value)
    }


    const creationChannelTypeOptions = Object.keys(CREATION_CHANNEL_TYPE_ENUM).map(opt => {
        return {
            value: opt,
            label: t(CREATION_CHANNEL_TYPE_ENUM[opt])
        };
    });


    return (
        <React.Fragment>
            <Autocomplete
                disableClearable
                fullWidth
                size="small"
                className={classes.autocompleteStyle}
                getOptionLabel={option => option.label}
                id="creation_channel_type"
                margin="normal"
                onChange={OnChangeCreationChannelType}
                options={creationChannelTypeOptions}
                value={
                    creationChannelTypeOptions.find(
                        item =>
                            item.value ===
                            institutionObj?.r_t_creationChannelType,
                    ) || {}
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        label={t(`${"dmTool:regChannelInputFieldLabel"}`)}
                        placeholder={t(`${"dmTool:regviaWebsitePlcHldr"}`)}
                        variant="outlined"
                        style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </React.Fragment>
    );
};

export default CreationChannelType;