import { BehaviorSubject } from 'rxjs';

// import config from 'config';
import { axios, handleResponse } from '../Helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('Authorization')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    validateLogin
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/authenticate`,
        { username, password },
        { 'Content-Type': 'application/json' }
    )
        // .then(handleResponse)
        .then(response => {
            if (response.data.status == 'success') {
                if (response.data.data.Authorization) {
                    localStorage.setItem('Authorization', JSON.stringify(response.data.data.Authorization));
                    currentUserSubject.next(response.data.data.Authorization);
                }
            }
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response.data;
        });
}

function logout() {
    // remove user from local storage to log user out
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/logout`)
    localStorage.removeItem('Authorization');
    currentUserSubject.next(null);
}

function validateLogin(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/validatelogin`,
        { username, password },
        { 'Content-Type': 'application/json' }
    )
        // .then(handleResponse)
        .then(response => {
            console.log(response.data.Authorization);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (response.data.Authorization) {
                localStorage.setItem('Authorization', JSON.stringify(response.data.Authorization));
                currentUserSubject.next(response.data.Authorization);
            }
            return response.data;
        });
}

