import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { sspLanguageEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../utils';
import UILanguageSelector from '../Languages';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const OtherServiceSectorDialog = props => {

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { open, apiCallProgress } = props;
    const classes = useStyles();

    const [otherServiceSector, setotherServiceSector] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (!!props.OtherServiceSector.length) {
            setotherServiceSector(props.OtherServiceSector)
        } else {
            setotherServiceSector([{
                key: '',
                value: ''
            }]
            )
        }
    }, [props.OtherServiceSector])


    useEffect(() => {
        let keys = otherServiceSector?.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!!otherServiceSector?.length && !!keys.length) {
            let langs = [];
            let filterLang = sspLanguageEnum.map((lang) => {
                if (!keys?.includes(lang.value)) {
                    langs.push(lang);
                }
            });
            setLanguageOptions(langs)
        } else {
            setLanguageOptions(sspLanguageEnum);
        }
    }, [otherServiceSector])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(otherServiceSector)
    };

    const OnChangeOtherServiceSectorValue = (e, index) => {
        let _otherServiceSector = otherServiceSector
        _otherServiceSector[index].value = e.target.value;
        addUpdateOrDeleteOtherServiceSector(_otherServiceSector, 'update')
    }

    const OnChangeOtherServiceSectorKey = (e, value, index) => {
        let _otherServiceSector = otherServiceSector
        _otherServiceSector[index].key = value.value
        addUpdateOrDeleteOtherServiceSector(_otherServiceSector, 'update')
    }

    const addCopanyFocusSector = (e) => {
        addUpdateOrDeleteOtherServiceSector({
            key: '',
            value: '',
        }, 'add')
    }

    const addUpdateOrDeleteOtherServiceSector = (object, type) => {
        if (type == 'add') {
            setotherServiceSector((_otherServiceSector) => {
                return ([..._otherServiceSector, object])

            })
        }
        if (type == 'delete') {
            setotherServiceSector((_otherServiceSector) => {
                return [..._otherServiceSector.filter((item, index) => index != object)]
            })
        }
        if (type == 'update') {
            setotherServiceSector((_otherServiceSector) => {
                return [...object]
            })
        }
    }
    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="other-Service-Sector-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="other-Service-Sector-dialog-title">
                        {!!props.OtherServiceSector.length
                            ? t("dmTool:updateOtherServiceSectorDialogHdng", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addOtherServiceSectorDialogHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        {!!otherServiceSector.length && otherServiceSector.map((_otherServiceSector, index) => {
                            return (<React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item lg={4} sm={4} xs={12}>
                                        {/* <Autocomplete
                                            disableClearable
                                            fullWidth
                                            size="small"
                                            className={classes.autocompleteStyle}
                                            getOptionLabel={option => option.label}
                                            id="other-service-sector-key"
                                            margin="normal"
                                            onChange={(e, value) => OnChangeOtherServiceSectorKey(e, value, index)}
                                            options={languageOptions}
                                            value={
                                                sspLanguageEnum.find(
                                                    item =>
                                                        item.value ===
                                                        _otherServiceSector?.key,
                                                ) || {}
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={t(`${"dmTool:languageInputFiledHdng"}`)+"*"}
                                                    placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        /> */}
                                        <UILanguageSelector
                                            selectedValues={otherServiceSector}
                                            label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                                            placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                            value={_otherServiceSector?.key}
                                            onChange={(e, value) => {
                                                OnChangeOtherServiceSectorKey(e, value, index)
                                            }
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={6} xs={12}>
                                        <TextField
                                            className={classes.marginSet}
                                            fullWidth
                                            label={t(`${"dmTool:otherServiceSectorInputFieldLabel"}`)}
                                            placeholder={t(`${"dmTool:enterOtherServiceSectorPlcHldr"}`)}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            onChange={(e) => OnChangeOtherServiceSectorValue(e, index)}
                                            size='small'
                                            required={false}
                                            value={_otherServiceSector?.value}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />

                                    </Grid>
                                    {index == 0 && <Grid item lg={1} sm={1} xs={12}>
                                        <Tooltip title={t(`${"dmTool:addOtherServiceSectorTooltip"}`)}>
                                            <IconButton aria-label="add"
                                                color="secondary"
                                                variant="contained"
                                                onClick={addCopanyFocusSector}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>}
                                    <Grid item lg={1} sm={1} xs={12}>
                                        <IconButton
                                            key={'delete'}
                                            onClick={(e) => addUpdateOrDeleteOtherServiceSector(index, 'delete')}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </React.Fragment>)
                        })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress || otherServiceSector?.filter((vid) => !isEmpty(vid.value) && isEmpty(vid.key)).length}
                            color='primary'
                            className={classes.addButton}
                        >
                            {!!props.OtherServiceSector.length ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default OtherServiceSectorDialog;