/* eslint-disable no-use-before-define */
import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from "@material-ui/core";
import ServiceZonesDialog from '../ServiceZonesDialog';
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import { useTranslation } from 'react-i18next';
import { countries } from '../Countrieslist';

import { isEmpty } from "../../utils";
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 13,
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    tag: {
        marginTop: 3,
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    milestones: {
        overflow: 'auto',
        maxHeight: 215,
    },
    serviceZones: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    },
    countries_style: {
        margin: "5px 0 0 0"
    }

}));


const ServiceZonesRenderer = (props) => {
    const classes = useStyles();
    const [serviceZones, setServiceZones] = React.useState(props?.value?.ServiceZones)
    const [serviceZoneDialogOpen, setServiceZoneDialogOpen] = React.useState(false);
    const [_serviceZone, _setServiceZone] = React.useState({});
    const [_editServiceZone, _setEditServiceZone] = React.useState(false);
    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);

    const openDialog = () => {
        setServiceZoneDialogOpen(true);
    }

    const closeDialog = () => {
        setServiceZoneDialogOpen(false);
        _setServiceZone({});
        _setEditServiceZone(false);
    }

    function updateApi(serviceZones, serviceAudienceType) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.Supplier.serviceAudienceType = [...serviceAudienceType]
        updatedData.Entity.Supplier.ServiceZones = [...serviceZones]
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
        closeDialog();

    }

    const renderElements = (serviceZones) => {
        return <React.Fragment>
            <div>{!!serviceZones.geographies && serviceZones.geographies?.map((item, index) => {
                if (typeof (item) == 'string' && item !== '') {
                    return (
                        <React.Fragment key={index}>
                            <Chip
                                size="small"
                                label={t(`${"configurationEnumerations:" + item}`)}
                                style={toMaterialStyle(
                                    item?.toLowerCase(),
                                    900,
                                )}
                            />
                        </React.Fragment>
                    )
                }
            })}</div>
            <div className={classes.countries_style}>
                {!isEmpty(serviceZones.countries) && t(`${"dmTool:allowedCountries"}`) + ":"} {!!serviceZones.countries && serviceZones.countries?.map((item, index) => {
                    if (typeof (item) == 'string' && item !== '') {
                        return (
                            <Tooltip key={index} title={countries.filter(type => type.code == item).map(option => option.label)} placement="top">
                                <img key={index} className="country-flag" src={`./flags/${item?.toLowerCase()}.png`} />
                            </Tooltip>
                        )
                    }
                })}
            </div>
            <div className={classes.countries_style}>
                {!isEmpty(serviceZones.blockedcountries) && t(`${"dmTool:blockedCountries"}`) + ":"}{!!serviceZones.blockedcountries && serviceZones.blockedcountries?.map(item => {
                    if (typeof (item) == 'string' && item !== '') {
                        return (
                            <Tooltip title={countries.filter(type => type.code == item).map(option => option.label)} placement="top">
                                <img className="country-flag" src={`./flags/${item?.toLowerCase()}.png`} />
                            </Tooltip>
                        )
                    }
                })}
            </div>
        </React.Fragment>
    }


    const onClickAddOrUpdate = (serviceZones, serviceAudienceType) => {
        updateApi(serviceZones, serviceAudienceType);
        setApiCallProgress(false);
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.topbuttons}>
                    <Tooltip title={t(`${"dmTool:editTargetCustomerAndServiceZonesTooltip"}`)}>
                        <IconButton aria-label="delete"
                            color="secondary"
                            variant="contained"
                            onClick={openDialog}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <List
                    className={classes.milestones}
                >
                    {serviceZones?.map((serviceZones, index) => (
                        < React.Fragment key={"fragment" + index} >
                            <ListItem
                                key={"serviceZones" + index}
                                ContainerComponent="li"
                                className={classes.serviceZones}
                            >
                                <ListItemText
                                    className={classes.textOverFlowSet}
                                    primary={
                                        <React.Fragment>
                                            {t(`${"configurationEnumerations:" + serviceZones.serviceAudienceType}`)}
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            {renderElements(serviceZones)}
                                        </React.Fragment>}
                                />
                                <ListItemSecondaryAction className={classes.actionPaddingSet}>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </div>
            {
                !!serviceZoneDialogOpen && !_editServiceZone && <ServiceZonesDialog
                    open={serviceZoneDialogOpen}
                    onClose={closeDialog}
                    serviceZones={props.data.Entity.Supplier.ServiceZones}
                    serviceAudienceType={props.data.Entity.Supplier.serviceAudienceType}
                    entity={props.data}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                />
            }
        </React.Fragment >
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceZonesRenderer);