/* eslint-disable no-use-before-define */
import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from "@material-ui/core";
import SupplierDialog from '../SupplierDialog';
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import { useTranslation } from 'react-i18next';
import { sspMilestoneTypeEnum, sspStatusTypeEnum } from '../../utils/enums';
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
const IN_CELL_SEPARATOR = '<br/>';
const IN_CELL_SPACE = ' ';
const IN_CELL_RECORD_SEPARATOR = '<br/>';
const useStyles = makeStyles((theme) => ({
    root: {
        // width: 221,
        fontSize: 13,
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    tag: {
        marginTop: 3,
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    milestones: {
        overflow: 'auto',
        maxHeight: 215,
    },
    milestone: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    }

}));



const SupplierInfo = (props) => {
    const classes = useStyles();
    const [mileStones, setMilestones] = React.useState(props.value)
    const [mileStoneDialogOpen, setMileStoneDialogOpen] = React.useState(false);
    const [_mileStone, _setMilestone] = React.useState({});
    const [_editMileStone, _setEditMilestone] = React.useState(false);
    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const { t } = useTranslation([
        "translation",
        "eventsEnumerations",
    ]);

    const openDialog = () => {
        setMileStoneDialogOpen(true);
    }

    const closeDialog = () => {
        setMileStoneDialogOpen(false);
        _setMilestone({});
        _setEditMilestone(false);
    }

    const hadleEditClick = (e, milestone) => {
        _setEditMilestone(true);
        openDialog();
        _setMilestone(milestone);
    }

    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        let finIndex = updatedData.Entity.Events.findIndex((item) => item.i_sourceKey === value.i_sourceKey)
        if (finIndex != -1) {
            updatedData.Entity.Events[finIndex] = value;
        } else {
            updatedData.Entity.Events.push(value)
        }
        props.updateEntityToServer && props.updateEntityToServer(updatedData); 
        closeDialog();

    }

    const hadleDeleteClick = (e, value, index) => {
        let updatedData = Object.assign({}, props.data);
        
        // value.hasOwnProperty("uuid") ? updatedData.Entity.Events[index].i_t_lifecycleElement_status = "CYC_STATUS_DELETED" : updatedData.Entity.Events.splice(index, 1)
        
        let supplierIndex = updatedData.Entity.Events.findIndex((item) => item.i_sourceKey == value.i_sourceKey)
        updatedData.Entity.Events[supplierIndex].i_t_lifecycleElement_status = "CYC_STATUS_DELETED"
        
        props.updateEntityToServer && props.updateEntityToServer(updatedData); 
        setApiCallProgress(false);
    }
    const renderLanguageChips = (milestone) => {
        let langugaleMap = new Map();
        milestone.title.forEach(item => langugaleMap.set(item.key, { title: item.value, key: item.key }));
        milestone.description.forEach(item => langugaleMap.set(item.key, { ...langugaleMap.get(item.key), ...{ description: item.value, key: item.key } }));

        return Array.from(langugaleMap.keys()).map((key, index) => {
            return <Chip
                size="small"
                component={"span"}
                key={"language" + key}
                label={key}
                style={toMaterialStyle(
                    key?.toLowerCase(),
                    500,
                )}
            />
        })
    }

    const renderTypeAndStatus = (milestone) => {
        return <><Chip
            component={"span"}
            size="small"
            key={"status" + milestone.status}
            label={t(`${"eventsEnumerations:" + sspStatusTypeEnum[milestone.status]}`)}
            style={toMaterialStyle(
                milestone.status?.toLowerCase(),
                500,
            )}
        /><Chip
                size="small"
                component={"span"}
                key={"milestoneType" + milestone.milestoneType}
                label={t(`${"eventsEnumerations:" + (sspMilestoneTypeEnum[milestone.milestoneType])}`)}
                style={toMaterialStyle(
                    milestone.milestoneType?.toLowerCase(),
                    500,
                )}
            />  </>
    }


    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.topbuttons}>
                    <Tooltip title={t("Add Milestone")}>
                        <IconButton aria-label="delete"
                            color="secondary"
                            variant="contained"
                            onClick={openDialog}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <List
                    className={classes.milestones}
                >
                    {mileStones?.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED").map((milestone, index) => (
                        < React.Fragment key={"fragment" + index} >
                            <ListItem
                                key={"milestone" + index}
                                ContainerComponent="li"
                                className={classes.milestone}
                            >
                                <ListItemText
                                    className={classes.textOverFlowSet}
                                    primary={
                                        <>
                                            {new Date(milestone.eventDate).format(props.context.date_format.value)}{" :"} {milestone.title[0].value}
                                        </>
                                    }
                                    secondary={<>
                                        {renderLanguageChips(milestone)}
                                        {renderTypeAndStatus(milestone)}
                                    </>}
                                />
                                <ListItemSecondaryAction className={classes.actionPaddingSet}>
                                    <IconButton
                                        key={'edit'}
                                        onClick={(e) => hadleEditClick(e, milestone)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        key={'delete'}
                                        onClick={(e) => hadleDeleteClick(e, milestone, index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </div>
            {
                !!mileStoneDialogOpen && !!Object.keys(_mileStone) && !!_editMileStone && <SupplierDialog
                    open={mileStoneDialogOpen}
                    onClose={closeDialog}
                    milestone={_mileStone}
                    entity={props.data}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                />
            }
            {
                !!mileStoneDialogOpen && !_editMileStone && <SupplierDialog
                    open={mileStoneDialogOpen}
                    onClose={closeDialog}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    entity={props.data}
                    apiCallProgress={apiCallProgress}
                />
            }
        </React.Fragment >
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SupplierInfo);