import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Rating from "@material-ui/lab/Rating";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../../utils";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    deletebutton:{
        marginBottom:"11px"
    }
}));

const RatingTechnologyReadiness = (props) => {
  const { t } = useTranslation([
    "translation",
    "configurationEnumerations",
    "dmTool",
  ]);
  const { ratingObj, OnChageRatingObj } = props;
  const OnChangeRatingTechnologyReadiness = (e) => {
    OnChageRatingObj("technologyReadiness", parseInt(e.target.value));
  };
  const [
    hoverTechnologyReadiness,
    setHoverTechnologyReadiness,
  ] = React.useState(
    ratingObj?.technologyReadiness !== 0 ? ratingObj?.technologyReadiness : null
  );
  const handleDelete = () => {
    return (
      setHoverTechnologyReadiness(null),
      OnChageRatingObj("technologyReadiness", 0)
    );
  };

  const labels = {
    1: t(`${"dmTool:TRL1"}`),
    2: t(`${"dmTool:TRL2"}`),
    3: t(`${"dmTool:TRL3"}`),
    4: t(`${"dmTool:TRL4"}`),
    5: t(`${"dmTool:TRL5"}`),
    6: t(`${"dmTool:TRL6"}`),
    7: t(`${"dmTool:TRL7"}`),
    8: t(`${"dmTool:TRL8"}`),
    9: t(`${"dmTool:TRL9"}`),
  };

  const classes = useStyles();

  return (
    <div style={{ marginBottom: "10px" }}>
      {t(`${"dmTool:techReadinessRatingHdng"}`)}
      <div>
        <Rating
          name="unique-rating2"
          value={Number(ratingObj?.technologyReadiness)}
          precision={1}
          max={9}
          onChange={OnChangeRatingTechnologyReadiness}
          onChangeActive={(event, newHover) => {
            if (newHover === -1 && ratingObj?.technologyReadiness == 0) {
              setHoverTechnologyReadiness(null);
            } else {
              setHoverTechnologyReadiness(newHover);
            }
          }}
        />
        <IconButton className={classes.deletebutton} onClick={() => handleDelete()}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
        <div>
          {!isEmpty(ratingObj?.technologyReadiness) &&
          !isEmpty(hoverTechnologyReadiness)
            ? labels[
                !isEmpty(hoverTechnologyReadiness) &&
                hoverTechnologyReadiness !== -1
                  ? hoverTechnologyReadiness
                  : Number(ratingObj?.technologyReadiness)
              ]
            : t(`${"dmTool:notRatedYetMsg"}`)}
        </div>
      </div>
    </div>
  );
};

export default RatingTechnologyReadiness;
