import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { sspBusinessIdentificationTypeEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';
import { isEmpty } from "../../utils"


const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const InstitutionIdentificationNumberDialog = props => {
    const { open, apiCallProgress } = props;
    const classes = useStyles();

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);

    const [institutionIdNumbers, setInstitutionIdNums] = useState([]);
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (props.institutionIdentificationNumber?.length) {
            setInstitutionIdNums(props.institutionIdentificationNumber)
        } else {
            setInstitutionIdNums([{
                key: '',
                value: ''
            }]
            )
        }

    }, [props.institutionIdentificationNumber])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };



    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(institutionIdNumbers)
    };



    const sspBusinessIdentificationTypeEnumOptions = Object.keys(sspBusinessIdentificationTypeEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspBusinessIdentificationTypeEnum[opt]}`)
        };
    });

    const OnChangeInstitutionIdValue = (e, index) => {
        let _InstitutionIdNumber = institutionIdNumbers
        _InstitutionIdNumber[index].value = e.target.value
        addUpdateOrDeleteInstitutionIdNumber(_InstitutionIdNumber, 'update')
    }

    const onChangeInstitutionIdKey = (e, value, index) => {
        let _InstitutionIdNumber = institutionIdNumbers
        _InstitutionIdNumber[index].key = value.value
        addUpdateOrDeleteInstitutionIdNumber(_InstitutionIdNumber, 'update')
    }

    const addInstitutionIdNumberRow = (e) => {
        addUpdateOrDeleteInstitutionIdNumber({
            key: '',
            value: ''
        }, 'add')
    }

    const addUpdateOrDeleteInstitutionIdNumber = (object, type) => {
        if (type == 'add') {
            setInstitutionIdNums((_institutionIdNumber) => {
                return ([..._institutionIdNumber, object])

            })
        }
        if (type == 'delete') {
            if (institutionIdNumbers.length == 1) {
                setInstitutionIdNums([{
                    key: '',
                    value: ''
                }])
            } else {
                setInstitutionIdNums((_institutionIdNumber) => {
                    return [..._institutionIdNumber.filter((item, index) => index != object)]
                })
            }
        }
        if (type == 'update') {
            setInstitutionIdNums((_institutionIdNumber) => {
                return [...object]
            })
        }
    }



    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="institutionIdnumber-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="institutionIdnumber-dialog-title">
                        {!!props.institutionIdentificationNumber.length
                            ? t("dmTool:updateInstIdNuberDialoHdng", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addInstIdNuberDialoHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        {institutionIdNumbers?.map((institutionIdnum, index) => {
                            return (
                                <Grid container spacing={1}>
                                    <Grid item lg={4} sm={4} xs={12}>
                                        <Autocomplete
                                            disableClearable
                                            fullWidth
                                            size="small"
                                            className={classes.autocompleteStyle}
                                            getOptionLabel={option => option.label}
                                            id="legal-form-key"
                                            margin="normal"
                                            onChange={(e, value) => onChangeInstitutionIdKey(e, value, index)}
                                            options={sspBusinessIdentificationTypeEnumOptions}
                                            value={
                                                sspBusinessIdentificationTypeEnumOptions.find(
                                                    item =>
                                                        item.value ===
                                                        institutionIdnum?.key,
                                                ) || {}
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={t(`${"dmTool:instIdentificationTitleInputFieldLabel"}`)}
                                                    placeholder={t(`${"dmTool:enterInstIdentificationTitlePlcHldr"}`)}
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={6} xs={12}>
                                        <TextField
                                            className={classes.marginSet}
                                            fullWidth
                                            disabled={isEmpty(institutionIdnum.key) ? true : false}
                                            label={t(`${"dmTool:instIdNumberInputFieldLabel"}`)}
                                            placeholder={t(`${"dmTool:selectInstIdNumberPlcHldr"}`)}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            onChange={(e) => OnChangeInstitutionIdValue(e, index)}
                                            size='small'
                                            required={false}
                                            value={institutionIdnum?.value}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />

                                    </Grid>
                                    {index == 0 && <Grid item lg={1} sm={1} xs={12}>
                                        <Tooltip title={t(`${"dmTool:addInstIdNumberTooltip"}`)}>
                                            <IconButton aria-label="add"
                                                color="secondary"
                                                variant="contained"
                                                onClick={addInstitutionIdNumberRow}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>}
                                    <Grid item lg={1} sm={1} xs={12}>
                                        <IconButton
                                            key={'delete'}
                                            onClick={(e) => addUpdateOrDeleteInstitutionIdNumber(index, 'delete')}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        })}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={institutionIdNumbers?.some(item => !isEmpty(item.key)) && onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color={institutionIdNumbers?.some(item => isEmpty(item.key)) ? "disabled" : 'primary'}
                            className={classes.addButton}
                        >
                            {!!props.institutionIdentificationNumber.length ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default InstitutionIdentificationNumberDialog;