import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';


import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));

const DocumentFeatureImage = props => {
    const { t } = useTranslation([
        "translation",
        "eventsEnumerations",
        "dmTool"
    ]);
    const { documentObj, OnChageDocumentObj } = props;
    const classes = useStyles();

    const onChangeDocumentFeturedImage = (e) => {
        OnChageDocumentObj('documentFeatureImage', e.target.value);
    }


    return (
        <React.Fragment>
            <TextField
                className={classes.marginSet}
                fullWidth
                label={t(`${"dmTool:featuredImageInputFieldLabel"}`)}
                inputProps={{
                    autoComplete: 'new-password',
                }}
                placeholder={t(`${"dmTool:enterLinkPlcHldr"}`)}
                onChange={onChangeDocumentFeturedImage}
                size='small'
                required={false}
                value={documentObj?.documentFeatureImage}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
            />
        </React.Fragment>
    );
};

export default DocumentFeatureImage;