import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';


import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));

const Thoughts = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { dataSourceInfoObj, onChageDataSourceInfoObj } = props;
    const classes = useStyles();

    const onChangeComment = (e) => {
        onChageDataSourceInfoObj('thoughts', e.target.value);
    }


    return (
        <React.Fragment>
            <TextField
                className={classes.marginSet}
                fullWidth
                label={t(`${"dmTool:thoughtsInputFieldLabel"}`)}
                inputProps={{
                    autoComplete: 'new-password',
                }}
                placeholder={t(`${"dmTool:enterThoughtsPlcHldr"}`)}
                onChange={onChangeComment}
                size='small'
                required={false}
                value={dataSourceInfoObj?.filter(item => item.key == "thoughts")?.[0]?.value}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
            />
        </React.Fragment>
    );
};

export default Thoughts;