import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';


import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));

const KeypersonLastName = props => {
    const { t } = useTranslation([
        "translation",
        "eventsEnumerations",
        "dmTool"
    ]);
    const { keyPersonObj, OnChageKeypersonObj } = props;
    const classes = useStyles();

    const onChangeLastName = (e) => {
        OnChageKeypersonObj('surname', e.target.value);
    }


    return (
        <React.Fragment>
            <TextField
                className={classes.marginSet}
                fullWidth
                label={t(`${"dmTool:lastNameInputFieldLabel"}`)}
                inputProps={{
                    autoComplete: 'new-password',
                }}
                placeholder={t(`${"dmTool:enterLastNameplcHldr"}`)}
                onChange={onChangeLastName}
                size='small'
                required={false}
                value={keyPersonObj?.surname}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
            />
        </React.Fragment>
    );
};

export default KeypersonLastName;