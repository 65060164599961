import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { sspServiceAudienceTypeEnum } from '../../../utils/enums';
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ServiceAudienceType = props => {
    const [serviceAudienceType, setServiceAudienceType] = useState(props.serviceAudienceType);
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    let sspServiceAudienceTypeEnumOptions = Object.keys(sspServiceAudienceTypeEnum)
        .map((item) => {
            return {
                name: t(`${"configurationEnumerations:" + sspServiceAudienceTypeEnum[item]}`),
                value: item
            }
        })

    function _onChangeHandler(e, value) {
        if (value) {
            setServiceAudienceType(value.map((item) => item.value));
        } else {
            setServiceAudienceType([sspServiceAudienceTypeEnum.CON_SERVICE_AUDIENCE_TYPE_ANY]);
        }
    }

    useEffect(() => {
        props.onChageServiceAudienceType(serviceAudienceType)
    }, [serviceAudienceType])

    return (
        <Autocomplete
            id="audience_Startup_Pahse"
            multiple
            disableCloseOnSelect
            value={sspServiceAudienceTypeEnumOptions.filter(item => serviceAudienceType?.indexOf(item.value) !== -1)}
            options={sspServiceAudienceTypeEnumOptions}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => value.value === option.value}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t(`${"dmTool:audienceStartupPhaseInputFieldLabel"}`)}
                    variant="standard"
                />
            )}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </React.Fragment>
            )}
            onChange={_onChangeHandler}
        />
    );
}

export default ServiceAudienceType;