import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import { toast } from "react-toastify";

import { loadFromEnvironment } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const LoadFormProdDialog = props => {
    const { open, content } = props;
    const [value, setValue] = useState("loadAsNewVersion");
    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const classes = useStyles();

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };
    const onClickSaveOrUpdate = () => {
        const { onClose } = props;
        setApiCallProgress(true);
        props.loadFromEnvironment && props.loadFromEnvironment({
            i_sourceKey: props.entity.Entity.i_sourceKey,
            environment: "PRODUCTION",
            options: {
                "forceMerge": true
            }
        })
        setTimeout(() => {
            onClose && onClose();
        }, 1000);
    };

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="legalForm-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="legalForm-dialog-title">
                        {t("dmTool:loadFromProdDialogHdng", { entityName: props?.entity?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        {t("dmTool:reloadFrom", { environment: "PRODUCTION" })}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color='primary'
                            className={classes.addButton}
                        >
                            {t(`${"dmTool:okBtn"}`)}
                        </Button>
                        {(apiCallProgress) && <CircularProgress style={{ width: "30px", height: "30px" }} />}
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadFromEnvironment: (payload) => dispatch(loadFromEnvironment(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoadFormProdDialog);