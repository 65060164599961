import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  IntellectualPropertyType,
  IntellectualPropertyStatus,
  IntellectualPropertyTitle,
  IntellectualPropertyDescription,
  IntellectualPropertyLink,
  IntellectualPropertyQualityFlag,
  IntellectualPropertyStartupSpiderInternalComment,
} from "./Components";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../utils";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 700,
  },
  legend: {
    padding: 0,
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "-0.05px",
    color: "#888888",
  },
  fieldset: {
    border: "1px solid #eceded",
    padding: "10px",
    borderRadius: "4px",
  },
  addButton: {
    minWidth: 91,
  },
}));

const IntellectualPropertyDialog = (props) => {
  const { t } = useTranslation([
    "translation",
    "configurationEnumerations",
    "dmTool",
  ]);
  const { open, apiCallProgress } = props;
  const classes = useStyles();

  const [intellectualPropertyObj, setIntellectualPropertyObj] = useState({});
  const [enitityObj, setEntityObj] = useState({});
  useEffect(() => {
    setEntityObj(props.entity);
    if (props.IntellectualProperty) {
      setIntellectualPropertyObj(props.IntellectualProperty);
    } else {
      setIntellectualPropertyObj({
        name: "IntellectualProperties",
        i_sourceKey: null,
        date_sourceCreationTs: null,
        date_sourceUpdateTs: null,
        i_t_qualitySourcePriorityLevel: "",
        i_t_qualitySourceDataQualityEvaluation: "",
        i_t_researchComment: "",
        i_t_startupSpiderInternalComment: "",
        i_t_qualitySourceExpertQualityComment: "",
        title: [
          {
            key: "",
            value: "",
          },
        ],
        type: "",
        description: [
          {
            key: "",
            value: "",
          },
        ],
        link: [
          {
            key: "",
            value: "",
          },
        ],
        status: "",
      });
    }
  }, [props.IntellectualProperty]);

  const closeModal = () => {
    const { onClose } = props;
    onClose && onClose();
  };

  const OnChageIntellectualPropertyObj = (key, value) => {
    setIntellectualPropertyObj({
      ...intellectualPropertyObj,
      [`${key}`]: value,
    });
  };

  const onClickSaveOrUpdate = () => {
    let updatedObject = Object.assign({}, intellectualPropertyObj);
    if (updatedObject?.i_sourceKey == null) {
      updatedObject.i_sourceKey =
        "ssp_intellectualproperty_" +
        Date.now() +
        "_" +
        parseInt(Math.random() * 100000);
    }
    props.onClickAddOrUpdate(updatedObject);
  };

  const OnAddOrDeleteIntellectualPropertyObjTitle = (value, action) => {
    let _IntellectualPropertyObj = Object.assign({}, intellectualPropertyObj);

    if (action == "add") {
      _IntellectualPropertyObj.title = [
        ..._IntellectualPropertyObj[`title`],
        value,
      ];
    } else {
      _IntellectualPropertyObj.title = value;
    }
    setIntellectualPropertyObj(_IntellectualPropertyObj);
  };

  const OnChangeIntellectualPropertyObjTitle = (key, value, index) => {
    let _IntellectualPropertyObj = Object.assign({}, intellectualPropertyObj);
    _IntellectualPropertyObj.title[index] = {
      ..._IntellectualPropertyObj.title[index],
      [`${key}`]: value,
    };
    setIntellectualPropertyObj(_IntellectualPropertyObj);
  };

  const OnAddOrDeleteIntellectualPropertyObjDesc = (value, action) => {
    let _IntellectualPropertyObj = Object.assign({}, intellectualPropertyObj);

    if (action == "add") {
      _IntellectualPropertyObj.description = [
        ..._IntellectualPropertyObj[`description`],
        value,
      ];
    } else {
      _IntellectualPropertyObj.description = value;
    }
    setIntellectualPropertyObj(_IntellectualPropertyObj);
  };

  const OnChangeIntellectualPropertyObjDesc = (key, value, index) => {
    let _IntellectualPropertyObj = Object.assign({}, intellectualPropertyObj);
    _IntellectualPropertyObj.description[index] = {
      ..._IntellectualPropertyObj.description[index],
      [`${key}`]: value,
    };
    setIntellectualPropertyObj(_IntellectualPropertyObj);
  };

  const OnAddOrDeleteIntellectualPropertyObjLink = (value, action) => {
    let _IntellectualPropertyObj = Object.assign({}, intellectualPropertyObj);

    if (action == "add") {
      _IntellectualPropertyObj.link = [
        ..._IntellectualPropertyObj[`link`],
        value,
      ];
    } else {
      _IntellectualPropertyObj.link = value;
    }
    setIntellectualPropertyObj(_IntellectualPropertyObj);
  };

  const OnChangeIntellectualPropertyObjLink = (key, value, index) => {
    let _IntellectualPropertyObj = Object.assign({}, intellectualPropertyObj);
    _IntellectualPropertyObj.link[index] = {
      ..._IntellectualPropertyObj.link[index],
      [`${key}`]: value,
    };
    setIntellectualPropertyObj(_IntellectualPropertyObj);
  };

  return (
    <React.Fragment>
      {open && (
        <Dialog
          aria-labelledby="IntellectualProperty-dialog-title"
          classes={{
            paperWidthSm: classes.dialog,
          }}
          disableBackdropClick
          onClose={closeModal}
          open={open}
        >
          <DialogTitle
            className={classes.title}
            disableTypography
            id="IntellectualProperty-dialog-title"
          >
            {intellectualPropertyObj?.i_sourceKey !== null
              ? t("dmTool:updateIpDialoHdng", {
                entityName: enitityObj?.Entity?.accountName,
              })
              : t("dmTool:addIpDialoHdng", {
                entityName: enitityObj?.Entity?.accountName,
              })}
          </DialogTitle>
          {apiCallProgress && <LinearProgress />}
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item lg={4} sm={4} xs={12}>
                <IntellectualPropertyType
                  intellectualPropertyObj={intellectualPropertyObj}
                  OnChageIntellectualPropertyObj={
                    OnChageIntellectualPropertyObj
                  }
                />
              </Grid>
              <Grid item lg={4} sm={4} xs={12}>
                <IntellectualPropertyStatus
                  intellectualPropertyObj={intellectualPropertyObj}
                  OnChageIntellectualPropertyObj={
                    OnChageIntellectualPropertyObj
                  }
                />
              </Grid>
              <Grid item lg={4} sm={4} xs={12}>
                <IntellectualPropertyQualityFlag
                  intellectualPropertyObj={intellectualPropertyObj}
                  OnChageIntellectualPropertyObj={
                    OnChageIntellectualPropertyObj
                  }
                />
              </Grid>

              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    Intellectual property title
                  </legend>
                  <Grid container spacing={1}>
                    <IntellectualPropertyTitle
                      intellectualPropertyObj={intellectualPropertyObj}
                      OnAddOrDeleteIntellectualPropertyObjTitle={
                        OnAddOrDeleteIntellectualPropertyObjTitle
                      }
                      OnChangeIntellectualPropertyObjTitle={
                        OnChangeIntellectualPropertyObjTitle
                      }
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    Intellectual property description
                  </legend>
                  <Grid container spacing={1}>
                    <IntellectualPropertyDescription
                      intellectualPropertyObj={intellectualPropertyObj}
                      OnAddOrDeleteIntellectualPropertyObjDesc={
                        OnAddOrDeleteIntellectualPropertyObjDesc
                      }
                      OnChangeIntellectualPropertyObjDesc={
                        OnChangeIntellectualPropertyObjDesc
                      }
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    Intellectual property link
                  </legend>
                  <Grid container spacing={1}>
                    <IntellectualPropertyLink
                      intellectualPropertyObj={intellectualPropertyObj}
                      OnAddOrDeleteIntellectualPropertyObjLink={
                        OnAddOrDeleteIntellectualPropertyObjLink
                      }
                      OnChangeIntellectualPropertyObjLink={
                        OnChangeIntellectualPropertyObjLink
                      }
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <IntellectualPropertyStartupSpiderInternalComment
                  intellectualPropertyObj={intellectualPropertyObj}
                  intellectualPropertyObj={intellectualPropertyObj}
                  OnChageIntellectualPropertyObj={
                    OnChageIntellectualPropertyObj
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={apiCallProgress}
              onClick={closeModal}
              variant="contained"
              color="primary"
            >
              {t(`${"dmTool:cancelBtn"}`)}
            </Button>
            <Button
              onClick={onClickSaveOrUpdate}
              variant="contained"
              disabled={
                apiCallProgress ||
                !Boolean(intellectualPropertyObj.type) ||
                !Boolean(intellectualPropertyObj.status) ||
                !Boolean(
                  intellectualPropertyObj.i_t_qualitySourceDataQualityEvaluation
                ) ||
                intellectualPropertyObj.link?.some(
                  (item) => !isEmpty(item.value) && isEmpty(item.key)
                ) ||
                intellectualPropertyObj.description?.some(
                  (item) => !isEmpty(item.value) && isEmpty(item.key)
                ) ||
                intellectualPropertyObj.title?.some(
                  (item) => !isEmpty(item.value) && isEmpty(item.key)
                )
              }
              color="primary"
              className={classes.addButton}
            >
              {intellectualPropertyObj?.i_sourceKey !== null
                ? t(`${"dmTool:updateBtn"}`)
                : t(`${"dmTool:addBtn"}`)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default IntellectualPropertyDialog;
