/* eslint-disable no-use-before-define */
import React from "react";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import DocumentsDialog from "../DocumentsDialog";
import { sspLanguageEnum } from "../../utils/enums";
import { sspSectorEnum } from "../../utils/enums";
import { sspAdStatusEnum } from "../../utils/enums";
import { useTranslation } from "react-i18next";
import Chip from "@material-ui/core/Chip";
import toMaterialStyle from "material-color-hash";
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";

import DetailsTwoToneIcon from "@material-ui/icons/DetailsTwoTone";
import Typography from "@material-ui/core/Typography";
import { isEmpty, time_ago } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 221,
    fontSize: 13,
  },
  topbuttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  documents: {
    overflow: "auto",
    maxHeight: 215,
  },
  document: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    marginBottom: "8px",
  },
  chip: {
    margin: 2,
    flexWrap: "nowrap",
    maxWidth: "130px",
  },
  chip_2: {
    margin: 2,
    flexWrap: "nowrap",
    maxWidth: "145px",
  },
  chip_3: {
marginRight: "5px",
borderRadius: 2
  },
  chipLongtext: {
    margin: 2,
    "& .MuiChip-label": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 170,
    },
  },
}));

const DocumentsRenderer = (props) => {
  const { t } = useTranslation([
    "translation",
    "eventsEnumerations",
    "lifecycleEnumerations",
    "SSPDataQualityCommentMessages",
    "languageEnumerations",
    "documentsEnumerations",
    "dmTool",
  ]);
  const classes = useStyles();

  const [documents, setDocuments] = React.useState(
    isEmpty(props.value)
      ? []
      : props.value.map((item) => {
          if (isEmpty(item.Documents)) {
            return (item.Documents = [
              {
                name: "EntityDoc",
                documentType: "DOC_ENTITY_FACTSHEET",
                i_sourceKey:
                  "ssp_entitydoc_" +
                  Date.now() +
                  "_" +
                  parseInt(Math.random() * 100000),
                connectionDocURL: null,
                docLanguage: null,
                docDocumentName: null,
                i_docMimeType: "",
                docSpecificJurisdictionRegion: [],
                docSpecificJurisdictionCountry: [],
                docSpecificJurisdictionType: "",
                docTargetGroupList: [],
                docDocumentTimeRangeStartDate: null,
                docDocumentTimeRangeType: "",
                i_t_lifecycleElement_status: "CYC_STATUS_ACTIVE",
                date_sourceCreationTs: null,
                date_sourceUpdateTs: null,
                i_t_qualitySourceDataQualityEvaluation: "",
                i_t_researchComment: null,
                i_t_startupSpiderInternalComment: "",
                i_t_qualitySourceExpertQualityComment: null,
              },
            ]);
          } else {
            return item;
          }
        })
  );
  const [documentsDialogOpen, setDocumentsDialogOpen] = React.useState(false);

  const [_document, _setDocument] = React.useState({});
  const [_editDocment, _setEditDocument] = React.useState(false);
  const [apiCallProgress, setApiCallProgress] = React.useState(false);

  const DATAQUALITYFILLCOLOR = {
    CYC_DATA_RELIABILITY_LOW: "#FF3D00",
    CYC_DATA_RELIABILITY_VAGUE: "#F44336",
    CYC_DATA_RELIABILITY_INCONSISTENT: "#0091EA",
    CYC_DATA_RELIABILITY_CREDIBLE: "#546E7A",
    CYC_DATA_RELIABILITY_FIRM: "#388E3C",
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#000000",
      color: "rgba(255, 255, 255, 1)",
      // maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const [doc, setDoc] = React.useState({});

  const openDialog = () => {
    setDocumentsDialogOpen(true);
  };

  const closeDialog = (e) => {
    _setDocument({});
    _setEditDocument(false);
    setDocumentsDialogOpen(false);
  };

  const hadleEditClick = (e, document) => {
    _setEditDocument(true);
    _setDocument(document);
    openDialog();
  };

  function updateApi(value) {
    setApiCallProgress(true);
    let updatedData = Object.assign({}, props.data);
    if (!updatedData.Entity.Documents) {
      updatedData.Entity = { ...updatedData.Entity, Documents: [] };
    }
    let finIndex = updatedData.Entity.Documents.findIndex(
      (item) => item.i_sourceKey === value.i_sourceKey
    );
    if (finIndex != -1) {
      updatedData.Entity.Documents[finIndex] = value;
    } else {
      updatedData.Entity.Documents.push(value);
    }
    props.updateEntityToServer && props.updateEntityToServer(updatedData);
    closeDialog();
  }

  const hadleDeleteClick = (e, value, index) => {
    let updatedData = Object.assign({}, props.data);
    let docIndex = updatedData.Entity.Documents.findIndex(
      (item) => item.i_sourceKey == value.i_sourceKey
    );
    updatedData.Entity.Documents[docIndex].i_t_lifecycleElement_status =
      "CYC_STATUS_DELETED";
    updatedData.Entity.Documents[docIndex].Documents?.map((doc) => {
      return (doc.i_t_lifecycleElement_status = "CYC_STATUS_DELETED");
    });

    props.updateEntityToServer && props.updateEntityToServer(updatedData);
  };

  const onClickAddOrUpdate = (value) => {
    updateApi(value);
    setApiCallProgress(false);
  };

  const renderDocumentImage = (_document) => {
    if (_document.documentFeatureImage) {
      return (
        <img src={_document.documentFeatureImage} width={100} height={100} />
      );
    }
  };

  const renderTitle = (document) => {
    let docLangFilter = document.titleOfDocument.filter(
      (item) => item.key === props.context.preffered_language.value
    );
    if (docLangFilter?.length) {
      return docLangFilter.map((lang, index) => {
        return (
          <div style={{ marginTop: "10px" }} key={"index" + index}>
            {lang.value}
          </div>
        );
      });
    } else {
      return "";
    }
  };


  const renderRefrenceNote = (document) => {
    let docLangFilter = document.referenceNote.filter(
      (item) => item.key === props.context.preffered_language.value
    );
    if (docLangFilter?.length) {
      return docLangFilter.map((lang, index) => {
        return (
          <div style={{ marginBottom: "15px" }} key={"index" + index}>
            {lang.value}
          </div>
        );
      });
    } else {
      return "";
    }
  };

  const renderButtons = (document, index) => {
    return (
      <div style={{ marginLeft: "auto" }}>
        <IconButton key={"edit"} onClick={(e) => hadleEditClick(e, document)}>
          <EditIcon />
        </IconButton>
        <IconButton
          key={"delete"}
          onClick={(e) => hadleDeleteClick(e, document, index)}
        >
          <DeleteIcon />
        </IconButton>{" "}
      </div>
    );
  };

  const renderFocussectors = (_document) => {
    if (!isEmpty(_document.documentFocusSectors)) {
      return (
        <div style={{ paddingBottom: "10px" }}>
          {_document.documentFocusSectors.map((item) => {
            return (
              <Tooltip
                title={`${t("configurationEnumerations:" + item?.key)} : ${t(
                  "configurationEnumerations:" + item?.value
                )}`}
              >
                <Chip
                  component={"span"}
                  size="small"
                  key={item.value}
                  label={`${t("configurationEnumerations:" + item?.value)}`}
                  style={toMaterialStyle(item.key, 500)}
                  className={classes.chip}
                ></Chip>
              </Tooltip>
            );
          })}
        </div>
      );
    }
  };

  const renderDocumentTypes = (_document) => {
    if (!isEmpty(_document.documentClass)) {
      return (
        <div style={{ paddingTop: "5px", paddingBottom: "8px" }}>
          {_document.documentClass.map((item) => {
            return (
              <Tooltip
                title={`${t("documentsEnumerations:" + item?.key)} : ${t(
                  "documentsEnumerations:" + item?.value
                )}`}
              >
                <Chip
                  component={"span"}
                  size="small"
                  key={item.value}
                  label={` ${t("documentsEnumerations:" + item?.value)}`}
                  style={toMaterialStyle(item.key, 500)}
                  className={classes.chip}
                ></Chip>
              </Tooltip>
            );
          })}
        </div>
      );
    }
  };

  const renderDateandState = (_document) => {
    return (
      <div style={{ paddingBottom: "5px" }}>
        {!isEmpty(_document.publicationDate) && (
          <Tooltip
            arrow
            title={
              t(`${"dmTool:publishDateTooltip"}`) +
              ": " +
              new Date(parseInt(_document.publicationDate)).format(
                props.context.date_format.value
                  .split(" ")[0]
                  .replace(new RegExp("m", "g"), "M")
              )
            }
          >
            <Chip
              component={"span"}
              size="small"
              key={"Date"}
              label={new Date(parseInt(_document.publicationDate)).format(
                props.context.date_format.value
                  .split(" ")[0]
                  .replace(new RegExp("m", "g"), "M")
              )}
              className={classes.chip_3}
              style={toMaterialStyle("date", 500)}
            />
          </Tooltip>
        )}
        {!isEmpty(_document.publicationState) && (
          <Tooltip
            arrow
            title={t(
              `${
                "lifecycleEnumerations:" +
                sspAdStatusEnum[_document.publicationState]
              }`
            )}
          >
            <Chip
              component={"span"}
              size="small"
              key={"Sattus"}
              label={t(
                `${
                  "lifecycleEnumerations:" +
                  sspAdStatusEnum[_document.publicationState]
                }`
              )}
              className={classes.chip_3}
              style={toMaterialStyle("Publishstate", 500)}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderDocuments = (_document) => {
    return _document.Documents?.filter(
      (item) => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
    ).map((doc, index) => {
      return (
        <div
          style={{
            border: "1px solid #ccc",
            marginLeft: "5px",
            marginTop: "5px",
          }}
        >
          <div key={"docrender" + index} style={{ display: "flex" }}>
            {!!doc?.docLanguage && (
              <Chip
                component={"span"}
                size="small"
                key={"language"}
                label={`${doc?.docLanguage.toLocaleUpperCase()} - ${t(
                  "languageEnumerations:" + doc?.docLanguage
                )}`}
                style={toMaterialStyle(doc?.docLanguage, 500)}
                className={classes.chip}
              />
            )}

            {!isEmpty(doc?.documentClass) && !!doc?.documentClass?.value && (
              <Tooltip
                title={`${t(
                  "documentsEnumerations:" + doc?.documentClass?.value
                )}`}
              >
                <Chip
                  component={"span"}
                  size="small"
                  key={"documentClass.value"}
                  label={` ${t(
                    "documentsEnumerations:" + doc?.documentClass?.value
                  )}`}
                  style={toMaterialStyle(doc?.documentClass, 500)}
                  className={classes.chip_2}
                />
              </Tooltip>
            )}
            {!isEmpty(doc.i_t_qualitySourceDataQualityEvaluation) && (
              <HtmlTooltip
                arrow
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      {t(
                        `${
                          "lifecycleEnumerations:" +
                          doc.i_t_qualitySourceDataQualityEvaluation
                        }`
                      )}
                    </Typography>
                    {!isEmpty(doc.i_t_qualitySourceDataQualityComment) &&
                      t(
                        `${
                          "SSPDataQualityCommentMessages:" +
                          doc.i_t_qualitySourceDataQualityComment
                        }`
                      )}
                  </React.Fragment>
                }
              >
                <span style={{marginLeft: "auto"}}>
                  <DetailsTwoToneIcon
                    style={{
                      fill: DATAQUALITYFILLCOLOR[
                        doc.i_t_qualitySourceDataQualityEvaluation
                      ],
                    }}
                  />
                </span>
              </HtmlTooltip>
            )}
          </div>
        </div>
      );
    });
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.topbuttons}>
          <Tooltip title={t(`${"dmTool:addFactsheetDialogTitle"}`)}>
            <IconButton
              aria-label="delete"
              color="secondary"
              variant="contained"
              onClick={openDialog}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
        <List className={classes.documents}>
          {documents
            ?.filter(
              (item) =>
                item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
            )
            ?.filter((doc) => doc?.documentType === "DOC_ENTITY_FACTSHEET")
            ?.map((document, index) => (
              <React.Fragment key={"doc-fragemrnt" + index}>
                <ListItem
                  key={"document" + index + new Date()}
                  ContainerComponent="li"
                  className={classes.document}
                >
                  <ListItemText
                    className={classes.textOverFlowSet}
                    primary={
                      <>
                        {!isEmpty(
                          document.i_t_qualitySourceDataQualityEvaluation
                        ) && (
                          <HtmlTooltip
                            arrow
                            title={
                              <React.Fragment>
                                <Typography color="inherit">
                                  {t(
                                    `${
                                      "lifecycleEnumerations:" +
                                      document.i_t_qualitySourceDataQualityEvaluation
                                    }`
                                  )}
                                </Typography>
                                {!isEmpty(
                                  document.i_t_qualitySourceDataQualityComment
                                ) &&
                                  t(
                                    `${
                                      "SSPDataQualityCommentMessages:" +
                                      document.i_t_qualitySourceDataQualityComment
                                    }`
                                  )}
                              </React.Fragment>
                            }
                          >
                            <span
                              style={{
                                position: "absolute",
                                right: "0px",
                                top: "0px",
                              }}
                            >
                              <DetailsTwoToneIcon
                                style={{
                                  fill: DATAQUALITYFILLCOLOR[
                                    document
                                      .i_t_qualitySourceDataQualityEvaluation
                                  ],
                                }}
                              />
                            </span>
                          </HtmlTooltip>
                        )}
                        <div>
                          <div style={{ display: "flex" }}>
                            {renderTitle(document)}
                            {renderButtons(document, index)}
                          </div>
                          {!isEmpty(document.referenceNote) && renderRefrenceNote(document)}
                          <div>{renderDateandState(document)}</div>
                          <div>
                            {renderFocussectors(document)}
                            <Divider />
                            {renderDocumentTypes(document)}
                            <Divider />
                          </div>
                        </div>
                      </>
                    }
                    secondary={
                      <div style={{ display: "flex", paddingTop: "5px" }}>
                        {renderDocumentImage(document)}
                        <div style={{width: "100%"}}>{renderDocuments(document)}</div>
                      </div>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
        </List>
      </div>
      {!!documentsDialogOpen && (
        <DocumentsDialog
          open={documentsDialogOpen}
          onClose={closeDialog}
          edit={_editDocment}
          entity={props.data}
          document={_document}
          onClickAddOrUpdate={onClickAddOrUpdate}
          apiCallProgress={apiCallProgress}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    // store: state.Entity
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsRenderer);
