import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";
/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import toMaterialStyle from "material-color-hash";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { sspDocClassEnum, sspDocTypeEnum } from "../../../utils/enums";
import { isEmpty } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: "5px 5px 0 0"
  }
}));
const DocumentType = (props) => {
  const { placeholder, limitTags } = props;
  const { t } = useTranslation([
    "translation",
    "eventsEnumerations",
    "documentsEnumerations",
    "dmTool",
  ]);
  const getOptionLabel = (option) => `${option.label}`;
  const classes = useStyles();
  const items = Object.keys(sspDocTypeEnum).map((option) => {
    let highLevel = option.split("_").slice(0, 4);
    // if (highLevel[3] == "COMP") {
    //   highLevel[3] = "COMPANY";
    // }
    highLevel[2] = "CLASS";
    return {
      value: option,
      label: t(`${"documentsEnumerations:" + sspDocTypeEnum[option]}`),
      group: highLevel.join("_"),
      groupDisplayName: t(`${"documentsEnumerations:" + highLevel.join("_")}`),
    };
  });
  // .filter((i) => i.value !== "OPP_DETAIL_SECTOR_ALL_ALL");
  console.log(items, "sectoritems");
  const [_selectedFocus, setSelectedFocus] = React.useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const handleChange = (event, selectedOptions, reason) => {
    if (event.key == "Backspace") return;
    let allGroupsSelected = selectedOptions.map((i) => i.group).filter(_unique);
    let uniqueGroups = allGroupsSelected.filter(
      (group) =>
        items.filter((i) => i.group == group).length ==
        selectedOptions.filter((i) => i.group == group).length
    );
    if (reason === "select-option") {
      console.log("selectedOptions", selectedOptions, reason);
      props.OnChangeDocumentType({
        documentType: selectedOptions
          // .filter((i) => !uniqueGroups.includes(i.group))
          .map((i) => i.value),
        documentClass: [
          ..._selectedFocus.documentClass,
          ...allGroupsSelected,
        ].filter(_unique),
      });
    } else if (reason === "remove-option") {
      console.log("selectedOptions1", selectedOptions, reason);
      props.OnChangeDocumentType({
        documentType: selectedOptions
          // .filter((i) => !uniqueGroups.includes(i.group))
          .map((i) => i.value),
        documentClass: [
          ...uniqueGroups.filter((group) =>
            _selectedFocus.documentClass.includes(group)
          ),
          ...allGroupsSelected,
        ].filter(_unique),
      });
    }
  };
  const optionRenderer = (option, { selected }) => {
    console.log("optionss", option, selected);
    return (
      <React.Fragment key={option.value}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8, padding: "9px 0 9px 9px" }}
          checked={selected}
        />
        <span style={{ fontSize: "15px" }}>{getOptionLabel(option)}</span>
      </React.Fragment>
    );
  };
  const inputRenderer = (params) => (
    <TextField
      {...params}
      id="outlined-basic"
      variant="outlined"
      placeholder={placeholder}
      label={t(`${"dmTool:" + "documentTypenputFieldLabel"}`)}
    />
  );
  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value;
  const groupBy = (option) => option.group;
  const toogleGroupSelection = (e, params) => {
    console.log("paramss", params, params.group.split("_")[3] === "COMPANY");
    if (e.target.checked) {
      let documentClass = [..._selectedFocus.documentClass, params.group];
      let _documentType = Object.keys(sspDocTypeEnum)?.filter((item) => {
        let type = `DOC_DOC_TYPE_${
          // params.group.split("_")[3] === "COMPANY"
          //   ? "COMP"
          //   :
          params.group.split("_")[3]
        }`;
        if (item.includes(type)) {
          return item;
        }
      });
      let documentType = [..._selectedFocus.documentType, ..._documentType];
      console.log("eeeeee", e, params, documentType);
      props.OnChangeDocumentType({
        documentType,
        documentClass,
      });
    } else {
      let documentType = props.selectedClass.documentType.filter(
        (_documentClass) => {
          let i = _documentClass.split("_").slice(0, 4);
          // if (i[3] == "COMP") {
          //   i[3] = "COMPANY";
          // }
          i[2] = "CLASS";
          i = i.join("_");
          return i !== params.group;
        }
      );
      props.OnChangeDocumentType({
        documentType: documentType,
        documentClass: [
          ..._selectedFocus.documentClass.filter((i) => i !== params.group),
        ],
      });
    }
  };
  const _viewIsGroupIndeterminate = (group) => {
    let _selectedValues = selectedValues.filter((i) => i.group == group);
    return (
      _selectedValues.length !== 0 &&
      _selectedValues.length !== items.filter((i) => i.group == group).length
    );
  };
  const _viewIsGroupChecked = (group) => {
    return selectedValues.filter((i) => i.group == group).length;
  };
  const renderGroup = (params) => {
    console.log("params", params);
    return [
      <div key={params.group} style={toMaterialStyle(params.group, 500)} component="div">
        <span style={{ fontSize: "15px" }}>
          <Checkbox
            indeterminate={_viewIsGroupIndeterminate(params.group)}
            onChange={(e) => toogleGroupSelection(e, params)}
            color="primary"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8, padding: "6px 0 9px 9px" }}
            checked={_viewIsGroupChecked(params.group)}
          />
          {t(`${"documentsEnumerations:" + params.group}`)}
        </span>
      </div>,
      params.children,
    ];
  };
  const getOptionDisabled = () => {};
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      [option.value, option.label, option.group, option.groupDisplayName].join(
        " "
      ),
  });
  const renderTags = (value) => {
    return "";
  };
  React.useEffect(() => {
    if (props.selectedClass) {
      let __selectedClass = _reCalculateCompanyFocus(props.selectedClass);
      console.log("_selectedFocus", _selectedFocus);
      setSelectedFocus(__selectedClass);
      setSelectedValues([
        ...items.filter((i) => __selectedClass.documentType.includes(i.value)),
      ]);
    }
  }, [props.selectedClass]);
  const _unique = (i, index, array) => {
    return array.indexOf(i) === index;
  };
  const _reCalculateCompanyFocus = ({ documentClass, documentType }) => {
    console.log("recalculating!!", documentClass, documentType);
    let groupsFromDocumentClass = documentType
      .map((i) => {
        i = i.split("_").slice(0, 4);
        // if (i[3] == "COMP") {
        //   i[3] = "COMPANY";
        // }
        i[2] = "CLASS";
        return i.join("_");
      })
      .filter(_unique);
    let differenceInDocumentClass = documentClass.filter(
      (x) => !groupsFromDocumentClass.includes(x)
    );
    return {
      documentClass,
      documentType: [
        ...documentType,
        ...items
          .filter((i) => differenceInDocumentClass.includes(i.group))
          .map((i) => i.value),
      ],
    };
  };
  return (
    <React.Fragment>
      <Autocomplete
        multiple
        size="small"
        limitTags={limitTags}
        renderTags={renderTags}
        options={items}
        value={selectedValues}
        disableClearable
        groupBy={groupBy}
        label="type"
        renderGroup={renderGroup}
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        filterOptions={(options, params) => {
          const filtered = filterOptions(options, params);
          return [...filtered];
        }}
        getOptionDisabled={getOptionDisabled}
        onChange={handleChange}
        renderOption={optionRenderer}
        renderInput={inputRenderer}
      />
      <div>
        {!isEmpty(props.documentClass) &&
          props.documentClass.map((item, index) => {
            return (
              <React.Fragment>
                  <Tooltip
                    title={`${t("documentsEnumerations:" + item?.key)} : ${t(
                      "documentsEnumerations:" + item?.value
                    )}`}
                  >
                    <Chip
                      component={"span"}
                      size="small"
                      key={item.value}
                      label={` ${t("documentsEnumerations:" + item?.value)}`}
                      style={toMaterialStyle(item.key, 500)}
                      className={classes.chip}
                      onDelete={() => {
                        props.onRemoveDocumentType(item?.value);
                      }}
                    ></Chip>
                  </Tooltip>
              </React.Fragment>
            );
          })}
      </div>
    </React.Fragment>
  );
};
DocumentType.defaultProps = {
  limitTags: 1,
  items: [],
  selectedClass: {
    documentClass: [],
    documentType: [],
  },
};
export default DocumentType;
