exports.default = {
    "en": {
        "family": "Indo-European",
        "name": "English",
        "native": "English",
        "639-1": "en"
    },
    "de": {
        "family": "Indo-European",
        "name": "German",
        "native": "Deutsch",
        "639-1": "de"
    },
    "aa": {
        "family": "Afro-Asiatic",
        "name": "Afar",
        "native": "Afaraf",
        "639-1": "aa"
    },
    "ab": {
        "family": "Northwest Caucasian",
        "name": "Abkhazian",
        "native": "аҧсуа бызшәа, аҧсшәа",
        "639-1": "ab"
    },
    "ae": {
        "family": "Indo-European",
        "name": "Avestan",
        "native": "avesta",
        "639-1": "ae"
    },
    "af": {
        "family": "Indo-European",
        "name": "Afrikaans",
        "native": "Afrikaans",
        "639-1": "af"
    },
    "ak": {
        "family": "Niger–Congo",
        "name": "Akan",
        "native": "Akan",
        "639-1": "ak"
    },
    "am": {
        "family": "Afro-Asiatic",
        "name": "Amharic",
        "native": "አማርኛ",
        "639-1": "am"
    },
    "an": {
        "family": "Indo-European",
        "name": "Aragonese",
        "native": "aragonés",
        "639-1": "an"
    },
    "ar": {
        "family": "Afro-Asiatic",
        "name": "Arabic",
        "native": "العربية",
        "639-1": "ar"
    },
    "as": {
        "family": "Indo-European",
        "name": "Assamese",
        "native": "অসমীয়া",
        "639-1": "as"
    },
    "av": {
        "family": "Northeast Caucasian",
        "name": "Avaric",
        "native": "авар мацӀ, магӀарул мацӀ",
        "639-1": "av"
    },
    "ay": {
        "family": "Aymaran",
        "name": "Aymara",
        "native": "aymar aru",
        "639-1": "ay"
    },
    "az": {
        "family": "Turkic",
        "name": "Azerbaijani",
        "native": "azərbaycan dili",
        "639-1": "az"
    },
    "ba": {
        "family": "Turkic",
        "name": "Bashkir",
        "native": "башҡорт теле",
        "639-1": "ba"
    },
    "be": {
        "family": "Indo-European",
        "name": "Belarusian",
        "native": "беларуская мова",
        "639-1": "be"
    },
    "bg": {
        "family": "Indo-European",
        "name": "Bulgarian",
        "native": "български език",
        "639-1": "bg"
    },
    "bh": {
        "family": "Indo-European",
        "name": "Bihari languages",
        "native": "भोजपुरी",
        "639-1": "bh"
    },
    "bi": {
        "family": "Creole",
        "name": "Bislama",
        "native": "Bislama",
        "639-1": "bi"
    },
    "bm": {
        "family": "Niger–Congo",
        "name": "Bambara",
        "native": "bamanankan",
        "639-1": "bm"
    },
    "bn": {
        "family": "Indo-European",
        "name": "Bengali",
        "native": "বাংলা",
        "639-1": "bn"
    },
    "bo": {
        "family": "Sino-Tibetan",
        "name": "Tibetan",
        "native": "བོད་ཡིག",
        "639-1": "bo"
    },
    "br": {
        "family": "Indo-European",
        "name": "Breton",
        "native": "brezhoneg",
        "639-1": "br"
    },
    "bs": {
        "family": "Indo-European",
        "name": "Bosnian",
        "native": "bosanski jezik",
        "639-1": "bs"
    },
    "ca": {
        "family": "Indo-European",
        "name": "Catalan, Valencian",
        "native": "català, valencià",
        "639-1": "ca"
    },
    "ce": {
        "family": "Northeast Caucasian",
        "name": "Chechen",
        "native": "нохчийн мотт",
        "639-1": "ce"
    },
    "ch": {
        "family": "Austronesian",
        "name": "Chamorro",
        "native": "Chamoru",
        "639-1": "ch"
    },
    "co": {
        "family": "Indo-European",
        "name": "Corsican",
        "native": "corsu, lingua corsa",
        "639-1": "co"
    },
    "cr": {
        "family": "Algonquian",
        "name": "Cree",
        "native": "ᓀᐦᐃᔭᐍᐏᐣ",
        "639-1": "cr"
    },
    "cs": {
        "family": "Indo-European",
        "name": "Czech",
        "native": "čeština, český jazyk",
        "639-1": "cs"
    },
    "cu": {
        "family": "Indo-European",
        "name": "Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic",
        "native": "ѩзыкъ словѣньскъ",
        "639-1": "cu"
    },
    "cv": {
        "family": "Turkic",
        "name": "Chuvash",
        "native": "чӑваш чӗлхи",
        "639-1": "cv"
    },
    "cy": {
        "family": "Indo-European",
        "name": "Welsh",
        "native": "Cymraeg",
        "639-1": "cy"
    },
    "da": {
        "family": "Indo-European",
        "name": "Danish",
        "native": "dansk",
        "639-1": "da"
    },
    "dv": {
        "family": "Indo-European",
        "name": "Divehi, Dhivehi, Maldivian",
        "native": "ދިވެހި",
        "639-1": "dv"
    },
    "dz": {
        "family": "Sino-Tibetan",
        "name": "Dzongkha",
        "native": "རྫོང་ཁ",
        "639-1": "dz"
    },
    "ee": {
        "family": "Niger–Congo",
        "name": "Ewe",
        "native": "Eʋegbe",
        "639-1": "ee"
    },
    "el": {
        "family": "Indo-European",
        "name": "Greek, Modern (1453-)",
        "native": "ελληνικά",
        "639-1": "el"
    },
    "eo": {
        "family": "Constructed",
        "name": "Esperanto",
        "native": "Esperanto",
        "639-1": "eo"
    },
    "es": {
        "family": "Indo-European",
        "name": "Spanish, Castilian",
        "native": "Español",
        "639-1": "es"
    },
    "et": {
        "family": "Uralic",
        "name": "Estonian",
        "native": "eesti, eesti keel",
        "639-1": "et"
    },
    "eu": {
        "family": "Language isolate",
        "name": "Basque",
        "native": "euskara, euskera",
        "639-1": "eu"
    },
    "fa": {
        "family": "Indo-European",
        "name": "Persian",
        "native": "فارسی",
        "639-1": "fa"
    },
    "ff": {
        "family": "Niger–Congo",
        "name": "Fulah",
        "native": "Fulfulde, Pulaar, Pular",
        "639-1": "ff"
    },
    "fi": {
        "family": "Uralic",
        "name": "Finnish",
        "native": "suomi, suomen kieli",
        "639-1": "fi"
    },
    "fj": {
        "family": "Austronesian",
        "name": "Fijian",
        "native": "vosa Vakaviti",
        "639-1": "fj"
    },
    "fo": {
        "family": "Indo-European",
        "name": "Faroese",
        "native": "føroyskt",
        "639-1": "fo"
    },
    "fr": {
        "family": "Indo-European",
        "name": "French",
        "native": "français, langue française",
        "639-1": "fr"
    },
    "fy": {
        "family": "Indo-European",
        "name": "Western Frisian",
        "native": "Frysk",
        "639-1": "fy"
    },
    "ga": {
        "family": "Indo-European",
        "name": "Irish",
        "native": "Gaeilge",
        "639-1": "ga"
    },
    "gd": {
        "family": "Indo-European",
        "name": "Gaelic, Scottish Gaelic",
        "native": "Gàidhlig",
        "639-1": "gd"
    },
    "gl": {
        "family": "Indo-European",
        "name": "Galician",
        "native": "Galego",
        "639-1": "gl"
    },
    "gn": {
        "family": "Tupian",
        "name": "Guarani",
        "native": "Avañe'ẽ",
        "639-1": "gn"
    },
    "gu": {
        "family": "Indo-European",
        "name": "Gujarati",
        "native": "ગુજરાતી",
        "639-1": "gu"
    },
    "gv": {
        "family": "Indo-European",
        "name": "Manx",
        "native": "Gaelg, Gailck",
        "639-1": "gv"
    },
    "ha": {
        "family": "Afro-Asiatic",
        "name": "Hausa",
        "native": "(Hausa) هَوُسَ",
        "639-1": "ha"
    },
    "he": {
        "family": "Afro-Asiatic",
        "name": "Hebrew",
        "native": "עברית",
        "639-1": "he"
    },
    "hi": {
        "family": "Indo-European",
        "name": "Hindi",
        "native": "हिन्दी, हिंदी",
        "639-1": "hi"
    },
    "ho": {
        "family": "Austronesian",
        "name": "Hiri Motu",
        "native": "Hiri Motu",
        "639-1": "ho"
    },
    "hr": {
        "family": "Indo-European",
        "name": "Croatian",
        "native": "hrvatski jezik",
        "639-1": "hr"
    },
    "ht": {
        "family": "Creole",
        "name": "Haitian, Haitian Creole",
        "native": "Kreyòl ayisyen",
        "639-1": "ht"
    },
    "hu": {
        "family": "Uralic",
        "name": "Hungarian",
        "native": "magyar",
        "639-1": "hu"
    },
    "hy": {
        "family": "Indo-European",
        "name": "Armenian",
        "native": "Հայերեն",
        "639-1": "hy"
    },
    "hz": {
        "family": "Niger–Congo",
        "name": "Herero",
        "native": "Otjiherero",
        "639-1": "hz"
    },
    "ia": {
        "family": "Constructed",
        "name": "Interlingua (International Auxiliary Language Association)",
        "native": "Interlingua",
        "639-1": "ia"
    },
    "id": {
        "family": "Austronesian",
        "name": "Indonesian",
        "native": "Bahasa Indonesia",
        "639-1": "id"
    },
    "ie": {
        "family": "Constructed",
        "name": "Interlingue, Occidental",
        "native": "(originally:) Occidental, (after WWII:) Interlingue",
        "639-1": "ie"
    },
    "ig": {
        "family": "Niger–Congo",
        "name": "Igbo",
        "native": "Asụsụ Igbo",
        "639-1": "ig"
    },
    "ii": {
        "family": "Sino-Tibetan",
        "name": "Sichuan Yi, Nuosu",
        "native": "ꆈꌠ꒿ Nuosuhxop",
        "639-1": "ii"
    },
    "ik": {
        "family": "Eskimo–Aleut",
        "name": "Inupiaq",
        "native": "Iñupiaq, Iñupiatun",
        "639-1": "ik"
    },
    "io": {
        "family": "Constructed",
        "name": "Ido",
        "native": "Ido",
        "639-1": "io"
    },
    "is": {
        "family": "Indo-European",
        "name": "Icelandic",
        "native": "Íslenska",
        "639-1": "is"
    },
    "it": {
        "family": "Indo-European",
        "name": "Italian",
        "native": "Italiano",
        "639-1": "it"
    },
    "iu": {
        "family": "Eskimo–Aleut",
        "name": "Inuktitut",
        "native": "ᐃᓄᒃᑎᑐᑦ",
        "639-1": "iu"
    },
    "ja": {
        "family": "Japonic",
        "name": "Japanese",
        "native": "日本語 (にほんご)",
        "639-1": "ja"
    },
    "jv": {
        "family": "Austronesian",
        "name": "Javanese",
        "native": "ꦧꦱꦗꦮ, Basa Jawa",
        "639-1": "jv"
    },
    "ka": {
        "family": "Kartvelian",
        "name": "Georgian",
        "native": "ქართული",
        "639-1": "ka"
    },
    "kg": {
        "family": "Niger–Congo",
        "name": "Kongo",
        "native": "Kikongo",
        "639-1": "kg"
    },
    "ki": {
        "family": "Niger–Congo",
        "name": "Kikuyu, Gikuyu",
        "native": "Gĩkũyũ",
        "639-1": "ki"
    },
    "kj": {
        "family": "Niger–Congo",
        "name": "Kuanyama, Kwanyama",
        "native": "Kuanyama",
        "639-1": "kj"
    },
    "kk": {
        "family": "Turkic",
        "name": "Kazakh",
        "native": "қазақ тілі",
        "639-1": "kk"
    },
    "kl": {
        "family": "Eskimo–Aleut",
        "name": "Kalaallisut, Greenlandic",
        "native": "kalaallisut, kalaallit oqaasii",
        "639-1": "kl"
    },
    "km": {
        "family": "Austroasiatic",
        "name": "Central Khmer",
        "native": "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ",
        "639-1": "km"
    },
    "kn": {
        "family": "Dravidian",
        "name": "Kannada",
        "native": "ಕನ್ನಡ",
        "639-1": "kn"
    },
    "ko": {
        "family": "Koreanic",
        "name": "Korean",
        "native": "한국어",
        "639-1": "ko"
    },
    "kr": {
        "family": "Nilo-Saharan",
        "name": "Kanuri",
        "native": "Kanuri",
        "639-1": "kr"
    },
    "ks": {
        "family": "Indo-European",
        "name": "Kashmiri",
        "native": "कश्मीरी, كشميري‎",
        "639-1": "ks"
    },
    "ku": {
        "family": "Indo-European",
        "name": "Kurdish",
        "native": "Kurdî, کوردی‎",
        "639-1": "ku"
    },
    "kv": {
        "family": "Uralic",
        "name": "Komi",
        "native": "коми кыв",
        "639-1": "kv"
    },
    "kw": {
        "family": "Indo-European",
        "name": "Cornish",
        "native": "Kernewek",
        "639-1": "kw"
    },
    "ky": {
        "family": "Turkic",
        "name": "Kirghiz, Kyrgyz",
        "native": "Кыргызча, Кыргыз тили",
        "639-1": "ky"
    },
    "la": {
        "family": "Indo-European",
        "name": "Latin",
        "native": "latine, lingua latina",
        "639-1": "la"
    },
    "lb": {
        "family": "Indo-European",
        "name": "Luxembourgish, Letzeburgesch",
        "native": "Lëtzebuergesch",
        "639-1": "lb"
    },
    "lg": {
        "family": "Niger–Congo",
        "name": "Ganda",
        "native": "Luganda",
        "639-1": "lg"
    },
    "li": {
        "family": "Indo-European",
        "name": "Limburgan, Limburger, Limburgish",
        "native": "Limburgs",
        "639-1": "li"
    },
    "ln": {
        "family": "Niger–Congo",
        "name": "Lingala",
        "native": "Lingála",
        "639-1": "ln"
    },
    "lo": {
        "family": "Tai–Kadai",
        "name": "Lao",
        "native": "ພາສາລາວ",
        "639-1": "lo"
    },
    "lt": {
        "family": "Indo-European",
        "name": "Lithuanian",
        "native": "lietuvių kalba",
        "639-1": "lt"
    },
    "lu": {
        "family": "Niger–Congo",
        "name": "Luba-Katanga",
        "native": "Kiluba",
        "639-1": "lu"
    },
    "lv": {
        "family": "Indo-European",
        "name": "Latvian",
        "native": "latviešu valoda",
        "639-1": "lv"
    },
    "mg": {
        "family": "Austronesian",
        "name": "Malagasy",
        "native": "fiteny malagasy",
        "639-1": "mg"
    },
    "mh": {
        "family": "Austronesian",
        "name": "Marshallese",
        "native": "Kajin M̧ajeļ",
        "639-1": "mh"
    },
    "mi": {
        "family": "Austronesian",
        "name": "Maori",
        "native": "te reo Māori",
        "639-1": "mi"
    },
    "mk": {
        "family": "Indo-European",
        "name": "Macedonian",
        "native": "македонски јазик",
        "639-1": "mk"
    },
    "ml": {
        "family": "Dravidian",
        "name": "Malayalam",
        "native": "മലയാളം",
        "639-1": "ml"
    },
    "mn": {
        "family": "Mongolic",
        "name": "Mongolian",
        "native": "Монгол хэл",
        "639-1": "mn"
    },
    "mr": {
        "family": "Indo-European",
        "name": "Marathi",
        "native": "मराठी",
        "639-1": "mr"
    },
    "ms": {
        "family": "Austronesian",
        "name": "Malay",
        "native": "Bahasa Melayu, بهاس ملايو‎",
        "639-1": "ms"
    },
    "mt": {
        "family": "Afro-Asiatic",
        "name": "Maltese",
        "native": "Malti",
        "639-1": "mt"
    },
    "my": {
        "family": "Sino-Tibetan",
        "name": "Burmese",
        "native": "ဗမာစာ",
        "639-1": "my"
    },
    "na": {
        "family": "Austronesian",
        "name": "Nauru",
        "native": "Dorerin Naoero",
        "639-1": "na"
    },
    "nb": {
        "family": "Indo-European",
        "name": "Norwegian Bokmål",
        "native": "Norsk Bokmål",
        "639-1": "nb"
    },
    "nd": {
        "family": "Niger–Congo",
        "name": "North Ndebele",
        "native": "isiNdebele",
        "639-1": "nd"
    },
    "ne": {
        "family": "Indo-European",
        "name": "Nepali",
        "native": "नेपाली",
        "639-1": "ne"
    },
    "ng": {
        "family": "Niger–Congo",
        "name": "Ndonga",
        "native": "Owambo",
        "639-1": "ng"
    },
    "nl": {
        "family": "Indo-European",
        "name": "Dutch, Flemish",
        "native": "Nederlands, Vlaams",
        "639-1": "nl"
    },
    "nn": {
        "family": "Indo-European",
        "name": "Norwegian Nynorsk",
        "native": "Norsk Nynorsk",
        "639-1": "nn"
    },
    "no": {
        "family": "Indo-European",
        "name": "Norwegian",
        "native": "Norsk",
        "639-1": "no"
    },
    "nr": {
        "family": "Niger–Congo",
        "name": "South Ndebele",
        "native": "isiNdebele",
        "639-1": "nr"
    },
    "nv": {
        "family": "Dené–Yeniseian",
        "name": "Navajo, Navaho",
        "native": "Diné bizaad",
        "639-1": "nv"
    },
    "ny": {
        "family": "Niger–Congo",
        "name": "Chichewa, Chewa, Nyanja",
        "native": "chiCheŵa, chinyanja",
        "639-1": "ny"
    },
    "oc": {
        "family": "Indo-European",
        "name": "Occitan",
        "native": "occitan, lenga d'òc",
        "639-1": "oc"
    },
    "oj": {
        "family": "Algonquian",
        "name": "Ojibwa",
        "native": "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        "639-1": "oj"
    },
    "om": {
        "family": "Afro-Asiatic",
        "name": "Oromo",
        "native": "Afaan Oromoo",
        "639-1": "om"
    },
    "or": {
        "family": "Indo-European",
        "name": "Oriya",
        "native": "ଓଡ଼ିଆ",
        "639-1": "or"
    },
    "os": {
        "family": "Indo-European",
        "name": "Ossetian, Ossetic",
        "native": "ирон æвзаг",
        "639-1": "os"
    },
    "pa": {
        "family": "Indo-European",
        "name": "Punjabi, Panjabi",
        "native": "ਪੰਜਾਬੀ, پنجابی‎",
        "639-1": "pa"
    },
    "pi": {
        "family": "Indo-European",
        "name": "Pali",
        "native": "पालि, पाळि",
        "639-1": "pi"
    },
    "pl": {
        "family": "Indo-European",
        "name": "Polish",
        "native": "język polski, polszczyzna",
        "639-1": "pl"
    },
    "ps": {
        "family": "Indo-European",
        "name": "Pashto, Pushto",
        "native": "پښتو",
        "639-1": "ps"
    },
    "pt": {
        "family": "Indo-European",
        "name": "Portuguese",
        "native": "Português",
        "639-1": "pt"
    },
    "qu": {
        "family": "Quechuan",
        "name": "Quechua",
        "native": "Runa Simi, Kichwa",
        "639-1": "qu"
    },
    "rm": {
        "family": "Indo-European",
        "name": "Romansh",
        "native": "Rumantsch Grischun",
        "639-1": "rm"
    },
    "rn": {
        "family": "Niger–Congo",
        "name": "Rundi",
        "native": "Ikirundi",
        "639-1": "rn"
    },
    "ro": {
        "family": "Indo-European",
        "name": "Romanian, Moldavian, Moldovan",
        "native": "Română",
        "639-1": "ro"
    },
    "ru": {
        "family": "Indo-European",
        "name": "Russian",
        "native": "русский",
        "639-1": "ru"
    },
    "rw": {
        "family": "Niger–Congo",
        "name": "Kinyarwanda",
        "native": "Ikinyarwanda",
        "639-1": "rw"
    },
    "sa": {
        "family": "Indo-European",
        "name": "Sanskrit",
        "native": "संस्कृतम्",
        "639-1": "sa"
    },
    "sc": {
        "family": "Indo-European",
        "name": "Sardinian",
        "native": "sardu",
        "639-1": "sc"
    },
    "sd": {
        "family": "Indo-European",
        "name": "Sindhi",
        "native": "सिन्धी, سنڌي، سندھی‎",
        "639-1": "sd"
    },
    "se": {
        "family": "Uralic",
        "name": "Northern Sami",
        "native": "Davvisámegiella",
        "639-1": "se"
    },
    "sg": {
        "family": "Creole",
        "name": "Sango",
        "native": "yângâ tî sängö",
        "639-1": "sg"
    },
    "si": {
        "family": "Indo-European",
        "name": "Sinhala, Sinhalese",
        "native": "සිංහල",
        "639-1": "si"
    },
    "sk": {
        "family": "Indo-European",
        "name": "Slovak",
        "native": "Slovenčina, Slovenský Jazyk",
        "639-1": "sk"
    },
    "sl": {
        "family": "Indo-European",
        "name": "Slovenian",
        "native": "Slovenski Jezik, Slovenščina",
        "639-1": "sl"
    },
    "sm": {
        "family": "Austronesian",
        "name": "Samoan",
        "native": "gagana fa'a Samoa",
        "639-1": "sm"
    },
    "sn": {
        "family": "Niger–Congo",
        "name": "Shona",
        "native": "chiShona",
        "639-1": "sn"
    },
    "so": {
        "family": "Afro-Asiatic",
        "name": "Somali",
        "native": "Soomaaliga, af Soomaali",
        "639-1": "so"
    },
    "sq": {
        "family": "Indo-European",
        "name": "Albanian",
        "native": "Shqip",
        "639-1": "sq"
    },
    "sr": {
        "family": "Indo-European",
        "name": "Serbian",
        "native": "српски језик",
        "639-1": "sr"
    },
    "ss": {
        "family": "Niger–Congo",
        "name": "Swati",
        "native": "SiSwati",
        "639-1": "ss"
    },
    "st": {
        "family": "Niger–Congo",
        "name": "Southern Sotho",
        "native": "Sesotho",
        "639-1": "st"
    },
    "su": {
        "family": "Austronesian",
        "name": "Sundanese",
        "native": "Basa Sunda",
        "639-1": "su"
    },
    "sv": {
        "family": "Indo-European",
        "name": "Swedish",
        "native": "Svenska",
        "639-1": "sv"
    },
    "sw": {
        "family": "Niger–Congo",
        "name": "Swahili",
        "native": "Kiswahili",
        "639-1": "sw"
    },
    "ta": {
        "family": "Dravidian",
        "name": "Tamil",
        "native": "தமிழ்",
        "639-1": "ta"
    },
    "te": {
        "family": "Dravidian",
        "name": "Telugu",
        "native": "తెలుగు",
        "639-1": "te"
    },
    "tg": {
        "family": "Indo-European",
        "name": "Tajik",
        "native": "тоҷикӣ, toçikī, تاجیکی‎",
        "639-1": "tg"
    },
    "th": {
        "family": "Tai–Kadai",
        "name": "Thai",
        "native": "ไทย",
        "639-1": "th"
    },
    "ti": {
        "family": "Afro-Asiatic",
        "name": "Tigrinya",
        "native": "ትግርኛ",
        "639-1": "ti"
    },
    "tk": {
        "family": "Turkic",
        "name": "Turkmen",
        "native": "Türkmen, Түркмен",
        "639-1": "tk"
    },
    "tl": {
        "family": "Austronesian",
        "name": "Tagalog",
        "native": "Wikang Tagalog",
        "639-1": "tl"
    },
    "tn": {
        "family": "Niger–Congo",
        "name": "Tswana",
        "native": "Setswana",
        "639-1": "tn"
    },
    "to": {
        "family": "Austronesian",
        "name": "Tonga (Tonga Islands)",
        "native": "Faka Tonga",
        "639-1": "to"
    },
    "tr": {
        "family": "Turkic",
        "name": "Turkish",
        "native": "Türkçe",
        "639-1": "tr"
    },
    "ts": {
        "family": "Niger–Congo",
        "name": "Tsonga",
        "native": "Xitsonga",
        "639-1": "ts"
    },
    "tt": {
        "family": "Turkic",
        "name": "Tatar",
        "native": "татар теле, tatar tele",
        "639-1": "tt"
    },
    "tw": {
        "family": "Niger–Congo",
        "name": "Twi",
        "native": "Twi",
        "639-1": "tw"
    },
    "ty": {
        "family": "Austronesian",
        "name": "Tahitian",
        "native": "Reo Tahiti",
        "639-1": "ty"
    },
    "ug": {
        "family": "Turkic",
        "name": "Uighur, Uyghur",
        "native": "ئۇيغۇرچە‎, Uyghurche",
        "639-1": "ug"
    },
    "uk": {
        "family": "Indo-European",
        "name": "Ukrainian",
        "native": "Українська",
        "639-1": "uk"
    },
    "ur": {
        "family": "Indo-European",
        "name": "Urdu",
        "native": "اردو",
        "639-1": "ur"
    },
    "uz": {
        "family": "Turkic",
        "name": "Uzbek",
        "native": "Oʻzbek, Ўзбек, أۇزبېك‎",
        "639-1": "uz"
    },
    "ve": {
        "family": "Niger–Congo",
        "name": "Venda",
        "native": "Tshivenḓa",
        "639-1": "ve"
    },
    "vi": {
        "family": "Austroasiatic",
        "name": "Vietnamese",
        "native": "Tiếng Việt",
        "639-1": "vi"
    },
    "vo": {
        "family": "Constructed",
        "name": "Volapük",
        "native": "Volapük",
        "639-1": "vo"
    },
    "wa": {
        "family": "Indo-European",
        "name": "Walloon",
        "native": "Walon",
        "639-1": "wa"
    },
    "wo": {
        "family": "Niger–Congo",
        "name": "Wolof",
        "native": "Wollof",
        "639-1": "wo"
    },
    "xh": {
        "family": "Niger–Congo",
        "name": "Xhosa",
        "native": "isiXhosa",
        "639-1": "xh"
    },
    "yi": {
        "family": "Indo-European",
        "name": "Yiddish",
        "native": "ייִדיש",
        "639-1": "yi"
    },
    "yo": {
        "family": "Niger–Congo",
        "name": "Yoruba",
        "native": "Yorùbá",
        "639-1": "yo"
    },
    "za": {
        "family": "Tai–Kadai",
        "name": "Zhuang, Chuang",
        "native": "Saɯ cueŋƅ, Saw cuengh",
        "639-1": "za"
    },
    "zh": {
        "family": "Sino-Tibetan",
        "name": "Chinese",
        "native": "中文 (Zhōngwén), 汉语, 漢語",
        "639-1": "zh"
    },
    "zu": {
        "family": "Niger–Congo",
        "name": "Zulu",
        "native": "isiZulu",
        "639-1": "zu"
    }
}

module.exports = exports["default"];


/**
 *
 *
https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
$("#Table tbody tr").map((index, item) => {
    return {
        family: $(item).find('td:eq(1)').text(),
        name: $(item).find('td:eq(2)').text(),
        native: $(item).find('td:eq(3)').text(),
        "639-1": $(item).find('td:eq(4)').text()
    }
})

*/
