import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));
const RatingStartupSpiderInternalComment = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { ratingObj, OnChageRatingObj } = props;
    const classes = useStyles();

    const onChangeComment = (e) => {
        OnChageRatingObj('i_t_startupSpiderInternalComment', e.target.value);
    }


    return (
        <React.Fragment>
            <TextField
                className={classes.marginSet}
                fullWidth
                label={t(`${"dmTool:commentInputFieldLabel"}`)}
                inputProps={{
                    autoComplete: 'new-password',
                }}
                placeholder={t(`${"dmTool:enterCommentPlcHldr"}`)}
                onChange={onChangeComment}
                size='small'
                required={false}
                value={ratingObj?.i_t_startupSpiderInternalComment}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
            />
        </React.Fragment>
    );
};

export default RatingStartupSpiderInternalComment;