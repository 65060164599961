import React, { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import HashtagDialog from '../HashatgDialog/HashtagDialog';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    const [openDialog, setOpenDialog] = useState(false)
    const hadleEditClick = (e) => {
        setOpenDialog(true);
    }
    const closeDialog = () => {
        setOpenDialog(false);
    }
    return (
        <React.Fragment>
            {< Tooltip title={t(`${"dmTool:hashTagTooltip"}`)}>
                <IconButton
                    key={'edit'}
                    onClick={hadleEditClick}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>}
            {!!props.value && props.value?.map((item, index) => {
                // if (typeof (item) == 'string' && item !== '') {
                if (!!item) {
                    return (
                        <Chip
                            key={index}
                            size="small"
                            label={`#${item}`}
                            style={toMaterialStyle(
                                item?.toLowerCase(),
                                900,
                            )}
                        />
                    )
                } else {
                    return ""
                }
                // } else {
                //     item?.map((innerItem) => {
                //         return (!!innerItem &&
                //             <Chip
                //                 label={`#${innerItem}`}
                //                 style={toMaterialStyle(
                //                     innerItem?.toLowerCase(),
                //                     900,
                //                 )}
                //             />
                //         )
                //     })
                // }
            })}
            {
                !!openDialog && <HashtagDialog
                    {...props}
                    open={openDialog}
                    onClose={closeDialog}
                />
            }
        </React.Fragment>
    )
}