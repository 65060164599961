import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
    KeypersonFirstName,
    KeypersonLastName,
    KeypersonFullName,
    KeypesonQualityFlag,
    KeypersonSocialProfiles,
    KeypersonRole
} from './Components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../utils';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));

const KeyPersonDialog = props => {
    const { t } = useTranslation([
        "translation",
        "eventsEnumerations",
        "dmTool"
    ]);
    const { open } = props;
    const classes = useStyles();
    const [apiCallProgress, setApiCallProgress] = useState(false);
    const [keyPersonObj, setKeyPersonObj] = useState({});
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (props.keyperson) {
            setKeyPersonObj(props.keyperson)
        } else {
            setKeyPersonObj({
                "name": "KeyPerson",
                "i_sourceKey": null,
                "i_t_qualitySourcePriorityLevel": "",
                "i_t_qualitySourceDataQualityEvaluation": "",
                "i_t_qualitySourceDataQualityComment": "",
                "i_t_researchComment": "",
                "i_t_startupSpiderInternalComment": "",
                "i_t_qualitySourceExpertQualityComment": "",
                "firstName": "",
                "surname": "",
                "fullName": "",
                "emailAddress": "",
                "socialProfileURL": [],
                "additionalInfo": [],
                "Roles": []
            })
        }
    }, [props.keyperson])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const OnChageKeypersonObj = (key, value) => {
        setKeyPersonObj({
            ...keyPersonObj,
            [`${key}`]: value
        })
    }

    const addUpdateOrDeleteKeypersonSocialProfiles = (object, type) => {
        if (type == 'add') {
            setKeyPersonObj((_keyPersonObj) => {
                return {
                    ..._keyPersonObj,
                    socialProfileURL: [..._keyPersonObj.socialProfileURL, object]
                }
            })
        }
        if (type == 'delete') {
            setKeyPersonObj((_keyPersonObj) => {
                return {
                    ..._keyPersonObj,
                    socialProfileURL: [..._keyPersonObj.socialProfileURL.filter((item, index) => index != object)]
                }
            })
        }
        if (type == 'update') {
            setKeyPersonObj((_keyPersonObj) => {
                return {
                    ..._keyPersonObj,
                    socialProfileURL: [...object]
                }
            })
        }
    }


    const addUpdateOrDeleteKeypersonRole = (object, type) => {
        if (type == 'add') {
            setKeyPersonObj((_keyPersonObj) => {
                return {
                    ..._keyPersonObj,
                    Roles: [..._keyPersonObj.Roles, object]
                }
            })
        }
        if (type == 'delete') {
            setKeyPersonObj((_keyPersonObj) => {
                return {
                    ..._keyPersonObj,
                    Roles: [..._keyPersonObj.Roles.filter((item, index) => (index != object && item?.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"))]
                }
            })
        }
        if (type == 'update') {
            setKeyPersonObj((_keyPersonObj) => {
                return {
                    ..._keyPersonObj,
                    Roles: [...object]
                }
            })
        }
        if (type == 'splice') {
            setKeyPersonObj((_keyPersonObj) => {
                return {
                    ..._keyPersonObj,
                    Roles: [..._keyPersonObj.Roles.filter((item, index) => (item?.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"))]
                }
            })
        }
    }


    const onClickSaveOrUpdate = () => {
        let updatedObject = Object.assign({}, keyPersonObj);
        if (updatedObject?.i_sourceKey == null) {
            updatedObject.i_sourceKey = "ssp_keyperson_" + Date.now() + "_" + parseInt(Math.random() * 100000)
        }
        props.onClickAddOrUpdate(updatedObject)
    };

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="key-person-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="key-person-dialog-title">
                        {keyPersonObj?.i_sourceKey !== null ?
                            t("dmTool:updateKeyPersonAndRoleDIalogHdng", { entityName: enitityObj?.Entity?.accountName }) :
                            t("dmTool:addKeyPersonAndRoleDIalogHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={6} sm={6} xs={12}>
                                <KeypersonFirstName
                                    keyPersonObj={keyPersonObj}
                                    OnChageKeypersonObj={OnChageKeypersonObj}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <KeypersonLastName
                                    keyPersonObj={keyPersonObj}
                                    OnChageKeypersonObj={OnChageKeypersonObj}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <KeypersonFullName
                                    keyPersonObj={keyPersonObj}
                                    OnChageKeypersonObj={OnChageKeypersonObj}
                                />
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <KeypesonQualityFlag
                                    keyPersonObj={keyPersonObj}
                                    OnChageKeypersonObj={OnChageKeypersonObj}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <KeypersonSocialProfiles
                                    keyPersonObj={keyPersonObj}
                                    addUpdateOrDeleteKeypersonSocialProfiles={addUpdateOrDeleteKeypersonSocialProfiles}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <KeypersonRole
                                    keyPersonObj={keyPersonObj}
                                    addUpdateOrDeleteKeypersonRole={addUpdateOrDeleteKeypersonRole}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >                            {t(`${"dmTool:cancelBtn"}`)}            </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress || !Boolean(keyPersonObj.i_t_qualitySourceDataQualityEvaluation) ||
                                (!isEmpty(keyPersonObj?.socialProfileURL) && keyPersonObj?.socialProfileURL?.some(item => isEmpty(item.key) || isEmpty(item.value))) ||
                                (!isEmpty(keyPersonObj?.Roles) && keyPersonObj?.Roles?.some(item => isEmpty(item.i_t_qualitySourceDataQualityEvaluation)))}
                            color="primary"
                            className={classes.addButton}
                        >
                            {keyPersonObj?.i_sourceKey !== null ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default KeyPersonDialog;