import React, { useState, forwardRef, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { sspServiceOfferingEnum } from '../../utils/enums';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const ServiceZonesSelectorEditor = forwardRef((props, ref) => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);

    let sspServiceOfferingEnumOptions = Object.keys(sspServiceOfferingEnum)
        .map((item) => {
            return {
                name: t(`${"configurationEnumerations:" + sspServiceOfferingEnum[item]}`),
                value: item
            }
        })
    const [value, setValue] = useState('');

    function onChangeHandler(e, value) {
        setValue(value.map((item) => item.value));
    }
    function updateApi() {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.Supplier.serviceOffering = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData); 
    }


    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                updateApi();
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value)
            }
        };
    });

    return (
        <Autocomplete
            id="service_offering"
            multiple
            disableCloseOnSelect
            value={sspServiceOfferingEnumOptions.filter(item => value?.indexOf(item.value) !== -1)}
            options={sspServiceOfferingEnumOptions}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => value.value === option.value}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t(`${"dmTool:serviceOfferingInputFieldLabel"}`)}
                    variant="standard"
                />
            )}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </React.Fragment>
            )}
            onChange={onChangeHandler}
        />
    );
})

const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ServiceZonesSelectorEditor);
