import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SourceInformationDialog from '../SourceInformationDialog';

export default (props) => {
    const { t } = useTranslation([
        "translation",
        "lifecycleEnumerations",
    ]);
    const [openDialog, setOpenDialog] = useState(false)
    const hadleEditClick = (e) => {
        setOpenDialog(true);
    }
    const closeDialog = () => {
        setOpenDialog(false);
    }

    return (
        <div>
            {< Tooltip title={t(`${"dmTool:editInfoSource"}`)}>
                <IconButton
                    key={'edit'}
                    onClick={hadleEditClick}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>}
            {props.value}

            {
                !!openDialog && <SourceInformationDialog
                    {...props}
                    open={openDialog}
                    onClose={closeDialog}
                />
            }
        </div >
    )
}
