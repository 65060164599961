import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sspStartupIntellectualPropertyEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));


const IntellectualPropertyStatus = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
    ]);
    const { intellectualPropertyObj, OnChageIntellectualPropertyObj } = props;
    const classes = useStyles();

    const OnChangesspIntellectualPropertyEnumStutus = (e, value) => {
        OnChageIntellectualPropertyObj('status', value.value)
    }



    const sspStartupIntellectualPropertyEnumOptions = Object.keys(sspStartupIntellectualPropertyEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspStartupIntellectualPropertyEnum[opt]}`)
        };
    });


    return (
        <React.Fragment>
            <Autocomplete
                disableClearable
                fullWidth
                size="small"
                className={classes.autocompleteStyle}
                getOptionLabel={option => option.label}
                id="intellectual-propert_status"
                margin="normal"
                onChange={OnChangesspIntellectualPropertyEnumStutus}
                options={sspStartupIntellectualPropertyEnumOptions}
                value={
                    sspStartupIntellectualPropertyEnumOptions.find(
                        item =>
                            item.value ===
                            intellectualPropertyObj?.status,
                    ) || {}
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        label={`${t(`${"dmTool:statusInputFieldLabel"}`)}*`}
                        placeholder={t(`${"dmTool:selectStatusPlcHldr"}`)}
                        variant="outlined"
                        style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </React.Fragment>
    );
};

export default IntellectualPropertyStatus;