import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ReactDiffViewer from 'react-diff-viewer';

const useStyles = makeStyles(theme => ({
    content_margin: {
        margin: "10px 0 0 0"
    },
    content: {
        border: "2px solid #ccc",
        margin: "5px 0 0 0",
        padding: "200px",
        textAlign: "center",
        background: "#f3f1f1"
    }
}));

const Compare = props => {
    const classes = useStyles();
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    return (
        <React.Fragment>

            {!props.compare ? <div className={classes.content}>
                <div>
                    {t(`${"dmTool:selectSourceAndTargetVersionsTitle"}`)}
                </div>
                <div>
                    {t(`${"dmTool:pressCompare"}`)}
                </div>
            </div> :
                <div className={classes.content_margin}>
                    <ReactDiffViewer oldValue={props._sourceValueFormatted} newValue={props._destinationValueFormatted} splitView={true} showDiffOnly={false} />
                </div>}
        </React.Fragment>
    );
};

export default Compare;