import React, { useState, useEffect, useRef } from 'react';
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import TimeAgo from "javascript-time-ago";

// Hook
export const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

export const useComponentWillMount = func => {
    const willMount = useRef(true);

    if (willMount.current) {
        func();
    }

    willMount.current = false;
};

export const isEmpty = obj => {
    // null and undefined are "empty"
    if (typeof obj === "string") obj = obj.replace(/\s/g, "");

    if (obj === null) return true;
    if (typeof obj === "undefined") return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.

    if (obj?.length > 0) return false;
    if (obj?.length === 0) return true;

    if (typeof obj === "number") return false;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
};

TimeAgo.addLocale(en);
TimeAgo.addLocale(ru);

export const time_ago = (ts, locale = "en") => {
    const timeAgo = new TimeAgo(locale);
    return timeAgo.format(ts);
  };