import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { WHERE_HEARD_ABOUT_USE_NUM } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({

}));

const WhereHeardAboutUs = props => {
    const { createdInfo, OnChageCratedInfo } = props;
    const classes = useStyles();
    const { t } = useTranslation()

    const OnChangeCreatedInfoAboutUsType = (e, value) => {
        OnChageCratedInfo('r_whereHeardAboutUsType', value.value)
    }


    const heardAboutUsOptions = Object.keys(WHERE_HEARD_ABOUT_USE_NUM).map(opt => {
        return {
            value: opt,
            //   label: t(`${"eventsEnumerations:"+(WHERE_HEARD_ABOUT_USE_NUM[opt])}`)
            label: t(WHERE_HEARD_ABOUT_USE_NUM[opt])
        };
    });


    return (
        <React.Fragment>
            <Autocomplete
                disableClearable
                fullWidth
                size="small"
                className={classes.autocompleteStyle}
                getOptionLabel={option => option.label}
                id="where_heard_sbout_us_type"
                margin="normal"
                onChange={OnChangeCreatedInfoAboutUsType}
                options={heardAboutUsOptions}
                value={
                    heardAboutUsOptions.find(
                        item =>
                            item.value ===
                            createdInfo?.r_whereHeardAboutUsType,
                    ) || {}
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Where you herad about us?"
                        placeholder="Select"
                        variant="outlined"
                        style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </React.Fragment>
    );
};

export default WhereHeardAboutUs;