import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';


import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));

const DocumentPublicationDate = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { documentObj, OnChangePublicationDate } = props;
    const classes = useStyles();

    const OnChangePublishDate = (date) => {
        OnChangePublicationDate(date !== null ? new Date(date).getTime() : null)
    }


    return (
        <React.Fragment>
            <KeyboardDatePicker
                autoOk
                size='small'
                clearable
                keyboard
                inputVariant="outlined"
                placeholder={t(`${"dmTool:enterPublishDatePlcHldr"}`)}
                label={`${t(`${"dmTool:publishDateInputFieldLabel"}`)}`}
                format={("DD/MM/YYYY")}
                value={documentObj.publicationDate}
                InputAdornmentProps={{
                    position: 'start',
                }}
                onChange={OnChangePublishDate}
            />
        </React.Fragment>
    );
};

export default DocumentPublicationDate;