import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default (props) => {
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    return <>
        {t(`${"dmTool:" + props.displayName}`)}
    </>
}
