import React, { useState, forwardRef, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sspSourcingStrategyTypeEnum } from "../../utils/enums";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";

const DataSourceSelectEditor = forwardRef((props, ref) => {
    const { t } = useTranslation([
        "translation",
        "lifecycleEnumerations",
    ]);
    let sspSourcingStrategyTypeEnumOptions = Object.keys(sspSourcingStrategyTypeEnum)
        .map((item) => {
            return {
                name: t(`${"lifecycleEnumerations:" + sspSourcingStrategyTypeEnum[item]}`),
                value: item
            }
        })
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState(props.value);

    function onChangeHandler(e, value) {
        setValue(value.value);
    }

    function onInputChangeHandler(e, inputValue) {
        setInputValue(inputValue);
    }

    function updateApi() {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity['quality_sourceDataStrategyType'] = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
    }


    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                updateApi();
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value)
            }
        };
    });

    return (
        <Autocomplete
            options={sspSourcingStrategyTypeEnumOptions}
            getOptionLabel={(option) => option.name}
            value={sspSourcingStrategyTypeEnumOptions?.find((item) => item.value == value) || ''}
            onChange={onChangeHandler}
            inputValue={inputValue}
            onInputChange={onInputChangeHandler}
            getOptionSelected={(option, value) => value.value === option.value}
            disableClearable
            renderInput={params => (
                <TextField
                    {...params}
                    value={value}
                    style={{ padding: '5px 0' }}
                    placeholder={'Select ' + props.colDef.headerName}
                />
            )}
        />
    );
})


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DataSourceSelectEditor);

