import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DobOfInstitutionDialog from '../DobOfInstitutionDialog';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 13,
    }
}));

const DobOfInstitutionRenderer = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [_institutionDob, _setInstitutionDob] = React.useState(props.value);

    const [openDialog, setOpenDialog] = React.useState(false);

    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    const _openDialog = () => {
        setOpenDialog(true);
    }

    const closeDialog = () => {
        setOpenDialog(false);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;


    const hadleEditClick = (e) => {
        _openDialog();
    }

    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.dateOfBirthOfInstitution = value
        props.updateEntityToServer && props.updateEntityToServer(updatedData); 
        closeDialog();

    }

    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }


    return (
        <React.Fragment>
            <div className={classes.root}>
                <div>
                    {_institutionDob && new Date(_institutionDob).toLocaleDateString()} <Tooltip title={t(`${"dmTool:editDobInstTooltip"}`)}>
                        <IconButton
                            key={'edit'}
                            onClick={hadleEditClick}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {
                !!openDialog && <DobOfInstitutionDialog
                    open={openDialog}
                    onClose={closeDialog}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                    entity={props.data}
                    DobOfInstitution={_institutionDob}
                />
            }
        </React.Fragment >
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DobOfInstitutionRenderer);