import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    addButton: {
        minWidth: 91
    }
}));


const DobOfInstitutionDialog = props => {

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    const { open, apiCallProgress } = props;
    const classes = useStyles();
    const [enitityObj, setEntityObj] = useState(props.entity);

    const [selectedDate, setSelectedDate] = useState(props.DobOfInstitution);

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(selectedDate)
    };

    const handleDateChange = (date) => {
        setSelectedDate((date !== null ? new Date(date).getTime() : null))
    }

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="institutionIdnumber-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="institutionIdnumber-dialog-title">
                        {t("dmTool:updateDobOfInstitutionDialogTitle", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <KeyboardDatePicker
                            autoOk
                            // margin="normal"
                            size='small'
                            // clearable
                            keyboard
                            inputVariant="outlined"
                            placeholder={t(`${"dmTool:enterDatePlcHldr"}`)}
                            label={t(`${"dmTool:dateInputFieldLabel"}`)}
                            format={("DD/MM/YYYY")}
                            value={selectedDate}
                            InputAdornmentProps={{
                                position: 'start',
                            }}
                            onChange={handleDateChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color='primary'
                            className={classes.addButton}
                        >
                            {t(`${"dmTool:updateBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default DobOfInstitutionDialog;