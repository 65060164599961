import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import { sspBusinessIdentificationTypeEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({

}));

const BusinessIdNumber = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { institution, onChangeBussinessId, onAddOrDeleteBussinessId } = props;
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const bussinessIdNumberOptions = Object.keys(sspBusinessIdentificationTypeEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspBusinessIdentificationTypeEnum[opt]}`)
        };
    });

    const _onChangeBussinessIdType = (e, value, index) => {
        onChangeBussinessId('key', value.value, index)
    }

    const _onChangeBusinessIdNmber = (e, index) => {
        onChangeBussinessId('value', e.target.value, index)
    }
    const _addBussinessIdRow = (e) => {
        onAddOrDeleteBussinessId({
            key: '',
            value: ''
        }, 'add')
    }


    const removeBusinessIdRow = (e, index) => {
        let filerBussinessIdNumbers = institution?.institutionIdentificationNumber;

        filerBussinessIdNumbers.splice(index, 1);
        // if (!filerBussinessIdNumbers.length) {
        //     enqueueSnackbar(t("dmTool:fieldShldHaveAtleastOneEntryErrorMsg", { type: t(`${"dmTool:businessIdNumber"}`) }), {
        //         variant: 'error',
        //         anchorOrigin: {
        //             vertical: 'bottom',
        //             horizontal: 'right',
        //         },
        //     });
        // } else {
        onAddOrDeleteBussinessId(
            filerBussinessIdNumbers,
            'delete',
        );
        enqueueSnackbar(t("dmTool:removingEntrySuccessMsg", { type: t(`${"dmTool:businessIdNumber"}`) }), {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });

        // }
    };


    const renderAddCloseIcon = (index) => {

        if (index === 0) {
            return (
                <span style={{ display: 'flex' }}>
                    <Tooltip title={t(`${"dmTool:addBusinessIdTooltip"}`)}>
                        <IconButton aria-label="Add more bussiness identification number"
                            onClick={_addBussinessIdRow}
                        >
                            <AddIcon className={classes.icons} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={t(`${"dmTool:removeBusinessIdTooltip"}`)}>
                        <IconButton
                            aria-label="settings"
                            onClick={(e) => removeBusinessIdRow(e, index)}
                        >
                            <CloseIcon className={classes.icons} />
                        </IconButton>
                    </Tooltip>
                </span>
            );
        }
        return (
            <Tooltip title={t(`${"dmTool:removeBusinessIdTooltip"}`)}>
                <IconButton
                    aria-label="settings"
                    onClick={(e) => removeBusinessIdRow(e, index)}
                >
                    <CloseIcon className={classes.icons} />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        institution.institutionIdentificationNumber.map((obj, index) => {
            return (
                <React.Fragment>
                    <Grid item lg={4} sm={4} xs={12}>
                        <Autocomplete
                            disableClearable
                            size="small"
                            fullWidth
                            className={classes.autocompleteStyle}
                            getOptionLabel={option => option.label}
                            id="business_id_type"
                            margin="normal"
                            onChange={(e, value) => _onChangeBussinessIdType(e, value, index)}
                            options={bussinessIdNumberOptions}
                            value={
                                bussinessIdNumberOptions.find(
                                    item =>
                                        item.value === obj?.key,
                                ) || {}
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={t(`${"dmTool:instIdentificationTitleInputFieldLabel"}`)}
                                    placeholder={t(`${"dmTool:enterInstIdentificationTitlePlcHldr"}`)}
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                        <TextField
                            className={classes.marginSet}
                            fullWidth
                            label={t(`${"dmTool:businessIdInputFieldLabel"}`)}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                            placeholder={t(`${"dmTool:businessIdPlcHldr"}`)}
                            onChange={(e) => _onChangeBusinessIdNmber(e, index)}
                            size='small'
                            required={false}
                            value={obj?.value}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item lg={2} sm={2} xs={12}>
                        {renderAddCloseIcon(index)}
                    </Grid>
                </React.Fragment>
            )
        })
    );
};

export default BusinessIdNumber;