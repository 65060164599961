import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import HashtagAsyncSelector from "../../HashatgDialog/HashtagAsyncSelector";
import { isEmpty } from "../../../utils";
const useStyles = makeStyles((theme) => ({}));
const DocumentHashtags = (props) => {
  const { t } = useTranslation(["translation", "eventsEnumerations", "dmTool"]);
  console.log("hashtag", props.hashtags);
  const [value, setValue] = useState(
    isEmpty(props?.hashtags)
      ? []
      : props?.hashtags.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
  );
  const { documentObj, OnChangeDocumentObjHashtag } = props;
  const classes = useStyles();
  const onChangeHashtag = (e, hashTagValue) => {
    setValue(
      hashTagValue.map((item) => {
        item.label = item.value;
        return item;
      })
    );
    console.log(value, hashTagValue, "value");
    OnChangeDocumentObjHashtag(
      hashTagValue.map((item) => {
        item.label = item.value;
        return item.value;
      })
    );
  };
  return (
    <React.Fragment>
      <HashtagAsyncSelector value={value} onChange={onChangeHashtag} />
    </React.Fragment>
  );
};
export default DocumentHashtags;
