import { isEmpty } from "../../utils";
import {
    COLUMN_DEFS,
} from "../actionsTypes";

export default (
    state = {
        columns: [
            {
                headerName: "actionsHeader",
                colId: "actions",
                cellRenderer: "ActionsRenderer",
                editable: false,
                hide: false,
                suppressToolPanel: true,
                filter: false,
                minWidth: 215,
                pinned: 'left',
                wrapText: true,
                autoHeight: true,
                cellRendererParams: {
                    checkbox: true
                }
            },
            {
                headerName: "accountNameOrVersionHistoryHeader",
                field: "Entity.accountName",
                colId: "Entity.accountName",
                cellRenderer: "VersionHistoryRenderer",
                editable: false,
                hide: false,
                suppressToolPanel: true,
                wrapText: true,
                autoHeight: true,
                pinned: 'left',
                minWidth: 530,
            },
            {
                headerName: "AdditionalDataSourceInformationHeader",
                field: "Entity.BlobEntityData",
                colId: "Entity.BlobEntityData",
                cellRenderer: "AdditionalDataSourceInformationSelectorRenderer",
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data?.Entity?.BlobEntityData?.sourceSpecificInformation?.map((item) => item.value)?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.BlobEntityData)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "sourceOfResourceInfoeHeader",
                field: "Entity.sourceIdentificationInformation",
                colId: "Entity.sourceIdentificationInformation",
                // cellEditor: "DataSourceSelectEditor",
                cellRenderer: "DataSourceSelectRenderer",
                wrapText: true,
                autoHeight: true,
                // editable: false,
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data.Entity?.sourceIdentificationInformation
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity?.sourceIdentificationInformation)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset", overflow: "auto" }
                    }

                }
            },
            {
                headerName: "mainPictureHeader",
                field: "Entity.mainPictureUrl",
                colId: "Entity.mainPictureUrl",
                cellRenderer: "MainPictureUrlRenderer",
                editable: false,
                hide: false,
                wrapText: true,
                autoHeight: true,
                minWidth: 500,
            },
            {
                headerName: "mainPictureHeaderInternal",
                field: "Entity.mainPictureInternal",
                colId: "Entity.mainPictureInternal",
                cellRenderer: "InternalMainPictureUrlRenderer",
                editable: false,
                hide: true,
                suppressToolPanel: true,
                wrapText: true,
                autoHeight: true,
                minWidth: 500,
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.mainPictureInternal)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    }
                }
            },
            {
                headerName: "accountProfileImageHeader",
                field: "Entity.t_institutionAccountProfileImage_URL",
                colId: "Entity.t_institutionAccountProfileImage_URL",
                cellRenderer: "AccountProfilePictureRenderer",
                editable: false,
                hide: false,
                suppressToolPanel: true,
                wrapText: true,
                autoHeight: true,
                minWidth: 500,
            },
            {
                headerName: "accountProfileImageHeaderInternal",
                field: "Entity.entityLogoImageInternal",
                colId: "Entity.entityLogoImageInternal",
                cellRenderer: "InternalAccountProfilePictureRenderer",
                editable: false,
                hide: true,
                suppressToolPanel: true,
                wrapText: true,
                autoHeight: true,
                minWidth: 500,
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.entityLogoImageInternal)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    }
                }
            },
            {
                headerName: "entityVideoHeader",
                field: "Entity.entityVideo",
                colId: "Entity.entityVideo",
                cellRenderer: "EntityVideoRenderer",
                editable: false,
                hide: false,
                suppressToolPanel: true,
                wrapText: true,
                autoHeight: true,
                minWidth: 500,
            },
            // {
            //     headerName: "institutionJurisdictionHeader",
            //     field: "Entity.institutionJurisdiction",
            //     colId: "Entity.institutionJurisdiction",
            //     cellEditor: "InstitutionJurisdictionSelectEditor",
            //     cellRenderer: "InstitutionJurisdictionSelectorRenderer",
            //     wrapText: true,
            //     autoHeight: true,
            //     editable: true,
            //     hide: false,
            //     suppressToolPanel: true,
            //     minWidth: 230,
            // },
            {
                headerName: "institutionDescriptionHeader",
                field: "Entity.institutionDescription",
                colId: "Entity.institutionDescription",
                cellRenderer: "InstitutionDescriptionRenderer",
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data.Entity.institutionDescription?.map((item) => item.value)?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.institutionDescription)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "dobInstitutionHeader",
                field: "Entity.dateOfBirthOfInstitution",
                colId: "Entity.dateOfBirthOfInstitution",
                cellRenderer: "DobOfInstitutionRenderer",
                minWidth: 230,
                autoHeight: true,
                wrapText: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                // cellEditor: "InstitutionDateEditor",
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.dateOfBirthOfInstitution)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }
                }
            },
            {
                headerName: "institutionAccStatusHeader",
                field: "Entity.institutionAccountStatus",
                colId: "Entity.institutionAccountStatus",
                cellEditor: "InstitutionAccountStatusSelectEditor",
                cellRenderer: "InstitutionAccountStatusSelectorRenderer",
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data.Entity.institutionAccountStatus
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.institutionAccountStatus)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "legalFormHeader",
                field: "Entity.legalForm",
                colId: "Entity.legalForm",
                cellRenderer: "LegalFormRenderer",
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data.Entity.legalForm?.value + " " + params.data.Entity.legalForm?.key
                },
            },
            {
                headerName: "institutionIdNumberHeader",
                field: "Entity.institutionIdentificationNumber",
                colId: "Entity.institutionIdentificationNumber",
                cellRenderer: "InstitutionIdentificationNumberRenderer",
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data.Entity.institutionIdentificationNumber?.map((item) => item.value + " " + item.key)?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.institutionIdentificationNumber)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "startupProductStatusHeader",
                field: "Entity.startupProductStatus",
                colId: "Entity.startupProductStatus",
                cellEditor: "StartupProductStatusSelectEditor",
                cellRenderer: "StartupProductStatusSelectorRenderer",
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data.Entity.startupProductStatus
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.startupProductStatus)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "ipHeader",
                field: "Entity.IntellectualProperties",
                colId: "Entity.IntellectualProperties",
                cellRenderer: "IntellectualPropertiesRenderer",
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data?.Entity?.IntellectualProperties?.map((item) => item?.i_t_qualitySourceDataQualityEvaluation + " " + item.type + item.status + item.i_t_startupSpiderInternalComment) +
                        params.data?.Entity?.IntellectualProperties?.map((item) => item.description?.map((desc) => desc.value)) +
                        params.data?.Entity?.IntellectualProperties?.map((item) => item.link?.map((link) => link.value)) +
                        params.data?.Entity?.IntellectualProperties?.map((item) => item.title?.map((title) => title.value))?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity?.IntellectualProperties?.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"))) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "businessTypeHeader",
                field: "Entity.businessType",
                colId: "Entity.businessType",
                cellEditor: "BusinessTypeSelectEditor",
                cellRenderer: "BusinessTypeSelectorRenderer",
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data.Entity.businessType
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.businessType)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "institutionSocialProfilesHeader",
                field: "Entity.institutionSocialProfileURL",
                colId: "Entity.institutionSocialProfileURL",
                cellRenderer: "InstitutionSocialProfileURLRenderer",
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data.Entity.institutionSocialProfileURL?.map((item) => item.value + " " + item.key)?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.institutionSocialProfileURL)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            // {
            //   headerName: "knowledgeRolesHeader",
            //   field: "Entity.r_knowledgeInRoles",
            //   colId: "Entity.r_knowledgeInRoles",
            //   cellRenderer: "KnowledgeInRolesSelectorRenderer",
            //   cellEditor: "KnowledgeInRolesSelectorEditor",
            //   wrapText: true,
            //   autoHeight: true,
            //   editable: true,
            //   minWidth: 500,
            //   cellStyle: function (params) {
            //     if (isEmpty(params.data.Entity.r_knowledgeInRoles)) {
            //       return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
            //     } else {
            //   }
            // },
            {
                headerName: "companyTypeHeader",
                field: "Entity.companyType",
                colId: "Entity.companyType",
                cellEditor: "CompanyTypeSelectEditor",
                cellRenderer: "CompanyTypeSelectorRenderer",
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data.Entity.companyType
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.companyType)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }
                }
            },
            {
                headerName: "startupCustomerStatusHeader",
                field: "Entity.startupCustomerStatus",
                colId: "Entity.startupCustomerStatus",
                cellEditor: "StartupCustomerStatusSelectEditor",
                cellRenderer: "StartupCustomerStatusSelectRenderer",
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data.Entity.startupCustomerStatus
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.startupCustomerStatus)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "institutionWebsiteHeader",
                field: "Entity.institutionWebsiteURL",
                colId: "Entity.institutionWebsiteURL",
                placeHolder: "enterInstWebsiteUrlPlcHldr",
                cellRenderer: "InstitutionWebsiteURLRenderer",
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                cellEditor: "SimpleEditor",
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data.Entity.institutionWebsiteURL
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.institutionWebsiteURL)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "emailAdressHeader",
                field: "Entity.emailAddress",
                colId: "Entity.emailAddress",
                placeHolder: "enterEmailAddressPlcHldr",
                editable: true,
                hide: false,
                suppressToolPanel: true,
                cellEditor: "SimpleEditor",
                wrapText: true,
                autoHeight: true,
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data?.Entity?.emailAddress
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.emailAddress)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "phoneNumberHeader",
                field: "Entity.phoneNumber",
                colId: "Entity.phoneNumber",
                placeHolder: "enterPhoneNumberPlcHldr",
                editable: true,
                hide: false,
                suppressToolPanel: true,
                cellEditor: "SimpleEditor",
                wrapText: true,
                autoHeight: true,
                minWidth: 230,
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.phoneNumber)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "startupCrowdFundingPlatformHeader",
                field: "Entity.startupCrowdfundingFromURL",
                colId: "Entity.startupCrowdfundingFromURL",
                cellRenderer: "StartupCrowdfundingFromURLRenderer",
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data?.Entity?.startupCrowdfundingFromURL?.map((item) => item.value + " " + item.key)?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.startupCrowdfundingFromURL)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "companyFocusSectorHeader",
                field: "Entity.companyFocusSectors",
                colId: "Entity.companyFocusSectors",
                cellRenderer: "CompanyFocusSectorsRenderer",
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data?.Entity?.companyFocusSectors?.map((item) => item.value + " " + item.key)?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.companyFocusSectors)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "startupLookingForHeader",
                field: "Entity.startupLookingForTypes",
                colId: "Entity.startupLookingForTypes",
                cellRenderer: "StartupLookingForTypesSelectorRenderer",
                cellEditor: "StartupLookingForTypesSelectorEditor",
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data?.Entity?.startupLookingForTypes?.map((item) => item)?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.startupLookingForTypes)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    }
                    else {
                        return { lineHeight: "30px" }
                    }

                }
            },
            {
                headerName: "hashTagsHeader",
                field: "Entity.hashtags",
                colId: "Entity.hashtags",
                cellRenderer: "HashtagsRenderer",
                wrapText: true,
                autoHeight: true,
                hide: false,
                suppressToolPanel: true,
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data?.Entity?.hashtags?.map((item) => item)?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.hashtags)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset", overflow: "auto" }
                    }

                }
            },
            {
                headerName: "milestonesHeader",
                field: "Entity.Events",
                colId: "Entity.Events",
                cellRenderer: "MileStoneRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    return params.data?.Entity?.Events?.map((item) => item?.i_t_qualitySourceDataQualityEvaluation + " " + item.milestoneType + " " + item.status) + " " +
                        params.data?.Entity?.Events?.map((item) => item.description?.map((desc) => desc.value)) +
                        params.data?.Entity?.Events?.map((item) => item.title?.map((title) => title.value))?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.Events?.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"))) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "keyPersonsHeader",
                field: "Entity.KeyPerson",
                colId: "Entity.KeyPerson",
                cellRenderer: "KeyPersonRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    return params.data?.Entity?.KeyPerson?.map((item) => item?.i_t_qualitySourceDataQualityEvaluation + " " + item.firstName + " " + item.fullName + " " + item.surname) + " " +
                        params.data?.Entity?.KeyPerson?.map((item) => item.Roles?.map((Roles) => Roles.i_t_qualitySourceDataQualityEvaluation + " " + Roles.roleType)) +
                        params.data?.Entity?.KeyPerson?.map((item) => item.socialProfileURL?.map((social) => social.key + " " + social.value))?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.KeyPerson?.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"))) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "addressHeader",
                field: "Entity.CrowdAccountRelevantAddress",
                colId: "Entity.CrowdAccountRelevantAddress",
                cellRenderer: "AddressRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    let address = params.data?.Entity?.CrowdAccountRelevantAddress
                    return address?.r_addressLine0_receiver + " " + address?.r_addressLine1_houseNumber + address?.r_addressLine1_streetName +
                        address?.r_addressLine2_addressSuffix + address?.r_addressName + address?.r_addressType + address?.r_address_jurisdiction + address?.r_city +
                        address?.r_nameOfProvince + address?.r_zipOrPostcode
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.CrowdAccountRelevantAddress)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "ratingsHeader",
                field: "Entity.Rating",
                colId: "Entity.Rating",
                cellRenderer: "RatingRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    let Rating = params.data?.Entity?.Rating
                    return Rating?.i_t_qualitySourceDataQualityEvaluation + " " + Rating?.i_t_startupSpiderInternalComment + Rating?.innovationDegree +
                        Rating?.technologyReadiness + Rating?.i_t_startupSpiderInternalComment
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.Rating)) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "factsheetHeader",
                field: "Entity.Documents",
                colId: "Entity.Documents",
                cellRenderer: "DocumentRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    return params.data?.Entity?.Documents?.map((item) => item.descriptionOfDocument?.map((desc) => desc.value)) +
                    params.data?.Entity?.Documents?.map((item) => item.referenceNote?.map((desc) => desc.value)) +
                        params.data?.Entity?.Documents?.map((item) => item.titleOfDocument?.map((title) => title.value))?.join(" ")
                },
                cellStyle: function (params) {
                    if (isEmpty(params.data.Entity.Documents?.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"))) {
                        return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                    } else {
                        return { lineHeight: "unset" }
                    }

                }
            },
            {
                headerName: "institutionTypeHeader",
                field: "Entity.Supplier.institutionType",
                colId: "Entity.Supplier.institutionType",
                cellRenderer: "InstitutionTypesSelectorRenderer",
                // cellEditor: "InstitutionTypeSelectorEditor",
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data?.Entity?.Supplier?.institutionType?.map((item) => item)?.join(" ")
                },
                cellStyle: function (params) {
                    if (params.data.Entity.Supplier || params.data.Entity.name == "StSpRecordedInstitution") {
                        if (isEmpty(params.data.Entity.Supplier?.institutionType)) {
                            return { backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc" };
                        } else {
                            return { lineHeight: "30px" }
                        }
                    } else {
                        return { display: "none" };
                    }
                },
            },
            {
                headerName: "serviceStatus",
                field: "Entity.Supplier.serviceStatus",
                colId: "Entity.Supplier.serviceStatus",
                cellEditor: "ServiceStatusSelectEditor",
                cellRenderer: "ServiceStatusSelectorRenderer",
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                minWidth: 230,
                filterValueGetter: params => {
                    return params.data.Entity.Supplier.serviceStatus
                },
                cellStyle: function (params) {
                    if (params.data.Entity.Supplier || params.data.Entity.name == "StSpRecordedInstitution") {
                        if (isEmpty(params.data.Entity.Supplier?.serviceStatus)) {
                            return {
                                backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc"
                            };
                        } else {
                            return { lineHeight: "30px" }
                        }
                    } else {
                        return { display: "none" };
                    }
                },
            },
            {
                headerName: "supplierTypeOfferingsHeader",
                field: "Entity.Supplier.serviceOffering",
                colId: "Entity.Supplier.serviceOffering",
                cellRenderer: "ServiceZonesSelectorRenderer",
                cellEditor: "ServiceZonesSelectorEditor",
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                minWidth: 500,
                filterValueGetter: params => {
                    return params.data?.Entity?.Supplier?.serviceOffering?.map((item) => item)?.join(" ")
                },
                cellStyle: function (params) {
                    if (params.data.Entity.Supplier || params.data.Entity.name == "StSpRecordedInstitution") {
                        if (isEmpty(params.data.Entity.Supplier?.serviceOffering)) {
                            return {
                                backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc"
                            };
                        } else {
                            return { lineHeight: "30px" }
                        }
                    } else {
                        return { display: "none" };
                    }
                },
                hide: false,
                suppressToolPanel: true,
            },
            {
                headerName: "supplierOtherServicesHeader",
                field: "Entity.Supplier.otherServiceOffing",
                colId: "Entity.Supplier.otherServiceOffing",
                cellRenderer: "OtherServiceOffingRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    return params.data?.Entity?.Supplier?.otherServiceOffing?.map((item) => item.value)?.join(" ")
                },
                cellStyle: function (params) {
                    if (params.data.Entity.Supplier || params.data.Entity.name == "StSpRecordedInstitution") {
                        if (isEmpty(params.data.Entity.Supplier?.otherServiceOffing)) {
                            return {
                                backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc"
                            };
                        } else {
                            return { lineHeight: "unset" }
                        }
                    } else {
                        return { display: "none" };
                    }
                }
            },
            {
                headerName: "supplierDescriptionHeader",
                field: "Entity.Supplier.serviceDescription",
                colId: "Entity.Supplier.serviceDescription",
                cellRenderer: "ServiceDescriptionRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    return params.data?.Entity?.Supplier?.serviceDescription?.map((item) => item.value)?.join(" ")
                },
                cellStyle: function (params) {
                    if (params.data.Entity.Supplier || params.data.Entity.name == "StSpRecordedInstitution") {
                        if (isEmpty(params.data.Entity.Supplier?.serviceDescription)) {
                            return {
                                backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc"
                            };
                        } else {
                            return { lineHeight: "unset" }
                        }
                    } else {
                        return { display: "none" };
                    }
                }
            },
            {
                headerName: "supplierAudienceHeader",
                field: "Entity.Supplier.audienceStartupPhase",
                colId: "Entity.Supplier.audienceStartupPhase",
                cellRenderer: "AudienceStartUpPhaseRenderer",
                cellEditor: "AudienceStartUpPhaseEditor",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: true,
                hide: false,
                suppressToolPanel: true,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    return params.data?.Entity?.Supplier?.audienceStartupPhase?.map((item) => item)?.join(" ")
                },
                cellStyle: function (params) {
                    if (params.data.Entity.Supplier || params.data.Entity.name == "StSpRecordedInstitution") {
                        if (isEmpty(params.data.Entity.Supplier?.audienceStartupPhase)) {
                            return {
                                backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc"
                            };
                        } else {
                            return { lineHeight: "unset" }
                        }
                    } else {
                        return { display: "none" };
                    }
                }
            },
            {
                headerName: "supplierSericeFocusHeader",
                field: "Entity.Supplier.serviceFocusSectors",
                colId: "Entity.Supplier.serviceFocusSectors",
                cellRenderer: "ServiceFocusSectorsRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    return params.data?.Entity?.Supplier?.serviceFocusSectors?.map((item) => item.key + " " + item.value)?.join(" ")
                },
                cellStyle: function (params) {
                    if (params.data.Entity.Supplier || params.data.Entity.name == "StSpRecordedInstitution") {
                        if (isEmpty(params.data.Entity.Supplier?.serviceFocusSectors)) {
                            return {
                                backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc"
                            };
                        } else {
                            return { lineHeight: "30px" }
                        }
                    } else {
                        return { display: "none" };
                    }
                }
            },
            {
                headerName: "supplierSectorOtherHeader",
                field: "Entity.Supplier.otherServiceSector",
                colId: "Entity.Supplier.otherServiceSector",
                cellRenderer: "OtherServiceSectorRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    return params.data?.Entity?.Supplier?.otherServiceSector?.map((item) => item.value)?.join(" ")
                },
                cellStyle: function (params) {
                    if (params.data.Entity.Supplier || params.data.Entity.name == "StSpRecordedInstitution") {
                        if (isEmpty(params.data.Entity.Supplier?.otherServiceSector)) {
                            return {
                                backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc"
                            };
                        } else {
                            return { lineHeight: "unset" }
                        }
                    } else {
                        return { display: "none" };
                    }
                }
            },
            {
                headerName: "supplierTargetCustomerAndServiceZoneHeader",
                field: "Entity.Supplier",
                colId: "Entity.Supplier",
                cellRenderer: "ServiceZonesRenderer",
                minWidth: 500,
                wrapText: true,
                autoHeight: true,
                editable: false,
                hide: false,
                suppressToolPanel: true,
                filterValueGetter: params => {
                    return params.data?.Entity?.Supplier?.ServiceZones?.map((item) => item.blockedcountries?.map((blockedCountry) => blockedCountry)) +
                        params.data?.Entity?.Supplier?.ServiceZones?.map((item) => item.countries?.map((country) => country.value)) +
                        params.data?.Entity?.Supplier?.ServiceZones?.map((item) => item.geographies?.map((geography) => geography)) +
                        params.data?.Entity?.Supplier?.ServiceZones?.map((item) => item.serviceAudienceType)?.join(" ")
                },
                cellStyle: function (params) {
                    if (params.data.Entity.Supplier || params.data.Entity.name == "StSpRecordedInstitution") {
                        if (isEmpty(params.data.Entity.Supplier?.ServiceZones)) {
                            return {
                                backgroundColor: '#FFB4002E ', borderLeft: "1px solid #ccc"
                            };
                        } else {
                            return { lineHeight: "unset" }
                        }
                    } else {
                        return { display: "none" };
                    }
                }
            },
        ],
        defaultColDef: {
            resizable: true,
            filter: true,
            floatingFilter: true,
            wrapText: true,
            autoHeight: true,
        }
    },
    action
) => {
    switch (action.type) {
        case COLUMN_DEFS:
            return {
                ...state,
                columns: [
                    ...state.columns.map((item, index) => {
                        if (action.payload.colId !== "Entity.accountName" && item.colId == action.payload.colId) {
                            item.hide = !item.hide
                        }
                        return item
                    })]
            }
            break;
        default:
            return { ...state }
    }
}