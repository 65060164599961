import React, { useState, useEffect } from 'react';
import Link from '@material-ui/core/Link';

export default (props) => {
    if(props.value){
        return (
            <Link href={`${props.value}`}>
                {props.value}
            </Link >
        )
    }else{
        return "";
    }
}