import React from 'react';
import { useTheme, fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from "@material-ui/core";
import CompanyFocusSectorsDialog from '../CompanyFocusSectorsDialog';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
    root: {
        // width: 221,
        fontSize: 13,
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    tag: {
        marginTop: 3,
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    listContainer: {
        overflow: 'auto',
        maxHeight: 215,
    },
    listItem: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    },
    flex: {
        display: 'flex',
        gap: 10

    }

}));



const CompanyFocusSectorsRenderer = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [_companyFocusSectors, _setCompanyFocusSectors] = React.useState(props.value);

    const theme = useTheme();

    const [companyFocusSectorDialogOpen, setCompanyFoucsSectorOpen] = React.useState(false);

    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const openDialog = () => {
        setCompanyFoucsSectorOpen(true);
    }

    const closeDialog = () => {
        setCompanyFoucsSectorOpen(false);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    const hadleEditClick = (e) => {
        openDialog();
    }

    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.companyFocusSectors = [...value]
        props.updateEntityToServer && props.updateEntityToServer(updatedData); 
        closeDialog();

    }

    const hadleDeleteClick = (e, value, index) => {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.companyFocusSectors.splice(index, 1)
        props.updateEntityToServer && props.updateEntityToServer(updatedData); 
    }

    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);


    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.topbuttons}>
                    {!_companyFocusSectors.length && <Tooltip title={t(`${"dmTool:addCompanySectorFocusTooltip"}`)}>
                        <IconButton aria-label="add"
                            color="secondary"
                            variant="contained"
                            onClick={openDialog}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>}
                    {!!_companyFocusSectors.length && <Tooltip title={t(`${"dmTool:editCompanySectorFocusTooltip"}`)}>
                        <IconButton
                            key={'edit'}
                            onClick={hadleEditClick}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>}
                </div>
                <List
                    className={classes.listContainer}
                >
                    {_companyFocusSectors?.map((companyFocusSector, index) => (
                        <React.Fragment key={"fragment" + index}>
                            <ListItem
                                key={"companyFocusSector" + index}
                                ContainerComponent="li"
                                className={classes.listItem}
                            >
                                <ListItemText
                                    className={classes.textOverFlowSet}
                                    primary={
                                        <>
                                            {t(`${"configurationEnumerations:" + companyFocusSector.key}`)}
                                        </>
                                    }
                                    secondary={<>
                                        {t(`${"configurationEnumerations:" + companyFocusSector.value}`)}
                                    </>}
                                />
                                <ListItemSecondaryAction className={classes.actionPaddingSet}>
                                    <IconButton
                                        key={'delete'}
                                        onClick={(e) => hadleDeleteClick(e, companyFocusSector, index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </div>
            {
                !!companyFocusSectorDialogOpen && <CompanyFocusSectorsDialog
                    open={companyFocusSectorDialogOpen}
                    onClose={closeDialog}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                    entity={props.data}
                    CompanyFocusSectors={_companyFocusSectors}
                />
            }
        </React.Fragment >
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyFocusSectorsRenderer);