import React from "react";


import Rating from "@material-ui/lab/Rating";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../../utils";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    deletebutton:{
        marginBottom:"11px"
    }
}));
const RatingInnovationDegree = (props) => {
  const { t } = useTranslation([
    "translation",
    "configurationEnumerations",
    "dmTool",
  ]);
  const { ratingObj, OnChageRatingObj } = props;
  const OnChangeRatingInnovationDegree = (e, value) => {
    OnChageRatingObj("innovationDegree", parseInt(e.target.value));
  };
  const [hoverInnovationDegree, setHoverInnovationDegree] = React.useState(
    ratingObj?.innovationDegree !== 0 ? ratingObj?.innovationDegree : null
  );
  const handleDelete = () => {
    return (
      setHoverInnovationDegree(null), OnChageRatingObj("innovationDegree", 0)
    );
  };

  const labels = {
    1: t(`${"dmTool:IL1"}`),
    2: t(`${"dmTool:IL2"}`),
    3: t(`${"dmTool:IL3"}`),
    4: t(`${"dmTool:IL4"}`),
    5: t(`${"dmTool:IL5"}`),
  };

  const classes = useStyles();

  return (
    <div style={{ marginBottom: "10px" }}>
      {t(`${"dmTool:innovationRatingHdng"}`)}
      <div>
        <Rating
          name="unique-rating1"
          value={Number(ratingObj?.innovationDegree)}
          precision={1}
          max={5}
          onChange={OnChangeRatingInnovationDegree}
          onChangeActive={(event, newHover) => {
            if (newHover === -1 && ratingObj?.innovationDegree == 0) {
              setHoverInnovationDegree(null);
            } else {
              setHoverInnovationDegree(newHover);
            }
          }}
        />
        <IconButton className={classes.deletebutton} onClick={() => handleDelete()}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
        <div>
          {!isEmpty(ratingObj?.innovationDegree) &&
          !isEmpty(hoverInnovationDegree)
            ? labels[
                !isEmpty(hoverInnovationDegree) && hoverInnovationDegree !== -1
                  ? hoverInnovationDegree
                  : Number(ratingObj?.innovationDegree)
              ]
            : t(`${"dmTool:notRatedYetMsg"}`)}
        </div>
      </div>
    </div>
  );
};

export default RatingInnovationDegree;
