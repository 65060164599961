import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import MilestoneDialog from '../MilestoneDialog';
import KeyPersonDialog from '../KeyPersonDialog';
import SupplierDialog from '../SupplierDialog';
import AddIcon from '@material-ui/icons/Add';
import AddressDialog from '../AddressDialog';
import { useTranslation } from 'react-i18next';
import DocumentsDialog from '../DocumentsDialog';
import ServiceZonesDialog from '../ServiceZonesDialog';
import { toast } from "react-toastify";
import LoadFormDevDialog from '../LoadFromDevDialog';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import VersionGrapgh from "./Components/VersionGrapgh";
import LoadFormProdDialog from '../LoadFromProdDialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const ActionsRenderer = (props) => {

    const [open, setOpen] = useState(false);
    const [keyPersonDialog, setKeyPersonDialog] = useState(false);
    const [openSupplierDialog, setSupplierDialog] = useState(false);
    const [addressDialog, setAddresDialog] = useState(false)
    const [openFactSheetDialog, setOpenFactSheetDialog] = useState(false)
    const [openSupplierZoneDialog, setOpenSupplierZoneDialog] = useState(false)
    const [openLoadFromDevDialog, setOpenLoadFromDevDialog] = useState(false)
    const [openLoadFromProdDialog, setOpenLoadFromProdDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [addMenuOpen, setAddMenuOpen] = useState(false)
    const [loadMenuOpen, setLoadMenuOpen] = useState(false)
    const addAnchorRef = React.useRef(null);
    const loadAnchorRef = React.useRef(null);
    const [addOptionsOpen, setAddOptionsOpen] = useState(false)
    const [loadOptionsOpen, setLoadOptionsOpen] = useState(false)


    const [entity, setEntity] = useState({});
    const [apiCallProgress, setApiCallProgress] = React.useState(false);
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    useEffect(() => {
        setEntity(props.data)
    }, [])

    const openDialog = () => {
        setOpen(true);
    }
    const closeDialog = () => {
        setOpen(false);
    }
    const OpenKeyPersonDialog = () => {
        setKeyPersonDialog(true);
    }
    const CloseKeyPersonDialogOpen = () => {
        setKeyPersonDialog(false);
    }
    const _closeSupplierDialog = () => {
        setSupplierDialog(false)
    }
    const _openAddressDialog = () => {
        setAddresDialog(true)
    }
    const _closeAddressDialog = () => {
        setAddresDialog(false)
    }
    const _openFactSheetDialog = () => {
        setOpenFactSheetDialog(true)
    }
    const _closeFactsheetsDialog = () => {
        setOpenFactSheetDialog(false)
    }
    const _openSupplierZoneDialog = () => {
        setOpenSupplierZoneDialog(true)
    }
    const _closeSupplierZoneDialog = () => {
        setOpenSupplierZoneDialog(false)
    }
    const _openOnLoadFromDev = () => {
        setOpenLoadFromDevDialog(true)
    }
    const _closeOnLoadFromDev = () => {
        setOpenLoadFromDevDialog(false)
    }
    const _openOnLoadFromProd = () => {
        setOpenLoadFromProdDialog(true)
    }
    const _closeOnLoadFromProd = () => {
        setOpenLoadFromProdDialog(false)
    }




    const onClickAddOrUpdateMileStone = (value) => {
        let updatedData = props.data;
        let finIndex = updatedData.Entity.Events.findIndex((item) => item.i_sourceKey === value.i_sourceKey)
        if (finIndex != -1) {
            updatedData.Entity.Events[finIndex] = value;
        } else {
            updatedData.Entity.Events.push(value)
        }
        props.updateEntityToServer && props.updateEntityToServer(updatedData);;

        setTimeout(() => {
            closeDialog();
        }, 1000);
    }
    const onClickAddOrUpdateKeyPerson = (value) => {
        let updatedData = props.data;
        let finIndex = updatedData.Entity.KeyPerson.findIndex((item) => item.i_sourceKey === value.i_sourceKey)
        if (finIndex != -1) {
            updatedData.Entity.KeyPerson[finIndex] = value;
        } else {
            updatedData.Entity.KeyPerson.push(value)
        }
        props.updateEntityToServer && props.updateEntityToServer(updatedData);;
        setTimeout(() => {
            closeDialog();
        }, 1000);
        setTimeout(() => {
            closeDialog();
        }, 1000);
    }

    const onClickAddOrUpdateAddress = (value) => {
        let updatedData = props.data
        updatedData.Entity.CrowdAccountRelevantAddress = value

        props.updateEntityToServer && props.updateEntityToServer(updatedData);;
        setTimeout(() => {
            closeDialog();
        }, 1000);
    }

    const onClickAddOrUpdateFactsheet = (value) => {
        let updatedData = props.data
        let finIndex = updatedData.Entity.Documents.findIndex((item) => item.i_sourceKey === value.i_sourceKey)
        if (finIndex != -1) {
            updatedData.Entity.Documents[finIndex] = value;
        } else {
            updatedData.Entity.Documents.push(value)
        }

        props.updateEntityToServer && props.updateEntityToServer(updatedData);;
        setTimeout(() => {
            closeDialog();
        }, 1000);
    }
    const onClickAddOrUpdateServiceZone = (serviceZones, serviceAudienceType) => {
        let updatedData = props.data
        updatedData.Entity.Supplier.serviceAudienceType = [...serviceAudienceType]
        updatedData.Entity.Supplier.ServiceZones = [...serviceZones]
        props.updateEntityToServer && props.updateEntityToServer(updatedData);;
        setTimeout(() => {
            closeDialog();
        }, 1000);
    }

    let ver = entity?.UploaderMetaData?.filter((item) => item.active && item.environment == "DEVELOPMENT")



    const handleToggleAddOptions = () => {
        setAddOptionsOpen((prevOpen) => !prevOpen);
    };
    const handleCloseAddOptions = (event) => {
        if (addAnchorRef.current && addAnchorRef.current.contains(event.target)) {
            return;
        }

        setAddOptionsOpen(false);
    };
    const handleToggleLoadOptions = () => {
        setLoadOptionsOpen((prevOpen) => !prevOpen);
    };
    const handleCloseLoadOptions = (event) => {
        if (loadAnchorRef.current && loadAnchorRef.current.contains(event.target)) {
            return;
        }

        setLoadOptionsOpen(false);
    };
    return (
        <React.Fragment>
            <React.Fragment>

                <ButtonGroup size="small" variant="contained" color="primary" ref={addAnchorRef} aria-label="split button">
                    <Button > <AddIcon />{t(`${"dmTool:add"}`)}</Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggleAddOptions}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={addOptionsOpen} anchorEl={addAnchorRef.current} role={undefined} transition disablePortal style={{ zIndex: "1" }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseAddOptions}>
                                    <MenuList id="split-button-menu">
                                        <MenuItem onClick={OpenKeyPersonDialog}><AddIcon />{t(`${"dmTool:addKeyPersonBtnHdng"}`)}</MenuItem>
                                        <MenuItem onClick={openDialog}><AddIcon />{t(`${"dmTool:addMilestoneBtnHdng"}`)}</MenuItem>
                                        <MenuItem onClick={_openAddressDialog}><AddIcon />{t(`${"dmTool:addAddressBtnHdng"}`)}</MenuItem>
                                        <MenuItem onClick={_openFactSheetDialog}><AddIcon />{t(`${"dmTool:addFactsheetBtnHdng"}`)}</MenuItem>
                                        {(entity?.Entity?.Supplier || entity?.Entity?.name == "StSpRecordedInstitution") && <MenuItem onClick={_openSupplierZoneDialog}>
                                            <AddIcon />{t(`${"dmTool:addSupplierZoneBtnHdng"}`)}</MenuItem>}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                <ButtonGroup size="small" variant="contained" color="primary" ref={loadAnchorRef} aria-label="split button">
                    <Button> <HourglassEmptyIcon />{t(`${"dmTool:load"}`)}</Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={loadOptionsOpen ? 'split-button-menu' : undefined}
                        aria-expanded={loadOptionsOpen ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggleLoadOptions}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={loadOptionsOpen} anchorEl={loadAnchorRef.current} role={undefined} transition disablePortal style={{ zIndex: "1" }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseLoadOptions}>
                                    <MenuList id="split-button-menu">
                                        <MenuItem onClick={_openOnLoadFromDev}><HourglassEmptyIcon />{t(`${"dmTool:loadFromDevBtnHdng"}`)}</MenuItem>
                                        <MenuItem onClick={_openOnLoadFromProd}><HourglassEmptyIcon />{t(`${"dmTool:loadFromProdBtnHdng"}`)}</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </React.Fragment>

            {!!open && <MilestoneDialog
                open={open}
                onClose={closeDialog}
                entity={entity}
                onClickAddOrUpdate={onClickAddOrUpdateMileStone}
            />}
            {!!keyPersonDialog && <KeyPersonDialog
                open={keyPersonDialog}
                onClose={CloseKeyPersonDialogOpen}
                entity={entity}
                onClickAddOrUpdate={onClickAddOrUpdateKeyPerson}
            />}
            {!!openSupplierDialog && <SupplierDialog
                open={openSupplierDialog}
                onClose={_closeSupplierDialog}
                entity={entity}
            />}
            {
                !!addressDialog && <AddressDialog
                    open={addressDialog}
                    onClose={_closeAddressDialog}
                    entity={entity}
                    {...props}
                    Address={entity?.Entity?.CrowdAccountRelevantAddress}
                    onClickAddOrUpdate={onClickAddOrUpdateAddress}
                />
            }
            {
                !!openFactSheetDialog && <DocumentsDialog
                    open={openFactSheetDialog}
                    onClose={_closeFactsheetsDialog}
                    entity={entity}
                    document={{}}
                    {...props}
                    onClickAddOrUpdate={onClickAddOrUpdateFactsheet}
                    apiCallProgress={apiCallProgress}
                />
            }
            {
                !!openSupplierZoneDialog && <ServiceZonesDialog
                    open={openSupplierZoneDialog}
                    onClose={_closeSupplierZoneDialog}
                    entity={entity}
                    {...props}
                    onClickAddOrUpdate={onClickAddOrUpdateServiceZone}
                    apiCallProgress={apiCallProgress}
                    serviceZones={props.data.Entity?.Supplier?.ServiceZones}
                    serviceAudienceType={props.data.Entity?.Supplier?.serviceAudienceType}
                />
            }
            {
                !!openLoadFromDevDialog && <LoadFormDevDialog
                    open={openLoadFromDevDialog}
                    onClose={_closeOnLoadFromDev}
                    entity={entity}
                    {...props}
                    apiCallProgress={apiCallProgress}
                    content={ver}
                />
            }
            {
                !!openLoadFromProdDialog && <LoadFormProdDialog
                    open={openLoadFromProdDialog}
                    onClose={_closeOnLoadFromProd}
                    entity={entity}
                    {...props}
                    apiCallProgress={apiCallProgress}
                    content={ver}
                />
            }
            {/* <VersionGrapgh/> */}
        </React.Fragment >
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActionsRenderer);