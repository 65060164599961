/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { countries } from '../../Countrieslist';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export default function CountrySelect(props) {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { institution, onChageInstitution } = props;
    const classes = useStyles();

    const onPlaceOfResidenceChange = (e, value) => {
        if (value) {
            onChageInstitution('institutionJurisdiction', value.code)
        } else {
            onChageInstitution('institutionJurisdiction', null)
        }
    }
    return (
        <Autocomplete
            id="country-select-demo"
            size='small'
            style={{ width: '100%' }}
            options={countries}
            classes={{
                option: classes.option,
            }}
            value={countries.find((item) => item.code === institution.institutionJurisdiction) || {}}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={onPlaceOfResidenceChange}
            renderOption={(option) => (
                <React.Fragment>
                    <span><img className="country-flag" src={`./flags/${option.code?.toLowerCase()}.png`} />
                    </span>
                    {option.label} ({option.code}) +{option.phone}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={`${t(`${"dmTool:countryInputFieldLabel"}`)}*`}
                    placeholder={t(`${"dmTool:countryofCompanyPlcHldr"}`)}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        startAdornment: institution.institutionJurisdiction && institution.institutionJurisdiction !== '' && (
                            <React.Fragment>
                                <span><img className="country-flag" src={`./flags/${institution.institutionJurisdiction?.toLowerCase()}.png`} />
                                </span> {' '}
                                {params.InputProps.startAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}


