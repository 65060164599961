
import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { logger, crashReporter, timeoutScheduler } from "./middlewares";


import Entity from "./Entity/reducer";
import ColumnsDefs from "./ColumnDefs/reducer"
import User from "./User/reducer"


const appReducer = combineReducers({
    Entity,
    ColumnsDefs,
    User
});
const rootReducer = (state, action) => {
    if (action.type === "LOG_OUT") {
        return (state = {
            Entity: {},
            ColumnsDefs: {},
            User: {}
        });
    }
    return appReducer(state, action);
};
export const store = createStore(
    rootReducer,
    process.env.NODE_ENV === "development"
        ? compose(
            applyMiddleware(thunk, logger, crashReporter, timeoutScheduler),
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
        : applyMiddleware(thunk, logger, crashReporter, timeoutScheduler)
);
