import React, { useState, forwardRef, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sspCompanyTypeEnum } from "../../utils/enums";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";

const CompanyTypeSelectEditor = forwardRef((props, ref) => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);


    let sspCompanyTypeEnumOptions = Object.keys(sspCompanyTypeEnum)
        .map((item) => {
            return {
                name: t(`${"configurationEnumerations:" + sspCompanyTypeEnum[item]}`),
                value: item
            }
        })
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState(props.value);

    function onChangeHandler(e, value) {
        setValue(value.value);
    }

    function onInputChangeHandler(e, inputValue) {
        setInputValue(inputValue);
    }


    function updateApi() {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity['companyType'] = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData);


    }


    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                updateApi();
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value)
            }
        };
    });

    return (
        <Autocomplete
            options={sspCompanyTypeEnumOptions}
            getOptionLabel={(option) => option.name}
            value={sspCompanyTypeEnumOptions?.find((item) => item.value == value) || ''}
            onChange={onChangeHandler}
            inputValue={inputValue}
            onInputChange={onInputChangeHandler}
            getOptionSelected={(option, value) => value.value === option.value}
            disableClearable
            renderInput={params => (
                <TextField
                    {...params}
                    value={value}
                    style={{ padding: '5px 0' }}
                    placeholder={t(`${"dmTool:selectCompanyTypePlcHldr"}`)}
                />
            )}
        />
    );
})

const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CompanyTypeSelectEditor);
