/* eslint-disable no-use-before-define */
import React from 'react';
import { useTheme, fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from "@material-ui/core";
import DataSourceInfoDialog from '../DataSourceInfoDialog';
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import { useTranslation } from 'react-i18next';
import { sspOrganizationContactTypeEnum } from '../../utils/enums';
import { isEmpty } from '../../utils';
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
const IN_CELL_SEPARATOR = '<br/>';
const IN_CELL_SPACE = ' ';
const IN_CELL_RECORD_SEPARATOR = '<br/>';
const useStyles = makeStyles((theme) => ({
    root: {
        // width: 221,
        fontSize: 13,
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    tag: {
        marginTop: 3,
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    milestones: {
        overflow: 'auto',
        maxHeight: 215,
    },
    milestone: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
    }

}));



const AdditionalDataSourceInformationSelectorRenderer = (props) => {
    const classes = useStyles();
    const [dataSourceInfo, setDataSourceInfo] = React.useState(props.value?.sourceSpecificInformation)
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool",
        "entityDataEnumerations"
    ]);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [_dataSourceInfo, _setDataSourceInfo] = React.useState({});
    const [_editDataSourceInfo, _setEditDataSourceInfo] = React.useState(false);
    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const openDialog = () => {
        setDialogOpen(true);
    }

    const closeDialog = () => {
        setDialogOpen(false);
        // _setDataSourceInfo({});
        // _setEditDataSourceInfo(false);
        // setDataSourceInfo(props.value.sourceSpecificInformation)
    }

    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        if (isEmpty(updatedData.Entity?.BlobEntityData?.i_sourceKey)) {
            updatedData.Entity.BlobEntityData = {
                ...updatedData.Entity.BlobEntityData,
                "i_sourceKey": "ssp_blobentitydata_" + Date.now() + "_" + parseInt(Math.random() * 100000)
            }
        }

        updatedData.Entity.BlobEntityData = {
            ...updatedData.Entity.BlobEntityData,
            sourceSpecificInformation: value
        }
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
        closeDialog();

    }

    const hadleDeleteClick = (e, value) => {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.BlobEntityData.sourceSpecificInformation = [{
            "key": "editorUserName",
            "value": null
        },
        {
            "key": "processingUserId",
            "value": null
        },
        {
            "key": "version",
            "value": null
        },
        {
            "key": "comment",
            "value": ""
        },
        {
            "key": "searchTrigger",
            "value": ""
        },
        {
            "key": "organizationContactStatus",
            "value": ""
        },
        {
            "key": "organizationContactStatusComment",
            "value": ""
        },
        {
            "key": "thoughts",
            "value": ""
        }]

        props.updateEntityToServer && props.updateEntityToServer(updatedData);


    }

    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }


    const renderOtherInfi = (dataSourceInfo) => {
        return <React.Fragment>
            {!isEmpty(dataSourceInfo?.filter(item => item.key == "comment")[0]?.value) && <p><b>{t(`${"dmTool:commentHdng"}`)}:</b> <br />{dataSourceInfo?.filter(item => item.key == "comment")[0]?.value}</p>}
            {!isEmpty(dataSourceInfo?.filter(item => item.key == "searchTrigger")[0]?.value) && <p><b>{t(`${"dmTool:searchTriggerHdng"}`)}:</b> <br />{dataSourceInfo?.filter(item => item.key == "searchTrigger")[0]?.value}</p>}
            {!isEmpty(dataSourceInfo?.filter(item => item.key == "organizationContactStatusComment")[0]?.value) && <p><b>{t(`${"dmTool:organizationContactCommentHdng"}`)} :</b> <br />{dataSourceInfo?.filter(item => item.key == "organizationContactStatusComment")[0]?.value}</p>}
            {!isEmpty(dataSourceInfo?.filter(item => item.key == "thoughts")[0]?.value) && <p><b>{t(`${"dmTool:thoughtsHdng"}`)} :</b> <br />{dataSourceInfo?.filter(item => item.key == "thoughts")[0]?.value}</p>}
        </React.Fragment>
    }


    const renderStatus = (dataSourceInfo) => {
        dataSourceInfo = dataSourceInfo?.filter(item => item.key == "organizationContactStatus");
        if (dataSourceInfo.length == 0) {
            return "";
        } else {
            dataSourceInfo = dataSourceInfo[0]
        }
        return <React.Fragment>
            {!isEmpty(dataSourceInfo.value) && <Chip
                component={"span"}
                size="small"
                key={"status" + dataSourceInfo.value}
                label={t(`${"entityDataEnumerations:" + sspOrganizationContactTypeEnum[dataSourceInfo.value]}`)}
                style={toMaterialStyle(
                    dataSourceInfo.value?.toLowerCase(),
                    500,
                )}
            />} </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                {
                    (isEmpty(dataSourceInfo)) ?
                        <div className={classes.topbuttons}>
                            <Tooltip title={t(`${"dmTool:addDataSourceInfoTooltip"}`)}>
                                <IconButton aria-label="delete"
                                    color="secondary"
                                    variant="contained"
                                    onClick={openDialog}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        : <div className={classes.topbuttons}>
                            <Tooltip title={t(`${"dmTool:editDataSourceInfoTooltip"}`)}>
                                <IconButton aria-label="delete"
                                    color="secondary"
                                    variant="contained"
                                    onClick={openDialog}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                }
                <List
                    className={classes.milestones}
                >
                    {!!dataSourceInfo &&
                        <React.Fragment >
                            <ListItem
                                key={"dataSourceInfo"}
                                ContainerComponent="li"
                                className={classes.milestone}
                            >
                                <ListItemText
                                    className={classes.textOverFlowSet}
                                    primary={
                                        <React.Fragment>
                                            {renderStatus(dataSourceInfo)}
                                        </React.Fragment>
                                    }
                                    secondary={<React.Fragment>
                                        {renderOtherInfi(dataSourceInfo)}
                                    </React.Fragment>}
                                />
                                {/* <ListItemSecondaryAction className={classes.actionPaddingSet}>
                                    <IconButton
                                        key={'delete'}
                                        onClick={(e) => hadleDeleteClick(e, dataSourceInfo)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction> */}
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    }
                </List>
            </div>
            {
                !!dialogOpen && <DataSourceInfoDialog
                    open={dialogOpen}
                    onClose={closeDialog}
                    blobDataEntityt={dataSourceInfo}
                    entity={props.data}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                />
            }
        </React.Fragment >
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDataSourceInformationSelectorRenderer);