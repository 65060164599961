import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    addButton: {
        minWidth: 91
    }
}));

const DeletePermanentlyDialog = props => {

    const { onClose, open, _deleteInactiveLocalVersion, entityName } = props
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    const closeModal = () => {
        onClose && onClose();
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={closeModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{t("dmTool:permanentlyDeleteFromDmTollDialgHdng")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t("dmTool:permanentlyDeleteFromDmTollDialgContent")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeModal} color="primary" variant="contained">
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button onClick={_deleteInactiveLocalVersion} color="primary" variant="contained">
                            {t(`${"dmTool:yesBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default DeletePermanentlyDialog;