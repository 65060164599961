import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useSnackbar } from "notistack";
import { sspLanguageEnum } from "../../../utils/enums";
import { useTranslation } from "react-i18next";
import UILanguageSelector from "../../Languages";
import languages from "../../Languages/languages";

const useStyles = makeStyles((theme) => ({}));

const DocumentTitle = (props) => {
  const { t } = useTranslation(["translation", "eventsEnumerations", "dmTool"]);
  const {
    documentObj,
    OnChangeDocumentObjTitle,
    OnAddOrDeleteDocumentObjTitle,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [languageOptions, setLanguageOptions] = useState([]);

  useEffect(() => {
    let keys = props.documentObj.titleOfDocument
      .filter((item) => item.key && item.key !== "")
      .map((item) => item.key);
    if (!!props.documentObj.titleOfDocument.length && !!keys.length) {
      let langs = [];
      let filterLang = sspLanguageEnum.map((lang) => {
        if (!keys?.includes(lang.value)) {
          langs.push(lang);
        }
      });
      setLanguageOptions(langs);
    } else {
      setLanguageOptions(sspLanguageEnum);
    }
  }, [props.documentObj.titleOfDocument]);

  const OnChangeTitleKeyChange = (e, value, index) => {
    OnChangeDocumentObjTitle("key", value.value, index);
  };

  const OnChangeTitleValueChange = (e, index) => {
    OnChangeDocumentObjTitle("value", e.target.value, index);
  };
  const addDocumentTitle = (e) => {
    let keys = props.documentObj.titleOfDocument
      .filter((item) => item.key && item.key !== "")
      .map((item) => item.key);
    if (!(keys.length === Object.keys(languages).length)) {
      if (!keys.length) {
        enqueueSnackbar(t(`${"dmTool:selectLangToAddNewErrorMsg"}`), {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        OnAddOrDeleteDocumentObjTitle(
          {
            key: "",
            value: "",
          },
          "add"
        );
      }
    } else {
      enqueueSnackbar(
        t("dmTool:duplicateLangNotAllowedErrorMsg", {
          type: t(`${"dmTool:title"}`),
        }),
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

  const removeTitleRow = (e, index) => {
    let filerTitle = documentObj?.titleOfDocument;
    filerTitle.splice(index, 1);

    if (!filerTitle.length) {
      enqueueSnackbar(
        t("dmTool:fieldShldHaveAtleastOneEntryErrorMsg", {
          type: t(`${"dmTool:title"}`),
        }),
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    } else {
      OnAddOrDeleteDocumentObjTitle(filerTitle, "delete");

      enqueueSnackbar(
        t("dmTool:removingEntrySuccessMsg", { type: t(`${"dmTool:title"}`) }),
        {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

  const renderAddCloseIcon = (index) => {
    if (index === documentObj.titleOfDocument.length - 1) {
      return (
        <span style={{ display: "flex" }}>
          <Tooltip title={t(`${"dmTool:addTitleTooltip"}`)}>
            <IconButton
              aria-label="Add more document title"
              color="secondary"
              onClick={addDocumentTitle}
            >
              <AddIcon className={classes.icons} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t(`${"dmTool:removeTitleTooltip"}`)}>
            <IconButton
              aria-label="settings"
              onClick={(e) => removeTitleRow(e, index)}
            >
              <DeleteIcon className={classes.icons} />
            </IconButton>
          </Tooltip>
        </span>
      );
    }
    return (
      <Tooltip title={t(`${"dmTool:removeTitleTooltip"}`)}>
        <IconButton
          aria-label="settings"
          onClick={(e) => removeTitleRow(e, index)}
        >
          <DeleteIcon className={classes.icons} />
        </IconButton>
      </Tooltip>
    );
  };

  return documentObj?.titleOfDocument?.map((titleObj, index) => {
    return (
      <React.Fragment key={"doctitle" + index}>
        <Grid item lg={4} sm={4} xs={12}>
          <UILanguageSelector
            selectedValues={documentObj?.titleOfDocument}
            label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
            placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
            value={titleObj?.key}
            onChange={(e, value) => {
              OnChangeTitleKeyChange(e, value, index);
            }}
          />
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <TextField
            className={classes.marginSet}
            fullWidth
            label={t(`${"dmTool:title"}`)}
            inputProps={{
              autoComplete: "new-password",
            }}
            placeholder={t(`${"dmTool:enterTitlePlcHldr"}`)}
            onChange={(e) => OnChangeTitleValueChange(e, index)}
            size="small"
            required={false}
            value={titleObj?.value}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item lg={2} sm={2} xs={12}>
          {renderAddCloseIcon(index)}
        </Grid>
      </React.Fragment>
    );
  });
};

export default DocumentTitle;
