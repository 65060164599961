import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  DocumentDescription,
  DocumentFeatureImage,
  DocumentTitle,
  DocumentLinks,
  DocumentQualityFlag,
  DocumentHashtags,
  DocumentFocusSectorsSelector,
  DocumentPublicationDate,
  DocumentPublicationStatus,
  DocumentType,
  DocumentReferenceNote,
} from "./Components";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../utils";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 700,
  },
  legend: {
    padding: 0,
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "-0.05px",
    color: "#888888",
  },
  fieldset: {
    border: "1px solid #eceded",
    padding: "10px",
    borderRadius: "4px",
  },
  addButton: {
    minWidth: 91,
  },
}));

const DocumentsDialog = (props) => {
  const { t } = useTranslation(["translation", "eventsEnumerations", "dmTool"]);
  const { open } = props;
  const classes = useStyles();
  const [apiCallProgress, setApiCallProgress] = useState(false);
  const [documentObj, setDocumentObj] = useState({});
  const [enitityObj, setEntityObj] = useState({});
  const [linkValidate, setLinkValidate] = useState(false);
  useEffect(() => {
    setEntityObj(props.entity);

    if (!!Object.keys(props?.document)?.length) {
      if (
        !props.document.hasOwnProperty("referenceNote") ||
        !props.document.referenceNote.length
      ) {
        setDocumentObj({
          ...props.document,
          referenceNote: [
            {
              key: "",
              value: "",
            },
          ],
        });
      } 
      else if(isEmpty(props.document.titleOfDocument) || isEmpty(props.document.descriptionOfDocument) ){
        if(isEmpty(props.document.titleOfDocument) && !isEmpty(props.document.descriptionOfDocument)){
          setDocumentObj({
            ...props.document,
            titleOfDocument: [
              {
                key: null,
                value: null,
              },
            ]
          });
        }
        else if(!isEmpty(props.document.titleOfDocument) && isEmpty(props.document.descriptionOfDocument)){
          setDocumentObj({
            ...props.document,
            descriptionOfDocument: [
              {
                key: null,
                value: null,
              },
            ],
          });
        }
        else {
          setDocumentObj({
            ...props.document,
            titleOfDocument: [
              {
                key: null,
                value: null,
              },
            ],
            descriptionOfDocument: [
              {
                key: null,
                value: null,
              },
            ],
          });
        }
      }
      else {
        setDocumentObj(props?.document);
      }
    } 
    else {
      setDocumentObj({
        name: "EntityDocument",
        documentType: "DOC_ENTITY_FACTSHEET",
        i_sourceKey: null,
        titleOfDocument: [
          {
            key: "",
            value: "",
          },
        ],
        descriptionOfDocument: [
          {
            key: "",
            value: "",
          },
        ],
        documentFeatureImage: null,
        i_t_lifecycleElement_status: "CYC_STATUS_ACTIVE",
        date_sourceCreationTs: Date.now(),
        date_sourceUpdateTs: Date.now(),
        versionTs:Date.now(),
        i_t_date_creationTs: Date.now(),
        i_t_date_updateTs: Date.now(),
        i_t_date_lastCheckTs: Date.now(),
        i_t_qualitySourcePriorityLevel: null,
        i_t_qualitySourceDataQualityEvaluation: null,
        i_t_researchComment: null,
        i_t_startupSpiderInternalComment: "",
        i_t_qualitySourceExpertQualityComment: null,
        publicationState: "CON_AD_STATUS_ACTIVE",
        publicationDate: props.entity.UploaderMetaData.date_sourceCreationTs,
        hashtags: [],
        documentFocusSectors: [],
        documentClass: [],
        referenceNote: [
          {
            key: "",
            value: "",
          },
        ],
        Documents: [
          {
            name: "EntityDoc",
            t_id_entity: "",
            documentType: "DOC_ENTITY_FACTSHEET",
            i_sourceKey:
              "ssp_entitydoc_" +
              Date.now() +
              "_" +
              parseInt(Math.random() * 100000),
            connectionDocURL: null,
            docLanguage: null,
            docDocumentName: null,
            i_docMimeType: "",
            docSpecificJurisdictionRegion: [],
            docSpecificJurisdictionCountry: [],
            docSpecificJurisdictionType: "",
            docTargetGroupList: [],
            docDocumentTimeRangeStartDate: null,
            docDocumentTimeRangeType: "",
            i_t_lifecycleElement_status: "CYC_STATUS_ACTIVE",
            date_sourceCreationTs: null,
            date_sourceUpdateTs: Date.now(),
            versionTs: Date.now(),
            i_t_qualitySourceDataQualityEvaluation: "",
            i_t_researchComment: null,
            i_t_startupSpiderInternalComment: "",
            i_t_qualitySourceExpertQualityComment: null,
            documentClass: {},
            versionIdentifier: null,
          },
        ],
      });
    }
  }, [props.document]);

  const closeModal = (e) => {
    props.onClose && props.onClose();
  };

  const OnChageDocumentObj = (key, value) => {
    setDocumentObj({
      ...documentObj,
      [`${key}`]: value,
    });
  };

  const OnAddOrDeleteDocumentObjDesc = (value, action) => {
    let _documentObj = Object.assign({}, documentObj);

    if (action == "add") {
      _documentObj.descriptionOfDocument = [
        ..._documentObj[`descriptionOfDocument`],
        value,
      ];
    } else {
      _documentObj.descriptionOfDocument = value;
    }
    setDocumentObj(_documentObj);
  };

  const OnChangeDocumentObjDesc = (key, value, index) => {
    let _documentObj = Object.assign({}, documentObj);
    _documentObj.descriptionOfDocument[index] = {
      ..._documentObj.descriptionOfDocument[index],
      [`${key}`]: value,
    };
    setDocumentObj(_documentObj);
  };

  const OnAddOrDeleteDocumentObjTitle = (value, action) => {
    let _documentObj = Object.assign({}, documentObj);

    if (action == "add") {
      _documentObj.titleOfDocument = [
        ..._documentObj[`titleOfDocument`],
        value,
      ];
    } else {
      _documentObj.titleOfDocument = value;
    }
    setDocumentObj(_documentObj);
  };

  const onChangeQualityFlag = (value) => {
    let _documentObj = Object.assign({}, documentObj);
    _documentObj.i_t_qualitySourceDataQualityEvaluation = value;
    setDocumentObj(_documentObj);
  };

  const OnChangePublicationStateType = (value) => {
    let _documentObj = Object.assign({}, documentObj);
    _documentObj.publicationState = value;
    setDocumentObj(_documentObj);
  };

  const OnChangePublicationDate = (value) => {
    let _documentObj = Object.assign({}, documentObj);
    _documentObj.publicationDate = value;
    setDocumentObj(_documentObj);
  };

  const OnChangeDocumentObjTitle = (key, value, index) => {
    let _documentObj = Object.assign({}, documentObj);
    _documentObj.titleOfDocument[index] = {
      ..._documentObj.titleOfDocument[index],
      [`${key}`]: value,
    };
    setDocumentObj(_documentObj);
  };

  const OnChangeDocumentObjHashtag = (value) => {
    let _documentObj = Object.assign({}, documentObj);
    console.log(value, "value");
    _documentObj.hashtags = value;
    setDocumentObj(_documentObj);
  };

  const OnChangeDocumentObjSector = (key, value, index) => {
    console.log("asjas", key, value, index);
    let _documentFocusDetailedSectors = key?.detailSector?.map((item) => {
      let highLevel = item.split("_").slice(0, 4);
      highLevel[1] = "HIGH_LEVEL";
      return { key: highLevel.join("_"), value: item };
    });
    // let _documentFocusHighSectors = key?.highLevelSector?.map((item) => {
    //     let sector = `CON_DETAIL_SECTOR_${item.split("_")[4]}`;
    //     return { key: highLevel.join("_"), value: item };
    //   });
    let _documentFocusSectors = _documentFocusDetailedSectors;
    console.log("asjas2", _documentFocusSectors);
    let _documentObj = Object.assign({}, documentObj);
    _documentObj.documentFocusSectors = _documentFocusSectors;
    setDocumentObj(_documentObj);
    console.log("asjas1", documentObj, _documentObj);
  };

  const onRemoveFocusSector = (key, value, index) => {
    let _documentObj = Object.assign({}, documentObj);
    let _documentFocusSector = _documentObj.documentFocusSectors.filter(
      (item) => item.value !== key
    );
    _documentObj.documentFocusSectors = _documentFocusSector;
    setDocumentObj(_documentObj);
  };

  const onRemoveDocumentType = (key, value, index) => {
    let _documentObj = Object.assign({}, documentObj);
    let _documentClass = _documentObj.documentClass.filter(
      (item) => item.value !== key
    );
    _documentObj.documentClass = _documentClass;
    setDocumentObj(_documentObj);
  };
  const OnChangeDocumentType = (key, value, index) => {
    console.log("docuType", key, value, index);
    let _documentClassOptions = key?.documentType?.map((item) => {
      let highLevel = item.split("_").slice(0, 4);
      // if (highLevel[3] == "COMP") {
      //   highLevel[3] = "COMPANY";
      // }
      highLevel[2] = "CLASS";
      return { key: highLevel.join("_"), value: item };
    });
    let _documentClass = _documentClassOptions;
    console.log("docuType1", _documentClass);
    let _documentObj = Object.assign({}, documentObj);
    _documentObj.documentClass = _documentClass;
    setDocumentObj(_documentObj);
    console.log("docuType2", documentObj, _documentObj);
  };

  const OnChangeDocumentsDocumentType = (key, value, index) => {
    let _documentObj = Object.assign({}, documentObj);
    _documentObj.Documents[index].documentClass = {
      key: key.documentClass,
      value: key.documentType,
    };
    setDocumentObj(_documentObj);
  };

  const OnChangeDocumentObjRefrence = (key, value, index) => {
    let _documentObj = Object.assign({}, documentObj);
    _documentObj.referenceNote[index] = {
      ..._documentObj.referenceNote[index],
      [`${key}`]: value,
    };
    setDocumentObj(_documentObj);
  };

  const OnAddOrDeleteDocumentObjRefrenceNote = (value, action) => {
    let _documentObj = Object.assign({}, documentObj);

    if (action == "add") {
      _documentObj.referenceNote = [..._documentObj[`referenceNote`], value];
    } else {
      _documentObj.referenceNote = value;
    }
    setDocumentObj(_documentObj);
  };

  const addUpdateOrDeleteDocumentsLink = (object, type) => {
    if (type == "add") {
      setDocumentObj((_documentObj) => {
        return {
          ..._documentObj,
          Documents: [..._documentObj.Documents, object],
        };
      });
    }
    if (type == "delete") {
      setDocumentObj((_documentObj) => {
        return {
          ..._documentObj,
          Documents: [
            ..._documentObj.Documents.filter(
              (item, index) =>
                index != object &&
                item?.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
            ),
          ],
        };
      });
    }
    if (type == "update") {
      setDocumentObj((_documentObj) => {
        return {
          ..._documentObj,
          Documents: [...object],
        };
      });
    }
    if (type == "splice") {
      setDocumentObj((_documentObj) => {
        return {
          ..._documentObj,
          Documents: [
            ..._documentObj.Documents.filter(
              (item, index) =>
                item?.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED"
            ),
          ],
        };
      });
    }
  };

  const onClickSaveOrUpdate = () => {
    let updatedObject = Object.assign({}, documentObj);
    if (updatedObject?.i_sourceKey == null) {
      updatedObject.i_sourceKey =
        "ssp_entitydocument_" +
        Date.now() +
        "_" +
        parseInt(Math.random() * 100000);
    }
    updatedObject.Documents = updatedObject.Documents.map((item) => {
      delete item.linkValidate;
      return item;
    });
    props.onClickAddOrUpdate && props.onClickAddOrUpdate(updatedObject);
  };

  const highLevelSector = documentObj?.documentFocusSectors?.map((item) => {
    return item.key;
  });
  const detailSector = documentObj?.documentFocusSectors?.map((item) => {
    return item.value;
  });

  const _documentClass = documentObj?.documentClass?.map((item) => {
    return item.key;
  });
  const _documentType = documentObj?.documentClass?.map((item) => {
    return item.value;
  });

  console.log("hughekke", highLevelSector, detailSector);

  return (
    <React.Fragment>
      {open && (
        <Dialog
          aria-labelledby="document-dialog-title"
          classes={{
            paperWidthSm: classes.dialog,
          }}
          disableBackdropClick
          onClose={closeModal}
          open={open}
        >
          <DialogTitle
            className={classes.title}
            disableTypography
            id="document-dialog-title"
          >
            {documentObj?.i_sourceKey !== null
              ? t(`${"dmTool:updateFactsheetDialogTitle"}`)
              : t(`${"dmTool:addFactsheetDialogTitle"}`)}{" "}
            {enitityObj?.Entity?.accountName}
          </DialogTitle>
          {apiCallProgress && <LinearProgress />}
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item lg={12} sm={12} xs={12}>
                <DocumentFeatureImage
                  documentObj={documentObj}
                  OnChageDocumentObj={OnChageDocumentObj}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:titlesectionHdng"}`) + "*"}
                  </legend>
                  <Grid container spacing={1}>
                    <DocumentTitle
                      documentObj={documentObj}
                      OnChangeDocumentObjTitle={OnChangeDocumentObjTitle}
                      OnAddOrDeleteDocumentObjTitle={
                        OnAddOrDeleteDocumentObjTitle
                      }
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:descriptionsectionHdng"}`)}
                  </legend>
                  <Grid container spacing={1}>
                    <DocumentDescription
                      documentObj={documentObj}
                      OnChangeDocumentObjDesc={OnChangeDocumentObjDesc}
                      OnAddOrDeleteDocumentObjDesc={
                        OnAddOrDeleteDocumentObjDesc
                      }
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:publishDateAndPublishStatusSectionHdng"}`)}
                  </legend>
                  <Grid container spacing={1}>
                    <Grid item lg={6} sm={6} xs={12}>
                      <DocumentPublicationDate
                        documentObj={documentObj}
                        OnChangePublicationDate={OnChangePublicationDate}
                      />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                      <DocumentPublicationStatus
                        documentObj={documentObj}
                        OnChangePublicationStateType={
                          OnChangePublicationStateType
                        }
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:hashtagsectionHdng"}`)}
                  </legend>
                  <Grid spacing={1}>
                    <DocumentHashtags
                      hashtags={documentObj?.hashtags}
                      documentObj={documentObj}
                      OnChangeDocumentObjHashtag={OnChangeDocumentObjHashtag}
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:documentFocusSectorsectionHdng"}`)}
                  </legend>
                  <Grid spacing={1}>
                    <DocumentFocusSectorsSelector
                      documentObj={documentObj}
                      OnChangeDocumentObjSector={OnChangeDocumentObjSector}
                      label={t(
                        `${"dmTool:documentFocusSectorsInputFieldLabel"}`
                      )}
                      placeholder={t(
                        `${"dmTool:selectDocumentFocusSectorsPlcHldr"}`
                      )}
                      // selectedFocus={documentObj?.documentFocusSectors}
                      selectedFocus={{
                        highLevelSector: highLevelSector,
                        detailSector: detailSector,
                      }}
                      documentFocusSectors={documentObj?.documentFocusSectors}
                      onChange={(data) => {
                        console.log(data);
                      }}
                      selectorOption={documentObj?.documentFocusSectors}
                      onRemoveFocusSector={onRemoveFocusSector}
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:documentTypesSectionHdng"}`) + "*"}
                  </legend>
                  <Grid spacing={1}>
                    <DocumentType
                      documentObj={documentObj}
                      OnChangeDocumentType={OnChangeDocumentType}
                      label={t(`${"dmTool:documentTypesInputFieldLabel"}`)}
                      placeholder={t(`${"dmTool:selectDocumentTypesPlcHldr"}`)}
                      // selectedFocus={documentObj?.documentFocusSectors}
                      selectedClass={{
                        documentClass: _documentClass,
                        documentType: _documentType,
                      }}
                      onChange={(data) => {
                        console.log(data);
                      }}
                      documentClass={documentObj.documentClass}
                      selectorOption={documentObj?.documentClass}
                      onRemoveDocumentType={onRemoveDocumentType}
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:referenceNotesectionHdng"}`)}
                  </legend>
                  <Grid container spacing={1}>
                    <DocumentReferenceNote
                      documentObj={documentObj}
                      setDocumentObj={setDocumentObj}
                      OnAddOrDeleteDocumentObjRefrenceNote={
                        OnAddOrDeleteDocumentObjRefrenceNote
                      }
                      OnChangeDocumentObjRefrence={OnChangeDocumentObjRefrence}
                    />
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t(`${"dmTool:linkssectionHdng"}`) + "*"}
                  </legend>
                  <Grid container spacing={1}>
                    <DocumentLinks
                      documentObj={documentObj}
                      addUpdateOrDeleteDocumentsLink={
                        addUpdateOrDeleteDocumentsLink
                      }
                      linkValidate={linkValidate}
                      setLinkValidate={setLinkValidate}
                    />
                  </Grid>
                </fieldset>
              </Grid>

              {/* <Grid item lg={12} sm={12} xs={10}>
                <DocumentTypes
                  documentObj={documentObj}
                  OnChangeDocumentType={OnChangeDocumentsDocumentType}
                  label="Select complex values"
                  placeholder={t("SelectDocType")}
                  // selectedFocus={documentObj?.documentFocusSectors}
                  selectedClass={{
                    documentClass: documentObj?.Documents[0]?.documentClass.key,
                    documentType: documentObj?.Documents[0]?.documentClass.value,
                  }}
                  onChange={(data) => {
                    console.log(data);
                  }}
                  selectorOption={documentObj?.Documents[0]?.documentClass}
                />
              </Grid> */}

              <Grid item lg={12} sm={12} xs={12}>
                <DocumentQualityFlag
                  documentObj={documentObj}
                  onChangeQualityFlag={onChangeQualityFlag}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={apiCallProgress}
              onClick={closeModal}
              variant="contained"
              color="primary"
            >
              {" "}
              {t(`${"dmTool:cancelBtn"}`)}{" "}
            </Button>
            <Button
              onClick={onClickSaveOrUpdate}
              variant="contained"
              disabled={
                apiCallProgress ||
                linkValidate ||
                documentObj.Documents?.some(
                  (link) =>
                    isEmpty(link.connectionDocURL) ||
                    isEmpty(link.docLanguage) ||
                    isEmpty(link.documentClass) ||
                    isEmpty(link.i_t_qualitySourceDataQualityEvaluation)
                ) ||
                isEmpty(documentObj.documentClass) ||
                documentObj.descriptionOfDocument?.filter(
                  (desc) => !isEmpty(desc.value) && isEmpty(desc.key)
                ).length ||
                documentObj.titleOfDocument?.filter(
                  (title) => isEmpty(title.value) || isEmpty(title.key)
                ).length ||
                documentObj.referenceNote?.filter(
                  (title) => !isEmpty(title.value) && isEmpty(title.key)
                ).length
              }
              color="primary"
              className={classes.addButton}
            >
              {documentObj?.i_sourceKey !== null
                ? t(`${"dmTool:updateBtn"}`)
                : t(`${"dmTool:addBtn"}`)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default DocumentsDialog;
