import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    OrganizationContactStatus,
    Comment,
    SearchTrigger,
    OrganizationContactComment,
    Thoughts
} from './Components';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));

const DataSourceInfoDialog = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { open, apiCallProgress } = props;
    const classes = useStyles();

    const [dataSourceInfoObj, setDataSourceInfoObj] = useState([]);
    const [enitityObj, setEntityObj] = useState({});
    useEffect(() => {
        setEntityObj(props.entity)
        if (props.blobDataEntityt) {
            setDataSourceInfoObj(props.blobDataEntityt)
        } else {
            setDataSourceInfoObj([
                {
                    "key": "editorUserName",
                    "value": props?.User?.userInfo?.email
                },
                {
                    "key": "processingUserId",
                    "value": ""
                },
                {
                    "key": "version",
                    "value": ""
                },
                {
                    "key": "comment",
                    "value": ""
                },
                {
                    "key": "searchTrigger",
                    "value": ""
                },
                {
                    "key": "organizationContactStatus",
                    "value": ""
                },
                {
                    "key": "organizationContactStatusComment",
                    "value": ""
                },
                {
                    "key": "thoughts",
                    "value": ""
                }
            ]
            )
        }

    }, [props.blobDataEntityt])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onChageDataSourceInfoObj = (key, value) => {
        let _dataSourceInfoObj = dataSourceInfoObj;
        let addNew = true;
        _dataSourceInfoObj = _dataSourceInfoObj?.map(item => {
            if (item.key == "editorUserName") {
                item.value = props.User.userInfo.email
            }
            if (item.key == key) {
                item.value = value
                addNew = false;
            }
            return { ...item }
        })
        if (addNew) {
            _dataSourceInfoObj = [
                ..._dataSourceInfoObj,
                {
                    key, value
                }
            ]
        }
        setDataSourceInfoObj(_dataSourceInfoObj)
    }

    const onClickSaveOrUpdate = () => {
        let updatedObject = Object.assign([], dataSourceInfoObj);
        props.onClickAddOrUpdate(updatedObject)
    };


    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="AdditionalDataSourceInformation-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="AdditionalDataSourceInformation-dialog-title">
                        {dataSourceInfoObj?.i_sourceKey !== null
                            ? t(`${"dmTool:updateDataSourceInfoDialoHdng"}`)
                            : t(`${"dmTool:addDataSourceInfoDialoHdng"}`)}{" "}{enitityObj?.Entity?.accountName}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <OrganizationContactStatus
                                    dataSourceInfoObj={dataSourceInfoObj}
                                    onChageDataSourceInfoObj={onChageDataSourceInfoObj}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Comment
                                    dataSourceInfoObj={dataSourceInfoObj}
                                    onChageDataSourceInfoObj={onChageDataSourceInfoObj}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <SearchTrigger
                                    dataSourceInfoObj={dataSourceInfoObj}
                                    onChageDataSourceInfoObj={onChageDataSourceInfoObj}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <OrganizationContactComment
                                    dataSourceInfoObj={dataSourceInfoObj}
                                    onChageDataSourceInfoObj={onChageDataSourceInfoObj}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Thoughts
                                    dataSourceInfoObj={dataSourceInfoObj}
                                    onChageDataSourceInfoObj={onChageDataSourceInfoObj}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>

                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color='primary'
                            className={classes.addButton}
                        >
                            {dataSourceInfoObj?.i_sourceKey !== null ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        User: state.User
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // updateUserInfo: (payload) => dispatch(updateUserInfo(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSourceInfoDialog);