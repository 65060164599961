import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { sspLegalFormTypeEnum } from '../../../utils/enums';
import { countries } from '../../Countrieslist';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({

}));

const InstitutionLegalFormType = props => {
    const { institution, onChangeLegalForm, onChageInstitution } = props;
    const classes = useStyles();

    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);

    const _onChangeLegalFormType = (e, value) => {
        onChangeLegalForm('value', value.value)
    }

    const _onChangeLegalFormKey = (e, value) => {
        if (value) {
            onChangeLegalForm('key', value.code);
            onChageInstitution('institutionJurisdiction', value.code)
        } else {
            onChangeLegalForm('key', null);
            onChageInstitution('institutionJurisdiction', null)
        }
    }


    const legalFormTypeOptions = Object.keys(sspLegalFormTypeEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"configurationEnumerations:" + sspLegalFormTypeEnum[opt]}`)
        };
    });



    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item lg={6} sm={6} xs={12}>
                    <Autocomplete
                        id="country-select-demo"
                        size='small'
                        style={{ width: '100%' }}
                        options={countries}
                        classes={{
                            option: classes.option,
                        }}
                        value={countries.find((item) => item.code === institution?.legalForm?.key) || {}}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        onChange={_onChangeLegalFormKey}
                        renderOption={(option) => (
                            <React.Fragment>
                                <span>
                                    <img className="country-flag" src={`./flags/${option.code?.toLowerCase()}.png`} />
                                </span>
                                {option.label} ({option.code}) +{option.phone}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t(`${"dmTool:legalFormCounteryInputFieldLabel"}`) + "*"}
                                placeholder={t(`${"dmTool:countryofCompanyPlcHldr"}`)}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    autoComplete: 'new-password',
                                    startAdornment: institution?.legalForm?.key && institution?.legalForm?.key !== '' && (
                                        <React.Fragment>
                                            <span>
                                                <img className="country-flag" src={`./flags/${institution?.legalForm?.key?.toLowerCase()}.png`} />
                                            </span> {' '}
                                            {params.InputProps.startAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item lg={6} sm={6} xs={12}>
                    <Autocomplete
                        disableClearable
                        fullWidth
                        size="small"
                        className={classes.autocompleteStyle}
                        getOptionLabel={option => option.label}
                        id="legalForm_type"
                        margin="normal"
                        onChange={_onChangeLegalFormType}
                        options={legalFormTypeOptions}
                        value={
                            legalFormTypeOptions.find(
                                item =>
                                    item.value ===
                                    institution?.legalForm?.value,
                            ) || {}
                        }
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={`${t(`${"dmTool:legalFormTypeInputFieldLabel"}`)}*`}
                                placeholder={t(`${"dmTool:selectLegalFoformTypePlcHldr"}`)}
                                variant="outlined"
                                style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default InstitutionLegalFormType;