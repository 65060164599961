import React, { useState, forwardRef, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countries } from '../Countrieslist';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";

let InstitutionJurisdictionOptions = countries
    .map((item) => {
        return {
            name: item.label,
            value: item.code
        }
    })

const InstitutionJurisdictionSelectEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState(props.value);

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    function onChangeHandler(e, value) {
        setValue(value.value);
    }

    function onInputChangeHandler(e, inputValue) {
        setInputValue(inputValue);
    }

    function updateApi() {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity['institutionJurisdiction'] = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                updateApi();
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value)
            }
        };
    });

    return (
        <Autocomplete
            options={InstitutionJurisdictionOptions}
            getOptionLabel={(option) => option.name}
            value={InstitutionJurisdictionOptions?.find((item) => item.value == value) || ''}
            onChange={onChangeHandler}
            inputValue={inputValue}
            onInputChange={onInputChangeHandler}
            disableClearable
            renderOption={(option) => (
                <React.Fragment>
                    <span>{
                        <img class="country-flag" src={`./flags/${option.value?.toLowerCase()}.png`}></img>
                    }</span> {' '}
                    {option.name} ({option.value})
                </React.Fragment>
            )}
            getOptionSelected={(option, value) => value === option.value}
            renderInput={params => (
                <TextField
                    {...params}
                    value={value}
                    style={{ padding: '5px 0' }}
                    placeholder={t(`${"dmTool:selectInstJurisdictionPlcHldr"}`)}
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        startAdornment: value && value !== '' && (
                            <React.Fragment>
                                <span>{<img class="country-flag" src={`./flags/${value?.toLowerCase()}.png`}></img>
                                }</span> {' '}
                                {params.InputProps.startAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
})

const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null , { forwardRef: true })(InstitutionJurisdictionSelectEditor);

