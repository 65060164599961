import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
/*
Material -UI -Components
*/
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    legend: {
        padding: 0,
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '-0.05px',
        color: '#888888',
    },
    fieldset: {
        border: '1px solid #eceded',
        padding: '10px',
        borderRadius: '4px',
    },
    addButton: {
        minWidth: 91
    }
}));


const StartupCrowdfundingFromURLDialog = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { open, apiCallProgress } = props;
    const classes = useStyles();
    const [enitityObj, setEntityObj] = useState({});
    const [startupCrowdfundingFromURL, setStartupCrowdfundingFromURL] = useState([]);
    useEffect(() => {
        setEntityObj(props.entity)
        if (!!props.startupCrowdfundingFromURL.length) {
            setStartupCrowdfundingFromURL(props.startupCrowdfundingFromURL)
        } else {
            setStartupCrowdfundingFromURL([{
                key: '',
                value: ''
            }]
            )
        }

    }, [props.startupCrowdfundingFromURL])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(startupCrowdfundingFromURL)
    };


    const OnChangeStartupCrowdFundiongFromUrl = (e, index) => {
        let _startupCrowdfundingFromURL = startupCrowdfundingFromURL
        _startupCrowdfundingFromURL[index].value = e.target.value
        addUpdateOrDeleteStartupCrowdfundingFromURL(_startupCrowdfundingFromURL, 'update')
    }

    const onChangeStartupCrowdFundiongFromUrlKey = (e, index) => {
        let _startupCrowdfundingFromURL = startupCrowdfundingFromURL
        _startupCrowdfundingFromURL[index].key = e.target.value;
        addUpdateOrDeleteStartupCrowdfundingFromURL(_startupCrowdfundingFromURL, 'update')
    }

    const addSocialProfileRow = (e) => {
        addUpdateOrDeleteStartupCrowdfundingFromURL({
            key: '',
            value: '',
        }, 'add')
    }

    const addUpdateOrDeleteStartupCrowdfundingFromURL = (object, type) => {
        if (type == 'add') {
            setStartupCrowdfundingFromURL((_startupCrowdfundingFromURL) => {
                return ([..._startupCrowdfundingFromURL, object])

            })
        }
        if (type == 'delete') {
            setStartupCrowdfundingFromURL((_startupCrowdfundingFromURL) => {
                return [..._startupCrowdfundingFromURL.filter((item, index) => index != object)]
            })
        }
        if (type == 'update') {
            setStartupCrowdfundingFromURL((_startupCrowdfundingFromURL) => {
                return [...object]
            })
        }
    }


    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="startup-crowdfunding-from-url-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="startup-crowdfunding-from-url-dialog-title">
                        {!!props.startupCrowdfundingFromURL.length
                            ? t("dmTool:updateStartUpCrowdFundingDialogTitle", { entityName: enitityObj?.Entity?.accountName })
                            : t("dmTool:addStartUpCrowdFundingDialogTitle", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        {!!startupCrowdfundingFromURL.length && startupCrowdfundingFromURL.map((_startupCrowdfundingFromURL, index) => {
                            return (<React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item lg={4} sm={4} xs={12}>
                                        <TextField
                                            className={classes.marginSet}
                                            fullWidth
                                            label={t(`${"dmTool:startUpCrowdFundingFromUrlKeyInputFieldLabel"}`)}
                                            placeholder={t(`${"dmTool:enterStartUpCrowdFundingFromUrlKeyPlcHldr"}`)}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            onChange={(e) => onChangeStartupCrowdFundiongFromUrlKey(e, index)}
                                            size='small'
                                            required={false}
                                            value={_startupCrowdfundingFromURL?.key}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item lg={6} sm={6} xs={12}>
                                        <TextField
                                            className={classes.marginSet}
                                            fullWidth
                                            label={t(`${"dmTool:startUpCrowdfundingFromUrlnputFieldHdng"}`)}
                                            placeholder={t(`${"dmTool:enterStartUpCrowdfundingFromUrlPlcHldr"}`)}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            onChange={(e) => OnChangeStartupCrowdFundiongFromUrl(e, index)}
                                            size='small'
                                            required={false}
                                            value={_startupCrowdfundingFromURL?.value}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />

                                    </Grid>
                                    {index == 0 && <Grid item lg={1} sm={1} xs={12}>
                                        <Tooltip title={t(`${"dmTool:addStartUpCrowdFundingToolTip"}`)}>
                                            <IconButton aria-label="add"
                                                color="secondary"
                                                variant="contained"
                                                onClick={addSocialProfileRow}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>}
                                    <Grid item lg={1} sm={1} xs={12}>
                                        <IconButton
                                            key={'delete'}
                                            onClick={(e) => addUpdateOrDeleteStartupCrowdfundingFromURL(index, 'delete')}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </React.Fragment>)
                        })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress}
                            color='primary'
                            className={classes.addButton}
                        >
                            {!!props.startupCrowdfundingFromURL.length ? t(`${"dmTool:updateBtn"}`) : t(`${"dmTool:addBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            }
        </React.Fragment >
    );
};

export default StartupCrowdfundingFromURLDialog;