/* eslint-disable no-use-before-define */
import React from 'react';
import { useTheme, fade, makeStyles, withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import DetailsTwoToneIcon from '@material-ui/icons/DetailsTwoTone';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from '../../../utils';


const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 13,
    },
    flex: {
        display: 'flex',
        gap: 10

    }
}));



export default function KeyPerstonRoles(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [roles, setValue] = React.useState(props.roles);
    const { t } = useTranslation([
        "translation",
        "lifecycleEnumerations",
        "keyPersonEnumerations",
        "SSPDataQualityCommentMessages"
    ]);
    const theme = useTheme();

    const DATAQUALITYFILLCOLOR = {
        CYC_DATA_RELIABILITY_LOW: "#FF3D00",
        CYC_DATA_RELIABILITY_VAGUE: "#F44336",
        CYC_DATA_RELIABILITY_INCONSISTENT: "#0091EA",
        CYC_DATA_RELIABILITY_CREDIBLE: "#546E7A",
        CYC_DATA_RELIABILITY_FIRM: "#388E3C"
    }
    const DATAQUALITYICON = {
        CYC_DATA_RELIABILITY_LOW: "key-person-triangle-down low",
        CYC_DATA_RELIABILITY_VAGUE: "key-person-triangle-down vague",
        CYC_DATA_RELIABILITY_INCONSISTENT: "key-person-triangle-down inconsistent",
        CYC_DATA_RELIABILITY_CREDIBLE: "key-person-triangle-down credible",
        CYC_DATA_RELIABILITY_FIRM: "key-person-triangle-down firm"
    }
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#000000',
            color: 'rgba(255, 255, 255, 1)',
            // maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);

    if (roles) {
        return roles?.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED").map((role, index) => {
            return <span className={classes.flex} key={index}>
                <span>
                    {role.dateFrom ? new Date(role.dateFrom).format(props.context.date_format.value.split(" ")[0].substring(3)
                        .replace(new RegExp("m", "g"), "M")) : "--"} -
                </span>
                <span>
                    {role.dateTo ? new Date(role.dateTo).format(props.context.date_format.value.split(" ")[0].substring(3)
                        .replace(new RegExp("m", "g"), "M")) : "--"}
                </span>
                <span>
                    {t(
                        `${"keyPersonEnumerations:" + role.roleType}`
                    )}
                </span>
                <HtmlTooltip
                    arrow
                    title={
                        <React.Fragment>
                            <Typography color="inherit">{t(`${"lifecycleEnumerations:" + role.i_t_qualitySourceDataQualityEvaluation}`)}</Typography>
                            {!isEmpty(role.i_t_qualitySourceDataQualityComment) && t(`${"SSPDataQualityCommentMessages:" + role.i_t_qualitySourceDataQualityComment}`)}
                        </React.Fragment>
                    }
                >
                    <span>
                        <DetailsTwoToneIcon style={{ fill: DATAQUALITYFILLCOLOR[role.i_t_qualitySourceDataQualityEvaluation] }} />
                    </span>
                </HtmlTooltip>
            </span >
        });
    } else {
        return "";
    }

}
