import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";
/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { sspDocTypeEnum } from "../../../utils/enums";
import { isEmpty } from "../../../utils";

const useStyles = makeStyles((theme) => ({}));
const SingleDocumentClassSelector = (props) => {
  const { placeholder, limitTags } = props;
  const { t } = useTranslation([
    "translation",
    "eventsEnumerations",
    "documentsEnumerations",
    "dmTool",
  ]);
  const getOptionLabel = (option) => `${option.label}`;
  const classes = useStyles();
  const items = Object.keys(sspDocTypeEnum).map((option) => {
    let highLevel = option.split("_").slice(0, 4);
    // if (highLevel[3] == "COMP") {
    //   highLevel[3] = "COMPANY";
    // }
    highLevel[2] = "CLASS";
    return {
      value: option,
      label: t(`${"documentsEnumerations:" + sspDocTypeEnum[option]}`),
      group: highLevel.join("_"),
      groupDisplayName: t(`${"documentsEnumerations:" + highLevel.join("_")}`),
    };
  });
  console.log(items, "sectoritems");
  const [_selectedFocus, setSelectedFocus] = React.useState([]);
  const [selectedValues, setSelectedValues] = React.useState({});
  const handleChange = (event, selectedOptions, reason) => {
    if (event.key == "Backspace") return;
    if (reason === "select-option") {
      console.log("selectedOptions", selectedOptions, reason);
      props.OnChangeDocumentType({
        documentType: selectedOptions.value,
        documentClass: selectedOptions.group,
      });
    } else if (reason === "remove-option") {
      console.log("selectedOptions1", selectedOptions, reason);
      props.OnChangeDocumentType({
        documentType: selectedOptions.value,
        documentClass: selectedOptions.group,
      });
    }
  };
  const optionRenderer = (option, { selected }) => {
    console.log("option1", option, selected);
    return (
      <React.Fragment key={option.value}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8, padding: "9px 0 9px 9px" }}
          checked={selected}
        />
        <span style={{ fontSize: "15px" }}>{getOptionLabel(option)}</span>
      </React.Fragment>
    );
  };
  const inputRenderer = (params) => (
    <TextField
      {...params}
      id="outlined-basic"
      variant="outlined"
      placeholder={placeholder}
      label={t(`${"dmTool:" + "documentTypenputFieldLabel"}`)+ "*"}
    />
  );
  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value;
  const groupBy = (option) => option.group;
  const toogleGroupSelection = (e, params) => {
    if (!e.target.checked) {
      props.OnChangeDocumentType({
        documentType: "",
        documentClass: "",
      });
    }
  };
  const _viewIsGroupIndeterminate = (group) => {
    let _selectedValues = selectedValues.filter((i) => i.group == group);
    return (
      _selectedValues.length !== 0 &&
      _selectedValues.length !== items.filter((i) => i.group == group).length
    );
  };
  const _viewIsGroupChecked = (group) => {
    return selectedValues.filter((i) => i.group == group).length;
  };
  const renderGroup = (params) => {
    console.log("params", params);
    return [
      <div key={params.group} component="div">
        <span
          style={{ marginRight: 8, padding: "6px 0 9px 9px", fontSize: "15px" }}
        >
          {/* <Checkbox
            indeterminate={_viewIsGroupIndeterminate(params.group)}
            onChange={(e) => toogleGroupSelection(e, params)}
            color="primary"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8, padding: "6px 0 9px 9px" }}
            checked={_viewIsGroupChecked(params.group)}
          /> */}
          {t(`${"documentsEnumerations:" + params.group}`)}
        </span>
      </div>,
      params.children,
    ];
  };
  const getOptionDisabled = () => {};
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      [option.value, option.label, option.group, option.groupDisplayName].join(
        " "
      ),
  });
  const renderTags = (value) => {
    return "";
  };
  React.useEffect(() => {
    console.log("selectedValues", props.selectedClass);
    if (props.selectedClass && !isEmpty(props.selectedClass.documentType)) {
      setSelectedFocus(props.selectedClass);
      let selectedOption = items.filter(
        (i) => i.value === props.selectedClass.documentType
      );
      if (selectedOption.length) {
        setSelectedValues(selectedOption[0]);
      } else {
        setSelectedValues({});
      }
    } else {
      setSelectedValues({
        value: "",
        label: "",
        group: "",
        groupDisplayName: "",
      });
    }
  }, [props.selectedClass]);

  return (
    <React.Fragment>
      <Autocomplete
        //   multiple
        size="small"
        limitTags={limitTags}
        renderTags={renderTags}
        options={items}
        value={selectedValues}
        groupBy={groupBy}
        renderGroup={renderGroup}
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        filterOptions={(options, params) => {
          const filtered = filterOptions(options, params);
          return [...filtered];
        }}
        disableClearable
        getOptionDisabled={getOptionDisabled}
        onChange={handleChange}
        renderOption={optionRenderer}
        renderInput={inputRenderer}
      />
    </React.Fragment>
  );
};
SingleDocumentClassSelector.defaultProps = {
  limitTags: 1,
  items: [],
  selectedClass: {
    documentClass: [],
    documentType: [],
  },
};
export default SingleDocumentClassSelector;
