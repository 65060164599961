/* eslint-disable no-use-before-define */
import React from 'react';
import { useTheme, fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import AddressDialog from '../AddressDialog';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import { countries } from '../Countrieslist';
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
import { isEmpty } from '../../utils';
const useStyles = makeStyles((theme) => ({
    root: {
        // width: 221,
        fontSize: 13,
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    tag: {
        marginTop: 3,
        height: 20,
        padding: '.15em 4px',
        fontWeight: 600,
        lineHeight: '15px',
        borderRadius: 2,
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    listItemContainer: {
        overflow: 'auto',
        maxHeight: 215,
    },
    listItem: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    },
    _margin: {
        margin: "0 3px 0 0"
    }
}));



const AddressRenderer = (props) => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const classes = useStyles();
    const [AddressDialogOpen, setAddressDialogOpen] = React.useState(false);
    const [_editAddress, _setEditAddress] = React.useState(false);
    const [apiCallProgress, setApiCallProgress] = React.useState(false);
    const [_address, _setAddress] = React.useState(props.value || {})


    const openDialog = () => {
        setAddressDialogOpen(true);
    }

    const closeDialog = () => {
        setAddressDialogOpen(false);
        _setEditAddress(false);
    }


    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.CrowdAccountRelevantAddress = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
        closeDialog();

    }
    const hadleDeleteClick = (e, value) => {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.CrowdAccountRelevantAddress = null
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
    }

    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }


    const hadleEditClick = (e) => {
        _setEditAddress(true);
        openDialog();
    }
    const renderChip = (data) => {
        return <Chip
            component={"span"}
            size="small"
            key={data}
            label={data}
            style={toMaterialStyle(
                data?.toLowerCase(),
                500,
            )}
            className={classes._margin}
        />
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.topbuttons}>
                    {!Object.keys(_address)?.length ?

                        <Tooltip title={t(`${"dmTool:addAddressTooltip"}`)}>
                            <IconButton aria-label="delete"
                                color="secondary"
                                variant="contained"
                                onClick={openDialog}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>

                        :
                        <Tooltip title={t(`${"dmTool:editAddressTooltip"}`)}>
                            <IconButton
                                key={"edit"}
                                color="secondary"
                                variant="contained"
                                onClick={hadleEditClick}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>

                    }
                </div>

                {!!_address && !!Object.keys(_address)?.length ?
                    <React.Fragment key={"fragment"}>
                        <List
                            className={classes.listItemContainer}
                        >

                            <ListItem
                                key={"address"}
                                ContainerComponent="li"
                                className={classes.listItem}
                            >
                                <ListItemText
                                    className={classes.textOverFlowSet}
                                    primary={
                                        <>
                                            {renderChip(_address?.r_addressName)}
                                            {renderChip(t(_address?.r_addressType))}
                                        </>
                                    }
                                    secondary={<>
                                        {_address?.r_addressLine0_receiver}<br />
                                        {_address.r_addressLine2_addressSuffix && _address?.r_addressLine2_addressSuffix}<br />
                                        {_address?.r_addressLine1_streetName} {_address?.r_addressLine1_houseNumber}<br />
                                        {_address?.r_zipOrPostcode} {_address?.r_city}<br />
                                        {_address.r_nameOfProvince && _address?.r_nameOfProvince}<br />
                                        {!isEmpty(_address?.r_address_jurisdiction) && <img class="country-flag" src={`./flags/${_address?.r_address_jurisdiction?.toLowerCase()}.png`}></img>}
                                        {!isEmpty(_address?.r_address_jurisdiction) && `${_address?.r_address_jurisdiction} - ${countries.filter(item => item.code == _address?.r_address_jurisdiction).map(country => country.label)}`} <br />
                                    </>}
                                />
                                <ListItemSecondaryAction className={classes.actionPaddingSet}>
                                    <IconButton
                                        key={"delete"}
                                        onClick={(e) => hadleDeleteClick(e, _address)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                        <Divider />
                    </React.Fragment>
                    : ''

                }

            </div >
            {
                !!AddressDialogOpen && !_editAddress && <AddressDialog
                    open={AddressDialogOpen}
                    onClose={closeDialog}
                    entity={props.data}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                />
            }

            {
                !!AddressDialogOpen && !!_address && !!Object.keys(_address) && !!_editAddress && <AddressDialog
                    open={AddressDialogOpen}
                    onClose={closeDialog}
                    edit={_editAddress}
                    entity={props.data}
                    Address={_address}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                />
            }

        </React.Fragment >
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddressRenderer);