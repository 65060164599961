import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ListItem } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import { isEmpty } from '../../../utils';

const useStyles = makeStyles(theme => ({
    flex1: {
        flex: 1
    },
    flex2: {
        flex: 2
    },
    display_flex: {
        display: "flex"
    },
    compare_button_margin: {
        margin: "0 0 0 10px"
    }
}));

const SourceAndDestFiles = props => {
    const classes = useStyles();
    const { sourceVersion, destinationVersion, data } = props;
    const [_sourceVersion, setSourceVersion] = useState(sourceVersion);
    const [_destinationVersion, setDestinationVersion] = useState(destinationVersion);
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    const [_destOptions, setSourceOptions] = useState([
        ...data.UploaderMetaData,
        {
            fromUpstream: true,
            environment: "DEVELOPMENT",
            active: true
        },
        {
            fromUpstream: true,
            environment: "PRODUCTION",
            active: true
        }
    ])
    const [_sourceOptions, setDestOptions] = useState(data.UploaderMetaData)
    console.log("UploaderMetaData", data.UploaderMetaData)
    const onChangeSourceVersion = (e, value) => {
        setSourceVersion(value);
        props.onChangeSourceVersion && props.onChangeSourceVersion(value)
    }

    const onChangeDestinationVersion = (e, value) => {
        setDestinationVersion(value)
        props.onChangeDestinationVersion && props.onChangeDestinationVersion(value)
    }

    const getOptionLabel = (option) => {
        if (option.fromUpstream) {
            return option.environment == "DEVELOPMENT" ? t(`${"dmTool:developmentLiveCompareOption"}`) : t(`${"dmTool:productionLiveCompareOption"}`);
        } else {
            return isEmpty(option?.environment) ? `${new Date(option.versionTs).format("UTC:yyyy-MM-dd'T'HH:mm:ss'Z'")}-${t(`${"dmTool:draftCompareOption"}`)}` :
                `${new Date(option.versionTs).format("UTC:yyyy-MM-dd'T'HH:mm:ss'Z'")}-${option.environment}${option.active ? "-Live" : ""}`
        }
    }
    return (
        <React.Fragment>

            {/* <List>
                <ListItem button> */}
            <div style={{ margin: "8px 0 12px 0" }}>
                {t(`${"dmTool:selectSourceAndDestVersionTitle"}`)}
            </div>
            {/* </ListItem>
            </List> */}
            <div className={classes.display_flex}>
                <div className={classes.flex2}>
                    <Autocomplete
                        disableClearable
                        fullWidth
                        size="small"
                        className={classes.autocompleteStyle}
                        getOptionLabel={getOptionLabel}
                        id="intellectual-propert_status"
                        margin="normal"
                        onChange={onChangeSourceVersion}
                        options={_sourceOptions}
                        value={_sourceVersion}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t(`${"dmTool:sourceVersionTxtFieldLabel"}`)}
                                placeholder={t(`${"dmTool:selectSourceVersionPlcHldr"}`)}
                                variant="outlined"
                                style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    />
                </div>
                <div className={classes.flex1}></div>
                <div className={classes.flex2}>
                    <Autocomplete
                        disableClearable
                        fullWidth
                        size="small"
                        className={classes.autocompleteStyle}
                        getOptionLabel={getOptionLabel}
                        id="destinatioon-compare-selector"
                        margin="normal"
                        onChange={onChangeDestinationVersion}
                        options={_destOptions}
                        value={_destinationVersion}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t(`${"dmTool:destinationVersionTxtFieldLabel"}`)}
                                placeholder={t(`${"dmTool:selectDestinationVersionPlcHldr"}`)}
                                variant="outlined"
                                style={{ width: '100%' }}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    />
                </div>
                <div className={classes.compare_button_margin} onClick={props.onClickCompare}>
                    <Button
                        disabled={isEmpty(_sourceVersion) || isEmpty(_destinationVersion)}
                        variant="contained"
                        color='primary'
                    >
                        {t(`${"dmTool:compareBtnHdng"}`)}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SourceAndDestFiles;