import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { dateFormat } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(theme => ({

}));

const DateFormatSelector = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const classes = useStyles();
    const [dateFormatOptions, setDateFormatOptions] = useState(dateFormat);
    const [selectedDateFormat, setSelectedDateFormat] = useState(props.context.date_format)

    useEffect(() => {
        const { selectedFormat } = props
        selectedFormat && selectedFormat(selectedDateFormat)
    }, [])

    const _changeDateFormat = (e, value) => {
        setSelectedDateFormat(value)
        const { selectedFormat } = props
        selectedFormat && selectedFormat(value)
    }

    return (
        <Autocomplete
            disableClearable
            size="small"
            fullWidth
            className={classes.autocompleteStyle}
            getOptionLabel={option => option.label}
            id="milestone_select_lang"
            margin="normal"
            onChange={_changeDateFormat}
            options={dateFormatOptions}
            value={selectedDateFormat}
            renderInput={params => (
                <TextField
                    {...params}
                    label={t(`${"dmTool:dateFormatInputFieldLabel"}`)}
                    placeholder={t(`${"dmTool:selectDateFormatPlcHldr"}`)}
                    variant="outlined"
                    style={{ width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
};

export default DateFormatSelector;