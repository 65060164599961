
import {
    USER_INFO
} from "../actionsTypes";
import { authenticationService } from "../../Services";

const _updateUserInfo = (payload) => {
    return {
        type: USER_INFO,
        payload
    }
}

export const updateUserInfo = (payload) => {
    return (dispatch) => {
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', process.env.PUBLIC_URL + `/me`);
        httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        httpRequest.send(JSON.stringify());
        httpRequest.onreadystatechange = () => {
            if (httpRequest.readyState === 4 && httpRequest.status === 200) {
                let res = JSON.parse(httpRequest.responseText);
                if (res.status === "success") {
                    dispatch(_updateUserInfo(res.data));
                } else {
                    authenticationService.logout()
                }
            }
        };

    }
}