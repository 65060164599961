import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";
/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import toMaterialStyle from "material-color-hash";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { sspSectorEnum } from "../../../utils/enums";
import { isEmpty } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  chip: {
    margin: "5px 5px 0 0"
  }
}));
const DocumentFocusSectorsSelector = (props) => {
  const { placeholder, limitTags } = props;
  const { t } = useTranslation([
    "translation",
    "eventsEnumerations",
    "configurationEnumerations",
    "dmTool",
  ]);
  const getOptionLabel = (option) => `${option.label}`;
  const classes = useStyles();
  const items = Object.keys(sspSectorEnum)
    .map((option) => {
      let highLevel = option.split("_").slice(0, 4);
      highLevel[1] = "HIGH_LEVEL";
      return {
        value: option,
        label: t(`${"configurationEnumerations:" + sspSectorEnum[option]}`),
        group: highLevel.join("_"),
        groupDisplayName: t(
          `${"configurationEnumerations:" + highLevel.join("_")}`
        ),
      };
    })
    .filter((i) => i.value !== "OPP_DETAIL_SECTOR_ALL_ALL");
  console.log(items, "sectoritems");
  const [_selectedFocus, setSelectedFocus] = React.useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const handleChange = (event, selectedOptions, reason) => {
    if (event.key == "Backspace") return;
    let allGroupsSelected = selectedOptions.map((i) => i.group).filter(_unique);
    let uniqueGroups = allGroupsSelected.filter(
      (group) =>
        items.filter((i) => i.group == group).length ==
        selectedOptions.filter((i) => i.group == group).length
    );
    if (reason === "select-option") {
      props.OnChangeDocumentObjSector({
        detailSector: selectedOptions
          // .filter((i) => !uniqueGroups.includes(i.group))
          .map((i) => i.value),
        highLevelSector: [
          ..._selectedFocus.highLevelSector,
          ...allGroupsSelected,
        ].filter(_unique),
      });
    } else if (reason === "remove-option") {
      props.OnChangeDocumentObjSector({
        detailSector: selectedOptions
          // .filter((i) => !uniqueGroups.includes(i.group))
          .map((i) => i.value),
        highLevelSector: [
          ...uniqueGroups.filter((group) =>
            _selectedFocus.highLevelSector.includes(group)
          ),
          ...allGroupsSelected,
        ].filter(_unique),
      });
    }
  };
  const optionRenderer = (option, { selected }) => {
    return (
      <React.Fragment key={option.value}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8, padding: "9px 0 9px 9px" }}
          checked={selected}
        />
        <span style={{ fontSize: "15px" }}>{getOptionLabel(option)}</span>
      </React.Fragment>
    );
  };
  const inputRenderer = (params) => (
    <TextField
      {...params}
      id="outlined-basic"
      variant="outlined"
      placeholder={placeholder}
      label={t(`${"dmTool:" + "documentFocusSectornputFieldLabel"}`)}
    />
  );
  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value;
  const groupBy = (option) => option.group;
  const toogleGroupSelection = (e, params) => {
    if (e.target.checked) {
      let highLevelSector = [..._selectedFocus.highLevelSector, params.group];
      let _detailSector = Object.keys(sspSectorEnum)?.filter((item) => {
        let sector = `CON_DETAIL_SECTOR_${params.group.split("_")[4]}`;
        if (item.includes(sector)) {
          return item;
        }
      });
      let detailSector = [..._selectedFocus.detailSector, ..._detailSector];
      console.log("eeeeee", e, params, detailSector);
      props.OnChangeDocumentObjSector({
        detailSector,
        highLevelSector,
      });
    } else {
      let detailSector = props.selectedFocus.detailSector.filter(
        (companyFocus) => {
          let i = companyFocus.split("_").slice(0, 4);
          i[1] = "HIGH_LEVEL";
          i = i.join("_");
          return i !== params.group;
        }
      );
      props.OnChangeDocumentObjSector({
        detailSector: detailSector,
        highLevelSector: [
          ..._selectedFocus.highLevelSector.filter((i) => i !== params.group),
        ],
      });
    }
  };
  const _viewIsGroupIndeterminate = (group) => {
    let _selectedValues = selectedValues.filter((i) => i.group == group);
    return (
      _selectedValues.length !== 0 &&
      _selectedValues.length !== items.filter((i) => i.group == group).length
    );
  };
  const _viewIsGroupChecked = (group) => {
    return selectedValues.filter((i) => i.group == group).length;
  };
  const renderGroup = (params) => {
    console.log("params", params);
    return [
      <div
        key={params.group}
        style={toMaterialStyle(params.group, 500)}
        component="div"
      >
        <span
          style={{
            fontSize: "15px",
          }}
        >
          <Checkbox
            indeterminate={_viewIsGroupIndeterminate(params.group)}
            onChange={(e) => toogleGroupSelection(e, params)}
            color="primary"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8, padding: "6px 0 9px 9px" }}
            checked={_viewIsGroupChecked(params.group)}
          />
          {t(`${"configurationEnumerations:" + params.group}`)}
        </span>
      </div>,
      params.children,
    ];
  };
  const getOptionDisabled = () => {};
  const filterOptions = createFilterOptions({
    stringify: (option) =>
      [option.value, option.label, option.group, option.groupDisplayName].join(
        " "
      ),
  });
  const renderTags = (value) => {
    return "";
  };
  React.useEffect(() => {
    if (props.selectedFocus) {
      let __selectedFocus = _reCalculateCompanyFocus(props.selectedFocus);
      console.log("_selectedFocus", _selectedFocus);
      setSelectedFocus(__selectedFocus);
      setSelectedValues([
        ...items.filter((i) => __selectedFocus.detailSector.includes(i.value)),
      ]);
    }
  }, [props.selectedFocus]);
  const _unique = (i, index, array) => {
    return array.indexOf(i) === index;
  };
  const _reCalculateCompanyFocus = ({ highLevelSector, detailSector }) => {
    console.log("recalculating!!", highLevelSector, detailSector);
    let groupsFromCompanyFocus = detailSector
      .map((i) => {
        i = i.split("_").slice(0, 4);
        i[1] = "HIGH_LEVEL";
        return i.join("_");
      })
      .filter(_unique);
    let differenceInHighLevel = highLevelSector.filter(
      (x) => !groupsFromCompanyFocus.includes(x)
    );
    return {
      highLevelSector,
      detailSector: [
        ...detailSector,
        ...items
          .filter((i) => differenceInHighLevel.includes(i.group))
          .map((i) => i.value),
      ],
    };
  };
  console.log("selectedValues", selectedValues);
  return (
    <React.Fragment>
      <Autocomplete
        multiple
        size="small"
        limitTags={limitTags}
        disableClearable
        renderTags={renderTags}
        options={items}
        value={selectedValues}
        groupBy={groupBy}
        renderGroup={renderGroup}
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        filterOptions={(options, params) => {
          const filtered = filterOptions(options, params);
          return [...filtered];
        }}
        getOptionDisabled={getOptionDisabled}
        onChange={handleChange}
        renderOption={optionRenderer}
        renderInput={inputRenderer}
      />
      <div>
        {!isEmpty(props.documentFocusSectors) &&
          props.documentFocusSectors.map((item, index) => {
            return (
              <Tooltip
                title={`${t("configurationEnumerations:" + item?.key)} : ${t(
                  "configurationEnumerations:" + item?.value
                )}`}
              >
                <Chip
                  component={"span"}
                  size="small"
                  key={item.value}
                  label={`${t("configurationEnumerations:" + item?.value)}`}
                  style={toMaterialStyle(item?.key, 500)}
                  className={classes.chip}
                  onDelete={() => {
                    props.onRemoveFocusSector(item?.value);
                  }}
                ></Chip>
              </Tooltip>
            );
          })}
      </div>
    </React.Fragment>
  );
};
DocumentFocusSectorsSelector.defaultProps = {
  limitTags: 1,
  items: [],
  selectedFocus: {
    highLevelSector: [],
    detailSector: [],
  },
};
export default DocumentFocusSectorsSelector;
