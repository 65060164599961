import React, { useState, useEffect } from 'react';
import { sspStartupIntellectualPropertyEnum } from '../../utils/enums';
import { useTranslation } from 'react-i18next';

let selectOptions = Object.keys(sspStartupIntellectualPropertyEnum)
    .map((item) => {
        return {
            name: sspStartupIntellectualPropertyEnum[item],
            value: item
        }
    })
export default (props) => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
    ]);
    return (
        <div>
            {t(`${"configurationEnumerations:" + sspStartupIntellectualPropertyEnum[props.value]}`)}
        </div >
    )
}