/* eslint-disable no-use-before-define */
import React from 'react';
import { useTheme, fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import Divider from '@material-ui/core/Divider';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from "@material-ui/core";
import EntityVideoDialog from '../EntityVideoDialog';
import Chip from '@material-ui/core/Chip';
import toMaterialStyle from 'material-color-hash';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player'
import { toast } from "react-toastify";
import { updateEntityToServer } from "../../Store/Entity/actions";
import { connect } from "react-redux";
import { isEmpty } from '../../utils';
const useStyles = makeStyles((theme) => ({
    root: {
        // width: 221,
        fontSize: 13,
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    listContainer: {
        overflow: 'auto',
        maxHeight: 215,
    },
    listItems: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    }

}));



const EntityVideoRenderer = (props) => {
    const classes = useStyles();
    const [entityVideos, setEntityVideos] = React.useState(props.value)
    const [entityVideoDialogOpen, setEntityVideoDialogOpen] = React.useState(false);
    const [apiCallProgress, setApiCallProgress] = React.useState(false);

    const { t } = useTranslation([
        "translation",
        "eventsEnumerations",
        "languageEnumerations",
    ]);


    const openDialog = () => {
        setEntityVideoDialogOpen(true);
    }

    const closeDialog = () => {
        setEntityVideoDialogOpen(false);
    }



    function updateApi(value) {
        setApiCallProgress(true);
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.entityVideo = value;
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
        closeDialog();

    }

    const hadleDeleteClick = (e, value, index) => {
        let updatedData = Object.assign({}, props.data);
        updatedData.Entity.entityVideo.splice(index, 1)
        props.updateEntityToServer && props.updateEntityToServer(updatedData);
    }
    const renderEntityVedios = (video, index) => {

        return !!Object.keys(video) &&
            !isEmpty(video?.key) && <React.Fragment>
                <Chip
                    size="small"
                    component={"span"}
                    key={"language" + video?.key + index}
                    label={`${video?.key.toLocaleUpperCase()} - ${t("languageEnumerations:" + video?.key)}`}
                    style={toMaterialStyle(
                        video?.key?.toLowerCase(),
                        500,
                    )}
                />

                <div>

                    <ReactPlayer url={`${video.value}`} width={440} height={200} />
                </div>
            </React.Fragment>
    }

    const onClickAddOrUpdate = (value) => {
        updateApi(value);
        setApiCallProgress(false);
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.topbuttons}>
                    <Tooltip title={"Edit entity video"}>
                        <IconButton
                            key={'edit'}
                            color="secondary"
                            onClick={openDialog}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                {!!entityVideos.length && <List
                    className={classes.listContainer}
                >
                    {entityVideos?.map((video, index) => (
                        <React.Fragment key={"fragment" + index}>
                            <ListItem
                                key={"entity_video" + index}
                                ContainerComponent="li"
                                className={classes.listItems}
                            >
                                <ListItemText
                                    className={classes.textOverFlowSet}
                                    primary={
                                        <>
                                            {renderEntityVedios(video, index)}
                                        </>
                                    }
                                    secondary={<>

                                    </>}
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>}
            </div>
            {
                !!entityVideoDialogOpen && <EntityVideoDialog
                    open={entityVideoDialogOpen}
                    onClose={closeDialog}
                    entityVideos={entityVideos}
                    entity={props.data}
                    onClickAddOrUpdate={onClickAddOrUpdate}
                    apiCallProgress={apiCallProgress}
                />
            }
        </React.Fragment >
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        // store: state.Entity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateEntityToServer: (payload) => dispatch(updateEntityToServer(payload))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EntityVideoRenderer);