import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sspDataReliabilityEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));
const DocumentQualityFlag = props => {
    const { documentObj, onChangeQualityFlag } = props;
    const classes = useStyles();
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "lifecycleEnumerations",
        "dmTool"
    ]);
    const _onChangeQualityFlag = (e, value) => {
        onChangeQualityFlag(value.value)
    }

    const sspDataReliabilityEnumOptions = Object.keys(sspDataReliabilityEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"lifecycleEnumerations:" + sspDataReliabilityEnum[opt]}`)
        };
    });


    return (
        <React.Fragment>
            <Autocomplete
                disableClearable
                fullWidth
                size="small"
                className={classes.autocompleteStyle}
                getOptionLabel={option => option.label}
                id="quality-flag"
                margin="normal"
                onChange={_onChangeQualityFlag}
                options={sspDataReliabilityEnumOptions}
                value={
                    sspDataReliabilityEnumOptions.find(
                        item =>
                            item.value ===
                            documentObj?.i_t_qualitySourceDataQualityEvaluation,
                    ) || {}
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        label={t(`${"dmTool:overallQualityInputFieldLabel"}`)}
                        placeholder={t(`${"dmTool:selectOverallQualityPlcHldr"}`)}
                        variant="outlined"
                        style={{ width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </React.Fragment>
    );
};

export default DocumentQualityFlag;