import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import { sspLanguageEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
import UILanguageSelector from '../../Languages';
import languages from "../../Languages/languages"

const useStyles = makeStyles(theme => ({

}));

const MileStoneDescription = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { mileStoneObj, OnChangeMileStoneObjDesc, OnAddOrDeleteMileStoneObjDesc } = props;
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [languageOptions, setLanguageOptions] = useState([]);

    useEffect(() => {
        let keys = props.mileStoneObj.description.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!!props.mileStoneObj.description.length && !!keys.length) {
            let langs = [];
            let filterLang = sspLanguageEnum.map((lang) => {
                if (!keys?.includes(lang.value)) {
                    langs.push(lang);
                }
            });
            setLanguageOptions(langs)
        } else {
            setLanguageOptions(sspLanguageEnum);
        }
    }, [props.mileStoneObj.description])


    const OnChangeMileStoneDescKey = (e, value, index) => {

        OnChangeMileStoneObjDesc('key', value.value, index)
    }

    const OnChangeDescValueChange = (e, index) => {
        OnChangeMileStoneObjDesc('value', e.target.value, index)
    }

    const addMileStoneDesc = (e) => {
        let keys = props.mileStoneObj.description.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!(keys.length === Object.keys(languages).length)) {
            if (!keys.length) {
                enqueueSnackbar(t(`${"dmTool:selectLangToAddNewErrorMsg"}`), {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            } else {
                OnAddOrDeleteMileStoneObjDesc({
                    key: '',
                    value: ''
                }, 'add')
            }
        } else {
            enqueueSnackbar(t("dmTool:duplicateLangNotAllowedErrorMsg", { type: t(`${"dmTool:description"}`) }), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            })
        }

    }


    const removeDescRow = (e, index) => {
        let filerDesc = mileStoneObj?.description
        filerDesc.splice(index, 1);

        if (!filerDesc.length) {
            enqueueSnackbar(t("dmTool:fieldShldHaveAtleastOneEntryErrorMsg", { type: t(`${"dmTool:milestoneDescription"}`) }), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        } else {
            OnAddOrDeleteMileStoneObjDesc(
                filerDesc,
                'delete',
            );

            enqueueSnackbar(t("dmTool:removingEntrySuccessMsg", { type: t(`${"dmTool:milestoneDescription"}`) }), {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });

        }
    };


    const renderAddCloseIcon = (index) => {
        if (index === 0) {
            return (
                <span style={{ display: 'flex' }}>
                    <Tooltip title={t(`${"dmTool:addMIlestoneDescriptionTooltip"}`)}>
                        <IconButton aria-label="Add more milestone description"
                            onClick={addMileStoneDesc}
                        >
                            <AddIcon className={classes.icons} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t(`${"dmTool:removeMIlestoneDescriptionTooltip"}`)}>
                        <IconButton
                            aria-label="settings"
                            onClick={(e) => removeDescRow(e, index)}
                        >
                            <CloseIcon className={classes.icons} />
                        </IconButton>
                    </Tooltip>
                </span>
            );
        }
        return (
            <Tooltip title={t(`${"dmTool:removeMIlestoneDescriptionTooltip"}`)}>
                <IconButton
                    aria-label="settings"
                    onClick={(e) => removeDescRow(e, index)}
                >
                    <CloseIcon className={classes.icons} />
                </IconButton>
            </Tooltip>
        );
    }


    return (
        mileStoneObj.description.map((descObj, index) => {
            return (
                <React.Fragment key={"milestone-desc" + index}>
                    <Grid item lg={4} sm={4} xs={12}>
                        {/* <Autocomplete
                            disableClearable
                            size="small"
                            fullWidth
                            className={classes.autocompleteStyle}
                            getOptionLabel={option => option.label}
                            id="milestone_select_lang"
                            margin="normal"
                            onChange={(e, value) => OnChangeMileStoneDescKey(e, value, index)}
                            options={languageOptions}
                            value={
                                sspLanguageEnum.find(
                                    item =>
                                        item.value ===
                                        descObj?.key,
                                ) || {}
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={t(`${"dmTool:languageInputFiledHdng"}`)}
                                    placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        /> */}
                        <UILanguageSelector
                            selectedValues={mileStoneObj.description}
                            label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                            placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                            value={descObj?.key}
                            onChange={(e, value) => {
                                OnChangeMileStoneDescKey(e, value, index)
                            }
                            }
                        />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                        <TextField
                            className={classes.marginSet}
                            fullWidth
                            label={t(`${"dmTool:milestoneDescriptionInputFieldLabel"}`)}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                            size='small'
                            multiline
                            placeholder={t(`${"dmTool:enterMilestoneDescriptionPlcHldr"}`)}
                            onChange={e => OnChangeDescValueChange(e, index)}
                            required={false}
                            className={classes.textfiledHeight}
                            value={descObj?.value}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item lg={2} sm={2} xs={12}>
                        {renderAddCloseIcon(index)}
                    </Grid>
                </React.Fragment>

            )
        })
    );
};

export default (MileStoneDescription);