/* eslint-disable no-use-before-define */
import React from 'react';
import { useTheme, fade, makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';


import {
    Facebook,
    GooglePlus,
    Instagram,
    Linkedin,
    Twitter,
    Vk,
    Whatsapp,
    Xing,
    Youtube,
} from "@trejgun/material-ui-icons-social-networks";
import { sspSocialPlatformEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
const Com = {
    "CON_SOCIAL_PLATFORM_ARMORY": Youtube,
    "CON_SOCIAL_PLATFORM_FACEBOOK": Facebook,
    "CON_SOCIAL_PLATFORM_GOOGLE": GooglePlus,
    "CON_SOCIAL_PLATFORM_INSTAGRAM": Instagram,
    "CON_SOCIAL_PLATFORM_LINKEDIN": Linkedin,
    "CON_SOCIAL_PLATFORM_TWTTER": Twitter,
    "CON_SOCIAL_PLATFORM_YOUTUBE": Youtube,
    "CON_SOCIAL_PLATFORM_STARTUPSPIDER": Vk,
    "CON_SOCIAL_PLATFORM_BITCOIN": Whatsapp,
    "CON_SOCIAL_PLATFORM_ELECTRUM": Xing,

}


const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 13,
    },
    flex: {
        display: 'flex',
        gap: 10

    }
}));



export default function KeyPersonSocialProfiles(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [socialProfileURL, setValue] = React.useState(props.socialProfileURL);
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
    ]);
    const theme = useTheme();

    if (socialProfileURL) {
        return socialProfileURL.map((socialProfile, index) => {
            let C = Com[socialProfile.key];
            return <span className={classes.flex} key={index}>
                <C />
                {socialProfile.value && <Link href={socialProfile.value} target="_blank" rel="noreferrer">
                    {socialProfile.value}
                </Link>}
                {!socialProfile.value && <span>{""}</span>}

            </span>
        });
    } else {
        return "";
    }

}
