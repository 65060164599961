import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    EntityVideoKeyAndValue
} from './Components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../utils';

const useStyles = makeStyles(theme => ({
    dialog: {
        maxWidth: 700,
        width: '100%'
    },
    addButton: {
        minWidth: 91
    }
}));

const EntityVideoDialog = props => {
    const { open, apiCallProgress } = props;
    const classes = useStyles();

    const [entityVideos, setEntityVideos] = useState([]);
    const [enitityObj, setEntityObj] = useState({});

    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])

    useEffect(() => {
        setEntityObj(props.entity)
        if (props.entityVideos?.length) {
            setEntityVideos(props.entityVideos)
        } else {
            setEntityVideos([{
                key: '',
                value: ''
            }]
            )
        }
    }, [props.entityVideos])

    const closeModal = () => {
        const { onClose } = props;
        onClose && onClose();
    };


    const OnChangeEntityVedio = (key, value, index) => {
        let _entityVideos = [...entityVideos]
        _entityVideos[index] = { ..._entityVideos[index], [`${key}`]: value }
        setEntityVideos(_entityVideos)
    }


    const onClickSaveOrUpdate = () => {
        props.onClickAddOrUpdate(entityVideos)
    };

    const OnAddOrDeleteEntityVideo = (value, action) => {
        let _entityVideos = [...entityVideos]
        if (action == 'add') {
            _entityVideos = [..._entityVideos, value]
        } else {
            _entityVideos = [...value]
        }
        setEntityVideos(_entityVideos);
    }

    return (
        <React.Fragment>
            {open && (
                <Dialog
                    aria-labelledby="entity_video-dialog-title"
                    classes={{
                        paperWidthSm: classes.dialog,
                    }}
                    disableBackdropClick
                    onClose={closeModal}
                    open={open}>
                    <DialogTitle
                        className={classes.title}
                        disableTypography
                        id="entity_video-dialog-title">
                        {t("dmTool:updateEntityVideoDialogHdng", { entityName: enitityObj?.Entity?.accountName })}
                    </DialogTitle>
                    {apiCallProgress && <LinearProgress />}
                    <DialogContent dividers>
                        <Grid container spacing={1}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Grid container spacing={1}>
                                    <EntityVideoKeyAndValue
                                        entityVideos={entityVideos}
                                        OnAddOrDeleteEntityVideo={OnAddOrDeleteEntityVideo}
                                        OnChangeEntityVedio={OnChangeEntityVedio}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={apiCallProgress}
                            onClick={closeModal}
                            variant="contained"
                            color='primary'
                        >
                            {t(`${"dmTool:cancelBtn"}`)}
                        </Button>
                        <Button
                            onClick={onClickSaveOrUpdate}
                            variant="contained"
                            disabled={apiCallProgress || entityVideos?.filter((vid)=>!isEmpty(vid.value) && isEmpty(vid.key)).length}
                            color='primary'
                            className={classes.addButton}
                        >
                            {t(`${"dmTool:updateBtn"}`)}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default EntityVideoDialog;