
import {
    COLUMN_DEFS
} from "../actionsTypes";
const _updateColumnDef = (payload) => {
    return {
        type: COLUMN_DEFS,
        payload: payload
    }
}

export const updateColumnsDef = (payload) => {    
    return (dispatch) => {
        dispatch(_updateColumnDef(payload))
    }
}