import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { sspDataReliabilityEnum, sspRoleTypeEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    row: {
        boxShadow: theme.shadows[3],
        padding: "10px",
        margin: "5px 0px"
    }
}));

const KeypersonRole = props => {
    const { keyPersonObj, addUpdateOrDeleteKeypersonRole } = props;
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation([
        "translation",
        "lifecycleEnumerations",
        "keyPersonEnumerations",
        "dmTool"
    ]);
    const sspRoleTypeEnumOptions = Object.keys(sspRoleTypeEnum).map(opt => {
        return {
            value: opt,
            label: t(
                `${"keyPersonEnumerations:" + sspRoleTypeEnum[opt]}`
            )
        };
    });

    const sspDataReliabilityEnumOptions = Object.keys(sspDataReliabilityEnum).map(opt => {
        return {
            value: opt,
            label: t(`${"lifecycleEnumerations:" + sspDataReliabilityEnum[opt]}`)
        };
    });



    const _changeRoleDateTo = (date, role, index) => {
        let Roles = keyPersonObj.Roles
        let roleIndex = Roles.findIndex((item) => item.i_sourceKey === role.i_sourceKey);
        Roles[roleIndex].dateTo = (date !== null ? new Date(date).getTime() : null);
        addUpdateOrDeleteKeypersonRole(Roles, 'update')
    }

    const _changeRoleDateFrom = (date, role, index) => {
        let Roles = keyPersonObj.Roles
        let roleIndex = Roles.findIndex((item) => item.i_sourceKey === role.i_sourceKey);
        Roles[roleIndex].dateFrom = (date !== null ? new Date(date).getTime() : null);
        addUpdateOrDeleteKeypersonRole(Roles, 'update')
    }

    const _changeRoleType = (e, value, role, index) => {
        let Roles = keyPersonObj.Roles
        let roleIndex = Roles.findIndex((item) => item.i_sourceKey === role.i_sourceKey);
        Roles[roleIndex].roleType = value.value;
        addUpdateOrDeleteKeypersonRole(Roles, 'update')
    }

    const _changeQualityFlag = (e, value, role, index) => {
        let Roles = keyPersonObj.Roles
        let roleIndex = Roles.findIndex((item) => item.i_sourceKey === role.i_sourceKey);
        Roles[roleIndex].i_t_qualitySourceDataQualityEvaluation = value.value;
        addUpdateOrDeleteKeypersonRole(Roles, 'update')
    }


    const addKeypersonRole = (e) => {
        addUpdateOrDeleteKeypersonRole({
            "i_sourceKey": "ssp_keyperson_role_" + Date.now() + "_" + parseInt(Math.random() * 100000), // Record Key used by the source, i.e. uploader tool, // Record Key used by the source, i.e. uploader tool
            "i_t_qualitySourcePriorityLevel": "",
            "i_t_qualitySourceDataQualityEvaluation": "",
            "i_t_qualitySourceDataQualityComment": "",
            "i_t_researchComment": "",
            "i_t_startupSpiderInternalComment": "",
            "i_t_qualitySourceExpertQualityComment": "",
            "roleStatus": "",
            "roleType": "",
            "roleOther": "",
            "dateFrom": null,
            "dateTo": null,
            "name": "KeyPersonRole",
            "i_t_lifecycleElement_status": "CYC_STATUS_ACTIVE"
        }, 'add')

    }


    const deleteOtherOfferings = (e, role, index) => {
        let Roles = keyPersonObj.Roles
        // role.hasOwnProperty("uuid") ?
        //     Roles[index].i_t_lifecycleElement_status = "CYC_STATUS_DELETED" && addUpdateOrDeleteKeypersonRole(index, 'splice')
        //     : addUpdateOrDeleteKeypersonRole(index, 'delete');

        let roleIndex = Roles.findIndex((item) => item.i_sourceKey === role.i_sourceKey);
        Roles[roleIndex].i_t_lifecycleElement_status = "CYC_STATUS_DELETED";
        addUpdateOrDeleteKeypersonRole(Roles, 'update')
        enqueueSnackbar('Role removed successfully', {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
        });
    };


    const renderAddDeleteIcon = (index, role) => {
        return (
            <Tooltip title={t(`${"dmTool:removeRoleTooltip"}`)}>
                <IconButton
                    aria-label="remove"
                    onClick={(e) => deleteOtherOfferings(e, role, index)}
                >
                    <DeleteIcon className={classes.icons} />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <>
            <Tooltip title={t(`${"dmTool:addRoleTooltip"}`)}>
                <Button
                    aria-label="add"
                    onClick={addKeypersonRole}
                    color='primary'
                    startIcon={<AddIcon />}
                >
                    {t(`${"dmTool:addRoleSectionHdng"}`)}
                </Button>
            </Tooltip>
            {keyPersonObj?.Roles?.filter(item => item.i_t_lifecycleElement_status !== "CYC_STATUS_DELETED").map((role, index) => {
                return (
                    <Grid container spacing={1} className={classes.row}>

                        <Grid item lg={5} sm={5} xs={12}>
                            <KeyboardDatePicker
                                autoOk
                                size='small'
                                openTo="month"
                                views={["month", "year"]}
                                clearable
                                keyboard
                                inputVariant="outlined"
                                placeholder={t(`${"dmTool:fromdatePlcHldr"}`)}
                                label={t(`${"dmTool:fromdateInputFieldLabel"}`)}
                                format={("MM/YYYY")}
                                value={role.dateFrom}
                                InputAdornmentProps={{
                                    position: 'start',
                                }}
                                onChange={(date) => _changeRoleDateFrom(date, role, index)}
                            />
                        </Grid>
                        <Grid item lg={5} sm={5} xs={12}>
                            <KeyboardDatePicker
                                autoOk
                                size='small'
                                clearable
                                keyboard
                                inputVariant="outlined"
                                placeholder={t(`${"dmTool:todateInputPlcHldr"}`)}
                                label={t(`${"dmTool:todateInputFieldLabel"}`)}
                                openTo="month"
                                views={["month", "year"]}
                                format={("MM/YYYY")}
                                value={role.dateTo}
                                InputAdornmentProps={{
                                    position: 'start',
                                }}
                                onChange={(date) => _changeRoleDateTo(date, role, index)}
                            />
                        </Grid>

                        <Grid item lg={5} sm={5} xs={12}>
                            <Autocomplete
                                disableClearable
                                size="small"
                                fullWidth
                                className={classes.autocompleteStyle}
                                getOptionLabel={option => option.label}
                                id="role"
                                margin="normal"
                                onChange={(e, value) => _changeRoleType(e, value, role, index)}
                                options={sspRoleTypeEnumOptions}
                                value={
                                    sspRoleTypeEnumOptions.find(
                                        item =>
                                            item.value ===
                                            role?.roleType,
                                    ) || {}
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t(`${"dmTool:roleInputFieldLabel"}`)}
                                        placeholder={t(`${"dmTool:selectRolePlcHldr"}`)}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item lg={5} sm={5} xs={12}>
                            <Autocomplete
                                disableClearable
                                size="small"
                                fullWidth
                                className={classes.autocompleteStyle}
                                getOptionLabel={option => option.label}
                                id="quality-flag"
                                margin="normal"
                                onChange={(e, value) => _changeQualityFlag(e, value, role, index)}
                                options={sspDataReliabilityEnumOptions}
                                value={
                                    sspDataReliabilityEnumOptions.find(
                                        item =>
                                            item.value ===
                                            role?.i_t_qualitySourceDataQualityEvaluation,
                                    ) || {}
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={t(`${"dmTool:qualityInputFieldLabel"}`) + "*"}
                                        placeholder={t(`${"dmTool:selectQualityPlcHldr"}`)}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item lg={1} sm={1} xs={12}>
                            {renderAddDeleteIcon(index, role)}
                        </Grid>
                    </Grid>
                )
            })}
        </>
    );
};

export default KeypersonRole;