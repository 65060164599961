import SimpleEditor from './Editors/SimpleEditor';
import AsyncValidationEditor from './Editors/AsyncValidationEditor';
import AutoCompleteEditor from './Editors/AutoCompleteEditor';
import DateEditor from './Editors/DateEditor';
import DataSourceSelectEditor from "./Editors/DataSourceSelectEditor";
import StartupCustomerStatusSelectEditor from "./Editors/StartupCustomerStatusSelectEditor";
import InstitutionJurisdictionSelectEditor from "./Editors/InstitutionJurisdictionSelectEditor";
import InstitutionAccountStatusSelectEditor from "./Editors/InstitutionAccountStatusSelectEditor";
import StartupProductStatusSelectEditor from "./Editors/StartupProductStatusSelectEditor";
import StartupIntellectualPropertySelectEditor from "./Editors/StartupIntellectualPropertySelectEditor";
import BusinessTypeSelectEditor from "./Editors/BusinessTypeSelectEditor";
import CompanyTypeSelectEditor from "./Editors/CompanyTypeSelectEditor";
import HashtagsSelectEditor from "./Editors/HashtagsSelectEditor";
import InstitutionDateEditor from "./Editors/InstitutionDateEditor";
import KnowledgeInRolesSelectorEditor from "./Editors/KnowledgeInRolesSelectorEditor";
import StartupLookingForTypesSelectorEditor from "./Editors/StartupLookingForTypesSelectorEditor";

import MyDatePicker from './DatePicker/MyDatePicker';
import ActionsRenderer from './Renderers/ActionsRenderer';
import LastSyncedProRenderer from './Renderers/LastSyncedProRenderer'
import LastSyncedDevRenderer from './Renderers/LastSyncedDevRenderer'
import LastUpdatedLocalRenderer from './Renderers/LastUpdatedLocalRenderer'

import AddRowStatusBar from './StatusBar/AddRowStatusBar';
import MileStoneRenderer from "./Renderers/MileStoneRenderer";
import KeyPersonRenderer from "./Renderers/KeyPersonRenderer";
import AddressRenderer from "./Renderers/AddressRenderer"
import DataSourceSelectRenderer from "./Renderers/DataSourceSelectRenderer";

import StartupCustomerStatusSelectorRenderer from "./Renderers/StartupCustomerStatusSelectorRenderer";
import InstitutionJurisdictionSelectorRenderer from "./Renderers/InstitutionJurisdictionSelectorRenderer";
import InstitutionAccountStatusSelectorRenderer from "./Renderers/InstitutionAccountStatusSelectorRenderer";
import StartupProductStatusSelectorRenderer from "./Renderers/StartupProductStatusSelectorRenderer";
import StartupIntellectualPropertySelectorRenderer from "./Renderers/StartupIntellectualPropertySelectorRenderer";
import BusinessTypeSelectorRenderer from "./Renderers/BusinessTypeSelectorRenderer";
import CompanyTypeSelectorRenderer from "./Renderers/CompanyTypeSelectorRenderer";
import InstitutionWebsiteURLRenderer from "./Renderers/InstitutionWebsiteURLRenderer";
import HashtagsRenderer from "./Renderers/HashtagsRenderer";
import LegalFormRenderer from "./Renderers/LegalFormRenderer";
import InstitutionIdentificationNumberRenderer from "./Renderers/InstitutionIdentificationNumberRenderer";
import InstitutionSocialProfileURLRenderer from "./Renderers/InstitutionSocialProfileURLRenderer";
import KnowledgeInRolesSelectorRenderer from "./Renderers/KnowledgeInRolesSelectorRenderer";
import StartupCrowdfundingFromURLRenderer from "./Renderers/StartupCrowdfundingFromURLRenderer";
import CompanyFocusSectorsRenderer from "./Renderers/CompanyFocusSectorsRenderer";
import InstitutionDescriptionRenderer from "./Renderers/InstitutionDescriptionRenderer";
import StartupLookingForTypesSelectorRenderer from "./Renderers/StartupLookingForTypesSelectorRenderer";
import IntellectualPropertiesRenderer from "./Renderers/IntellectualPropertiesRenderer";
import RatingRenderer from "./Renderers/RatingRenderer";
import SupplierInfo from "./Renderers/SupplierInfo"
import MainPictureUrlRenderer from "./Renderers/MainPictureUrlRenderer";
import InternalMainPictureUrlRenderer from "./Renderers/InternalMainPictureUrlRenderer"
import EntityVideoRenderer from "./Renderers/EntityVideoRenderer";

import ServiceZonesSelectorRenderer from "./Renderers/ServiceZonesSelectorRenderer"
import ServiceZonesSelectorEditor from "./Editors/ServiceZonesSelectorEditor"
import AudienceStartUpPhaseRenderer from "./Renderers/AudienceStartUpPhaseRenderer"
import AudienceStartUpPhaseEditor from "./Editors/AudienceStartUpPhaseEditor"
import OtherServiceOffingRenderer from "./Renderers/OtherServiceOffingRenderer"
import ServiceDescriptionRenderer from "./Renderers/ServiceDescriptionRenderer"
import OtherServiceSectorRenderer from "./Renderers/OtherServiceSectorRenderer"
import ServiceFocusSectorsRenderer from "./Renderers/ServiceFocusSectorsRenderer"
import ServiceZonesRenderer from "./Renderers/ServiceZonesRenderer"
import DocumentsRenderer from "./Renderers/DocumentsRenderer";

import VersionHistoryRenderer from "./Renderers/VersionHistoryRenderer"
import ColumnHeaderComponents from "./Renderers/ColumnHeaderComponents"

import AccountProfilePictureRenderer from "./Renderers/AccountProfilePictureRenderer"
import InternalAccountProfilePictureRenderer from "./Renderers/InternalAccountProfilePictureRenderer"

import InstitutionTypesSelectorRenderer from "./Renderers/InstitutionTypesSelectorRenderer"
import InstitutionTypeSelectorEditor from "./Editors/InstitutionTypeSelectorEditor"

import AdditionalDataSourceInformationSelectorRenderer from "./Renderers/AdditionalDataSourceInformationSelectorRenderer"

import DobOfInstitutionRenderer from "./Renderers/DobOfInstitutionRenderer"

import ServiceStatusSelectorRenderer from "./Renderers/ServiceStatusSelectorRenderer"
import ServiceStatusSelectEditor from "./Editors/ServiceStatusSelectEditor"

export default {
    SimpleEditor,
    AsyncValidationEditor,
    AutoCompleteEditor,
    DataSourceSelectEditor,
    InstitutionDateEditor,
    DateEditor,
    StartupCustomerStatusSelectEditor,
    InstitutionJurisdictionSelectEditor,
    InstitutionAccountStatusSelectEditor,
    StartupProductStatusSelectEditor,
    StartupIntellectualPropertySelectEditor,
    BusinessTypeSelectEditor,
    CompanyTypeSelectEditor,
    HashtagsSelectEditor,
    KnowledgeInRolesSelectorEditor,
    StartupLookingForTypesSelectorEditor,

    StartupCrowdfundingFromURLRenderer,
    CompanyFocusSectorsRenderer,
    InstitutionDescriptionRenderer,
    StartupLookingForTypesSelectorRenderer,
    IntellectualPropertiesRenderer,


    MyDatePicker,
    ActionsRenderer,
    LastSyncedProRenderer,
    LastSyncedDevRenderer,
    LastUpdatedLocalRenderer,

    AddRowStatusBar,
    MileStoneRenderer,
    KeyPersonRenderer,
    AddressRenderer,
    DataSourceSelectRenderer,
    RatingRenderer,
    SupplierInfo,
    MainPictureUrlRenderer,
    InternalMainPictureUrlRenderer,
    EntityVideoRenderer,
    StartupCustomerStatusSelectorRenderer,
    InstitutionJurisdictionSelectorRenderer,
    InstitutionAccountStatusSelectorRenderer,
    StartupProductStatusSelectorRenderer,
    StartupIntellectualPropertySelectorRenderer,
    BusinessTypeSelectorRenderer,
    CompanyTypeSelectorRenderer,
    InstitutionWebsiteURLRenderer,
    HashtagsRenderer,
    LegalFormRenderer,
    InstitutionIdentificationNumberRenderer,
    InstitutionSocialProfileURLRenderer,
    KnowledgeInRolesSelectorRenderer,

    ServiceZonesSelectorRenderer,
    ServiceZonesSelectorEditor,
    AudienceStartUpPhaseRenderer,
    AudienceStartUpPhaseEditor,
    OtherServiceOffingRenderer,
    ServiceDescriptionRenderer,
    OtherServiceSectorRenderer,
    ServiceFocusSectorsRenderer,
    ServiceZonesRenderer,
    DocumentsRenderer,

    VersionHistoryRenderer,
    ColumnHeaderComponents,
    AccountProfilePictureRenderer,
    InternalAccountProfilePictureRenderer,
    InstitutionTypesSelectorRenderer,
    InstitutionTypeSelectorEditor,
    AdditionalDataSourceInformationSelectorRenderer,


    DobOfInstitutionRenderer,

    ServiceStatusSelectorRenderer,
    ServiceStatusSelectEditor
}