import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { authenticationService } from "./../../Services";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { updateUserInfo } from "../../Store/User/actions"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),

    },
    title: {
        marginLeft: theme.spacing(2),
        flexGrow: 1,
    },
}));

const Topbar = (props) => {
    const inputRef = useRef();
    const { t } = useTranslation([
        "translation",
        "dmTool"
    ])
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [user, setUser] = React.useState(props.User);
    const open = Boolean(anchorEl);

    const handleChange = (event) => {
        // setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {

        authenticationService.logout()
    }

    const handleClickProfile = () => {
        props.history.push("/admin-operations");
    }
    React.useEffect(() => {
        if (props.currentUser) {
            //call 
            props.updateUserInfo && props.updateUserInfo()
            // const httpRequest = new XMLHttpRequest();
            // httpRequest.open('POST', process.env.PUBLIC_URL + `/me`);
            // httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            // httpRequest.send(JSON.stringify());
            // httpRequest.onreadystatechange = () => {
            //     if (httpRequest.readyState === 4 && httpRequest.status === 200) {
            //         let res = JSON.parse(httpRequest.responseText);
            //         if (res.status == "success") {
            //             setUser(res.data)
            //         } else {
            //             authenticationService.logout()
            //         }
            //     }
            // };
        }
    }, [props.currentUser])
    React.useEffect(() => {
        setUser(props.User.userInfo)
    }, [props.User.userInfo])
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <img src="https://www.startupspider.com/assets/images/logo-transparent-startupspider-1-182x182.png" />
                    <Typography variant="h6" className={classes.title}>{t(`${"dmTool:dataManagementToolHdng"}`)}</Typography>
                    <div>
                        {!!props.currentUser && (user.firstName + " " + user.lastName)}
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            onMouseEnter={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ onMouseLeave: handleClose }}
                        >
                            <MenuItem onClick={handleClickProfile}>{t(`${"dmTool:profileMenuOptionHdng"}`)}</MenuItem>
                            {props.currentUser && <MenuItem onClick={handleLogout}>{t(`${"dmTool:logoutMenuOptionHdng"}`)}</MenuItem>}
                            <MenuItem onClick={handleClose}>{t(`${"dmTool:myAccountMenuOptionHdng"}`)}</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div >
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        User: state.User
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUserInfo: (payload) => dispatch(updateUserInfo(payload)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);