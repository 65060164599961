import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import { sspLanguageEnum } from '../../../utils/enums';
import { useTranslation } from 'react-i18next';
import UILanguageSelector from '../../Languages';
import languages from "../../Languages/languages"

const useStyles = makeStyles(theme => ({

}));

const IntellectualPropertyLink = props => {
    const { t } = useTranslation([
        "translation",
        "configurationEnumerations",
        "dmTool"
    ]);
    const { intellectualPropertyObj, OnChangeIntellectualPropertyObjLink, OnAddOrDeleteIntellectualPropertyObjLink } = props;
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [languageOptions, setLanguageOptions] = useState([]);

    useEffect(() => {
        let keys = props.intellectualPropertyObj.link.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!!props.intellectualPropertyObj.link.length && !!keys.length) {
            let langs = [];
            let filterLang = sspLanguageEnum.map((lang) => {
                if (!keys?.includes(lang.value)) {
                    langs.push(lang);
                }
            });
            setLanguageOptions(langs)
        } else {
            setLanguageOptions(sspLanguageEnum);
        }
    }, [props.intellectualPropertyObj.link])


    const OnChangeLinkKeyChange = (e, value, index) => {
        OnChangeIntellectualPropertyObjLink('key', value.value, index)
    }

    const OnChangeLinkValueChange = (e, index) => {
        OnChangeIntellectualPropertyObjLink('value', e.target.value, index)
    }
    const addIntellectualPropertyLink = (e) => {
        let keys = props.intellectualPropertyObj.link.filter((item) => item.key && item.key !== '').map(item => item.key);
        if (!(keys.length === Object.keys(languages).length)) {
            if (!keys.length) {
                enqueueSnackbar(t(`${"dmTool:selectLangToAddNewErrorMsg"}`), {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            } else {
                OnAddOrDeleteIntellectualPropertyObjLink({
                    key: '',
                    value: ''
                }, 'add')
            }
        } else {
            enqueueSnackbar(t("dmTool:duplicateLangNotAllowedErrorMsg", { type: t(`${"dmTool:link"}`) }), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            })
        }
    }

    const removeLinkRow = (e, index) => {
        let filerTitle = intellectualPropertyObj?.link
        filerTitle.splice(index, 1);

        if (!filerTitle.length) {
            enqueueSnackbar(t("dmTool:fieldShldHaveAtleastOneEntryErrorMsg", { type: t(`${"dmTool:iplink"}`) }), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        } else {
            OnAddOrDeleteIntellectualPropertyObjLink(
                filerTitle,
                'delete',
            );

            enqueueSnackbar(t("dmTool:removingEntrySuccessMsg", { type: t(`${"dmTool:iplink"}`) }), {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });

        }
    };


    const renderAddCloseIcon = (index) => {

        if (index === 0) {
            return (
                <span style={{ display: 'flex' }}>
                    <Tooltip title={t(`${"dmTool:addIpLinkTooltip"}`)}>
                        <IconButton aria-label="Add more intellectual property link"
                            onClick={addIntellectualPropertyLink}
                        >
                            <AddIcon className={classes.icons} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={t(`${"dmTool:removeIpLinkTooltip"}`)}>
                        <IconButton
                            aria-label="settings"
                            onClick={(e) => removeLinkRow(e, index)}
                        >
                            <CloseIcon className={classes.icons} />
                        </IconButton>
                    </Tooltip>
                </span>
            );
        }
        return (
            <Tooltip title={t(`${"dmTool:removeIpLinkTooltip"}`)}>
                <IconButton
                    aria-label="settings"
                    onClick={(e) => removeLinkRow(e, index)}
                >
                    <CloseIcon className={classes.icons} />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        intellectualPropertyObj?.link?.map((linkObj, index) => {
            return (
                <React.Fragment key={"intellectual_property" + index}>
                    <Grid item lg={4} sm={4} xs={12}>
                        {/* <Autocomplete
                            disableClearable
                            size="small"
                            fullWidth
                            className={classes.autocompleteStyle}
                            getOptionLabel={option => option.label}
                            id="intellectual_property_select_lang"
                            margin="normal"
                            onChange={(e, value) => OnChangeLinkKeyChange(e, value, index)}
                            options={languageOptions}
                            value={
                                sspLanguageEnum.find(
                                    item =>
                                        item.value ===
                                        linkObj?.key,
                                ) || {}
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={t(`${"dmTool:languageInputFiledHdng"}`)}
                                    placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        /> */}
                        <UILanguageSelector
                            selectedValues={intellectualPropertyObj?.link}
                            label={t(`${"dmTool:languageInputFiledHdng"}`) + "*"}
                            placeholder={t(`${"dmTool:selectLangPlcHldr"}`)}
                            value={linkObj?.key}
                            onChange={(e, value) => {
                                OnChangeLinkKeyChange(e, value, index)
                            }
                            }
                        />
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12}>
                        <TextField
                            className={classes.marginSet}
                            fullWidth
                            label={t(`${"dmTool:enterIpLinkInputFieldLabel"}`)}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                            placeholder={t(`${"dmTool:enterIpLinkPlcHldr"}`)}
                            onChange={(e) => OnChangeLinkValueChange(e, index)}
                            size='small'
                            required={false}
                            value={linkObj?.value}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item lg={2} sm={2} xs={12}>
                        {renderAddCloseIcon(index)}
                    </Grid>
                </React.Fragment>
            )
        })
    );
};

export default IntellectualPropertyLink;