import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 13,
    },
    topbuttons: {
        display: "flex",
        justifyContent: "space-between"
    },
    milestones: {
        overflow: 'auto',
        maxHeight: 215,
    },
    milestone: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    }

}));

const InternalAccountProfilePictureRenderer = (props) => {
    const classes = useStyles();
    const [_accountProfileImageUrl, _setAccountProfileImageUrl] = useState(props.value)



    return (
        <div className={classes.root}>
            <div className={classes.milestones}>
                {_accountProfileImageUrl}
                {/* {_accountProfileImageUrl && <img src={_accountProfileImageUrl} width={200} height={200} style={{ background: "#dbd7d7" }} />} */}
            </div>
        </div>
    )
}

export default (InternalAccountProfilePictureRenderer);